import React from 'react';
import { Control, FieldErrors } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { ListDivider, Button, Typography } from 'rmwc';
import { TextQuestion } from '../FieldTypes/TextQuestion';

export function TaskBlockType({
  control,
  block,
  index,
  errors,
  isLoading,
  handleRemoveBlock
}: {
  control: Control;
  block: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  index: number;
  errors: FieldErrors;
  isLoading: boolean;
  handleRemoveBlock: (index: number, name: string) => void;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ padding: '0 10px' }}>
        <Typography use="body1" tag="p" style={{ margin: '0', padding: '10px 0', display: 'inline-block' }}>
          <Trans i18nKey="journey-step.blocks.task.title">Task</Trans>
        </Typography>
        <Button
          trailingIcon="delete_forever"
          onClick={(): void => handleRemoveBlock(index, `${t('journey-step.blocks.task.add-button')}`)}
          style={{ float: 'right', marginTop: '4px' }}
        >
          <Trans i18nKey="journey-step.form.blocks.delete-button">Delete</Trans>
        </Button>
      </div>
      <ListDivider />
      <div style={{ padding: '10px' }}>
        <TextQuestion
          control={control}
          property="start_button"
          labelProps={{ labelText: 'Email button label', htmlFor: `message_blocks.${index}.start_button`, i18nKey: 'journey-step.form.blocks.task.start_button.label' }}
          controllerName={`message_blocks.${index}.start_button`}
          invalid={errors?.message_blocks?.[index]?.start_button?.message}
          requiredProps={{ i18nKey: 'journey-step.form.blocks.task.start_button.required', requiredText: 'Email button label is required.' }}
          block={block}
          isLoading={isLoading}
          placeholder={t('journey-step.form.blocks.task.start_button.placeholder')}
        />

        <TextQuestion
          control={control}
          property="question"
          labelProps={{ labelText: 'Task', htmlFor: `message_blocks.${index}.question`, i18nKey: 'journey-step.form.blocks.task.question.label' }}
          controllerName={`message_blocks.${index}.question`}
          invalid={errors?.message_blocks?.[index]?.question?.message}
          requiredProps={{ i18nKey: 'journey-step.form.blocks.task.question.required', requiredText: 'Task is required' }}
          block={block}
          isLoading={isLoading}
          placeholder={t('journey-step.form.blocks.task.question.placeholder')}
        />
      </div>
    </>
  );
}
