import HttpRepository from '../../../services/HttpRepository/HttpRepository';

export enum ReportDashboardType {
  HOME = 'home',
  SURVEYS = 'surveys',
  MESSAGES = 'messages',
  TURNOVER = 'turnover'
}

export interface ReportDashboard {
  link: string;
}

class ReportsDashboardsRepository extends HttpRepository<ReportDashboard> {
  constructor() {
    super({ basePath: '/reports/dashboard' });
  }
}

export default ReportsDashboardsRepository;
