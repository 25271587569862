import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Grid, GridRow, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Dialog, DialogTitle, DialogContent, DialogActions, DialogButton } from '@rmwc/dialog';
import '@rmwc/dialog/styles';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { Typography } from '@rmwc/typography';
import '@rmwc/typography/styles';

import { Select } from '@rmwc/select';
import '@rmwc/select/styles';

import { Employee } from './EmployeeRepository';
import { Journey } from '../Journey/JourneyRepository';
import JourneyService from '../Journey/JourneyService';
import ErrorMessage from '../ErrorMessage';
import LoadingModal from '../LoadingModal';

function EmployeeStartJourneyDialog({ employee, onClose }: { employee: Employee; onClose: () => void }): JSX.Element {
  const [open, setOpen] = React.useState(true);
  const [journeyId, setJourneyId] = React.useState('');

  const employeeFirstName = employee.social_name || employee.first_name;

  const journeyService = new JourneyService();
  const { data, isFetching, isError, error } = journeyService.useList();

  function onCloseDialog() {
    setOpen(false);
    onClose && onClose();
    return true;
  }

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching) {
    return <LoadingModal open={true} />;
  }

  const journeysOptions = data?.map((journey: Journey) => ({
    label: journey.name,
    value: journey.id
  }));

  const startJourneyUrl = journeyId ? `/employees/${employee.id}/start/${journeyId}` : '#';

  return (
    <Dialog open={open} onClosed={onCloseDialog}>
      <DialogTitle>
        <Typography use="headline6">
          <Trans i18nKey="employee.start-journey.title">Start a journey for {{ employeeFirstName }}</Trans>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid>
          <GridRow style={{ height: '100%' }}>
            <GridCell span={12}>
              <Typography use="body1" tag="p">
                <label htmlFor="journey-id">
                  <Trans i18nKey="employee.start-journey.form.journey">Select a journey to start</Trans>
                </label>
              </Typography>
              <Select
                id="journey-id"
                onChange={(event: { currentTarget: { value: string } }) => setJourneyId(event.currentTarget.value)}
                options={journeysOptions}
                style={{
                  minWidth: '100%',
                  backgroundColor: 'whitesmoke'
                }}
              />
              <Typography use="caption">
                <Trans i18nKey="employee.start-journey.form.journey-help">You will review the steps in the next screen</Trans>
              </Typography>
            </GridCell>
          </GridRow>
        </Grid>
      </DialogContent>
      <DialogActions>
        <DialogButton action="close" style={{ marginRight: '10px' }}>
          <Trans i18nKey="crud.cancel">Cancel</Trans>
        </DialogButton>
        <Button disabled={!journeyId} raised icon="auto_graph" tag={Link} {...{ to: startJourneyUrl }}>
          <Trans i18nKey="employee.start-journey.form.submit">Review steps</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

EmployeeStartJourneyDialog.propTypes = {
  employee: PropTypes.object,
  onClose: PropTypes.func
};

export default EmployeeStartJourneyDialog;
