import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './StepJourneyPreview.module.css';

import { UserAuthContext } from '../common/Contexts/appContexts';

import { StepJourney, StepJourneyChannel } from './StepJourneyRepository';
import StepJourneyService from './StepJourneyService';

import parseExtractHashId from '../../services/HttpRepository/parseExtractHashId';

import Slack from '../Settings/IntegrationSettings/Icons/slack.png';
import Whatsapp from '../Settings/IntegrationSettings/Icons/whatsapp.png';
import Message from '../../services/Message';
import EllaMessage from '../common/EllaMessage';
import StepJourneyMessagePreview from './StepJourneyMessagePreview';

import { Grid, GridRow, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Dialog, DialogTitle, DialogContent, DialogActions, DialogButton } from '@rmwc/dialog';
import '@rmwc/dialog/styles';

import { Typography } from '@rmwc/typography';
import '@rmwc/typography/styles';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/circular-progress.css';

import { Icon } from '@rmwc/icon';
import '@rmwc/icon/styles';

function StepJourneyPreviewDialog({ step, onClose }: { step: StepJourney; onClose: () => void }): JSX.Element {
  const [open, setOpen] = React.useState(true);

  const journeyId = step.journey_id || '';
  const stepId = step.id;

  function onCloseDialog() {
    setOpen(false);
    onClose && onClose();
    return true;
  }

  return (
    <Dialog open={open} onClosed={onCloseDialog} className={styles.previewDialog}>
      <DialogTitle>
        <Typography use="headline6">{step.name}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid>
          <GridRow style={{ height: '100%' }}>
            <StepJourneyPreviewSendInstruction journeyId={step.journey_id} stepId={step.id} messageChannel={step.channel} />
            <StepJourneyMessagePreview
              loadFromFormEdit={false}
              journeyId={journeyId}
              stepId={stepId}
              messageChannel={step.channel}
              messageToWhom={step.to_whom}
              messageToWhomCopy={step.to_whom_copy}
              messageSubject={step.subject}
              messageHtml={step.message_html || ''}
              messageBlocks={step.message_blocks}
              whatsappTemplate={step.whatsapp_template}
            />
          </GridRow>
        </Grid>
      </DialogContent>
      <DialogActions>
        <DialogButton action="close" style={{ marginRight: '10px' }}>
          <Trans i18nKey="crud.close">Close</Trans>
        </DialogButton>
        <Button raised icon="edit" tag={Link} {...{ to: `/journeys/${step.journey_id}/steps/${parseExtractHashId(step.id || '')}/edit` }}>
          <Trans i18nKey="journey.step.edit">Edit</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function StepJourneyPreviewSendInstruction({
  journeyId,
  stepId,
  messageChannel
}: {
  journeyId: string | undefined;
  stepId: string | undefined;
  messageChannel: string | undefined;
}): JSX.Element {
  const { t } = useTranslation();
  const userAuthValue = React.useContext(UserAuthContext);
  const stepJourneyService = new StepJourneyService(journeyId || '');
  const useStepSendPreviewTest = stepJourneyService.useSendPreviewTest();

  async function onClickSendPreviewTest() {
    try {
      await useStepSendPreviewTest.mutateAsync(stepId || '');
      Message.success(t('journey-step.preview.dialog.preview-sent'));
    } catch (error) {
      console.error('Error on step sending preview test: ', error);
      Message.error(t('journey-step.preview.dialog.preview-sent-error'));
    }
  }
  return (
    <>
      <GridCell span={12} align="middle">
        <EllaMessage title={t('journey-step.preview.dialog.title')} message={t('journey-step.preview.dialog.text')} />
      </GridCell>
      <GridCell span={12} style={{ textAlign: 'center' }}>
        <Button
          raised
          icon={useStepSendPreviewTest.isLoading ? <CircularProgress theme="secondary" /> : <MessageChannelIcon messageChannel={messageChannel} />}
          onClick={onClickSendPreviewTest}
          disabled={useStepSendPreviewTest.isLoading}
        >
          <Trans i18nKey="journey-step.preview.dialog.send">Send step test to</Trans> {userAuthValue.given_name} ({userAuthValue.email})
        </Button>
      </GridCell>
    </>
  );
}

function MessageChannelIcon({ messageChannel }: { messageChannel: string | undefined }): JSX.Element {
  if (messageChannel === StepJourneyChannel.Slack) {
    return <Icon icon={{ icon: Slack, size: 'xsmall' }} aria-hidden="true" style={{ filter: 'brightness(0) invert(1)' }} />;
  } else if (messageChannel === StepJourneyChannel.Whatsapp) {
    return <Icon icon={{ icon: Whatsapp, size: 'xsmall' }} aria-hidden="true" style={{ filter: 'brightness(0) invert(1)' }} />;
  }

  return <Icon icon={{ icon: 'forward_to_inbox', size: 'xsmall' }} aria-hidden="true" />;
}

StepJourneyPreviewDialog.propTypes = {
  step: PropTypes.object,
  onClose: PropTypes.func
};

export default StepJourneyPreviewDialog;
