import HttpRepository from '../../../services/HttpRepository/HttpRepository';

export interface AdminUser {
  id?: string;
  email?: string;
  name?: string;
  cognito_username?: string;
  send_invite?: boolean;
  invited_by_name?: string;
}

export interface AdminUserPortalLink {
  url: string;
}

class AdminUsersRepository extends HttpRepository<AdminUser> {
  constructor() {
    super({ basePath: '/users' });
  }

  public async getPortalLink(): Promise<AdminUserPortalLink> {
    const result = await super.getPath('portal/link');
    return result.data;
  }
}

export default AdminUsersRepository;
