import HttpRepository from '../../services/HttpRepository/HttpRepository';

export interface EmployeeHistory {
  id?: string;
  createdAt?: string;
  operation_type?: string;
  data?: string;
  origin_data?: string;
  user_id?: string;
  user_name?: string;
  user_email?: string;
  user_ip: string;
}

class EmployeeHistoryRepository extends HttpRepository<EmployeeHistory> {
  constructor(employeeId: string) {
    super({ basePath: `/history/people/${employeeId}` });
  }
}

export default EmployeeHistoryRepository;
