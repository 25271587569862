import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TabBar, Tab } from '@rmwc/tabs';
import '@rmwc/tabs/styles';

import { ListDivider } from 'rmwc';
import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { BadgeAnchor, Badge } from '@rmwc/badge';
import '@rmwc/badge/styles';

import Breadcrumb from '../common/Breadcrumb';
import ErrorMessage from '../ErrorMessage';
import LoadingModal from '../LoadingModal';
import EmployeeService from './EmployeeService';
import EmployeeViewHome from './EmployeeViewHome';
import EmployeeViewForms from './EmployeeViewForms';
import EmployeeViewSurveys from './EmployeeViewSurveys';
import EmployeeViewProgress from './EmployeeViewProgress';
import EmployeeViewHistory from './EmployeeViewHistory';
import EmployeeViewDetails from './EmployeeViewDetails';
import EmployeeViewLogs from './EmployeeViewLogs';

enum SETTINGS_TABS {
  EMPLOYEE = 0,
  DETAILS = 1,
  PROGRESS = 2,
  FORMS = 3,
  SURVEYS = 4,
  HISTORY = 5,
  LOGS = 6
}

function tabIndexFromType(type: string): number {
  switch (type) {
    case 'employee':
      return SETTINGS_TABS.EMPLOYEE;
    case 'details':
      return SETTINGS_TABS.DETAILS;
    case 'progress':
      return SETTINGS_TABS.PROGRESS;
    case 'forms':
      return SETTINGS_TABS.FORMS;
    case 'surveys':
      return SETTINGS_TABS.SURVEYS;
    case 'history':
      return SETTINGS_TABS.HISTORY;
    case 'logs':
      return SETTINGS_TABS.LOGS;
    default:
      break;
  }
  return SETTINGS_TABS.EMPLOYEE;
}

function EmployeeView({ history }: InferProps<typeof EmployeeView.propTypes>): JSX.Element {
  const { t } = useTranslation();
  const { id, tab } = useParams() as { id: string; tab: string };
  const currentActiveTabIndex = tabIndexFromType(tab);
  const [activeTabIndex, setActiveTabIndex] = React.useState<number>(currentActiveTabIndex);

  React.useEffect(() => {
    const currentActiveTabIndex = tabIndexFromType(tab);
    setActiveTabIndex(currentActiveTabIndex);
  }, [tab]);

  const employeeService = new EmployeeService();
  const { data, isFetching, isError, error } = employeeService.useGet(id);

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching) {
    return <LoadingModal open={true} />;
  }

  function tabNameFromIndex(activeTabIndex: number): string {
    switch (activeTabIndex) {
      case SETTINGS_TABS.EMPLOYEE:
        return t('employee.view.first_tab_view');
      case SETTINGS_TABS.DETAILS:
        return t('employee.view.tab_details');
      case SETTINGS_TABS.PROGRESS:
        return t('employee.view.tab_progress');
      case SETTINGS_TABS.FORMS:
        return t('employee.view.tab_forms');
      case SETTINGS_TABS.SURVEYS:
        return t('employee.view.tab_surveys');
      case SETTINGS_TABS.HISTORY:
        return t('employee.view.tab_history');
      case SETTINGS_TABS.LOGS:
        return t('employee.view.tab_logs');
      default:
        break;
    }
    return t('employee.view.first_tab_view');
  }

  return (
    <>
      <Grid>
        <GridCell span={12}>
          <Breadcrumb
            routeSegments={[
              { name: t('navlink.employees'), path: '/employees' },
              { name: t('employee.view-title') },
              { name: tabNameFromIndex(activeTabIndex) },
              { name: `${data?.social_name || data?.first_name} ${data?.last_name}` }
            ]}
          />
        </GridCell>
      </Grid>
      <TabBar activeTabIndex={activeTabIndex} onActivate={(event): void => setActiveTabIndex(event.detail.index)}>
        <Tab minWidth label={t('employee.view.first_tab_view')} />
        <Tab minWidth label={t('employee.view.tab_details')} />
        <Tab minWidth label={t('employee.view.tab_progress')} />
        <Tab minWidth>
          <BadgeAnchor>
            {t('employee.view.tab_forms')}
            {data && data.form_answers && data.form_answers.length && (
              <Badge label={data.form_answers.length} style={{ right: '-1.6rem', transform: 'scale(0.7)', fontSize: '0.9rem' }} theme={['secondaryBg', 'onSecondary']} />
            )}
          </BadgeAnchor>
        </Tab>
        <Tab minWidth>
          <BadgeAnchor>
            {t('employee.view.tab_surveys')}
            {data && data.survey_answers && data.survey_answers.length && (
              <Badge label={data.survey_answers.length} style={{ right: '-1.6rem', transform: 'scale(0.7)', fontSize: '0.9rem' }} theme={['secondaryBg', 'onSecondary']} />
            )}
          </BadgeAnchor>
        </Tab>
        <Tab minWidth label={t('employee.view.tab_history')} />
        <Tab minWidth label={t('employee.view.tab_logs')} />
      </TabBar>
      <ListDivider />
      <EmployeeViewContent history={history} activeTabIndex={activeTabIndex} employee={data} />
    </>
  );
}

function EmployeeViewContent({ history, activeTabIndex, employee }: InferProps<typeof EmployeeViewContent.propTypes>): JSX.Element {
  const onGoBack = async (): Promise<void> => {
    history && history.goBack();
  };

  if (!employee) {
    return <></>;
  }

  switch (activeTabIndex) {
    case SETTINGS_TABS.EMPLOYEE:
      return <EmployeeViewHome employee={employee} onGoBack={onGoBack} />;
    case SETTINGS_TABS.DETAILS:
      return <EmployeeViewDetails employee={employee} />;
    case SETTINGS_TABS.PROGRESS:
      return <EmployeeViewProgress employee={employee} />;
    case SETTINGS_TABS.FORMS:
      return <EmployeeViewForms employee={employee} />;
    case SETTINGS_TABS.SURVEYS:
      return <EmployeeViewSurveys employee={employee} />;
    case SETTINGS_TABS.HISTORY:
      return <EmployeeViewHistory employee={employee} />;
    case SETTINGS_TABS.LOGS:
      return <EmployeeViewLogs employee={employee} />;
  }
  return <EmployeeViewHome employee={employee} onGoBack={onGoBack} />;
}

EmployeeViewContent.propTypes = {
  history: PropTypes.any,
  activeTabIndex: PropTypes.number,
  employee: PropTypes.any
};

EmployeeView.propTypes = {
  history: PropTypes.any
};

export default withRouter(EmployeeView);
