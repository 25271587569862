import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import tanslationPT from './locales/pt/translation.json';
import translationEN from './locales/en/translation.json';

i18n.use(initReactI18next).init({
  resources: {
    pt: {
      translation: tanslationPT
    },
    en: {
      translation: translationEN
    }
  },
  lng: 'pt',
  fallbackLng: 'pt',
  debug: false,
  interpolation: {
    escapeValue: false
  }
});
