import React from 'react';

import { SnackbarQueue } from '@rmwc/snackbar';
import '@material/snackbar/dist/mdc.snackbar.css';
import '@material/button/dist/mdc.button.css';
import '@material/ripple/dist/mdc.ripple.css';

import './MessagesSnackbar.css';

import messagesSnackbarQueue from './MessagesSnackbarQueue';

function MessagesSnackbar(): JSX.Element {
  return <SnackbarQueue messages={messagesSnackbarQueue.messages} />;
}

export default MessagesSnackbar;
