import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import Ella from './ella.svg';

import { Avatar } from '@rmwc/avatar';
import '@rmwc/avatar/avatar.css';

function AvatarImage({ avatarName, avatarUrl, avatarSize }: InferProps<typeof AvatarImage.propTypes>): JSX.Element {
  const imageSrc = avatarUrl ? avatarUrl : Ella;
  const imageDescription = avatarName ? avatarName : 'Ella';

  return <Avatar name={imageDescription} size="xlarge" src={imageSrc} {...(avatarSize ? { style: { fontSize: avatarSize } } : {})} />;
}

AvatarImage.propTypes = {
  avatarName: PropTypes.string,
  avatarUrl: PropTypes.string,
  avatarSize: PropTypes.string
};

export default AvatarImage;
