import HttpRepository from '../../services/HttpRepository/HttpRepository';

export interface TextAnalysis {
  error: string;
  accessibility: Accessibility[];
  readability: Readability;
}

export interface Accessibility {
  name: string;
  contents: string[];
}

export interface Readability {
  score: number;
  totalWords: number;
  totalSentences: number;
  readingTime: number;
  totalCharacters: number;
}

const INBOARDING_EDITOR_URL = process.env.REACT_APP_INBOARDING_EDITOR_URL;

class EditorRepository extends HttpRepository<TextAnalysis> {
  constructor() {
    super({ baseURL: INBOARDING_EDITOR_URL, basePath: '/text/analysis' });
  }

  public async postText(data: string): Promise<TextAnalysis> {
    const body = {
      data: data
    };
    const result = await super.post(body);
    return result.data;
  }
}

export default EditorRepository;
