import HttpRepository from '../../../services/HttpRepository/HttpRepository';
import { Employee } from '../../Employee/EmployeeRepository';

class EmployeeFormAnswersRepository extends HttpRepository<Employee> {
  constructor() {
    super({ basePath: '/reports/people/form-answers' });
  }
}

export default EmployeeFormAnswersRepository;
