import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/circular-progress.css';

import { ListDivider } from '@rmwc/list';
import { TabBar, Tab } from '@rmwc/tabs';
import '@rmwc/tabs/styles';

import { Cell } from 'react-table';

import SimpleTable from '../../Table/SimpleTable';
import LoadingModal from '../../LoadingModal';
import ErrorMessage from '../../ErrorMessage';
import Breadcrumb from '../../common/Breadcrumb';

import MessagesDeliveryService from './MessagesDeliveryService';
import MetabaseAnalytics from '../../MetabaseAnalytics';

import { EmployeeJourneyStep, StepEmailMessage, StepSlackMessage, StepWhatsappMessage } from '../../Employee/EmployeeRepository';
import { dateTimeFormat } from '../../common/DatesFormat/dateTimeFormat';
import { dateFormatWeekdayText } from '../../common/DatesFormat/dateFormatWeekdayText';
import { EmployeeStepStatusCell } from '../../Employee/EmployeeViewProgress';
import { StepJourneyChannel } from '../../Journey/StepJourneyRepository';
import { StepJourneyChannelImage } from '../../Journey/StepJourneyMessagePreview';
import { CompanyConfigurationContext } from '../../common/Contexts/appContexts';
import { ReportDashboardType } from './ResportsDashboardsRepository';

const LIST_SIZE = 50;

function MessagesDelivery(): JSX.Element {
  const { t } = useTranslation();
  const [activeTabIndex, setActiveTabIndex] = React.useState<number>(0);

  return (
    <>
      <Grid>
        <GridCell span={12}>
          <Breadcrumb routeSegments={[{ name: t('navlink.analytics') }, { name: t('navlink.analytic.messages') }]} />
        </GridCell>
        <GridCell span={12} align="middle">
          <h2>
            <Trans i18nKey="analytics.messages-delivery.title">Messages delivery</Trans>
          </h2>
        </GridCell>
      </Grid>
      <TabBar activeTabIndex={activeTabIndex} onActivate={(event): void => setActiveTabIndex(event.detail.index)}>
        <Tab minWidth icon="list" label={t('navlink.analytic.report-list')} />
        <Tab minWidth icon="bar_chart" label={t('navlink.analytic.report-dashboard')} />
      </TabBar>
      <ListDivider />
      {activeTabIndex === 0 ? <MessagesDeliveryList /> : null}
      {activeTabIndex === 1 ? <MessagesDeliveryAnalytics /> : null}
    </>
  );
}

function MessagesDeliveryList(): JSX.Element {
  const messagesDeliveryService = new MessagesDeliveryService();

  const searchFilter = { limit: LIST_SIZE, query: '' };

  const { data, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage, isError, error } = messagesDeliveryService.useListSearch(searchFilter, LIST_SIZE);

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching && !isFetchingNextPage && !searchFilter.query) {
    return <LoadingModal open={true} />;
  }

  const reportData = data?.pages.flat();
  const dataLength = reportData ? reportData.length : 0;

  return (
    <Grid>
      <GridCell span={12}>
        <MessagesDeliveryTable journeySteps={reportData}></MessagesDeliveryTable>
        {!dataLength && (
          <p>
            {isFetching ? (
              <CircularProgress theme="secondary" />
            ) : (
              <>
                <Trans i18nKey="analytics.no-data-search">No data found.</Trans>
              </>
            )}
          </p>
        )}
      </GridCell>

      {hasNextPage && (
        <GridCell span={12} style={{ textAlign: 'center' }}>
          <Button
            outlined
            {...(isFetching ? { icon: <CircularProgress theme="secondary" /> } : {})}
            disabled={isFetching}
            onClick={(): void => {
              fetchNextPage();
            }}
          >
            <Trans i18nKey="analytics.load-more">Load More</Trans>
          </Button>
        </GridCell>
      )}
    </Grid>
  );
}

function MessagesDeliveryAnalytics(): JSX.Element {
  const companyConfigurationValue = React.useContext(CompanyConfigurationContext);
  const dashboardId = companyConfigurationValue.dashboard_messages_id;

  if (dashboardId) {
    return <MetabaseAnalytics dashboardName={ReportDashboardType.MESSAGES} showPrintExport={true} />;
  }

  return (
    <Grid>
      <GridCell span={12}>
        <Trans i18nKey="analytics.no-data-search">No data found.</Trans>
      </GridCell>
    </Grid>
  );
}

function MessageChannelCell({ cell }: { cell: Cell }): JSX.Element {
  const stepItem = cell.row.original as EmployeeJourneyStep;
  const stepChannel = stepItem?.step_channel || StepJourneyChannel.Email;
  return <StepJourneyChannelImage channel={stepChannel} />;
}

function EmployeeEmailCell({ cell }: { cell: Cell }): JSX.Element {
  return <EmployeeEmailCellComponent employeeJourneyStep={cell.row.original as EmployeeJourneyStep}></EmployeeEmailCellComponent>;
}

function EmployeeEmailCellComponent({ employeeJourneyStep }: { employeeJourneyStep: EmployeeJourneyStep }): JSX.Element {
  const toDestination = employeeDestinationToName(employeeJourneyStep);
  return (
    <div>
      <NavLink to={`/employees/${employeeJourneyStep.people_id}`} target="_blank">
        {toDestination}
      </NavLink>
    </div>
  );
}

function employeeDestinationToName(employeeJourneyStep: EmployeeJourneyStep): string {
  switch (employeeJourneyStep.step_channel) {
    case StepJourneyChannel.Email: {
      const sentMessage = employeeJourneyStep.message_sent as StepEmailMessage;
      if (sentMessage?.to?.length) {
        return sentMessage?.to?.[0];
      }
      break;
    }

    case StepJourneyChannel.Whatsapp: {
      const sentMessage = employeeJourneyStep.message_sent as StepWhatsappMessage;
      if (sentMessage?.to?.phoneNumber) {
        return sentMessage?.to?.phoneNumber;
      }
      break;
    }

    case StepJourneyChannel.Slack: {
      const sentMessage = employeeJourneyStep.message_sent as StepSlackMessage;
      if (sentMessage?.username) {
        return sentMessage?.username;
      }
      break;
    }
  }

  return employeeJourneyStep.people_id;
}

function MessageScheduleDateCell({ cell }: { cell: Cell }): JSX.Element {
  const stepItem = cell.row.original as EmployeeJourneyStep;
  return (
    <>
      <div>{dateTimeFormat(stepItem.schedule_date)}</div>
      <div>{dateFormatWeekdayText(stepItem.schedule_date)}</div>
    </>
  );
}

function MessageSentDateCell({ cell }: { cell: Cell }): JSX.Element {
  const stepItem = cell.row.original as EmployeeJourneyStep;
  return (
    <>
      <div>{dateTimeFormat(stepItem.sent_date)}</div>
      <div>{dateFormatWeekdayText(stepItem.sent_date)}</div>
    </>
  );
}

function MessageStatusCell({ cell }: { cell: Cell }): JSX.Element {
  const stepItem = cell.row.original as EmployeeJourneyStep;
  return <EmployeeStepStatusCell stepItem={stepItem} />;
}

function MessagesDeliveryTable({ journeySteps }: InferProps<typeof MessagesDeliveryTable.propTypes>): JSX.Element {
  const { t } = useTranslation();
  const columns = React.useMemo(
    () => [
      {
        Header: t('analytics.messages-delivery.step_channel'),
        accessor: 'step_channel',
        Cell: MessageChannelCell
      },
      {
        Header: t('analytics.messages-delivery.step_name'),
        accessor: 'step_name'
      },
      {
        Header: t('analytics.messages-delivery.sent_email'),
        id: 'employeeEmail',
        Cell: EmployeeEmailCell
      },
      {
        Header: t('analytics.messages-delivery.schedule_date'),
        id: 'messageScheduleDate',
        Cell: MessageScheduleDateCell
      },
      {
        Header: t('analytics.messages-delivery.sent_date'),
        id: 'messageSentDate',
        Cell: MessageSentDateCell
      },
      {
        Header: t('analytics.messages-delivery.status'),
        id: 'messageStatus',
        Cell: MessageStatusCell
      }
    ],
    [t]
  );
  return (
    <>
      <SimpleTable columns={columns} data={journeySteps}></SimpleTable>
    </>
  );
}

MessagesDeliveryTable.propTypes = {
  journeySteps: PropTypes.array
};

export default MessagesDelivery;
