import { FieldValues } from 'react-hook-form';
import HttpBaseService from '../../../services/HttpBaseService';
import { LogsIntegration } from './LogsIntegrationInboundRepository';
import LogsIntegrationErrorRepository from './LogsIntegrationErrorRepository';

const QUERY_CACHE_KEY = 'logs-integration-error';

class LogsIntegrationsErrorService extends HttpBaseService<LogsIntegration> {
  constructor(private integrationsRepository = new LogsIntegrationErrorRepository()) {
    super(QUERY_CACHE_KEY, integrationsRepository);
  }

  convertValuesToModel(values: FieldValues): LogsIntegration {
    return {
      id: values.id
    } as LogsIntegration;
  }
}

export default LogsIntegrationsErrorService;
