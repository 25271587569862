import React from 'react';
import { Helmet } from 'react-helmet';
import { Auth, Hub } from 'aws-amplify';
import { useLocation } from 'react-router-dom';

import storeService from '../../services/StoreService';
import { Redirect } from 'react-router-dom';

import { Grid } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import AppLoading from '../AppLoading';
import ErrorNotAuthorizedMessage from '../ErrorMessage/ErrorNotAuthorizedMessage';
import ErrorReturnHomeCell from '../ErrorMessage/ErrorReturnHomeCell';

function useQuerySearchParams(): URLSearchParams {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * Error request example:
 * http://localhost:3000/login/callback?error_description=unauthorized_client&state=qu8N95MillPd7z7apnhtt4f5iTur5B1O&error=invalid_request
 * To simulate the error you can change the callback URL
 */
function LoginCallback(): JSX.Element {
  const queryParams = useQuerySearchParams();
  const [redirect, setRedirect] = React.useState(false);
  const [timeoutError, setTimeoutError] = React.useState(false);

  const loginErrorDrescription = queryParams.get('error_description');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const authListener = (data: any): Promise<void> => {
    if (redirect) return Promise.resolve();
    const { payload } = data;
    switch (payload.event) {
      case 'signIn':
        console.log('Now the user is signed in. User: ', payload.data);
        storeService.set('login', true);
        Hub.remove('auth', authListener);
        setRedirect(true);
        return Promise.resolve();
      case 'signIn_failure':
        console.log('User failed to sign in. Error: ', payload.data);
        Hub.remove('auth', authListener);
        setRedirect(true);
        return Promise.resolve();
      default:
        break;
    }
    return Promise.resolve();
  };

  function listenAuthenticatedUser(): void {
    Hub.listen('auth', authListener);
    setTimeout(() => {
      setTimeoutError(true);
    }, 5000);
  }

  function checkAuthenticatedUser(): void {
    Auth.currentAuthenticatedUser()
      .then((cognitoUser) => {
        if (redirect) return;
        console.log('Authenticated User = ', cognitoUser);
        storeService.set('login', true);
        setRedirect(true);
        return Promise.resolve();
      })
      .catch(() => {
        return listenAuthenticatedUser();
      });
  }

  if (loginErrorDrescription) {
    return <ErrorNotAuthorizedMessage errorMessage={loginErrorDrescription} />;
  }

  checkAuthenticatedUser();

  if (redirect) {
    return <Redirect to="/start" />;
  }

  if (timeoutError) {
    return (
      <Grid>
        <ErrorReturnHomeCell />
      </Grid>
    );
  }

  return (
    <>
      <Helmet>
        <title>Login | Inboarding</title>
      </Helmet>
      <AppLoading />
    </>
  );
}

export default LoginCallback;
