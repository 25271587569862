import React from 'react';
import { Control, FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CustomizableOption } from './CustomizableOption';
import { parseOptionsDefaultValues } from './ScoreOptions';

export function SingleRatioOptions({
  control,
  block,
  index,
  errors,
  isLoading
}: {
  control: Control;
  block: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  index: number;
  errors: FieldErrors;
  isLoading: boolean;
}): JSX.Element {
  const { t } = useTranslation();

  const optionsDefaultValues = parseOptionsDefaultValues(block.options);
  const validOptionsArray = optionsDefaultValues && optionsDefaultValues?.length > 0;

  return (
    <>
      <CustomizableOption
        control={control}
        defaultValue={validOptionsArray ? optionsDefaultValues[0].value : t('journey-step.form.blocks.poll.options.one.value', 'Yes')}
        controllerName={`message_blocks.${index}.options.0.value`}
        requiredProps={{ i18nKey: 'journey-step.form.blocks.poll.options.required', requiredText: 'Option text is required' }}
        placeholderTag="journey-step.form.blocks.poll.options.one.placeholder"
        invalid={errors?.message_blocks?.[index]?.options?.[0]?.message}
        isLoading={isLoading}
        label={t('journey-step.form.blocks.poll.options.one.label')}
        style={{ marginTop: '10px' }}
      />

      <CustomizableOption
        control={control}
        defaultValue={validOptionsArray ? optionsDefaultValues[1].value : t('journey-step.form.blocks.poll.options.two.value', 'No')}
        controllerName={`message_blocks.${index}.options.1.value`}
        requiredProps={{ i18nKey: 'journey-step.form.blocks.poll.options.required', requiredText: 'Option text is required' }}
        placeholderTag="journey-step.form.blocks.poll.options.two.placeholder"
        invalid={errors?.message_blocks?.[index]?.options?.[1]?.message}
        isLoading={isLoading}
        label={t('journey-step.form.blocks.poll.options.two.label')}
        style={{ marginTop: '10px', marginLeft: '10px' }}
      />
    </>
  );
}
