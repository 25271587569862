/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosResponse } from 'axios';
import { FieldValues } from 'react-hook-form';
import { useInfiniteQuery, UseInfiniteQueryResult, useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query';

import { SearchFilter } from '../../services/HttpRepository/SearchFilter';
import HttpBaseService from '../../services/HttpBaseService';
import EmployeePhotoRepository, { EmployeePhotoMetadata } from './EmployeePhotoRepository';
import EmployeeRepository, { Employee, EmployeeJourneyStep } from './EmployeeRepository';
import { LogsIntegration } from '../Settings/LogsSettings/LogsIntegrationInboundRepository';

const employeeQueryKey = 'employees';

class EmployeeService extends HttpBaseService<Employee> {
  constructor(private employeeRepository = new EmployeeRepository()) {
    super(employeeQueryKey, employeeRepository);
  }

  public useListSearch(searchFilter: SearchFilter, dataLimit: number): UseInfiniteQueryResult<Employee[]> {
    return useInfiniteQuery<Employee[]>(
      [employeeQueryKey, searchFilter.query],
      async ({ pageParam }) => {
        if (pageParam) {
          searchFilter.lastKey = pageParam;
        }
        return this.employeeRepository.listSearch(searchFilter);
      },
      {
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPageData) => {
          const dataLength = lastPageData?.length;
          if (!dataLength || dataLength < dataLimit) {
            return undefined;
          }
          const lastDataItem = lastPageData[dataLength - 1] as unknown as Employee;
          return {
            id: lastDataItem.id,
            start_date: lastDataItem.start_date,
            order_date: lastDataItem.order_date,
            full_name: lastDataItem.full_name
          };
        }
      }
    );
  }

  public useListOffboardingSearch(searchFilter: SearchFilter, dataLimit: number): UseInfiniteQueryResult<Employee[]> {
    return useInfiniteQuery<Employee[]>(
      [employeeQueryKey, searchFilter.query],
      async ({ pageParam }) => {
        if (pageParam) {
          searchFilter.lastKey = pageParam;
        }
        return this.employeeRepository.listOffboardingSearch(searchFilter);
      },
      {
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPageData) => {
          const dataLength = lastPageData?.length;
          if (!dataLength || dataLength < dataLimit) {
            return undefined;
          }
          const lastDataItem = lastPageData[dataLength - 1] as unknown as Employee;
          return {
            id: lastDataItem.id,
            last_date: lastDataItem.last_date
          };
        }
      }
    );
  }

  public async listSearchEmployees(searchText: string): Promise<Employee[]> {
    const searchFilter = {
      query: searchText
    } as SearchFilter;
    return this.employeeRepository.listSearch(searchFilter);
  }

  public async listSearchByStartDate(startDate: string): Promise<Employee[]> {
    const searchFilter = {
      queryFieldName: 'start_date',
      query: startDate
    } as SearchFilter;
    return this.employeeRepository.listSearch(searchFilter);
  }

  public async uploadPhoto(employee: Employee, photoFile: Blob): Promise<void> {
    const metadata: EmployeePhotoMetadata = {
      title: employee.first_name,
      description: `Employee picture from person id ${employee.id}`,
      contentType: photoFile.type
    };
    const employeePhotoRepository = new EmployeePhotoRepository(employee.id || 's');
    const { url: s3UploadUrl } = await employeePhotoRepository.create(metadata);

    if (!s3UploadUrl) {
      return;
    }

    await this.uploadFileToS3(s3UploadUrl, photoFile, metadata);
  }

  public useListSteps(id: string): UseQueryResult {
    return useQuery(
      'people-journey-steps',
      async () => {
        return this.employeeRepository.listSteps(id);
      },
      {
        refetchOnWindowFocus: false,
        select: (steps) => {
          return steps.sort(this.sortByDateProgress);
        }
      }
    );
  }

  public useListLogs(id: string): UseQueryResult {
    return useQuery(
      'people-logs',
      async () => {
        return this.employeeRepository.listLogs(id);
      },
      {
        refetchOnWindowFocus: false,
        select: (logs) => {
          return logs.sort(this.sortByLogCreated);
        }
      }
    );
  }

  public useListStartJourney(id: string, journeyId: string): UseQueryResult<EmployeeJourneyStep[]> {
    return useQuery<EmployeeJourneyStep[]>(
      'people-start-journey',
      async () => {
        return this.employeeRepository.listStartJourney(id, journeyId);
      },
      {
        refetchOnWindowFocus: false,
        select: (steps) => {
          return steps.sort(this.sortByDateStart);
        }
      }
    );
  }

  public useCreateStartJourney(
    { updateCache } = { updateCache: true }
  ): UseMutationResult<EmployeeJourneyStep[], AxiosError, { id: string; journeyId: string; journeySteps: any }> {
    return useMutation<EmployeeJourneyStep[], AxiosError, any>(
      ({ id, journeyId, journeySteps }) => {
        return this.employeeRepository.createStartJourney(id, journeyId, journeySteps);
      },
      {
        onMutate: () => {
          if (updateCache) {
            this.queryClient.cancelQueries('people-journey-steps');
            this.queryClient.cancelQueries('people-start-journey');
          }
        },
        onSuccess: () => {
          if (updateCache) {
            this.queryClient.invalidateQueries('people-journey-steps');
            this.queryClient.invalidateQueries('people-start-journey');
          }
        }
      }
    );
  }

  public useSendStep(): UseMutationResult<any, AxiosError, any> {
    return useMutation<any, AxiosError, any>(
      ({ id, stepId }) => {
        return this.employeeRepository.sendStep(id, stepId);
      },
      {
        onMutate: () => {
          this.queryClient.cancelQueries('people-journey-steps');
        },
        onSuccess: () => {
          this.queryClient.invalidateQueries('people-journey-steps');
        }
      }
    );
  }

  public useForceReSendStep(): UseMutationResult<any, AxiosError, any> {
    return useMutation<any, AxiosError, any>(
      ({ id, stepId }) => {
        return this.employeeRepository.reSendStep(id, stepId);
      },
      {
        onMutate: () => {
          this.queryClient.cancelQueries('people-journey-steps');
        },
        onSuccess: () => {
          this.queryClient.invalidateQueries('people-journey-steps');
        }
      }
    );
  }

  convertValuesToModel(values: FieldValues): Employee {
    return {
      first_name: values.first_name,
      last_name: values.last_name,
      social_name: values.social_name,
      gender_pronouns: values.gender_pronouns,
      short_name: values.short_name,
      fake_name: values.fake_name,
      birth_date: values.birth_date,
      start_date: values.start_date,
      last_date: values.last_date,
      mobile_number: values.mobile_number,
      phone_number: values.phone_number,
      personal_email: values.personal_email,
      work_email: values.work_email,
      notes_software: values.notes_software,
      notes_equipament: values.notes_equipament,
      notes_other: values.notes_other,
      address: values.address,
      education: values.education,
      gender: values.gender,
      ethnicity: values.ethnicity,
      cost_category: values.cost_category,
      cost_center: values.cost_center,
      job_title: values.job_title,
      department: values.department,
      contract_type: values.contract_type,
      experience_period_days: isNaN(values.experience_period_days) ? values.experience_period_days : `${values.experience_period_days}`,
      instagram_handle: values.instagram_handle,
      linkedin_url: values.linkedin_url,
      leader_id: values.leader?.value ? values.leader.value : values.leader,
      buddy_id: values.buddy?.value ? values.buddy.value : values.buddy
    } as Employee;
  }

  private async uploadFileToS3(s3PutObjectUrl: string, photoFile: Blob, metadata: EmployeePhotoMetadata): Promise<AxiosResponse> {
    return axios.put(s3PutObjectUrl, photoFile, {
      headers: {
        'Content-Type': metadata.contentType
      }
    });
  }

  private sortByDateStart(a: EmployeeJourneyStep, b: EmployeeJourneyStep): number {
    const aDate = a.order_date || a.sent_date || a.schedule_date || a.createdAt;
    const bDate = b.order_date || b.sent_date || b.schedule_date || b.createdAt;
    return bDate < aDate ? 1 : -1;
  }

  private sortByLogCreated(a: LogsIntegration, b: LogsIntegration): number {
    const aDate = a.createdAt;
    const bDate = b.createdAt;
    return bDate < aDate ? -1 : 1;
  }

  private sortByDateProgress(a: EmployeeJourneyStep, b: EmployeeJourneyStep): number {
    const aDate = a.order_date || a.sent_date || a.schedule_date || a.createdAt;
    const bDate = b.order_date || b.sent_date || b.schedule_date || b.createdAt;
    return bDate < aDate ? -1 : 1;
  }
}

export default EmployeeService;
