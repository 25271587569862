import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useForm, Controller, FieldValues } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { GridRow, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { TextField } from '@rmwc/textfield';
import '@rmwc/textfield/styles';

import { Checkbox } from '@rmwc/checkbox';
import '@rmwc/checkbox/styles';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/circular-progress.css';

import { Typography } from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

const fullWithStyle = {
  width: '100%',
  backgroundColor: 'whitesmoke'
} as React.CSSProperties;

function UsersSettingsForm({ onFormSubmit, onCancel, defaultValues, isLoading }: InferProps<typeof UsersSettingsForm.propTypes>): JSX.Element {
  const { t } = useTranslation();

  const { control, handleSubmit, errors } = useForm({ defaultValues });

  const onSubmit = async (values: FieldValues): Promise<void> => {
    onFormSubmit(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GridRow>
        <GridCell span={12}>
          <Controller
            control={control}
            defaultValue=""
            name="email"
            rules={{ required: t('admin-users.form.email.required', 'The admin user email is required.') as string }}
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="admin-users.form.email.label">What is admin user email?</Trans>
                  </label>
                </Typography>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  placeholder={t('admin-users.form.email.placeholder')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.title?.message ? true : false}
                  aria-invalid={errors?.title?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />
              </>
            )}
          />
          {errors?.email?.message && <div role="alert">{errors?.email?.message}</div>}
        </GridCell>
        <GridCell span={12}>
          <Controller
            control={control}
            defaultValue={false}
            name="send_invite"
            render={({ onChange, onBlur, name, ref }): React.ReactElement => (
              <>
                <Checkbox id={name} name={name} value="true" onBlur={onBlur} onChange={onChange} inputRef={ref} disabled={!!isLoading}>
                  <Trans i18nKey="admin-users.form.send_invite.label">Send invite email to user?</Trans>
                </Checkbox>
              </>
            )}
          />
          {errors?.send_invite?.message && <div role="alert">{errors?.send_invite?.message}</div>}
        </GridCell>
        <GridCell span={12} style={{ textAlign: 'right' }}>
          <Button type="button" onClick={onCancel} style={{ marginRight: '1.5rem' }}>
            <Trans i18nKey="crud.cancel">Cancel</Trans>
          </Button>
          <Button type="submit" raised {...(isLoading ? { icon: <CircularProgress theme="secondary" /> } : {})}>
            {defaultValues && defaultValues.id ? (
              <Trans i18nKey="admin-users.save-update">Update admin user</Trans>
            ) : (
              <Trans i18nKey="admin-users.save-new">Create new admin user</Trans>
            )}
          </Button>
        </GridCell>
      </GridRow>
    </form>
  );
}

UsersSettingsForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  defaultValues: PropTypes.any,
  isLoading: PropTypes.bool
};

export default UsersSettingsForm;
