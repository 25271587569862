import HttpRepository from '../../../services/HttpRepository/HttpRepository';
import { EmployeeJourneyStep } from '../../Employee/EmployeeRepository';

class MessagesDeliveryRepository extends HttpRepository<EmployeeJourneyStep> {
  constructor() {
    super({ basePath: '/reports/messages/emails' });
  }
}

export default MessagesDeliveryRepository;
