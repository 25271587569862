export const dateFormatWeekdayText = (dateISO: string): string => {
  if (!dateISO) {
    return '';
  }

  const date = new Date(dateISO);
  if (!date) {
    return '';
  }

  // const wekkDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const wekkDays = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
  return wekkDays[date.getDay()];
};
