import HttpRepository from '../../services/HttpRepository/HttpRepository';

export interface Connection {
  id?: string;
  name: string;
}

class ConnectionRepository extends HttpRepository<Connection> {
  constructor() {
    super({ basePath: '/engagement/connections' });
  }
}

export default ConnectionRepository;
