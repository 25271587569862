import { FieldValues } from 'react-hook-form';
import HttpBaseService from '../../../services/HttpBaseService';
import ContentsGlossaryRepository, { ContentsGlossary } from './ContentsGlossaryRepository';

class ContentsGlossaryService extends HttpBaseService<ContentsGlossary> {
  constructor(private glossaryRepository = new ContentsGlossaryRepository()) {
    super('contents-glossary', glossaryRepository);
  }

  convertValuesToModel(values: FieldValues): ContentsGlossary {
    return {
      tags: values.tags,
      title: values.title,
      description: values.description,
      source_url: values.source_url
    } as ContentsGlossary;
  }
}

export default ContentsGlossaryService;
