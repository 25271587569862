import React from 'react';
import { EChartsReactProps } from 'echarts-for-react';

// Lazy load Charts to reduse bundle size
const ReactECharts = React.lazy(() => import('./ReactECharts'));

/**
 * Echarts options details here on the documentation:
 * Option: https://echarts.apache.org/en/option.html
 * Examples: https://echarts.apache.org/examples/en/index.html
 */
function Chart(chartOptions: EChartsReactProps): JSX.Element {
  return (
    <React.Suspense fallback={<>Loading...</>}>
      <ReactECharts opts={{ renderer: 'svg' }} {...chartOptions} />
    </React.Suspense>
  );
}

export default Chart;
