import HttpClientProtected from './HttpClientProtected';
import parseExtractHashId from './parseExtractHashId';
import { SearchFilter } from './SearchFilter';

const API_GATEWAY_URL = process.env.REACT_APP_API_GATEWAY_URL || '';

abstract class HttpRepository<T> extends HttpClientProtected<T> {
  public constructor({ baseURL = API_GATEWAY_URL, basePath }: { baseURL?: string; basePath: string }) {
    super({ baseURL, basePath });
  }

  public async list(): Promise<T[]> {
    const { data } = await super.getAll();
    return data;
  }

  public async listSearch(searchFilter: SearchFilter): Promise<T[]> {
    const { data } = await super.getAllSearch(searchFilter);
    return data;
  }

  public async listSearchPath(path: string, searchFilter: SearchFilter): Promise<T[]> {
    const { data } = await super.getAllSearchPath(path, searchFilter);
    return data;
  }

  public async get(id: string): Promise<T> {
    const parsedId = this.removeHashId(id);
    const { data } = await super.getById(parsedId);
    return data;
  }

  public async delete(id: string): Promise<T> {
    const parsedId = this.removeHashId(id);
    const { data } = await super.deleteById(parsedId);
    return data;
  }

  public async create(data: T): Promise<T> {
    const body = {
      data: data
    };
    const result = await super.post(body);
    return result.data;
  }

  public async update(id: string, data: T): Promise<T> {
    const parsedId = this.removeHashId(id);
    const body = {
      data: data
    };
    const result = await super.put(parsedId, body);
    return result.data;
  }

  protected removeHashId(id: string): string {
    return parseExtractHashId(id);
  }
}

export default HttpRepository;
