import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { FieldValues } from 'react-hook-form';

import AdminUsersService from './AdminUsersService';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import UsersSettingsForm from './UsersSettingsForm';
import Message from '../../../services/Message';
import Breadcrumb from '../../common/Breadcrumb';

function UsersSettingsNew({ history }: InferProps<typeof UsersSettingsNew.propTypes>): JSX.Element {
  const { t } = useTranslation();
  const adminUsersService = new AdminUsersService();
  const useCreateUser = adminUsersService.useCreate();

  const onSubmit = async (values: FieldValues): Promise<void> => {
    try {
      await useCreateUser.mutateAsync(values);
      Message.success(t('admin-users.created'));
      history.push('/settings/users');
    } catch (error) {
      Message.error(t('admin-users.created-error'));
      console.error('Error on save admin user: ', error);
    }
  };

  const onCancel = async (): Promise<void> => {
    history.goBack();
  };

  return (
    <Grid>
      <GridCell span={12}>
        <Breadcrumb
          routeSegments={[{ name: t('navlink.settings'), path: '/settings' }, { name: t('navlink.settings-users'), path: '/settings/users' }, { name: t('admin-users.new-title') }]}
        />
      </GridCell>
      <GridCell span={12}>
        <h1>
          <Trans i18nKey="admin-users.new-title">New Admin User</Trans>
        </h1>
      </GridCell>
      <GridCell span={12}>
        <UsersSettingsForm onFormSubmit={onSubmit} onCancel={onCancel} isLoading={useCreateUser.isLoading}></UsersSettingsForm>
      </GridCell>
    </Grid>
  );
}

UsersSettingsNew.propTypes = {
  history: PropTypes.any
};

export default UsersSettingsNew;
