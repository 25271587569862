import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';

class UserService {
  async get(): Promise<CognitoUser> {
    const user: CognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
    return user;
  }

  async update(attribute: string, value: string): Promise<string> {
    const user: CognitoUser = await this.get();
    const updatedUserResult = await Auth.updateUserAttributes(user, {
      [attribute]: value
    });
    console.log('Updated user: ', updatedUserResult);
    return updatedUserResult;
  }

  async updateStartCompleted(): Promise<string> {
    return this.update('custom:start_completed', 'true');
  }

  async updateProfileType(profileType: string): Promise<string> {
    return this.update('custom:start_profile_type', profileType);
  }

  async updateCompanySize(companySize: string): Promise<string> {
    return this.update('custom:start_company_size', companySize);
  }

  async updateCompanyChallenge(companyChallenge: string): Promise<string> {
    return this.update('custom:start_challenge', companyChallenge);
  }
}

export default new UserService();
