import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import LoadingModal from '../../LoadingModal';
import ErrorMessage from '../../ErrorMessage';

import { Grid, GridRow, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { ListDivider } from '@rmwc/list';
import { TabBar, Tab } from '@rmwc/tabs';
import '@rmwc/tabs/styles';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/circular-progress.css';

import { DataTable, DataTableContent, DataTableHead, DataTableHeadCell, DataTableBody, DataTableRow, DataTableCell } from '@rmwc/data-table';
import '@rmwc/data-table/styles';

import { ChipSet, Chip } from '@rmwc/chip';
import '@rmwc/chip/styles';

import { Tooltip } from '@rmwc/tooltip';
import '@rmwc/tooltip/styles';

import { Icon } from '@rmwc/icon';
import '@rmwc/icon/styles';

import ConveniaLogo from '../IntegrationSettings/Icons/convenia-symbol.png';
import GupyLogo from '../IntegrationSettings/Icons/gupy-symbol.svg';
import SeniorLogo from '../IntegrationSettings/Icons/senior-symbol.png';
import ADPLogo from '../IntegrationSettings/Icons/adp.png';
import FeedzLogo from '../IntegrationSettings/Icons/feedz.png';
import KeepsLogo from '../IntegrationSettings/Icons/keeps.png';
import SalvyLogo from '../IntegrationSettings/Icons/salvy.png';
import DominioLogo from '../IntegrationSettings/Icons/dominio-thomson-reuters.png';
import SolidesLogo from '../IntegrationSettings/Icons/solides.png';

import { LogsIntegration } from './LogsIntegrationInboundRepository';
import { IntegrationName } from '../IntegrationSettings/IntegrationsRepository';
import { dateTimeFormat } from '../../common/DatesFormat/dateTimeFormat';
import LogsIntegrationsInboundService from './LogsIntegrationsInboundService';
import LogsIntegrationsOutboundService from './LogsIntegrationsOutboundService';
import LogsIntegrationsErrorService from './LogsIntegrationsErrorService';

const textColumnStyle = {
  textOverflow: 'unset',
  wordWrap: 'break-word',
  overflowWrap: 'break-word',
  whiteSpace: 'normal'
} as React.CSSProperties;

const LIST_SIZE = 20;

function LogsSettingsHome(): JSX.Element {
  const { t } = useTranslation();
  const [activeTabIndex, setActiveTabIndex] = React.useState<number>(0);

  return (
    <>
      <Grid>
        <GridCell span={12} align="middle">
          <h2>
            <Trans i18nKey="logs-settings.title">Audit Logs</Trans>
          </h2>
        </GridCell>
      </Grid>
      <TabBar activeTabIndex={activeTabIndex} onActivate={(event): void => setActiveTabIndex(event.detail.index)}>
        <Tab minWidth icon="file_download" label={t('logs-settings.tabs.inbound')} />
        <Tab minWidth icon="file_upload" label={t('logs-settings.tabs.outbound')} />
        <Tab minWidth icon="sync_problem" label={t('logs-settings.tabs.errors')} />
      </TabBar>
      <ListDivider />
      {activeTabIndex === 0 ? <LogsSettingsHomeInbound /> : null}
      {activeTabIndex === 1 ? <LogsSettingsHomeOutbound /> : null}
      {activeTabIndex === 2 ? <LogsSettingsHomeErrors /> : null}
    </>
  );
}

function LogsSettingsHomeOutbound(): JSX.Element {
  const logsService = new LogsIntegrationsOutboundService();

  const searchFilter = { limit: LIST_SIZE, query: '' };

  const { data, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage, isError, error } = logsService.useListSearch(searchFilter, LIST_SIZE);

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching && !isFetchingNextPage && !searchFilter.query) {
    return <LoadingModal open={true} />;
  }

  const logs = data?.pages.flat();

  if (!logs || !logs.length) {
    return <LogsIntegrationDataListEmpty />;
  }

  return (
    <>
      <GridRow>
        <GridCell span={12}>
          <LogsIntegrationList logs={logs} />
        </GridCell>
        {hasNextPage && (
          <GridCell span={12} style={{ textAlign: 'center' }}>
            <Button
              outlined
              {...(isFetching ? { icon: <CircularProgress theme="secondary" /> } : {})}
              disabled={isFetching}
              onClick={(): void => {
                fetchNextPage();
              }}
            >
              <Trans i18nKey="analytics.load-more">Load More</Trans>
            </Button>
          </GridCell>
        )}
      </GridRow>
    </>
  );
}

function LogsSettingsHomeInbound(): JSX.Element {
  const logsService = new LogsIntegrationsInboundService();

  const searchFilter = { limit: LIST_SIZE, query: '' };

  const { data, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage, isError, error } = logsService.useListSearch(searchFilter, LIST_SIZE);

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching && !isFetchingNextPage && !searchFilter.query) {
    return <LoadingModal open={true} />;
  }

  const logs = data?.pages.flat();

  if (!logs || !logs.length) {
    return <LogsIntegrationDataListEmpty />;
  }

  return (
    <>
      <GridRow>
        <GridCell span={12}>
          <LogsIntegrationList logs={logs} />
        </GridCell>
        {hasNextPage && (
          <GridCell span={12} style={{ textAlign: 'center' }}>
            <Button
              outlined
              {...(isFetching ? { icon: <CircularProgress theme="secondary" /> } : {})}
              disabled={isFetching}
              onClick={(): void => {
                fetchNextPage();
              }}
            >
              <Trans i18nKey="analytics.load-more">Load More</Trans>
            </Button>
          </GridCell>
        )}
      </GridRow>
    </>
  );
}

function LogsSettingsHomeErrors(): JSX.Element {
  const logsService = new LogsIntegrationsErrorService();

  const searchFilter = { limit: LIST_SIZE, query: '' };

  const { data, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage, isError, error } = logsService.useListSearch(searchFilter, LIST_SIZE);

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching && !isFetchingNextPage && !searchFilter.query) {
    return <LoadingModal open={true} />;
  }

  const logs = data?.pages.flat();

  if (!logs || !logs.length) {
    return <LogsIntegrationErrorListEmpty />;
  }

  return (
    <>
      <GridRow>
        <GridCell span={12}>
          <LogsIntegrationList logs={logs} />
        </GridCell>
        {hasNextPage && (
          <GridCell span={12} style={{ textAlign: 'center' }}>
            <Button
              outlined
              {...(isFetching ? { icon: <CircularProgress theme="secondary" /> } : {})}
              disabled={isFetching}
              onClick={(): void => {
                fetchNextPage();
              }}
            >
              <Trans i18nKey="analytics.load-more">Load More</Trans>
            </Button>
          </GridCell>
        )}
      </GridRow>
    </>
  );
}

function LogsIntegrationErrorListEmpty(): JSX.Element {
  return (
    <p style={{ paddingLeft: '20px' }}>
      <Trans i18nKey="logs-settings.no-data-errors">All good! No logs found for review.</Trans>
    </p>
  );
}

function LogsIntegrationDataListEmpty(): JSX.Element {
  return (
    <p style={{ paddingLeft: '20px' }}>
      <Trans i18nKey="logs-settings.no-data">No logs data was found.</Trans>
    </p>
  );
}

function LogsIntegrationList({ logs }: { logs: LogsIntegration[] }): JSX.Element {
  return (
    <DataTable style={{ width: '100%' }}>
      <DataTableContent>
        <DataTableHead>
          <DataTableRow>
            <DataTableHeadCell>
              <Trans i18nKey="logs-settings.logs.date">Date</Trans>
            </DataTableHeadCell>
            <DataTableHeadCell>
              <Trans i18nKey="logs-settings.logs.person">Employee</Trans>
            </DataTableHeadCell>
            <DataTableHeadCell>
              <Trans i18nKey="logs-settings.logs.integration">Integration</Trans>
            </DataTableHeadCell>
            <DataTableHeadCell>
              <Trans i18nKey="logs-settings.logs.status">Status</Trans>
            </DataTableHeadCell>
            <DataTableHeadCell>
              <Trans i18nKey="logs-settings.logs.details">Details</Trans>
            </DataTableHeadCell>
          </DataTableRow>
        </DataTableHead>
        <DataTableBody>
          {logs.map((log) => (
            <DataTableRow key={log.id}>
              <DataTableCell style={textColumnStyle}>{dateTimeFormat(log.createdAt)}</DataTableCell>
              <DataTableCell style={textColumnStyle}>
                <LogsIntegrationPerson log={log} />
              </DataTableCell>
              <DataTableCell style={textColumnStyle}>
                <LogsIntegrationName log={log} />
              </DataTableCell>
              <DataTableCell style={textColumnStyle}>
                <LogsIntegrationStatus log={log} />
              </DataTableCell>
              <DataTableCell style={textColumnStyle}>{log.error || log.source_ip}</DataTableCell>
            </DataTableRow>
          ))}
        </DataTableBody>
      </DataTableContent>
    </DataTable>
  );
}

function LogsIntegrationPerson({ log }: { log: LogsIntegration }): JSX.Element {
  const { t } = useTranslation();
  const personId = log.people_id;
  const personOriginId = log.people_origin_id;

  if (!personId) {
    return <>{personOriginId}</>;
  }

  return (
    <Link to={`/employees/${personId}/logs`} style={{ color: 'rgba(0, 0, 0, 0.87)', textDecoration: 'none' }} onClick={(event): void => event.stopPropagation()}>
      {log.people_name || t('logs-settings.logs.open-people', 'Open employee')}
    </Link>
  );
}

export function LogsIntegrationStatus({ log }: { log: LogsIntegration }): JSX.Element {
  const { t } = useTranslation();
  const status = log.status;

  function buildLogStatus() {
    switch (status) {
      case 'SUCCESS':
        return (
          <ChipSet>
            <Chip label={t('employee.logs.status-values.success')} style={{ backgroundColor: 'var(--mdc-theme-green-enabled)' }} />
          </ChipSet>
        );

      case 'PROCESSING':
        return (
          <ChipSet>
            <Chip label={t('employee.logs.status-values.processing')} style={{ backgroundColor: 'var(--mdc-theme-yellow)' }} />
          </ChipSet>
        );

      case 'ERROR':
        return (
          <ChipSet>
            <Chip label={t('employee.logs.status-values.error')} style={{ backgroundColor: 'var(--mdc-theme-error-light)' }} />
          </ChipSet>
        );
    }
    return <>{status}</>;
  }

  const details = log.request_body;
  return <Tooltip content={details}>{buildLogStatus()}</Tooltip>;
}

function getIntegrationLogoImagem(integration: IntegrationName): string {
  switch (integration) {
    case IntegrationName.ADP:
      return ADPLogo;
    case IntegrationName.Convenia:
      return ConveniaLogo;
    case IntegrationName.Gupy:
      return GupyLogo;
    case IntegrationName.Senior:
      return SeniorLogo;
    case IntegrationName.Dominio:
      return DominioLogo;
    case IntegrationName.Feedz:
      return FeedzLogo;
    case IntegrationName.Keeps:
      return KeepsLogo;
    case IntegrationName.Salvy:
      return SalvyLogo;
    case IntegrationName.Solides:
      return SolidesLogo;
    case IntegrationName.File:
      return 'upload_file';
  }

  return '';
}

export function LogsIntegrationName({ log }: { log: LogsIntegration }): JSX.Element {
  const integrationName = log.integration_name;

  const logo = getIntegrationLogoImagem(integrationName);

  if (!logo) {
    return <>{integrationName}</>;
  }

  return (
    <p style={{ marginBottom: '0.4em', marginTop: '0.4em' }}>
      <Icon icon={logo} style={{ minHeight: '1.2em', verticalAlign: 'middle', marginRight: '10px', backgroundSize: 'contain' }} aria-hidden="true" />
      {integrationName}
    </p>
  );

  return <></>;
}

export default LogsSettingsHome;
