import HttpRepository from '../../services/HttpRepository/HttpRepository';

export enum StepJourneyPeriod {
  beforeFirstDay = 'before-first-day',
  firstDay = 'first-day',
  afterFirstDay = 'after-first-day',
  fixedDate = 'fixed-date'
}

export enum StepJourneyChannel {
  Email = 'EMAIL',
  Slack = 'SLACK',
  Teams = 'TEAMS',
  Workplace = 'WORKPLACE',
  Whatsapp = 'WHATSAPP',
  SMS = 'SMS',
  Beedoo = 'BEEDOO'
}

export enum WhatsappTemplate {
  WelcomeFirstMessage = 'WELCOME-FIRST',
  WelcomeSecondMessage = 'WELCOME-SECOND',
  GenericNotificationMessage = 'NOTIFICATION-MESSAGE',
  TaskNotificationMessage = 'NOTIFICATION-TASK',
  UpdateNotificationMessage = 'NOTIFICATION-UPDATE'
}

export interface StepJourney {
  id?: string;
  journey_id?: string;
  name?: string;
  to_whom?: string;
  to_whom_copy?: string;
  to_whom_reply?: string;
  subject?: string;
  message_source?: string;
  message_html?: string;
  message_blocks?: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
  period?: StepJourneyPeriod;
  period_days?: number;
  period_date?: string;
  period_time?: string;
  channel?: StepJourneyChannel;
  whatsapp_template?: WhatsappTemplate;
}

class StepJourneyRepository extends HttpRepository<StepJourney> {
  constructor(journeyId: string) {
    super({ basePath: `/journeys/${journeyId}/steps` });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async sendPreviewTest(id: string): Promise<any> {
    const parsedId = super.removeHashId(id);
    const result = await super.postPath(`${parsedId}/send-preview`, {});
    return result.data;
  }
}

export default StepJourneyRepository;
