import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import LoadingModal from '../LoadingModal';

import styles from './Start.module.css';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Theme } from '@rmwc/theme';
import { Typography } from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

import { Avatar } from '@rmwc/avatar';
import '@rmwc/avatar/avatar.css';

import { Elevation } from '@rmwc/elevation';
import '@material/elevation/dist/mdc.elevation.css';

import Ella from '../Brand/ella.svg';
import Culture from '../Ilustrations/culture.svg';
import Gender from '../Ilustrations/gender.svg';
import Blind from '../Ilustrations/blind.svg';
import Men from '../Ilustrations/men.svg';
import Race from '../Ilustrations/race.svg';
import Other from '../Ilustrations/other.svg';

import userService from '../../services/UserService';
import messageSnackbarQueue from '../MessagesSnackbar/MessagesSnackbarQueue';

function StartChallengeType({ history }: InferProps<typeof StartChallengeType.propTypes>): JSX.Element {
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation();

  function handleOnClick({ loading = true }): void {
    setLoading(loading);
  }

  return (
    <>
      <Helmet>
        <title>{t('start3.title')}</title>
      </Helmet>
      <LoadingModal open={loading} />

      <Grid className={styles.startHeader}>
        <GridCell phone={1} tablet={2} desktop={2} align="middle" className={styles.avatarCell}>
          <Avatar src={Ella} name="Ella" size="large" className={styles.ellaAvatar} />
        </GridCell>
        <GridCell phone={3} tablet={6} desktop={10} tabIndex={0}>
          <h1>
            <Typography use="headline6">
              <Trans i18nKey="start3.header">What does your company need to be more inclusive for its talents?</Trans>
            </Typography>
          </h1>
          <p>
            <Trans i18nKey="start3.text">Tell me about your current challenges to improve the Diversity and Inclusion in your workplace?</Trans>
          </p>
        </GridCell>
      </Grid>

      <Grid className={styles.answersGrid}>
        <GridCell phone={2} tablet={4} desktop={4}>
          <ElevationCardAnswer answerImage={Gender} answerText={t('start3.gender', 'Gender')} handleOnClick={handleOnClick} history={history} answerKey="gender" />
        </GridCell>
        <GridCell phone={2} tablet={4} desktop={4}>
          <ElevationCardAnswer answerImage={Men} answerText={t('start3.lgbt', 'LGBT+')} handleOnClick={handleOnClick} history={history} answerKey="lgbt" />
        </GridCell>
        <GridCell phone={2} tablet={4} desktop={4}>
          <ElevationCardAnswer
            answerImage={Blind}
            answerText={t('start3.disability', 'People with Disability')}
            handleOnClick={handleOnClick}
            history={history}
            answerKey="disability"
          />
        </GridCell>
        <GridCell phone={2} tablet={4} desktop={4}>
          <ElevationCardAnswer answerImage={Race} answerText={t('start3.race', 'Race')} handleOnClick={handleOnClick} history={history} answerKey="race" />
        </GridCell>
        <GridCell phone={2} tablet={4} desktop={4}>
          <ElevationCardAnswer answerImage={Culture} answerText={t('start3.culture', 'Culture')} handleOnClick={handleOnClick} history={history} answerKey="culture" />
        </GridCell>
        <GridCell phone={2} tablet={4} desktop={4}>
          <ElevationCardAnswer answerImage={Other} answerText={t('start3.other', 'Other')} handleOnClick={handleOnClick} history={history} answerKey="other" />
        </GridCell>
      </Grid>
    </>
  );
}

function ElevationCardAnswer({ answerKey, answerImage, answerText, handleOnClick, history }: InferProps<typeof ElevationCardAnswer.propTypes>): JSX.Element {
  async function handleAnswerOnClick(): Promise<void> {
    handleOnClick({ loading: true });
    try {
      await userService.updateCompanyChallenge(answerKey);
      handleOnClick({ loading: false });
      history.push('/journeys');
    } catch (error) {
      console.log('Error saving: ', error);
      const { message } = error as Error;
      messageSnackbarQueue.notifyError(message);
      handleOnClick({ loading: false });
    }
  }

  return (
    <Theme use="primary">
      <Elevation z={2} className={styles.answerCard} onClick={handleAnswerOnClick} onKeyPress={handleAnswerOnClick} tabIndex={0}>
        <img src={answerImage} aria-hidden="true" alt={answerText} className={styles.answerIlustration} />
        <p>{answerText}</p>
      </Elevation>
    </Theme>
  );
}

StartChallengeType.propTypes = {
  history: PropTypes.any,
  userAttributes: PropTypes.any
};

ElevationCardAnswer.propTypes = {
  answerImage: PropTypes.string.isRequired,
  answerKey: PropTypes.string.isRequired,
  answerText: PropTypes.string.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  history: PropTypes.any
};

export default withRouter(StartChallengeType);
