import HttpRepository from '../../../services/HttpRepository/HttpRepository';

export enum IntegrationName {
  Convenia = 'CONVENIA',
  Solides = 'SOLIDES',
  Kenoby = 'KENOBY',
  Gupy = 'GUPY',
  ADP = 'ADP',
  Dominio = 'DOMINIO',
  Senior = 'SENIOR',
  Slack = 'SLACK',
  MsTeams = 'TEAMS',
  Beedoo = 'BEEDOO',
  Feedz = 'FEEDZ',
  Keeps = 'KEEPS',
  Salvy = 'SALVY',
  File = 'FILE'
}

export interface Integrations {
  id?: string;
  name?: string;
  enabled?: boolean;
}

class IntegrationsRepository extends HttpRepository<Integrations> {
  constructor() {
    super({ basePath: '/integrations' });
  }

  public async createSlackApp(tenantId: string, code: string): Promise<Integrations> {
    const body = {
      data: { code }
    };
    const result = await this.customPostUrl(`slack/app/installed/${tenantId}`, body);
    return result.data;
  }
}

export default IntegrationsRepository;
