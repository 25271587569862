import React from 'react';
import Chart from '../Chart';
import { useTranslation } from 'react-i18next';

function HiresAgeGenerationChart(): JSX.Element {
  const { t } = useTranslation();
  const chartTitle = t('hires-generation.pie.title', 'People by Generation');
  const chartSeriesName = t('hires-generation.pie.series-name', 'People by Generation');
  const babyBoomersName = t('hires-generation.pie.baby-boomers', 'Baby Boomers (aged 55+)');
  const millenialsName = t('hires-generation.pie.millenials', 'Millenials (aged 25-34)');
  const genXName = t('hires-generation.pie.genXName', 'Gen X (aged 35-54)');
  const genZName = t('hires-generation.pie.genYName', 'Gen Z (aged 16-24)');
  const textColor = '#303030';
  //   const style = { height: '100px' };
  const style = {};
  const option = {
    title: {
      show: true,
      text: chartTitle,
      left: 'center',
      textStyle: {
        color: textColor,
        fontSize: 16,
        fontFamily: 'PT Sans'
      }
    },
    legend: {
      show: true,
      itemGap: 10,
      icon: 'roundRect',
      bottom: 0,
      textStyle: {
        color: textColor,
        fontSize: 13
      }
    },
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c} ({d}%)'
    },
    xAxis: [
      {
        axisLine: {
          show: false
        },
        splitLine: {
          show: false
        }
      }
    ],
    yAxis: [
      {
        axisLine: {
          show: false
        },
        splitLine: {
          show: false
        }
      }
    ],
    series: [
      {
        name: chartSeriesName,
        type: 'pie',
        radius: ['45%', '72.55%'],
        center: ['50%', '50%'],
        avoidLabelOverlap: false, // hoverOffset: 5,
        stillShowZeroSum: false,
        label: {
          normal: {
            show: false,
            position: 'center', // shows the description data to center, turn off to show in right side
            textStyle: {
              color: textColor,
              fontSize: 13,
              fontFamily: 'PT Sans'
            },
            formatter: '{a}'
          },
          emphasis: {
            show: true,
            textStyle: {
              fontSize: 14,
              fontWeight: 'normal'
            },
            formatter: '{b} \n{c} ({d}%)'
          }
        },
        labelLine: {
          normal: {
            show: false
          }
        },
        data: [
          {
            value: 9,
            name: babyBoomersName
          },
          {
            value: 35,
            name: millenialsName
          },
          {
            value: 35,
            name: genXName
          },
          {
            value: 21,
            name: genZName
          }
        ],
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        }
      }
    ],
    colors: []
  };
  return <Chart option={option} style={style} />;
}

export default HiresAgeGenerationChart;
