import HttpRepository from '../../../services/HttpRepository/HttpRepository';
import { Employee } from '../../Employee/EmployeeRepository';

class CelebrationsWorkRepository extends HttpRepository<Employee> {
  constructor() {
    super({ basePath: '/celebrations/work' });
  }
}

export default CelebrationsWorkRepository;
