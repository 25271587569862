/* eslint-disable no-undef */
export default {
  // Add common config values here
  EXAMPLE_CONFIG: 5000000,
  // Environment variables fom .env
  apiGateway: {
    REGION: process.env.REACT_APP_API_GATEWAY_REGION,
    URL: process.env.REACT_APP_API_GATEWAY_URL
  },
  cognito: {
    REGION: process.env.REACT_APP_COGNITO_REGION,
    USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    IDENTITY_POOL_DOMAIN: process.env.REACT_APP_COGNITO_IDENTITY_POOL_DOMAIN,
    IDENTITY_POOL_REDIRECT_SIGN_IN: process.env.REACT_APP_COGNITO_IDENTITY_POOL_REDIRECT_SIGN_IN,
    IDENTITY_POOL_REDIRECT_SIGN_OUT: process.env.REACT_APP_COGNITO_IDENTITY_POOL_REDIRECT_SIGN_OUT
  }
};
