import React from 'react';
import { Control, FieldErrors, useFieldArray, Controller } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { Elevation, Select, Button } from 'rmwc';
import { MessageBlocksTypes, BlockTitleActions, fullWithStyle } from '../../StepJourneyForm';
import { MessageBlockFormField, MessageBlocksFormTypes } from '../BlockTypes/FormBlockType';
import { MultipleChoiceOptions } from './MultipleChoiceOptions';
import { TextQuestion } from './TextQuestion';
import { RequiredFieldSwitch } from './RequiredFieldSwitch';
import { OptionsList } from './OptionsList';

export function FieldsGroup({ control, index, errors, isLoading }: { control: Control; index: number; errors: FieldErrors; isLoading: boolean }): JSX.Element {
  const { t } = useTranslation();

  const { fields: groupFields, append: appendGroupField, remove: removeGroupField, move: moveGroupField } = useFieldArray({ name: `message_blocks.${index}.group`, control });

  const handleMoveUpGroupField = (index: number) => {
    if (index > 0) {
      moveGroupField(index, index - 1);
    }
  };

  const handleMoveDownGroupField = (index: number) => {
    if (index >= 0) {
      moveGroupField(index, index + 1);
    }
  };

  const handleAddGroupField = (): void => {
    appendGroupField({
      type: MessageBlocksTypes.Form,
      start_button: '',
      field_type: MessageBlocksFormTypes.Text,
      question: '',
      description: ''
    });
  };

  const groupFormFieldTypeOptions = [
    {
      label: t('journey-step.form.blocks.form.type.title', 'Title and description'),
      value: MessageBlocksFormTypes.Title
    },
    {
      label: t('journey-step.form.blocks.form.type.text', 'Short text'),
      value: MessageBlocksFormTypes.Text
    },
    {
      label: t('journey-step.form.blocks.form.type.text-long', 'Long text'),
      value: MessageBlocksFormTypes.TextLong
    },
    {
      label: t('journey-step.form.blocks.form.type.single-ratio', 'Single Ratio (Yes/No)'),
      value: MessageBlocksFormTypes.SingleRatio
    },
    {
      label: t('journey-step.form.blocks.form.type.multiple-choice', 'Multiple choice'),
      value: MessageBlocksFormTypes.MultipleChoice
    },
    {
      label: t('journey-step.form.blocks.form.type.options-list', 'Options List'),
      value: MessageBlocksFormTypes.OptionsList
    },
    {
      label: t('journey-step.form.blocks.form.type.link', 'Link'),
      value: MessageBlocksFormTypes.Link
    },
    {
      label: t('journey-step.form.blocks.form.type.date', 'Date'),
      value: MessageBlocksFormTypes.Date
    },
    {
      label: t('journey-step.form.blocks.form.type.profile-picture', 'Employee profile picture'),
      value: MessageBlocksFormTypes.PersonProfilePicture
    },
    {
      label: t('journey-step.form.blocks.form.type.short-name', 'Employee short name'),
      value: MessageBlocksFormTypes.PersonShorName
    },
    {
      label: t('journey-step.form.blocks.form.type.birthdate', 'Employee birthdate'),
      value: MessageBlocksFormTypes.PersonBirthdate
    },
    {
      label: t('journey-step.form.blocks.form.type.birthdate-celebrate', 'Employee celebrate birthdate (Yes/No)'),
      value: MessageBlocksFormTypes.PersonBirthdateCelebrate
    }
  ];

  return (
    <div style={{ padding: '10px' }}>
      {groupFields.map((field: MessageBlockFormField, fieldIndex: number) => (
        <Elevation
          key={field.id}
          style={{
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: '4px',
            marginTop: '10px',
            padding: '15px',
            borderLeftColor: 'var(--mdc-theme-primary)',
            borderLeftWidth: '2px'
          }}
        >
          <BlockTitleActions
            index={fieldIndex}
            handleRemoveBlock={() => removeGroupField(fieldIndex)}
            handleMoveUpBlock={handleMoveUpGroupField}
            handleMoveDownBlock={handleMoveDownGroupField}
          >
            <label htmlFor={`message_blocks.${index}.group.field_type`}>
              <Trans i18nKey="journey-step.form.blocks.form.type.label">Field form type</Trans> {fieldIndex + 1}
            </label>
          </BlockTitleActions>
          <Controller
            control={control}
            defaultValue={field.field_type || 'TEXT'}
            name={`message_blocks.${index}.group.${fieldIndex}.field_type`}
            rules={{ required: t('journey-step.form.blocks.form.type.required', 'Form field type is required.') as string }}
            render={({ onChange, onBlur, value, name }): React.ReactElement => (
              <>
                <Select
                  enhanced
                  options={groupFormFieldTypeOptions}
                  id={name}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  placeholder={t('journey-step.form.blocks.form.type.placeholder')}
                  icon="view_list"
                  invalid={errors?.message_blocks?.[index]?.group?.[fieldIndex]?.field_type?.message ? true : false}
                  aria-invalid={errors?.message_blocks?.[index]?.group?.[fieldIndex]?.field_type?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />

                <TextQuestion
                  control={control}
                  property="question"
                  labelProps={{
                    labelText: 'Question or Title',
                    htmlFor: `message_blocks.${index}.group.${fieldIndex}.question`,
                    i18nKey: 'journey-step.form.blocks.form.question.label'
                  }}
                  controllerName={`message_blocks.${index}.group.${fieldIndex}.question`}
                  requiredProps={{ i18nKey: 'journey-step.form.blocks.form.question.required', requiredText: 'Question is required' }}
                  block={field}
                  invalid={errors?.message_blocks?.[index]?.group?.[fieldIndex]?.question?.message}
                  isLoading={isLoading}
                />

                <TextQuestion
                  control={control}
                  property="description"
                  labelProps={{
                    labelText: 'Description (optional)',
                    htmlFor: `message_blocks.${index}.group.${fieldIndex}.description`,
                    i18nKey: 'journey-step.form.blocks.form.description.label'
                  }}
                  controllerName={`message_blocks.${index}.group.${fieldIndex}.description`}
                  block={field}
                  bigTextField={true}
                  invalid={errors?.message_blocks?.[index]?.group?.[fieldIndex]?.description?.message}
                  isLoading={isLoading}
                />

                {value === MessageBlocksFormTypes.MultipleChoice && (
                  <MultipleChoiceOptions control={control} block={field} index={index} groupIndex={fieldIndex} errors={errors} isLoading={!!isLoading} />
                )}

                {value === MessageBlocksFormTypes.Title && (
                  <TextQuestion
                    control={control}
                    property="image_url"
                    labelProps={{
                      labelText: 'Image URL (optional)',
                      htmlFor: `message_blocks.${index}.group.${fieldIndex}.image_url`,
                      i18nKey: 'journey-step.form.blocks.form.image_url.label'
                    }}
                    controllerName={`message_blocks.${index}.group.${fieldIndex}.description`}
                    block={field}
                    invalid={errors?.message_blocks?.[index]?.group?.[fieldIndex]?.image_url?.message}
                    isLoading={isLoading}
                  />
                )}

                {value === MessageBlocksFormTypes.OptionsList && (
                  <OptionsList control={control} block={field} index={index} groupIndex={fieldIndex} errors={errors} isLoading={!!isLoading} />
                )}

                <RequiredFieldSwitch control={control} controllerName={`message_blocks.${index}.group.${fieldIndex}.required`} block={field} />
              </>
            )}
          />
        </Elevation>
      ))}
      <Button icon="add" outlined onClick={handleAddGroupField} style={{ width: '100%', marginTop: '10px' }}>
        <Trans i18nKey="journey-step.form.blocks.form.group.add-field">Add field on group</Trans>
      </Button>
    </div>
  );
}
