import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useForm, Controller, FieldValues } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { GridRow, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Select } from '@rmwc/select';
import '@rmwc/select/styles';

import { TextField } from '@rmwc/textfield';
import '@rmwc/textfield/styles';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/circular-progress.css';

import { Typography } from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

const fullWithStyle = {
  minWidth: '100%',
  backgroundColor: 'whitesmoke'
} as React.CSSProperties;

function JourneyForm({ onFormSubmit, onCancel, defaultValues, isLoading }: InferProps<typeof JourneyForm.propTypes>): JSX.Element {
  const { t } = useTranslation();

  const { control, handleSubmit, errors } = useForm({ defaultValues });

  const onSubmit = async (values: FieldValues): Promise<void> => {
    onFormSubmit(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GridRow>
        <GridCell span={12}>
          <Controller
            control={control}
            defaultValue="ONBOARDING"
            name="flow_type"
            render={({ onChange, onBlur, value, name }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="journey.form.flow_type.label">What is your journey type?</Trans>
                  </label>
                </Typography>
                <Select
                  options={{
                    ONBOARDING: t('journey.form.flow_type.onboarding'),
                    OFFBOARDING: t('journey.form.flow_type.offboarding'),
                    COMMUNICATION: t('journey.form.flow_type.communication')
                  }}
                  id={name}
                  disabled={defaultValues?.id}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  invalid={errors?.flow_type?.message ? true : false}
                  aria-invalid={errors?.flow_type?.message ? true : false}
                  style={fullWithStyle}
                  enhanced={true}
                />
              </>
            )}
          />
          {errors?.flow_type?.message && <div role="alert">{errors?.flow_type?.message}</div>}
        </GridCell>
        <GridCell span={12}>
          <Controller
            control={control}
            defaultValue=""
            name="name"
            rules={{ required: t('journey.form.name.required', 'Name is required.') as string }}
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="journey.form.name.label">What is your journey name?</Trans>
                  </label>
                </Typography>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  placeholder={t('journey.form.name.placeholder')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.name?.message ? true : false}
                  aria-invalid={errors?.name?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />
              </>
            )}
          />
          {errors?.name?.message && <div role="alert">{errors?.name?.message}</div>}
        </GridCell>
        <GridCell span={12}>
          <Controller
            control={control}
            defaultValue=""
            name="description"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="journey.form.description.label">Would you like do describe your journey? (optional)</Trans>
                  </label>
                </Typography>
                <TextField
                  type="text"
                  textarea
                  rows={3}
                  id={name}
                  name={name}
                  placeholder={t('journey.form.description.placeholder')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.description?.message ? true : false}
                  aria-invalid={errors?.description?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />
              </>
            )}
          />
          {errors?.description?.message && <div role="alert">{errors?.description?.message}</div>}
        </GridCell>
        <GridCell span={12} style={{ textAlign: 'right' }}>
          <Button type="button" onClick={onCancel} style={{ marginRight: '1.5rem' }}>
            <Trans i18nKey="crud.cancel">Cancel</Trans>
          </Button>
          <Button type="submit" raised {...(isLoading ? { icon: <CircularProgress theme="secondary" /> } : {})} style={{ minWidth: '150px' }}>
            {defaultValues && defaultValues.id ? <Trans i18nKey="journey.save-update">Save</Trans> : <Trans i18nKey="journey.save-new">Save</Trans>}
          </Button>
        </GridCell>
      </GridRow>
    </form>
  );
}

JourneyForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  defaultValues: PropTypes.any,
  isLoading: PropTypes.bool
};

export default JourneyForm;
