import React from 'react';
import { Control, FieldErrors } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { CustomizableOption } from './CustomizableOption';
import { Typography } from 'rmwc';
import { parseOptionsDefaultValues } from './ScoreOptions';

export function LikertAgreementOptions({
  control,
  block,
  index,
  errors,
  isLoading
}: {
  control: Control;
  block: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  index: number;
  errors: FieldErrors;
  isLoading: boolean;
}): JSX.Element {
  const { t } = useTranslation();

  const optionsDefaultValues = parseOptionsDefaultValues(block.options);
  const validOptionsArray = optionsDefaultValues && optionsDefaultValues?.length > 0;

  return (
    <>
      <Typography use="body1" tag="p">
        <label htmlFor="journey-step.form.blocks.poll.type.likert-agreement">
          <Trans i18nKey="journey-step.form.blocks.poll.type.likert-agreement">Likert agreement scale</Trans>
        </label>
      </Typography>
      <CustomizableOption
        control={control}
        defaultValue={validOptionsArray ? optionsDefaultValues[0].value : t('journey-step.form.blocks.poll.likert-options.strongly-agree.value', 'Strongly agree 😍')}
        controllerName={`message_blocks.${index}.options.0.value`}
        requiredProps={{ i18nKey: 'journey-step.form.blocks.poll.options.required', requiredText: 'Option text is required' }}
        placeholderTag="journey-step.form.blocks.poll.likert-options.strongly-agree.value"
        invalid={errors?.message_blocks?.[index]?.options?.[0]?.message}
        isLoading={isLoading}
      />

      <CustomizableOption
        control={control}
        defaultValue={validOptionsArray ? optionsDefaultValues[1].value : t('journey-step.form.blocks.poll.likert-options.agree.value', 'Agree 😃')}
        controllerName={`message_blocks.${index}.options.1.value`}
        requiredProps={{ i18nKey: 'journey-step.form.blocks.poll.options.required', requiredText: 'Option text is required' }}
        placeholderTag="journey-step.form.blocks.poll.likert-options.agree.value"
        invalid={errors?.message_blocks?.[index]?.options?.[1]?.message}
        isLoading={isLoading}
      />

      <CustomizableOption
        control={control}
        defaultValue={validOptionsArray ? optionsDefaultValues[2].value : t('journey-step.form.blocks.poll.likert-options.neutral.value', 'Neither agree nor disagree 😐')}
        controllerName={`message_blocks.${index}.options.2.value`}
        requiredProps={{ i18nKey: 'journey-step.form.blocks.poll.options.required', requiredText: 'Option text is required' }}
        placeholderTag="journey-step.form.blocks.poll.likert-options.neutral.value"
        invalid={errors?.message_blocks?.[index]?.options?.[2]?.message}
        isLoading={isLoading}
      />

      <CustomizableOption
        control={control}
        defaultValue={validOptionsArray ? optionsDefaultValues[3].value : t('journey-step.form.blocks.poll.likert-options.disagree.value', 'Disagree 😕')}
        controllerName={`message_blocks.${index}.options.3.value`}
        requiredProps={{ i18nKey: 'journey-step.form.blocks.poll.options.required', requiredText: 'Option text is required' }}
        placeholderTag="journey-step.form.blocks.poll.likert-options.disagree.value"
        invalid={errors?.message_blocks?.[index]?.options?.[3]?.message}
        isLoading={isLoading}
      />

      <CustomizableOption
        control={control}
        defaultValue={validOptionsArray ? optionsDefaultValues[4].value : t('journey-step.form.blocks.poll.likert-options.strongly-disagree.value', 'Strongly disagree 😖')}
        controllerName={`message_blocks.${index}.options.4.value`}
        requiredProps={{ i18nKey: 'journey-step.form.blocks.poll.options.required', requiredText: 'Option text is required' }}
        placeholderTag="journey-step.form.blocks.poll.likert-options.strongly-disagree.value"
        invalid={errors?.message_blocks?.[index]?.options?.[4]?.message}
        isLoading={isLoading}
      />
    </>
  );
}
