/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Helmet } from 'react-helmet';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Icon } from '@rmwc/icon';
import '@rmwc/icon/icon.css';

// import { Card } from '@rmwc/card';
// import '@material/card/dist/mdc.card.css';

// import { Theme } from '@rmwc/theme';
// import { Typography } from '@rmwc/typography';
// import '@material/typography/dist/mdc.typography.css';

import { Elevation } from '@rmwc/elevation';
import '@material/elevation/dist/mdc.elevation.css';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { CompanyConfigurationContext } from '../common/Contexts/appContexts';
import { ReportDashboardType } from '../pages/Analytics/ResportsDashboardsRepository';

import OrganisationGrowthChart from './OrganisationGrowthChart';
import EmployeeNPSChart from './EmployeeNPSChart';
import HiresGenderChart from './HiresGenderChart';
import HiresAgeGenerationChart from './HiresAgeGenerationChart';
import EmployeeNPSGenderCorrelationChart from './EmployeeNPSGenderCorrelationChart';
import EmployeeNPSCostCenterChart from './EmployeeNPSCostCenterChart';
import MetabaseAnalytics from '../MetabaseAnalytics';

// const metricsCardStyle = {
//   textAlign: 'center',
//   padding: '30px 0'
// } as any;

function Dashboard(): JSX.Element {
  const companyConfigurationValue = React.useContext(CompanyConfigurationContext);
  const dashboardId = companyConfigurationValue.dashboard_id;
  return (
    <>
      <Helmet>
        <title>Inboarding App</title>
      </Helmet>
      {dashboardId ? <MetabaseAnalytics dashboardName={ReportDashboardType.HOME} showPrintExport={false} /> : <DefaultNoDataDashboard />}
    </>
  );
}

function DefaultNoDataDashboard(): JSX.Element {
  return (
    <>
      <Grid>
        <GridCell span={12}>
          <Elevation z={1} style={{ backgroundColor: 'var(--mdc-theme-yellow)', padding: '5px 25px', borderRadius: '.5rem' }}>
            <p>
              <Icon icon="warning_amber" style={{ marginRight: '10px', verticalAlign: 'bottom' }} />
              <Trans i18nKey="dashboard.demo-data">
                You don&apos;t have sufficiente data to load your Dashboard. Here we are loading demonstration data to help you visualize it.
              </Trans>
            </p>
          </Elevation>
        </GridCell>
        <GridCell span={12}>
          <OrganisationGrowthChart></OrganisationGrowthChart>
        </GridCell>
        <GridCell span={12}>
          <EmployeeNPSChart></EmployeeNPSChart>
        </GridCell>
        <GridCell span={6}>
          <HiresGenderChart></HiresGenderChart>
        </GridCell>
        <GridCell span={6}>
          <HiresAgeGenerationChart></HiresAgeGenerationChart>
        </GridCell>
        <GridCell span={12}>
          <EmployeeNPSCostCenterChart></EmployeeNPSCostCenterChart>
        </GridCell>
        <GridCell span={12}>
          <EmployeeNPSGenderCorrelationChart></EmployeeNPSGenderCorrelationChart>
          <Button raised type="button" tag={Link} {...{ to: '/analytics' }} style={{ marginTop: '30px', marginBottom: '40px' }}>
            <Trans i18nKey="dashboard.more-correlation-analytics">Access Analytics to see more correlations</Trans>
          </Button>
        </GridCell>

        {/* <GridCell desktop={4} phone={12}>
            <Card style={metricsCardStyle}>
              <Theme use="secondary">
                <Typography use="button">Employees</Typography>
              </Theme>
              <Typography use="headline3">50</Typography>
            </Card>
          </GridCell>
          <GridCell desktop={4} phone={12}>
            <Card style={metricsCardStyle}>
              <Theme use="secondary">
                <Typography use="button">eNPS</Typography>
              </Theme>
              <Typography use="headline3">75</Typography>
            </Card>
          </GridCell>
          <GridCell desktop={4} phone={12}>
            <Card style={metricsCardStyle}>
              <Theme use="secondary">
                <Typography use="button">New Hires</Typography>
              </Theme>
              <Typography use="headline3">4</Typography>
            </Card>
          </GridCell> */}
      </Grid>
    </>
  );
}

export default Dashboard;
