import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import AppLoading from '../AppLoading';
import ErrorNotAuthorizedMessage from '../ErrorMessage/ErrorNotAuthorizedMessage';

import authService from '../../services/AuthService';
import { AuthUserAtrributes } from '../../services/AuthService/AuthService';
import { Typography } from 'rmwc';
import IntegrationsService from '../Settings/IntegrationSettings/IntegrationsService';
import Message from '../../services/Message';

function useQuerySearchParams(): URLSearchParams {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * Slack redirect URL after user authorization:
 * https://app-staging.inboarding.co/slack/app/installed?code=1445235784865.3565687318434.34a03f41370ae821f1db1c6f8effd3466d45d6ce9c89b7d6af5e447027e3e13a&state=
 */
function SlackAppInstalled(): JSX.Element {
  const { t } = useTranslation();
  const queryParams = useQuerySearchParams();
  const integrationsService = new IntegrationsService();
  const [isFetching, setIsFetching] = React.useState(true);
  const [error, setError] = React.useState('');
  const [authUser, setAuthUser] = React.useState<AuthUserAtrributes>();
  const slackCode = queryParams.get('code');

  const loadAuthUser = React.useCallback(async (): Promise<void> => {
    if (authUser && authUser.email) {
      setIsFetching(false);
      return;
    }
    try {
      const authUser = authService.getAuthUser();
      if (authUser) {
        setAuthUser(authUser);
        setIsFetching(false);
      } else {
        setError('Usuário não autorizado.');
        setIsFetching(false);
      }
    } catch (error) {
      console.error('Error processing Slack app installed.', error);
      setError('Erro ao verificar instalação do app.');
    }
  }, [authUser]);

  React.useEffect(() => {
    loadAuthUser();
  });

  if (error) {
    return (
      <>
        <ErrorNotAuthorizedMessage errorMessage={error} />;
        <Grid>
          <GridCell span={12} align="middle" style={{ textAlign: 'center' }}>
            <p>
              <Trans i18nKey="integrations.slack.auth-admin-text">
                If you do not have admin access to Inboarding, forward the URL below to an admin user to complete the installation process.
              </Trans>
            </p>
            <p>{window.location.href}</p>
          </GridCell>
        </Grid>
      </>
    );
  }

  const useSaveSlackApp = integrationsService.useSaveSlackApp();
  async function onClickContinueInstall() {
    console.log('Slack app code. ', slackCode);

    const gettingMessageId = Message.loading(t('integrations.slack.install.processing'));
    try {
      const result = await useSaveSlackApp.mutateAsync({
        tenantId: authService.tenantId(),
        code: slackCode || ''
      });
      Message.dismiss(gettingMessageId);
      if (result) {
        Message.success(t('integrations.slack.install.success'));
        setTimeout((): void => {
          window.location.href = '/settings/integrations';
        }, 400);
      } else {
        Message.error(t('integrations.slack.install.error'));
      }
    } catch (error) {
      console.error('Error saving Slack app: ', error);
      Message.error(t('integrations.slack.install.error'));
    }
  }

  return (
    <>
      <Helmet>
        <title>Slack | Inboarding</title>
      </Helmet>
      <Grid>
        <GridCell span={12} align="middle" style={{ textAlign: 'center' }}>
          <Typography use="heading3" tag="h1">
            <Trans i18nKey="integrations.slack.title">Slack app installation</Trans>
          </Typography>

          {isFetching && (
            <>
              <Typography use="body1" tag="p">
                <Trans i18nKey="integrations.slack.loading">Loading Slack app installation.</Trans>
              </Typography>
              <AppLoading />
            </>
          )}

          {!isFetching && authUser && (
            <>
              {!slackCode && (
                <Typography use="body1" tag="p">
                  <Trans i18nKey="integrations.slack.not-authorized">Slack did not authorized the app installation in your Slack workspace. Please try again our contact us.</Trans>
                </Typography>
              )}
              {slackCode && (
                <>
                  <Typography use="body1" tag="p">
                    <Trans i18nKey="integrations.slack.authorized">
                      Slack app authorized. We are almost there, here it is the last step to confirm and install the Slack app at Inboarding.
                    </Trans>
                  </Typography>
                  <p>
                    <Button raised onClick={onClickContinueInstall}>
                      <Trans i18nKey="integrations.slack.install-continue-button">Confirm and install the Slack app at Inboarding</Trans>
                    </Button>
                  </p>
                </>
              )}
            </>
          )}
        </GridCell>
      </Grid>
    </>
  );
}

export default SlackAppInstalled;
