import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import AdminUsersService from '../../Settings/UsersSettings/AdminUsersService';
import Message from '../../../services/Message';

function ContentsViewPortalButton(): JSX.Element {
  const { t } = useTranslation();
  const userService = new AdminUsersService();

  const useGetPortalLink = userService.useGetPortalLink();

  async function onOpenEmployeePortal() {
    const gettingMessageId = Message.loading(t('admin-users.portal-link.processing'));
    try {
      const result = await useGetPortalLink.mutateAsync({});
      Message.dismiss(gettingMessageId);
      if (result?.url) {
        Message.success(t('admin-users.portal-link.sucess'));
        window.open(result.url, '_blank');
      } else {
        Message.error(t('admin-users.portal-link.error'));
      }
    } catch (error) {
      console.error('Error getting employee portal link: ', error);
      Message.error(t('admin-users.portal-link.error'));
    }
  }
  return (
    <Button outlined onClick={onOpenEmployeePortal}>
      <Trans i18nKey="contents.portal.access-button">View in portal</Trans>
    </Button>
  );
}

export default ContentsViewPortalButton;
