import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import EmployeeService from './EmployeeService';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/circular-progress.css';

import LoadingModal from '../LoadingModal';
import EmployeeOffboardingTable from './EmployeeOffboardingTable';
import ErrorMessage from '../ErrorMessage';
import Breadcrumb from '../common/Breadcrumb';
import EllaMessage from '../common/EllaMessage';

const LIST_SIZE = 15;

function EmployeeOffboarding({ history }: InferProps<typeof EmployeeOffboarding.propTypes>): JSX.Element {
  const { t } = useTranslation();
  const employeeService = new EmployeeService();

  const searchFilter = { limit: LIST_SIZE, query: '' };

  const { data, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage, isError, error } = employeeService.useListOffboardingSearch(searchFilter, LIST_SIZE);

  async function onClickEmployee(values: { id: string }): Promise<void> {
    if (history) history.push(`/employees/${values.id}`);
  }

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching && !isFetchingNextPage && !searchFilter.query) {
    return <LoadingModal open={true} />;
  }

  const employeeData = data?.pages.flat();
  const dataLength = employeeData ? employeeData.length : 0;
  const hasDataCreated = !!searchFilter.query || !!dataLength;

  return (
    <Grid>
      <GridCell span={12}>
        <Breadcrumb routeSegments={[{ name: t('navlink.employees') }]} />
      </GridCell>
      <GridCell span={4} align="middle">
        <h2>
          <Trans i18nKey="employee-offboarding.title">Employees</Trans>
        </h2>
      </GridCell>
      {hasDataCreated ? (
        <>
          <GridCell span={12}>
            <EmployeeOffboardingTable employees={employeeData} onRowClick={onClickEmployee}></EmployeeOffboardingTable>
          </GridCell>

          {hasNextPage && (
            <GridCell span={12} style={{ textAlign: 'center' }}>
              <Button
                outlined
                {...(isFetching ? { icon: <CircularProgress theme="secondary" /> } : {})}
                disabled={isFetching}
                onClick={(): void => {
                  fetchNextPage();
                }}
              >
                <Trans i18nKey="employee.load-more">Load More</Trans>
              </Button>
            </GridCell>
          )}
        </>
      ) : (
        <FirstEmployeeInstruction></FirstEmployeeInstruction>
      )}
    </Grid>
  );
}

function FirstEmployeeInstruction(): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <GridCell span={12} align="middle">
        <EllaMessage title={t('employee-offboarding.nodata.title')} message={t('employee-offboarding.nodata.text')} />
      </GridCell>
      <GridCell span={12} style={{ textAlign: 'center' }}>
        <Button outlined tag={Link} {...{ to: '/settings/integrations' }} style={{ marginRight: '15px', marginBottom: '15px' }}>
          <Trans i18nKey="employee-offboarding.add-integration">Integration with other systems</Trans>
        </Button>
        <Button raised icon="add" tag={Link} {...{ to: '/employees' }} style={{ marginBottom: '15px' }}>
          <Trans i18nKey="employee-offboarding.update-turnover">Update employee turnover</Trans>
        </Button>
      </GridCell>
    </>
  );
}

EmployeeOffboarding.propTypes = {
  history: PropTypes.any
};

export default withRouter(EmployeeOffboarding);
