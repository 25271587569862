import React from 'react';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { ListDivider, Button, Typography, Select, Elevation } from 'rmwc';
import { fullWithStyle, BlockTitleActions } from '../../StepJourneyForm';
import { TextQuestion } from '../FieldTypes/TextQuestion';
import { MultipleChoiceOptions } from '../FieldTypes/MultipleChoiceOptions';
import { RequiredFieldSwitch } from '../FieldTypes/RequiredFieldSwitch';
import { FieldsGroup } from '../FieldTypes/FieldsGroup';
import { MessageBlocksPollTypes } from './PollBlockType';
import { LikertAgreementOptions } from '../FieldTypes/LikertAgreementOptions';
import { ScoreOptions } from '../FieldTypes/ScoreOptions';
import { AskFollowUpQuestion } from '../FieldTypes/AskFollowUpQuestion';
import { OptionsList } from '../FieldTypes/OptionsList';

export enum MessageBlocksFormTypes {
  Group = 'GROUP',
  Title = 'TITLE',
  Text = 'TEXT',
  TextLong = 'TEXT-LONG',
  SingleRatio = 'SINGLE-RATIO',
  MultipleChoice = 'MULTIPLE-CHOICE',
  OptionsList = 'OPTIONS-LIST',
  Date = 'DATE',
  Number = 'NUMBER',
  Email = 'EMAIL',
  Link = 'URL',
  File = 'FILE',
  PersonProfilePicture = 'PROFILE-PICTURE',
  PersonShorName = 'SHORT-NAME',
  PersonBirthdate = 'BIRTHDATE',
  PersonBirthdateCelebrate = 'BIRTHDATE-CELEBRATE'
}

export type MessageBlockFormField = {
  id?: string;
  start_button?: string;
  required?: boolean;
  multiple?: boolean;
  field_type?: MessageBlocksFormTypes;
  value?: string;
  options?: string[];
  question?: string;
  description?: string;
  link?: string;
  image_url?: string;
  group?: MessageBlockFormField[];
};

type FieldType = {
  fieldLabel: string;
  fieldValue: MessageBlocksFormTypes | MessageBlocksPollTypes;
};

export function FormBlockType({
  control,
  block,
  index,
  blocksLength,
  errors,
  isLoading,
  handleRemoveBlock,
  handleMoveUpBlock,
  handleMoveDownBlock,
  handleAddForm
}: {
  control: Control;
  block: MessageBlockFormField;
  index: number;
  blocksLength: number;
  errors: FieldErrors;
  isLoading: boolean;
  handleRemoveBlock: (index: number, name: string) => void;
  handleMoveUpBlock: (index: number) => void;
  handleMoveDownBlock: (index: number) => void;
  handleAddForm: () => void;
}): JSX.Element {
  const { t } = useTranslation();

  const blockFormFieldTypeOptions: Record<string, FieldType[]> = {
    form: [
      {
        fieldLabel: t('journey-step.form.blocks.form.type.group', 'Fields group'),
        fieldValue: MessageBlocksFormTypes.Group
      },
      {
        fieldLabel: t('journey-step.form.blocks.form.type.title', 'Title and description'),
        fieldValue: MessageBlocksFormTypes.Title
      },
      {
        fieldLabel: t('journey-step.form.blocks.form.type.text', 'Short text'),
        fieldValue: MessageBlocksFormTypes.Text
      },
      {
        fieldLabel: t('journey-step.form.blocks.form.type.text-long', 'Long text'),
        fieldValue: MessageBlocksFormTypes.TextLong
      },
      {
        fieldLabel: t('journey-step.form.blocks.form.type.single-ratio', 'Single Ratio (Yes/No)'),
        fieldValue: MessageBlocksFormTypes.SingleRatio
      },
      {
        fieldLabel: t('journey-step.form.blocks.form.type.multiple-choice', 'Multiple choice'),
        fieldValue: MessageBlocksFormTypes.MultipleChoice
      },
      {
        fieldLabel: t('journey-step.form.blocks.form.type.options-list', 'Options List'),
        fieldValue: MessageBlocksFormTypes.OptionsList
      },
      {
        fieldLabel: t('journey-step.form.blocks.form.type.link', 'Link'),
        fieldValue: MessageBlocksFormTypes.Link
      },
      {
        fieldLabel: t('journey-step.form.blocks.form.type.date', 'Date'),
        fieldValue: MessageBlocksFormTypes.Date
      },
      {
        fieldLabel: t('journey-step.form.blocks.form.type.profile-picture', 'Employee profile picture'),
        fieldValue: MessageBlocksFormTypes.PersonProfilePicture
      },
      {
        fieldLabel: t('journey-step.form.blocks.form.type.short-name', 'Employee short name'),
        fieldValue: MessageBlocksFormTypes.PersonShorName
      },
      {
        fieldLabel: t('journey-step.form.blocks.form.type.birthdate', 'Employee birthdate'),
        fieldValue: MessageBlocksFormTypes.PersonBirthdate
      },
      {
        fieldLabel: t('journey-step.form.blocks.form.type.birthdate-celebrate', 'Employee celebrate birthdate (Yes/No)'),
        fieldValue: MessageBlocksFormTypes.PersonBirthdateCelebrate
      }
    ],
    poll: [
      {
        fieldLabel: t('journey-step.form.blocks.poll.type.likert-agreement', 'Likert agreement scale'),
        fieldValue: MessageBlocksPollTypes.Likert
      },
      {
        fieldLabel: t('journey-step.form.blocks.poll.type.score', 'Score options'),
        fieldValue: MessageBlocksPollTypes.Score
      },
      {
        fieldLabel: t('journey-step.form.blocks.poll.type.enps', 'eNPS'),
        fieldValue: MessageBlocksPollTypes.ENPS
      }
    ]
  };

  const pollFieldTypes = [MessageBlocksPollTypes.Likert, MessageBlocksPollTypes.Score, MessageBlocksPollTypes.ENPS];

  return (
    <>
      <div style={{ padding: '0 10px' }}>
        {index === 0 && (
          <Typography use="body1" tag="p" style={{ margin: '0', padding: '10px 0', display: 'inline-block' }}>
            <Trans i18nKey="journey-step.blocks.form.title">Form</Trans>
          </Typography>
        )}
      </div>
      {index === 0 && <ListDivider />}
      <div style={{ padding: '10px' }}>
        {index === 0 && (
          <TextQuestion
            control={control}
            property="start_button"
            labelProps={{ labelText: 'Email button label', htmlFor: `message_blocks.${index}.start_button`, i18nKey: 'journey-step.form.blocks.form.start_button.label' }}
            controllerName={`message_blocks.${index}.start_button`}
            invalid={errors?.message_blocks?.[index]?.start_button?.message}
            requiredProps={{ i18nKey: 'journey-step.form.blocks.form.start_button.required', requiredText: 'Email button label is required.' }}
            block={block}
            isLoading={isLoading}
          />
        )}

        <Elevation
          key={block.id}
          style={{
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: '4px',
            marginTop: '10px',
            padding: '15px',
            borderLeftColor: 'var(--mdc-theme-primary)',
            borderLeftWidth: '2px'
          }}
        >
          <BlockTitleActions index={index} handleRemoveBlock={handleRemoveBlock} handleMoveUpBlock={handleMoveUpBlock} handleMoveDownBlock={handleMoveDownBlock}>
            <label htmlFor={`message_blocks.${index}.field_type`}>
              <Trans i18nKey="journey-step.form.blocks.form.type.label">Field form type</Trans> {index + 1}
            </label>
          </BlockTitleActions>

          <Controller
            control={control}
            defaultValue={block.field_type || 'TEXT'}
            name={`message_blocks.${index}.field_type`}
            rules={{ required: t('journey-step.form.blocks.form.type.required', 'Form field type is required.') as string }}
            render={({ onChange, onBlur, value, name }): React.ReactElement => (
              <>
                <SelectFieldTypes
                  options={blockFormFieldTypeOptions}
                  value={value}
                  name={name}
                  invalid={errors?.message_blocks?.[index]?.field_type?.message}
                  isLoading={isLoading}
                  onChange={onChange}
                  onBlur={onBlur}
                />

                <TextQuestion
                  control={control}
                  property="question"
                  labelProps={{ labelText: 'Question or Title', htmlFor: `message_blocks.${index}.question`, i18nKey: 'journey-step.form.blocks.form.question.label' }}
                  controllerName={`message_blocks.${index}.question`}
                  invalid={errors?.message_blocks?.[index]?.question?.message}
                  requiredProps={{ i18nKey: 'journey-step.form.blocks.form.question.required', requiredText: 'Question is required' }}
                  block={block}
                  isLoading={isLoading}
                />

                <TextQuestion
                  control={control}
                  property="description"
                  labelProps={{ labelText: 'Description (optional)', htmlFor: `message_blocks.${index}.description`, i18nKey: 'journey-step.form.blocks.form.description.label' }}
                  controllerName={`message_blocks.${index}.description`}
                  invalid={errors?.message_blocks?.[index]?.description?.message}
                  block={block}
                  isLoading={isLoading}
                  bigTextField={true}
                />

                {value === MessageBlocksFormTypes.MultipleChoice && <MultipleChoiceOptions control={control} block={block} index={index} errors={errors} isLoading={!!isLoading} />}

                {value === MessageBlocksFormTypes.Title && (
                  <TextQuestion
                    control={control}
                    property="image_url"
                    labelProps={{ labelText: 'Image URL (optional)', htmlFor: `message_blocks.${index}.image_url`, i18nKey: 'journey-step.form.blocks.form.image_url.label' }}
                    controllerName={`message_blocks.${index}.image_url`}
                    invalid={errors?.message_blocks?.[index]?.image_url?.message}
                    block={block}
                    isLoading={isLoading}
                  />
                )}

                {value === MessageBlocksFormTypes.Group && <FieldsGroup control={control} index={index} errors={errors} isLoading={isLoading} />}

                {value === MessageBlocksPollTypes.Likert && <LikertAgreementOptions control={control} block={block} index={index} errors={errors} isLoading={!!isLoading} />}

                {value === MessageBlocksPollTypes.Score && <ScoreOptions control={control} block={block} index={index} errors={errors} isLoading={!!isLoading} />}

                {value === MessageBlocksFormTypes.OptionsList && <OptionsList control={control} block={block} index={index} errors={errors} isLoading={!!isLoading} />}

                {value !== MessageBlocksFormTypes.Group && <RequiredFieldSwitch control={control} controllerName={`message_blocks.${index}.required`} block={block} />}

                {pollFieldTypes.includes(value) && <AskFollowUpQuestion control={control} block={block} index={index} errors={errors} isLoading={isLoading} />}
              </>
            )}
          />
        </Elevation>
        {blocksLength === index + 1 && (
          <Button outlined onClick={handleAddForm} style={{ width: '100%', marginTop: '10px' }}>
            <Trans i18nKey="journey-step.form.blocks.add-field">Add another field</Trans>
          </Button>
        )}
      </div>
    </>
  );
}

function SelectFieldTypes({
  options,
  value,
  name,
  invalid,
  isLoading,
  onChange,
  onBlur
}: {
  options: Record<string, FieldType[]>;
  value: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  name: string;
  invalid: string;
  isLoading: boolean;
  onChange: (...event: any[]) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
  onBlur: () => void;
}): JSX.Element {
  return (
    <Select
      id={name}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      icon="view_list"
      invalid={invalid ? true : false}
      aria-invalid={invalid ? true : false}
      disabled={!!isLoading}
      style={fullWithStyle}
    >
      {Object.entries(options).map(([blockTypeLabel, blockTypeOptions]) => (
        <optgroup label={getSelectBlockTypeLabel(blockTypeLabel)} key={blockTypeLabel}>
          {blockTypeOptions.map(({ fieldLabel, fieldValue }: { fieldLabel: string; fieldValue: MessageBlocksFormTypes | MessageBlocksPollTypes }) => (
            <option value={fieldValue} key={fieldValue}>
              {fieldLabel}
            </option>
          ))}
        </optgroup>
      ))}
    </Select>
  );
}

function getSelectBlockTypeLabel(label: string): string {
  const { t } = useTranslation();

  return t(`journey-step.blocks.${label}.title`, label);
}
