import React from 'react';
import Chart from '../Chart';
import { Trans, useTranslation } from 'react-i18next';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Theme } from '@rmwc/theme';
import { Typography } from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

function OrganisationGrowthChart(): JSX.Element {
  const { t } = useTranslation();
  const headCountTitle = t('organisation-chart.headcount');
  const newJoinerTitle = t('organisation-chart.new-joiners');
  const leaversTitle = t('organisation-chart.leavers');

  const textColor = '#303030';
  //   const style = { height: '100px' };
  const style = {};
  const option = {
    grid: {
      top: '10%',
      bottom: '10%',
      left: '5%',
      right: '5%'
    },
    legend: {
      itemGap: 20,
      //   icon: 'circle',
      textStyle: {
        color: textColor,
        fontSize: 13,
        fontFamily: 'PT Sans'
      }
    },
    tooltip: {},
    xAxis: {
      type: 'category',
      data: ['Set', 'Oct', 'Nov', 'Dec', '2021', 'Fev', 'Mar'],
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        color: textColor,
        fontSize: 14,
        fontFamily: 'PT Sans'
      }
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      splitLine: {
        // show: false
        lineStyle: {
          color: textColor,
          opacity: 0.1
        }
      },
      axisLabel: {
        color: textColor,
        fontSize: 13,
        fontFamily: 'PT Sans'
      }
    },
    series: [
      {
        data: [30, 44, 49, 66, 80, 88, 98],
        type: 'line',
        name: headCountTitle,
        smooth: true,
        symbol: 'circle',
        symbolSize: 7,
        lineStyle: {
          width: 2
        }
      },
      {
        data: [11, 15, 10, 20, 16, 10, 11],
        type: 'line',
        name: newJoinerTitle,
        smooth: true,
        symbol: 'triangle',
        symbolSize: 7,
        lineStyle: {
          width: 2
        }
      },
      {
        data: [2, 1, 5, 3, 2, 2, 1],
        type: 'line',
        name: leaversTitle,
        smooth: true,
        symbol: 'rect',
        symbolSize: 7,
        lineStyle: {
          width: 2
        }
      }
    ],
    colors: []
  };
  return (
    <Grid>
      <GridCell span={12}>
        <h2>
          <Typography use="headline5">
            <Trans i18nKey="organisation-chart.title">Organisation Growth</Trans>{' '}
          </Typography>
        </h2>
      </GridCell>
      <GridCell span={3} align="middle">
        <div>
          <Typography use="headline4">98 </Typography>
          <Theme use="secondary">
            <Typography use="button">{headCountTitle}</Typography>
          </Theme>
        </div>
        <div>
          <Typography use="headline4">93 </Typography>
          <Theme use="secondary">
            <Typography use="button">{newJoinerTitle}</Typography>
          </Theme>
        </div>
        <div>
          <Typography use="headline4">16 </Typography>
          <Theme use="secondary">
            <Typography use="button">{leaversTitle}</Typography>
          </Theme>
        </div>
      </GridCell>
      <GridCell span={9}>
        <Chart option={option} style={style} />
      </GridCell>
    </Grid>
  );
}

export default OrganisationGrowthChart;
