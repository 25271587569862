import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import { Employee, EmployeeCustomFields } from './EmployeeRepository';

import { Grid, GridRow, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { DataTable, DataTableContent, DataTableBody, DataTableRow, DataTableCell } from '@rmwc/data-table';
import '@rmwc/data-table/styles';

import { Typography } from '@rmwc/typography';
import '@rmwc/typography/styles';

import { dateFormat } from '../common/DatesFormat/dateTimeFormat';

const questionColumnStyle = {
  textOverflow: 'unset',
  wordWrap: 'break-word',
  overflowWrap: 'break-word',
  whiteSpace: 'normal'
} as React.CSSProperties;

function EmployeeViewDetails({ employee }: { employee: Employee }): JSX.Element {
  const deatilsParsedFields = parseEmployeeDetailsFields(employee);

  return (
    <Grid>
      <GridCell span={12}>
        <EmployeeDetailsValues employeeDeatils={deatilsParsedFields}></EmployeeDetailsValues>
      </GridCell>
    </Grid>
  );
}

function EmployeeDetailsValues({ employeeDeatils }: { employeeDeatils: EmployeeCustomFields[] }): JSX.Element {
  if (!employeeDeatils || !employeeDeatils.length) {
    return <EmployeeDetailsEmpty />;
  }

  return (
    <>
      <GridRow>
        <GridCell span={12}>
          <EmployeeDetailsValuesList employeeDeatils={employeeDeatils} />
        </GridCell>
      </GridRow>
    </>
  );
}

function EmployeeDetailsValuesList({ employeeDeatils }: { employeeDeatils: EmployeeCustomFields[] }): JSX.Element {
  return (
    <DataTable style={{ width: '100%' }}>
      <DataTableContent>
        <DataTableBody>
          {employeeDeatils.map(({ label, subtitle, value }) => (
            <DataTableRow key={label}>
              <DataTableCell style={questionColumnStyle}>
                <p>
                  {label}
                  {subtitle ? (
                    <>
                      <br />
                      <Typography use="caption" tag="span">
                        {subtitle}
                      </Typography>
                    </>
                  ) : null}
                </p>

                <p style={{ fontSize: '14px' }}>
                  <strong>{(value || '-') as string}</strong>
                </p>
              </DataTableCell>
            </DataTableRow>
          ))}
        </DataTableBody>
      </DataTableContent>
    </DataTable>
  );
}

function EmployeeDetailsEmpty(): JSX.Element {
  return (
    <p>
      <Trans i18nKey="employee.details.no-data">No details or preferences saved for this employee.</Trans>
    </p>
  );
}

function parseTranslateAnswer(answer: string | undefined | null): string {
  if (!answer) {
    return '-';
  }

  switch (answer) {
    case 'yes':
      return 'Sim';
    case 'no':
      return 'Não';
    case 'uploaded':
      return 'Enviada';
  }

  return answer;
}

function parseEmployeeDetailsFields(employee: Employee): EmployeeCustomFields[] {
  const { t } = useTranslation();

  let detailsFileds = [
    {
      label: t('employee.view.name.label'),
      value: `${employee.social_name || employee.first_name} ${employee.last_name}`
    },
    {
      label: t('employee.form.short_name.label'),
      subtitle: t('employee.form.short_name.help-text'),
      value: employee.short_name || '-'
    },
    {
      label: t('employee.view.birthday.label'),
      value: employee.birth_date ? dateFormat(employee.birth_date) : '-'
    },
    {
      label: t('employee.view.birthday_celebrate.label'),
      subtitle: t('employee.view.birthday_celebrate.help-text'),
      value: parseTranslateAnswer(employee.birth_date_celebrate)
    }
  ] as EmployeeCustomFields[];

  if (employee.custom_fields) {
    const customFields = employee.custom_fields.map((customField: EmployeeCustomFields) => {
      customField.value = parseTranslateAnswer(customField.value);
      return customField;
    });
    detailsFileds = detailsFileds.concat(customFields);
  }

  detailsFileds = detailsFileds.concat([
    {
      label: t('employee.form.pwd_disability.label'),
      subtitle: t('employee.form.pwd_disability.help-text'),
      value: parseTranslateAnswer(employee.pwd_disability)
    },
    {
      label: t('employee.form.inclusive_notes.label'),
      value: employee.inclusive_notes || '-'
    }
  ]);

  return detailsFileds;
}

EmployeeViewDetails.propTypes = {
  employee: PropTypes.object
};

export default EmployeeViewDetails;
