import React from 'react';
import PropTypes from 'prop-types';
import Routes from './routes';
import MessagesSnackbar from './components/MessagesSnackbar';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster } from 'react-hot-toast';

import './i18n';
import './App.css';

const queryClient = new QueryClient();

function App(): JSX.Element {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Routes />
        <MessagesSnackbar />
        <Toaster position="top-center" toastOptions={{ duration: 5000 }} />
      </QueryClientProvider>
    </>
  );
}

App.propTypes = {
  isMobile: PropTypes.bool
};

export default App;
