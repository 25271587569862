import HttpRepository from '../../../services/HttpRepository/HttpRepository';

export interface ContentsQuestion {
  id?: string;
  tags: string;
  title: string;
  description: string;
  source_url?: string;
}

class ContentsQuestionsRepository extends HttpRepository<ContentsQuestion> {
  constructor() {
    super({ basePath: '/contents/questions' });
  }
}

export default ContentsQuestionsRepository;
