import React from 'react';
import { Trans } from 'react-i18next';
import PropTypes, { InferProps } from 'prop-types';
import { Auth } from 'aws-amplify';

import styles from './WelcomeSection.module.css';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { Theme } from '@rmwc/theme';
import { Typography } from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

import Welcome from '../Ilustrations/welcome-happy.svg';

function WelcomeSection({ children, betaFeatureForm }: InferProps<typeof WelcomeSection.propTypes>): JSX.Element {
  const [name, setName] = React.useState(undefined);
  const [betaForm, setBetaForm] = React.useState(false);

  React.useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        setName(currentUser.attributes.given_name);
      })
      .catch(() => {
        // Ignore in case is not authenticate
      });
  }, []);

  return (
    <Grid>
      <GridCell span={12}>
        <Grid className={styles.welcomeSectionGrid}>
          <GridCell desktop={4} tablet={3} phone={4} className={styles.welcomeIllustrationCell}>
            <img src={Welcome} alt="Welcome illustration" />
          </GridCell>
          <GridCell desktop={8} tablet={5} phone={4} className={styles.welcomeHeaderCells}>
            <Theme use="primary">
              <strong>
                <Typography use="headline5">
                  <strong>
                    <Trans i18nKey="welcome.hello">Hello</Trans>
                  </strong>
                </Typography>
              </strong>
            </Theme>
            {name ? (
              <h2>
                <Typography use="headline4">{name}</Typography>
              </h2>
            ) : (
              ''
            )}

            <p>
              <Trans i18nKey="welcome.mission">
                Our goal is to help you and organisation to create a diverse and inclusive workplace where everyone belong and become the best place to work.
              </Trans>
            </p>
            <p>{children}</p>
            {!!betaFeatureForm && (
              <p>
                <Button onClick={(): void => setBetaForm(true)}>
                  <Trans i18nKey="welcome.beta-interest-button">Or click here to register your interest</Trans>
                </Button>
              </p>
            )}
          </GridCell>
          {!!betaFeatureForm && (
            <GridCell span={12} style={betaForm ? { minHeight: '700px' } : { display: 'none' }}>
              <iframe
                title="Inboarding grupo de usuários beta"
                src={`https://share.hsforms.com/11TjrY80aQaef5XfzngwL5w3e2oh?interest_beta_feature_name=${betaFeatureForm}`}
                width="100%"
                height="100%"
                frameBorder="0"
                style={{ width: '100%', height: '100%', border: '0', minHeight: '500px' }}
              />
            </GridCell>
          )}
        </Grid>
      </GridCell>
    </Grid>
  );
}

WelcomeSection.propTypes = {
  children: PropTypes.node,
  betaFeatureForm: PropTypes.string
};

export default WelcomeSection;
