import React, { MouseEventHandler } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Trans, useTranslation, TFunction } from 'react-i18next';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Typography } from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/circular-progress.css';

import { Tooltip } from '@rmwc/tooltip';
import '@rmwc/tooltip/styles';

import { ListDivider } from '@rmwc/list';
import { TabBar, Tab } from '@rmwc/tabs';
import '@rmwc/tabs/styles';

import { Icon } from '@rmwc/icon';
import '@rmwc/icon/styles';

import { Cell } from 'react-table';

import { CSVLink } from 'react-csv';

import SimpleTable from '../../Table/SimpleTable';
import LoadingModal from '../../LoadingModal';
import ErrorMessage from '../../ErrorMessage';
import Breadcrumb from '../../common/Breadcrumb';

import EmployeeSurveyAnswersService from './EmployeeSurveyAnswersService';
import MetabaseAnalytics from '../../MetabaseAnalytics';

import { Employee, EmployeeSurveyAsnwer } from '../../Employee/EmployeeRepository';
import { dateTimeFormat } from '../../common/DatesFormat/dateTimeFormat';
import { SurveyAnswersStatusChip } from '../../Employee/EmployeeViewSurveys';
import { EmployeeStartEndDateCell } from '../../Employee/EmployeeTable';
import { CompanyConfigurationContext } from '../../common/Contexts/appContexts';
import { ReportDashboardType } from './ResportsDashboardsRepository';

const LIST_SIZE = 100;

interface CSVHeader {
  label: string;
  key: string;
}

function SurveyAnswers(): JSX.Element {
  const { t } = useTranslation();
  const [activeTabIndex, setActiveTabIndex] = React.useState<number>(0);

  return (
    <>
      <Grid>
        <GridCell span={12}>
          <Breadcrumb routeSegments={[{ name: t('navlink.analytics') }, { name: t('navlink.analytic.survey') }]} />
        </GridCell>
        <GridCell span={12} align="middle">
          <h2>
            <Trans i18nKey="analytics.survey-answers.title">Survey Answers</Trans>
          </h2>
        </GridCell>
      </Grid>
      <TabBar activeTabIndex={activeTabIndex} onActivate={(event): void => setActiveTabIndex(event.detail.index)}>
        <Tab minWidth icon="list" label={t('navlink.analytic.report-list')} />
        <Tab minWidth icon="bar_chart" label={t('navlink.analytic.report-dashboard')} />
      </TabBar>
      <ListDivider />
      {activeTabIndex === 0 ? <SurveyAnswersList /> : null}
      {activeTabIndex === 1 ? <SurveyAnswersAnalytics /> : null}
    </>
  );
}

function convertDataToCSV(t: TFunction, employeesData: Employee[] | undefined) {
  const answerHeaders = new Set<string>();
  const header = [
    { label: t('analytics.survey-answers.headers.id', 'Employee ID'), key: 'id' },
    { label: t('analytics.survey-answers.headers.first_name', 'First name'), key: 'first_name' },
    { label: t('analytics.survey-answers.headers.last_name', 'Last name'), key: 'last_name' },
    { label: t('analytics.survey-answers.headers.short_name', 'Short name'), key: 'short_name' },
    { label: t('analytics.survey-answers.headers.work_email', 'Email'), key: 'work_email' },
    { label: t('analytics.survey-answers.headers.personal_email', 'Personal email'), key: 'personal_email' },
    { label: t('analytics.survey-answers.headers.start_date', 'First day'), key: 'start_date' },
    { label: t('analytics.survey-answers.headers.last_date', 'Last day'), key: 'last_date' },
    { label: t('analytics.survey-answers.headers.job_title', 'Job title'), key: 'job_title' },
    { label: t('analytics.survey-answers.headers.department', 'Department'), key: 'department' },
    { label: t('analytics.survey-answers.headers.cost_center', 'Cost center'), key: 'cost_center' },
    { label: t('analytics.survey-answers.headers.survey_id', 'Survey ID'), key: 'survey_id' },
    { label: t('analytics.survey-answers.headers.survey_name', 'Survey name'), key: 'survey_name' },
    { label: t('analytics.survey-answers.headers.survey_submitted', 'Survey submitted'), key: 'survey_submitted' },
    { label: t('analytics.survey-answers.headers.survey_status', 'Survey status'), key: 'survey_status' }
  ] as CSVHeader[];
  const data = [] as Record<string, string | number | undefined>[];
  if (employeesData) {
    for (const employee of employeesData) {
      const surveyAnswers = employee.survey_answers;
      if (surveyAnswers) {
        const employeeData = {
          id: employee.id,
          first_name: employee.social_name || employee.first_name,
          last_name: employee.last_name,
          short_name: employee.short_name,
          work_email: employee.work_email,
          personal_email: employee.personal_email,
          start_date: employee.start_date,
          last_date: employee.last_date,
          job_title: employee.job_title,
          department: employee.department,
          cost_center: employee.cost_center
        } as Record<string, string | number | undefined>;
        for (const surveyAnswer of surveyAnswers) {
          const answerData = Object.assign(
            {
              survey_id: surveyAnswer.id,
              survey_name: surveyAnswer.name,
              survey_submitted: surveyAnswer.submitted,
              survey_status: surveyAnswer.status
            },
            employeeData
          );

          if (surveyAnswer.answers) {
            const parsedAnswers = JSON.parse(surveyAnswer.answers);
            for (const answer of parsedAnswers) {
              if (answer.label) {
                const parsedValue = answer.value?.replace(/"/g, '""');
                answerData[answer.label as string] = parsedValue;
                if (!answerHeaders.has(answer.label)) {
                  answerHeaders.add(answer.label);
                  header.push({
                    label: answer.label,
                    key: answer.label
                  });
                }
              }
            }
          }
          data.push(answerData);
        }
      }
    }
  }
  return { header, data };
}

function SurveyAnswersList(): JSX.Element {
  const { t } = useTranslation();
  const [exportHeader, setExportHeader] = React.useState<CSVHeader[]>([]);
  const [exportData, setExportData] = React.useState<any>([]); // eslint-disable-line @typescript-eslint/no-explicit-any
  const [exporting, setExporting] = React.useState(false);
  const employeeSurveyAnswersService = new EmployeeSurveyAnswersService();

  const searchFilter = { limit: LIST_SIZE, query: '' };

  const { data, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage, isError, error } = employeeSurveyAnswersService.useListSearch(searchFilter, LIST_SIZE);

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching && !isFetchingNextPage && !searchFilter.query) {
    return <LoadingModal open={true} />;
  }

  const reportData = data?.pages.flat();
  const dataLength = reportData ? reportData.length : 0;
  const filename = `Inboarding relatorio ${new Date().toJSON().slice(0, 10)}.csv`;

  const handleExportCSV = async (event: MouseEventHandler<HTMLAnchorElement>, done: (proceed: boolean) => void) => {
    setTimeout(() => {
      setExporting(true);
    }, 100);
    try {
      const convertedCSV = await convertDataToCSV(t, reportData);
      await setTimeout(() => {
        // wait 1 second
      }, 1000);
      setExportData(convertedCSV.data);
      setExportHeader(convertedCSV.header);
      done(true);
      setTimeout(() => {
        setExporting(false);
      }, 100);
    } catch (error) {
      console.error('Error exporting to CSV. ', error);
    }
  };

  return (
    <Grid>
      <GridCell span={12} style={{ justifySelf: 'end' }}>
        <CSVLink separator=";" data={exportData} headers={exportHeader} asyncOnClick={true} onClick={handleExportCSV} filename={filename} style={{ textDecoration: 'none' }}>
          <Typography use="button" theme="primary">
            {exporting ? (
              <CircularProgress size="small" style={{ marginRight: '15px', verticalAlign: 'middle' }} />
            ) : (
              <Icon icon={{ icon: 'download', size: 'small' }} style={{ marginRight: '5px', verticalAlign: 'middle' }} />
            )}
            <Trans i18nKey="analytics.export.csv">File as CSV</Trans>
          </Typography>
        </CSVLink>
      </GridCell>
      <GridCell span={12}>
        <SurveyAnswersTable employees={reportData}></SurveyAnswersTable>
        {!dataLength && (
          <p>
            {isFetching ? (
              <CircularProgress theme="secondary" />
            ) : (
              <>
                <Trans i18nKey="analytics.no-data-search">No data found.</Trans>
              </>
            )}
          </p>
        )}
      </GridCell>

      {hasNextPage && (
        <GridCell span={12} style={{ textAlign: 'center' }}>
          <Button
            outlined
            {...(isFetching ? { icon: <CircularProgress theme="secondary" /> } : {})}
            disabled={isFetching}
            onClick={(): void => {
              fetchNextPage();
            }}
          >
            <Trans i18nKey="analytics.load-more">Load More</Trans>
          </Button>
        </GridCell>
      )}
    </Grid>
  );
}

function SurveyAnswersAnalytics(): JSX.Element {
  const companyConfigurationValue = React.useContext(CompanyConfigurationContext);
  const dashboardId = companyConfigurationValue.dashboard_surveys_id;

  if (dashboardId) {
    return <MetabaseAnalytics dashboardName={ReportDashboardType.SURVEYS} showPrintExport={true} />;
  }

  return (
    <Grid>
      <GridCell span={12}>
        <Trans i18nKey="analytics.no-data-search">No data found.</Trans>
      </GridCell>
    </Grid>
  );
}

function EmployeeNameCell({ cell }: { cell: Cell }): JSX.Element {
  return <EmployeeNameCellComponent employee={cell.row.original as Employee}></EmployeeNameCellComponent>;
}

function EmployeeNameCellComponent({ employee }: { employee: Employee }): JSX.Element {
  return (
    <div style={{ marginLeft: '0.4rem', whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
      <Typography use="subtitle1" tag="h5" style={{ margin: 0, lineHeight: '85%' }}>
        <NavLink to={`/employees/${employee.id}/surveys`} target="_blank">
          {employee.social_name || employee.first_name} {employee.last_name}
        </NavLink>
      </Typography>
      <Typography use="caption" tag="small">
        {employee.work_email || employee.personal_email || employee.mobile_number}
      </Typography>
    </div>
  );
}

function EmployeeDepartmentCell({ cell }: { cell: Cell }): JSX.Element {
  const employee = cell.row.original as Employee;

  return (
    <>
      {employee.job_title && <div>{employee.job_title}</div>}
      {employee.department && (
        <div>
          {employee.department} {employee.cost_center && <span> - {employee.cost_center}</span>}
        </div>
      )}
    </>
  );
}

function EmployeeSurveyQuantityCell({ cell }: { cell: Cell }): JSX.Element {
  const employee = cell.row.original as Employee;
  const surveys = employee.survey_answers;
  const totalAnswers = surveys?.length || 0;
  return (
    <Typography use="subtitle1" tag="h5">
      <Trans i18nKey="analytics.survey-answers.survey-answers-total" count={totalAnswers}>
        {{ count: totalAnswers }} answers
      </Trans>
    </Typography>
  );
}

function EmployeeSurveyAnswersDataCell({ cell }: { cell: Cell }): JSX.Element {
  const employee = cell.row.original as Employee;
  const surveyAnswers = employee.survey_answers;
  if (!surveyAnswers) {
    return <></>;
  }

  const tooltipAnswersContent = (
    <div
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        background: 'white',
        width: '30rem',
        minHeight: '4rem',
        color: 'black',
        borderRadius: '3px',
        margin: '-2px -5px',
        padding: '10px'
      }}
    >
      {surveyAnswers.map((answer: EmployeeSurveyAsnwer) => {
        return (
          <div key={answer.name} style={{ marginBottom: '5px' }}>
            <SurveyAnswersStatusChip status={answer.status} /> {dateTimeFormat(answer.submitted)} : {answer.name}
          </div>
        );
      })}
    </div>
  );

  return (
    <Tooltip content={tooltipAnswersContent} align="top" showArrow={true}>
      <Typography use="subtitle1" tag="h5" style={{ margin: 0, lineHeight: '85%' }}>
        <NavLink to={`/employees/${employee.id}/surveys`} target="_blank">
          <Trans i18nKey="analytics.survey-answers.survey-answers-see">Read answers</Trans>
        </NavLink>
      </Typography>
    </Tooltip>
  );
}

function SurveyAnswersTable({ employees }: InferProps<typeof SurveyAnswersTable.propTypes>): JSX.Element {
  const { t } = useTranslation();
  const columns = React.useMemo(
    () => [
      {
        Header: t('analytics.survey-answers.employee'),
        accessor: 'employee',
        Cell: EmployeeNameCell
      },
      {
        Header: t('analytics.survey-answers.department'),
        accessor: 'department',
        Cell: EmployeeDepartmentCell
      },
      {
        Header: t('analytics.survey-answers.start_date'),
        id: 'employeeStartDate',
        Cell: EmployeeStartEndDateCell
      },
      {
        Header: t('analytics.survey-answers.survey-sent'),
        id: 'surveyAnswerQuantity',
        Cell: EmployeeSurveyQuantityCell
      },
      {
        Header: '',
        id: 'surveyAnswerData',
        Cell: EmployeeSurveyAnswersDataCell
      }
    ],
    [t]
  );
  const isRowDisabled = (rowData: Employee): boolean => {
    return Boolean(rowData?.last_date);
  };
  return (
    <>
      <SimpleTable columns={columns} data={employees} isRowDisabled={isRowDisabled}></SimpleTable>
    </>
  );
}

SurveyAnswersTable.propTypes = {
  employees: PropTypes.array
};

export default SurveyAnswers;
