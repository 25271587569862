import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { FieldValues } from 'react-hook-form';

import EmployeeService from './EmployeeService';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import EmployeeForm from './EmployeeForm';
import Message from '../../services/Message';
import Breadcrumb from '../common/Breadcrumb';

function EmployeeNew({ history }: InferProps<typeof EmployeeNew.propTypes>): JSX.Element {
  const { t } = useTranslation();
  const employeeService = new EmployeeService();
  const useCreateEmployee = employeeService.useCreate();

  const onSubmit = async (values: FieldValues): Promise<void> => {
    console.log('Submitted values = ', values);
    try {
      const employeeCreated = await useCreateEmployee.mutateAsync(values);
      Message.success(t('employee.created'));
      history.push(`/employees/${employeeCreated.id}`);
    } catch (error) {
      Message.error(t('employee.created-error'));
      console.error('Error on save: ', error);
    }
  };

  const onCancel = async (): Promise<void> => {
    history.goBack();
  };

  return (
    <Grid>
      <GridCell span={12}>
        <Breadcrumb routeSegments={[{ name: t('navlink.employees'), path: '/employees' }, { name: t('employee.new-title') }]} />
      </GridCell>
      <GridCell span={12}>
        <h1>
          <Trans i18nKey="employee.new-title">Create new employee</Trans>
        </h1>
      </GridCell>
      <GridCell span={12}>
        <EmployeeForm onFormSubmit={onSubmit} onCancel={onCancel} isLoading={useCreateEmployee.isLoading}></EmployeeForm>
      </GridCell>
    </Grid>
  );
}

EmployeeNew.propTypes = {
  history: PropTypes.any
};

export default EmployeeNew;
