import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useForm, useWatch, Controller, FieldValues } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { GridRow, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { TextField } from '@rmwc/textfield';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/circular-progress.css';

import { Typography } from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

import ContentsTagsChips from '../../common/ContentsTagsChips';

const fullWithStyle = {
  width: '100%',
  backgroundColor: 'whitesmoke'
} as React.CSSProperties;

function ContentsGlossaryForm({ onFormSubmit, onCancel, defaultValues, isLoading }: InferProps<typeof ContentsGlossaryForm.propTypes>): JSX.Element {
  const { t } = useTranslation();

  const { control, handleSubmit, errors } = useForm({ defaultValues });

  const tags: string | undefined = useWatch({ control, name: 'tags' });

  const onSubmit = async (values: FieldValues): Promise<void> => {
    onFormSubmit(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GridRow>
        <GridCell span={12}>
          <Controller
            control={control}
            defaultValue=""
            name="title"
            rules={{ required: t('contents.glossary.form.title.required', 'The glossary is required.') as string }}
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="contents.glossary.form.title.label">What is the term name?</Trans>
                  </label>
                </Typography>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  placeholder={t('contents.glossary.form.title.placeholder')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.title?.message ? true : false}
                  aria-invalid={errors?.title?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />
              </>
            )}
          />
          {errors?.title?.message && <div role="alert">{errors?.title?.message}</div>}
        </GridCell>
        <GridCell span={12}>
          <Controller
            control={control}
            defaultValue=""
            name="tags"
            rules={{ required: t('contents.glossary.form.tags.required', 'At least one category is required.') as string }}
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="contents.glossary.form.tags.label">Select one or more categories</Trans>
                  </label>
                </Typography>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  placeholder={t('contents.glossary.form.tags.placeholder')}
                  helpText={t('contents.glossary.form.tags.help-text')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.tags?.message ? true : false}
                  aria-invalid={errors?.tags?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />
              </>
            )}
          />
          {errors?.tags?.message && <div role="alert">{errors?.tags?.message}</div>}
        </GridCell>
        <GridCell span={12}>
          <ContentsTagsChips tags={tags} />
        </GridCell>
        <GridCell span={12}>
          <Controller
            control={control}
            defaultValue=""
            name="description"
            rules={{ required: t('contents.glossary.form.description.required', 'The answer content is required.') as string }}
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="contents.glossary.form.description.label">Glossary answer</Trans>
                  </label>
                </Typography>
                <TextField
                  textarea
                  fullwidth
                  rows={4}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.description?.message ? true : false}
                  aria-invalid={errors?.description?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />
              </>
            )}
          />
          {errors?.description?.message && <div role="alert">{errors?.description?.message}</div>}
        </GridCell>
        <GridCell span={12}>
          <Controller
            control={control}
            defaultValue=""
            name="source_url"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="contents.glossary.form.source_url.label">Source or more details URL (optional)</Trans>
                  </label>
                </Typography>
                <TextField
                  type="url"
                  id={name}
                  name={name}
                  placeholder={t('contents.glossary.form.source_url.placeholder')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.source_url?.message ? true : false}
                  aria-invalid={errors?.source_url?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />
              </>
            )}
          />
          {errors?.source_url?.message && <div role="alert">{errors?.source_url?.message}</div>}
        </GridCell>
        <GridCell span={12} style={{ textAlign: 'right' }}>
          <Button type="button" onClick={onCancel} style={{ marginRight: '1.5rem' }}>
            <Trans i18nKey="crud.cancel">Cancel</Trans>
          </Button>
          <Button type="submit" raised {...(isLoading ? { icon: <CircularProgress theme="secondary" /> } : {})}>
            {defaultValues && defaultValues.id ? (
              <Trans i18nKey="contents.glossary.save-update">Update glossary</Trans>
            ) : (
              <Trans i18nKey="contents.glossary.save-new">Create new glossary</Trans>
            )}
          </Button>
        </GridCell>
      </GridRow>
    </form>
  );
}

ContentsGlossaryForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  defaultValues: PropTypes.any,
  isLoading: PropTypes.bool
};

export default ContentsGlossaryForm;
