import { FieldValues } from 'react-hook-form';
import HttpBaseService from '../../services/HttpBaseService';
import EditorRepository, { TextAnalysis } from './EditorRepository';
import { UseMutationResult, useMutation } from 'react-query';
import { AxiosError } from 'axios';

class EditorService extends HttpBaseService<TextAnalysis> {
  constructor(private editorRepository = new EditorRepository()) {
    super('text-analysis', editorRepository);
  }

  public usePost(): UseMutationResult<TextAnalysis, AxiosError, string> {
    return useMutation<TextAnalysis, AxiosError, string>((messageHtml: string) => {
      const data: string = messageHtml;
      return this.editorRepository.postText(data);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  convertValuesToModel(values: FieldValues): TextAnalysis {
    throw new Error('Method not implemented.');
  }
}

export default EditorService;
