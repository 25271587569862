import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import LoadingModal from '../../LoadingModal';
import ErrorMessage from '../../ErrorMessage';
import Message from '../../../services/Message';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { GridList, GridTile } from '@rmwc/grid-list';
import '@rmwc/grid-list/styles';

import { Card, CardPrimaryAction, CardActions, CardActionButton } from '@rmwc/card';
import { ListDivider } from '@rmwc/list';
import '@rmwc/card/styles';

import { ChipSet, Chip } from '@rmwc/chip';
import '@rmwc/chip/styles';

import { Typography } from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

import authService from '../../../services/AuthService';
import CompanyService from '../CompanySettings/CompanyService';

import Email from './Icons/email.png';
import Slack from './Icons/slack.png';
import Teams from './Icons/teams.png';
import RocketChat from './Icons/rocketchat.png';
import Whatsapp from './Icons/whatsapp.png';
import Telegram from './Icons/telegram.svg';
import Signal from './Icons/signal.png';
import Discord from './Icons/discord.svg';
import GoogleChat from './Icons/google-chat.png';
import Workplace from './Icons/workplace.png';
import SMS from './Icons/sms.png';
import Beedoo from './Icons/beedoo.png';

import Gupy from './Icons/gupy.svg';
import Kenoby from './Icons/kenoby.png';
import ADP from './Icons/adp.png';
import Convenia from './Icons/convenia.png';
import Greenhouse from './Icons/greenhouse.svg';
import Lever from './Icons/lever.svg';
import Recruitee from './Icons/recruitee.png';
import SAP from './Icons/sap-successfactors.png';
import Senior from './Icons/senior.png';
import Workday from './Icons/workday.png';
import Rhello from './Icons/rhello.png';
import Feedz from './Icons/feedz.png';
import Keeps from './Icons/keeps.png';
import Abler from './Icons/abler.svg';

import Zapier from './Icons/zapier.png';
import Inboarding from './Icons/inboarding.svg';

const gridTileStyle = {
  width: '255px',
  padding: '10px'
};

const SLACK_APP_INSTALL = process.env.REACT_APP_SLACK_APP_INSTALL;
const SLACK_REDIRECT_URL = process.env.REACT_APP_SLACK_REDIRECT;

function isNotFoundError(error: unknown): boolean {
  const httpError = error as { response: { status: number } };
  return !!httpError?.response?.status;
}

function IntegrationSettings({ history }: InferProps<typeof IntegrationSettings.propTypes>): JSX.Element {
  const { t } = useTranslation();

  const companyService = new CompanyService();
  const userAuthCompanyId = authService.tenantId();

  const { data, isFetching, isError, error } = companyService.useGet(userAuthCompanyId, false);
  console.log('Company data loaded: ', data);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onCancel = async (): Promise<void> => {
    history.goBack();
  };

  const isSollo = CompanyService.isSollo(userAuthCompanyId);
  const isAsaas = CompanyService.isAsaas(userAuthCompanyId);

  const communicationApps = [
    {
      name: t('integration-settings.email.name', 'Email'),
      description: t('integration-settings.email.description', 'Email'),
      image: Email,
      enabled: true
    },
    {
      name: t('integration-settings.slack.name', 'Slack'),
      description: t('integration-settings.slack.description', 'Slack'),
      image: Slack,
      enabled: isAsaas ? true : false
    },
    {
      name: t('integration-settings.teams.name', 'Teams'),
      description: t('integration-settings.teams.description', 'Teams'),
      image: Teams,
      enabled: false
    },
    {
      name: t('integration-settings.workplace.name', 'Workplace'),
      description: t('integration-settings.workplace.description', 'Workplace'),
      image: Workplace,
      enabled: false
    },
    {
      name: t('integration-settings.whatsapp.name', 'WhatsApp'),
      description: t('integration-settings.whatsapp.description', 'WhatsApp'),
      image: Whatsapp,
      enabled: false
    },
    {
      name: t('integration-settings.telegram.name', 'Telegram'),
      description: t('integration-settings.telegram.description', 'Telegram'),
      image: Telegram,
      enabled: false
    },
    {
      name: t('integration-settings.signal.name', 'Signal'),
      description: t('integration-settings.signal.description', 'Signal'),
      image: Signal,
      enabled: false
    },
    {
      name: t('integration-settings.sms.name', 'SMS'),
      description: t('integration-settings.sms.description', 'SMS'),
      image: SMS,
      enabled: false
    },
    {
      name: 'Google Chat',
      description: 'Google Chat',
      image: GoogleChat,
      enabled: false
    },
    {
      name: t('integration-settings.beedoo.name', 'Beedoo'),
      description: t('integration-settings.beedoo.description', 'Beedoo'),
      image: Beedoo,
      enabled: isSollo ? true : false
    },
    {
      name: t('integration-settings.rocketchat.name', 'Rocket.Chat'),
      description: t('integration-settings.rocketchat.description', 'Rocket.Chat'),
      image: RocketChat,
      enabled: false
    },
    {
      name: t('integration-settings.discord.name', 'Discord'),
      description: t('integration-settings.discord.description', 'Discord'),
      image: Discord,
      enabled: false
    }
  ];

  const humanResourcesApps = [
    {
      name: t('integration-settings.gupy.name', 'Gupy'),
      description: t('integration-settings.gupy.description', 'Gupy'),
      image: Gupy,
      enabled: isAsaas ? true : false
    },
    {
      name: t('integration-settings.kenoby.name', 'Kenoby'),
      description: t('integration-settings.kenoby.description', 'Kenoby'),
      image: Kenoby,
      enabled: false
    },
    {
      name: t('integration-settings.abler.name', 'Abler'),
      description: t('integration-settings.abler.description', 'Abler'),
      image: Abler,
      enabled: false
    },
    {
      name: t('integration-settings.convenia.name', 'Convenia'),
      description: t('integration-settings.convenia.description', 'Convenia'),
      image: Convenia,
      enabled: isAsaas ? true : false
    },
    {
      name: t('integration-settings.rhello.name', 'RHello'),
      description: t('integration-settings.rhello.description', 'RHello'),
      image: Rhello,
      enabled: false
    },
    {
      name: t('integration-settings.senior.name', 'Senior Sistemas'),
      description: t('integration-settings.senior.description', 'Senior Sistemas'),
      image: Senior,
      enabled: isSollo ? true : false
    },
    {
      name: t('integration-settings.greenhouse.name', 'Greenhouse'),
      description: t('integration-settings.greenhouse.description', 'Greenhouse'),
      image: Greenhouse,
      enabled: false
    },
    {
      name: t('integration-settings.lever.name', 'Lever'),
      description: t('integration-settings.lever.description', 'Lever'),
      image: Lever,
      enabled: false
    },
    {
      name: t('integration-settings.recruitee.name', 'Recruitee'),
      description: t('integration-settings.recruitee.description', 'Recruitee'),
      image: Recruitee,
      enabled: false
    },
    {
      name: t('integration-settings.sap.name', 'SAP SuccessFactors'),
      description: t('integration-settings.sap.description', 'SAP SuccessFactors'),
      image: SAP,
      enabled: false
    },
    {
      name: t('integration-settings.adp.name', 'ADP'),
      description: t('integration-settings.adp.description', 'ADP'),
      image: ADP,
      enabled: false
    },
    {
      name: t('integration-settings.workday.name', 'Workday'),
      description: t('integration-settings.workday.description', 'Workday'),
      image: Workday,
      enabled: false
    },
    {
      name: t('integration-settings.feedz.name', 'Feedz'),
      description: t('integration-settings.feedz.description', 'Feedz'),
      image: Feedz,
      enabled: false
    },
    {
      name: t('integration-settings.keeps.name', 'Keeps'),
      description: t('integration-settings.keeps.description', 'Keeps'),
      image: Keeps,
      enabled: false
    }
  ];

  const integrationApps = [
    {
      name: 'API',
      description: 'Inboarding API',
      image: Inboarding,
      enabled: false
    },
    {
      name: 'Webhooks',
      description: 'Inboarding Webhook',
      image: Inboarding,
      enabled: false
    },
    {
      name: 'Zapier',
      description: 'Zapier',
      image: Zapier,
      enabled: false
    }
  ];

  if (isError && !isNotFoundError(error)) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching) {
    return <LoadingModal open={true} />;
  }

  return (
    <Grid>
      <GridCell span={12}>
        <h1>
          <Trans i18nKey="integration-settings.edit-title">Integrations and Apps</Trans>
        </h1>
      </GridCell>
      <GridCell span={12}>
        <h2>
          <Trans i18nKey="integration-settings.communication-apps-title">Communication Channels</Trans>
        </h2>
      </GridCell>
      <GridCell span={12}>
        <GridList tileAspect="4x3">
          {communicationApps
            .filter((int) => int.enabled)
            .map((cardData, index) => (
              <GridTile key={index} style={gridTileStyle}>
                <IntegrationCard {...cardData} isAsaas={isAsaas} tenantId={userAuthCompanyId} />
              </GridTile>
            ))}
          {communicationApps
            .filter((int) => !int.enabled)
            .map((cardData, index) => (
              <GridTile key={index} style={gridTileStyle}>
                <IntegrationCard {...cardData} isAsaas={isAsaas} tenantId={userAuthCompanyId} />
              </GridTile>
            ))}
        </GridList>
      </GridCell>
      <GridCell span={12}>
        <h2>
          <Trans i18nKey="integration-settings.hr-integration-title">Human Resoures and Recruitment</Trans>
        </h2>
      </GridCell>
      <GridCell span={12}>
        <GridList tileAspect="4x3">
          {humanResourcesApps
            .filter((int) => int.enabled)
            .map((cardData, index) => (
              <GridTile key={index} style={gridTileStyle}>
                <IntegrationCard {...cardData} isAsaas={isAsaas} tenantId={userAuthCompanyId} />
              </GridTile>
            ))}
          {humanResourcesApps
            .filter((int) => !int.enabled)
            .map((cardData, index) => (
              <GridTile key={index} style={gridTileStyle}>
                <IntegrationCard {...cardData} isAsaas={isAsaas} tenantId={userAuthCompanyId} />
              </GridTile>
            ))}
        </GridList>
      </GridCell>

      <GridCell span={12}>
        <h2>
          <Trans i18nKey="integration-settings.api-integration-title">API and Webhooks</Trans>
        </h2>
      </GridCell>
      <GridCell span={12}>
        <GridList tileAspect="4x3">
          {integrationApps.map((cardData, index) => (
            <GridTile key={index} style={gridTileStyle}>
              <IntegrationCard {...cardData} isAsaas={isAsaas} tenantId={userAuthCompanyId} />
            </GridTile>
          ))}
        </GridList>
      </GridCell>
    </Grid>
  );
}

function IntegrationCard({
  enabled,
  image,
  name,
  description,
  isAsaas,
  tenantId
}: {
  enabled: boolean;
  image: string;
  name: string;
  description: string;
  isAsaas: boolean;
  tenantId: string;
}): JSX.Element {
  const { t } = useTranslation();

  const onClickDisabled = (): void => {
    Message.error('Contate seu gerente de conta ou o suporte para habilitar essa funcionalidade.');
  };

  const SLACK_INSTALL_URL = isAsaas ? '' : `${SLACK_APP_INSTALL}${SLACK_REDIRECT_URL}/${tenantId}`;

  return (
    <Card style={{ maxWidth: '21rem', height: '100%' }}>
      <CardPrimaryAction style={{ height: '100%' }}>
        <div style={{ padding: '0 1rem 1rem 1rem' }}>
          <Typography use="subtitle1" tag="h2" style={{ marginBottom: '0' }}>
            {name}
          </Typography>
          <Typography use="body1" tag="div" theme="textSecondaryOnBackground">
            {description}
          </Typography>
          <div style={{ marginTop: '20px' }}>
            <img src={image} alt={name} style={{ maxHeight: '35px', maxWidth: '85px' }} />
            <ChipSet style={{ float: 'right' }}>
              {enabled ? (
                <Chip
                  icon="done"
                  label={t('integration-settings.card.enabled', 'Connected')}
                  style={{ backgroundColor: 'var(--mdc-theme-green-enabled)', fontSize: '80%', borderRadius: '5px', height: '25px' }}
                />
              ) : (
                <Chip
                  label={t('integration-settings.card.disabled', 'Not connected')}
                  style={{ backgroundColor: 'var(--mdc-theme-grey-disabled)', fontSize: '80%', borderRadius: '5px', height: '25px' }}
                />
              )}
            </ChipSet>
          </div>
        </div>
      </CardPrimaryAction>
      <ListDivider />
      <CardActions fullBleed={true}>
        {!enabled && name === 'Slack' && (
          <>
            <a href={SLACK_INSTALL_URL}>
              <img
                alt="Add to Slack"
                height="40"
                width="139"
                src="https://platform.slack-edge.com/img/add_to_slack.png"
                srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
              />
            </a>
          </>
        )}
        {enabled ? (
          <CardActionButton label={t('integration-settings.card.edit', 'Edit')} trailingIcon="edit" onClick={onClickDisabled} />
        ) : (
          <CardActionButton label={t('integration-settings.card.enable', 'Enable')} trailingIcon="file_downloadz" onClick={onClickDisabled} />
        )}
      </CardActions>
    </Card>
  );
}

IntegrationSettings.propTypes = {
  history: PropTypes.any
};

export default IntegrationSettings;
