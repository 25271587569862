import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import { ChipSet, Chip } from '@rmwc/chip';
import '@rmwc/chip/styles';

import { Cell } from 'react-table';

import { Tooltip } from '@rmwc/tooltip';
import '@rmwc/tooltip/styles';

function ContentsTagsChips({ tags, max }: InferProps<typeof ContentsTagsChips.propTypes>): JSX.Element {
  if (!tags || !tags.trim()) {
    return <></>;
  }

  let showMore = false;
  const parsedTags = tags
    .split(',')
    .map((tag) => tag.trim())
    .filter((tag) => !!tag);
  let displayTags = parsedTags;
  if (max && parsedTags.length > max) {
    displayTags = parsedTags.slice(0, max);
    showMore = true;
  }

  return (
    <ChipSet>
      <ChipsForTags tags={displayTags} />
      {showMore && (
        <Tooltip
          content={
            <div style={{ background: 'white', margin: '-6px -9px', borderRadius: '3px' }}>
              <ChipSet>
                <ChipsForTags tags={parsedTags} />
              </ChipSet>
            </div>
          }
        >
          <Chip label="..." title={parsedTags.join(', ')} />
        </Tooltip>
      )}
    </ChipSet>
  );
}

function ChipsForTags({ tags }: { tags: string[] }): JSX.Element {
  return (
    <>
      {tags.map((tag: string, index: number) => {
        return <Chip label={tag} key={index} />;
      })}
    </>
  );
}

export function ContentsTagsChipsTableCell({ cell, max }: { cell: Cell; max?: number }): JSX.Element {
  const content = cell.row.original as { tags: string };
  return <ContentsTagsChips tags={content.tags} max={max} />;
}

ContentsTagsChips.propTypes = {
  tags: PropTypes.string,
  max: PropTypes.number
};

export default ContentsTagsChips;
