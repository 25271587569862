import React from 'react';
import { SnackbarQueueMessage, createSnackbarQueue } from '@rmwc/snackbar';
import { ArrayEmitter } from '@rmwc/base';

class MessagesSnackbarQueue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private queue: any;
  public messages: ArrayEmitter<SnackbarQueueMessage>;

  constructor() {
    this.queue = createSnackbarQueue();
    this.messages = this.queue.messages;
  }

  notify(message: SnackbarQueueMessage): void {
    this.queue.notify(message);
  }

  notifyError(errorMessage: string): void {
    this.queue.notify({
      title: <b>Warning</b>,
      icon: 'warning',
      body: errorMessage,
      dismissIcon: true,
      dismissesOnAction: false
    } as SnackbarQueueMessage);
  }

  notifySuccess(successMessage: string): void {
    this.queue.notify({
      title: <b>Success</b>,
      icon: 'check',
      body: successMessage,
      actions: [
        {
          title: 'Dismiss'
        }
      ]
    } as SnackbarQueueMessage);
  }
}

export default new MessagesSnackbarQueue();
