import HttpRepository from '../../../services/HttpRepository/HttpRepository';
import { PeopleOriginType } from '../../Employee/EmployeeRepository';

export interface ImportEmployeesFileMetadata {
  contentType?: string;
  description?: string;
  filename?: string; // returne by API
  uploadURL?: string; // returne by API
}

class ImportFileUploadRepository extends HttpRepository<ImportEmployeesFileMetadata> {
  constructor(originType: PeopleOriginType) {
    super({ basePath: `/import/file/${originType}` });
  }
}

export default ImportFileUploadRepository;
