import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import { Trans, useTranslation } from 'react-i18next';

import { DiversitySpecialDate } from './CelebrationsDiversityHome';

import { DataTable, DataTableCell, DataTableHeadCell, DataTableRow, DataTableBody, DataTableContent, DataTableHead } from '@rmwc/data-table';
import '@rmwc/data-table/styles';

import { Button } from '@rmwc/button';
import '@rmwc/button/styles';
import '@rmwc/icon-button/styles';

const mouseOverStyle = {
  cursor: 'pointer'
};

function getMonthName(monthName: string | null | undefined): string {
  const { t } = useTranslation();

  return monthName ? t('celebrations.diversity.months.' + monthName) : '';
}

function DateTitleCell({ date }: { date: DiversitySpecialDate }): JSX.Element {
  return date.description && !date.date ? (
    <div>
      {date.title}: {date.description}
    </div>
  ) : (
    <div>{date.title}</div>
  );
}

function DateCell({ date, monthName }: { date: DiversitySpecialDate; monthName: string | null | undefined }): JSX.Element {
  const dateLabel = date.date ? date.date : monthName;

  return <div>{dateLabel}</div>;
}

function ContentLinkCell({ date }: { date: DiversitySpecialDate }): JSX.Element {
  return date.link ? <Button icon="arrow_right_alt" href={date.link} target="_blank"></Button> : <></>;
}

function CelebrationsDiversityTable({ dates, month }: InferProps<typeof CelebrationsDiversityTable.propTypes>): JSX.Element {
  if (!dates) {
    dates = [];
  }

  const monthName = getMonthName(month);

  async function onRowClick(values: DiversitySpecialDate): Promise<void> {
    if (values.link) window.open(values.link, '_blank');
  }

  return (
    <div>
      <DataTable style={{ width: '100%' }}>
        <DataTableContent>
          <DataTableHead>
            <DataTableRow>
              <DataTableHeadCell>
                <Trans i18nKey="celebrations.diversity.month-or-date">Date</Trans>
              </DataTableHeadCell>
              <DataTableHeadCell>
                <Trans i18nKey="celebrations.diversity.title-cell">Title</Trans>
              </DataTableHeadCell>
              <DataTableHeadCell>
                <Trans i18nKey="celebrations.diversity.content">Content</Trans>
              </DataTableHeadCell>
            </DataTableRow>
          </DataTableHead>
          <DataTableBody>
            {dates.map((date: DiversitySpecialDate, index) => {
              return (
                <DataTableRow
                  onClick={(): void => {
                    onRowClick(date);
                  }}
                  style={date.link ? mouseOverStyle : {}}
                  key={index}
                >
                  <DataTableCell>
                    <DateCell date={date} monthName={monthName}></DateCell>
                  </DataTableCell>
                  <DataTableCell>
                    <DateTitleCell date={date}></DateTitleCell>
                  </DataTableCell>
                  <DataTableCell>
                    <ContentLinkCell date={date}></ContentLinkCell>
                  </DataTableCell>
                </DataTableRow>
              );
            })}
          </DataTableBody>
        </DataTableContent>
      </DataTable>
    </div>
  );
}

CelebrationsDiversityTable.propTypes = {
  dates: PropTypes.array,
  month: PropTypes.string
};

export default CelebrationsDiversityTable;
