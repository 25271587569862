/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import { useTranslation } from 'react-i18next';

import SimpleTable from '../../Table/SimpleTable';

function FieldsSettingsTable({ fields, onRowClick, onDelete, onEdit }: InferProps<typeof FieldsSettingsTable.propTypes>): JSX.Element {
  const { t } = useTranslation();
  const columns = React.useMemo(
    () => [
      {
        Header: t('fields-settings.name'),
        accessor: 'name'
      },
      {
        Header: t('fields-settings.type'),
        accessor: 'type'
      },
      {
        Header: t('fields-settings.configuration'),
        accessor: 'configuration'
      }
    ],
    [t]
  );
  return (
    <>
      <SimpleTable columns={columns} data={fields} onRowClick={onRowClick} onDelete={onDelete} onEdit={onEdit}></SimpleTable>
    </>
  );
}

FieldsSettingsTable.propTypes = {
  fields: PropTypes.array,
  onRowClick: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func
};

export default FieldsSettingsTable;
