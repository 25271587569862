/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import './Start.css';

import AppLayout from '../AppLayout';
import LoadingModal from '../LoadingModal';
import StartPersonType from './StartPersonType';
import StartCompanySize from './StartCompanySize';
import StartChallengeType from './StartChallengeType';

import { Dialog, DialogContent } from '@rmwc/dialog';
import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';

import userService from '../../services/UserService';

const blurBackground = {
  filter: 'blur(4px)',
  WebkitFilter: 'blur(4px)',
  MozFilter: 'blur(4px)',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%'
} as any;

function checkRedirect(userAttributes: any): { redirectPath: string } {
  const profileType = userAttributes['custom:start_profile_type'];
  const companySize = userAttributes['custom:start_company_size'];
  const challenge = userAttributes['custom:start_challenge'];

  if (profileType && companySize && challenge) {
    return {
      redirectPath: '/'
    };
  }

  return {
    redirectPath: ''
  };
}

function Start({ match }: InferProps<typeof Start.propTypes>): JSX.Element {
  const [openDialog, setOpenDialog] = React.useState(true);
  const [isFetching, setIsFetching] = React.useState(true);
  const [userAttributes, setUserAttributes] = React.useState(undefined);
  const [redirectToPath, setRedirectToPath] = React.useState('');

  function preventToCloseDialog(): void {
    setOpenDialog(false);
    setTimeout(() => {
      setOpenDialog(true);
    });
  }

  async function getUserProfilAttributes(): Promise<void> {
    const currentAuthenticatedUser: any = await userService.get();
    setUserAttributes(currentAuthenticatedUser.attributes as any);
  }

  React.useEffect(() => {
    getUserProfilAttributes();
  }, []);

  React.useEffect(() => {
    if (userAttributes) {
      const { redirectPath } = checkRedirect(userAttributes);
      if (redirectPath) {
        setRedirectToPath(redirectPath);
      }
      setIsFetching(false);
    }
  }, [userAttributes]);

  if (redirectToPath) {
    return <Redirect to={redirectToPath} />;
  }

  if (isFetching) {
    return <LoadingModal open={true} />;
  }

  return (
    <>
      <div style={blurBackground} aria-disabled="true">
        <AppLayout />
      </div>
      <div className="Start">
        <div></div>
        <Dialog open={openDialog} className="StartModal" preventOutsideDismiss={true} onClose={preventToCloseDialog}>
          <DialogContent>
            <Switch>
              <Route path={`${match.path}/welcome`} component={StartPersonType} />
              <Route path={`${match.path}/company`} component={StartCompanySize} />
              <Route path={`${match.path}/challenge`} component={StartChallengeType} />
              <Route exact path={`${match.path}/`} component={StartPersonType} />
              <Redirect to="/" />
            </Switch>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

Start.propTypes = {
  match: PropTypes.any
};

export default Start;
