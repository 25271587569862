import { FieldValues } from 'react-hook-form';
import HttpBaseService from '../../../services/HttpBaseService';
import LogsIntegrationInboundRepository, { LogsIntegration } from './LogsIntegrationInboundRepository';

const QUERY_CACHE_KEY = 'logs-integration-inbound';

class LogsIntegrationsInboundService extends HttpBaseService<LogsIntegration> {
  constructor(private integrationsRepository = new LogsIntegrationInboundRepository()) {
    super(QUERY_CACHE_KEY, integrationsRepository);
  }

  convertValuesToModel(values: FieldValues): LogsIntegration {
    return {
      id: values.id
    } as LogsIntegration;
  }
}

export default LogsIntegrationsInboundService;
