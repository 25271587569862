import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useForm, Controller, FieldValues } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { TextField } from '@rmwc/textfield';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/circular-progress.css';
import ConnectionService from './ConnectionService';

function ConnectionNew({ history }: InferProps<typeof ConnectionNew.propTypes>): JSX.Element {
  const { t } = useTranslation();
  const { control, handleSubmit, errors } = useForm();

  const connectionService = new ConnectionService();
  const useCreateConnection = connectionService.useCreate();

  const onSubmit = async (values: FieldValues): Promise<void> => {
    console.log('Submitted values = ', values);
    try {
      const connectionCreated = await useCreateConnection.mutateAsync(values);
      console.log('Created connection = ', connectionCreated);
      history.push('/engagement/connection');
    } catch (error) {
      console.error('Error on save = ', error);
    }
  };

  return (
    <Grid>
      <GridCell span={12}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            defaultValue=""
            name="name"
            rules={{ required: t('connection.form.name.required', 'The name is required.') as string }}
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <TextField
                type="text"
                name={name}
                label={t('connection.form.name.label')}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                inputRef={ref}
                invalid={errors && errors.name}
                disabled={useCreateConnection.isLoading}
              />
            )}
          />
          <div>{errors?.name?.message}</div>
          <Button type="submit" raised {...(useCreateConnection.isLoading ? { icon: <CircularProgress theme="secondary" /> } : {})}>
            <Trans i18nKey="connection.save-new">Create New Connection</Trans>
          </Button>
        </form>
      </GridCell>
    </Grid>
  );
}

ConnectionNew.propTypes = {
  history: PropTypes.any
};

export default ConnectionNew;
