import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import Inboarding from './inboarding.svg';

function LogoImage({ companyName, companyLogoUrl }: InferProps<typeof LogoImage.propTypes>): JSX.Element {
  const imageSrc = companyLogoUrl ? companyLogoUrl : Inboarding;
  const imageDescription = companyName ? companyName : 'Inboarding logo';
  return <img src={imageSrc} alt={imageDescription} style={{ maxWidth: '100%', maxHeight: '90%' }} />;
}

LogoImage.propTypes = {
  companyName: PropTypes.string,
  companyLogoUrl: PropTypes.string
};

export default LogoImage;
