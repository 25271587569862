import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import { Employee, EmployeeFormAnswersLegacy, EmployeeFormAnswerValues, EmployeeFormAsnwerStatus, EmployeeSurveyAsnwer } from './EmployeeRepository';

import { Grid, GridRow, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { List, CollapsibleList, SimpleListItem } from '@rmwc/list';
import '@rmwc/list/styles';

import { Chip } from '@rmwc/chip';
import '@rmwc/chip/styles';

import { DataTable, DataTableContent, DataTableBody, DataTableRow, DataTableCell } from '@rmwc/data-table';
import '@rmwc/data-table/styles';

import { dateTimeFormat } from '../common/DatesFormat/dateTimeFormat';

const questionColumnStyle = {
  textOverflow: 'unset',
  wordWrap: 'break-word',
  overflowWrap: 'break-word',
  whiteSpace: 'normal'
} as React.CSSProperties;

function EmployeeViewSurveys({ employee }: { employee: Employee }): JSX.Element {
  return (
    <Grid>
      <GridCell span={12}>
        <EmployeeSurveysAnswers employee={employee}></EmployeeSurveysAnswers>
      </GridCell>
    </Grid>
  );
}

function EmployeeSurveysAnswers({ employee }: { employee: Employee }): JSX.Element {
  if (!employee || !employee.survey_answers || !employee.survey_answers.length) {
    return <EmployeeSurveyListEmpty />;
  }

  return (
    <>
      <GridRow>
        <GridCell span={12}>
          <List>
            {employee.survey_answers.map((surveyAnswers) => (
              <EmployeeSurveysList key={surveyAnswers.id} surveyAnswers={surveyAnswers} />
            ))}
          </List>
        </GridCell>
      </GridRow>
    </>
  );
}

function surveyAnswersStatusTrans(surveyAnswersStatus: EmployeeFormAsnwerStatus | undefined): string {
  const { t } = useTranslation();
  if (surveyAnswersStatus === EmployeeFormAsnwerStatus.Submitted) {
    return t('employee.forms.answers.status.submitted', 'Submitted');
  }
  return t('employee.forms.answers.status.draft', 'Draft');
}

export function SurveyAnswersStatusChip({ status }: { status: EmployeeFormAsnwerStatus | undefined }): JSX.Element {
  const statusTrans = surveyAnswersStatusTrans(status);
  return <Chip style={{ marginLeft: '10px' }}>{statusTrans}</Chip>;
}

function EmployeeSurveysList({ surveyAnswers }: { surveyAnswers: EmployeeSurveyAsnwer }): JSX.Element {
  const parsedAnswers = parseAnswersFromObjectOrArray(surveyAnswers.answers);

  function buildFormTitle(surveyAnswers: EmployeeSurveyAsnwer) {
    const submittedDate = dateTimeFormat(surveyAnswers.submitted);
    return (
      <div>
        {surveyAnswers.name} - {submittedDate}
        <SurveyAnswersStatusChip status={surveyAnswers.status} />
      </div>
    );
  }

  const formAnswerTitle = buildFormTitle(surveyAnswers);

  return (
    <CollapsibleList
      defaultOpen
      handle={<SimpleListItem text={formAnswerTitle} metaIcon="chevron_right" />}
      style={{ borderWidth: '1px', borderStyle: 'solid', borderColor: 'rgba(0, 0, 0, 0.12)', borderRadius: '5px' }}
    >
      <DataTable style={{ width: '100%', borderTopLeftRadius: '0', borderTopRightRadius: '0' }}>
        <DataTableContent>
          <DataTableBody>
            {parsedAnswers.map(({ label, value }) => (
              <DataTableRow key={label}>
                <DataTableCell style={questionColumnStyle}>
                  <p>{label}</p>
                  <p style={{ fontSize: '16px' }}>
                    <strong>{(value || '-') as string}</strong>
                  </p>
                </DataTableCell>
              </DataTableRow>
            ))}
          </DataTableBody>
        </DataTableContent>
      </DataTable>
    </CollapsibleList>
  );
}

function parseAnswersFromObjectOrArray(answers: string): EmployeeFormAnswerValues[] {
  const parsedAnswers: EmployeeFormAnswerValues[] | EmployeeFormAnswersLegacy = JSON.parse(answers); // Object || Array of Objects
  if (Array.isArray(parsedAnswers)) {
    return parsedAnswers;
  }

  return Object.entries(parsedAnswers).map(
    ([question, answer]) =>
      ({
        label: question,
        value: answer
      } as EmployeeFormAnswerValues)
  ) as EmployeeFormAnswerValues[];
}

function EmployeeSurveyListEmpty(): JSX.Element {
  return (
    <p>
      <Trans i18nKey="employee.surveys.no-data">No polls or surveys saved for this employee.</Trans>
    </p>
  );
}

EmployeeViewSurveys.propTypes = {
  employee: PropTypes.object
};

export default EmployeeViewSurveys;
