import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import InboardingLogo from './inboarding-symbol.svg';

import { Avatar } from '@rmwc/avatar';
import '@rmwc/avatar/avatar.css';

function AvatarInboardingLogo({ avatarSize }: InferProps<typeof AvatarInboardingLogo.propTypes>): JSX.Element {
  return <Avatar name="Inboarding" size="xlarge" src={InboardingLogo} {...(avatarSize ? { style: { fontSize: avatarSize } } : {})} />;
}

AvatarInboardingLogo.propTypes = {
  avatarSize: PropTypes.string
};

export default AvatarInboardingLogo;
