import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import EllaMessage from '../../common/EllaMessage';
import Breadcrumb from '../../common/Breadcrumb';

import { CompanyConfigurationContext } from '../../common/Contexts/appContexts';
import CompanyService from '../../Settings/CompanySettings/CompanyService';
import Message from '../../../services/Message';

function ImportEmployees(): JSX.Element {
  const { t } = useTranslation();
  const inputFileRef = React.useRef<HTMLInputElement | null>(null);
  const [originFilenameSelected, setOriginFilenameSelected] = React.useState('');
  const [filenameUploaded, setFilenameUploaded] = React.useState('');
  const [processingImport, setProcessingImport] = React.useState(false);
  const [totalProcessed, setTotalProcessed] = React.useState(0);

  const companyConfigurationValue = React.useContext(CompanyConfigurationContext);
  const importFileType = companyConfigurationValue.import_file;

  const companyService = new CompanyService();

  async function handleOnClickConfirmProcessing() {
    setProcessingImport(true);
    const processMessageId = Message.loading(t('import.employees.processing.start-import', 'Start processing import file.'));

    try {
      const processResult = await companyService.processFileImport(importFileType, filenameUploaded);

      if (processResult.status === 'OK') {
        if (processResult.total_processed) {
          setTotalProcessed(processResult.total_processed);
          Message.dismiss(processMessageId);
          Message.success(t('import.employees.processing.success', 'File imported and {{total}} lines were processed with success.', { total: processResult.total_processed }));
        } else {
          console.error('File was uploaded and processed, but not data was updated (zero). Result: ', processResult);
          Message.dismiss(processMessageId);
          Message.error(
            t('import.employees.processing.error-zero-processed', 'File was uploaded and processed, but not data was updated. Verify your file format or contact support.')
          );
          setProcessingImport(false);
          return;
        }
      } else {
        console.error('File was uploaded and an error happened. Result: ', processResult);
        Message.dismiss(processMessageId);
        Message.error(t('import.employees.processing.error-processed', 'File was uploaded and an error happened processing the data. Verify your file format or contact support.'));
        setProcessingImport(false);
        return;
      }
    } catch (error) {
      console.error('Error uploading employee import file.', error);
      Message.dismiss(processMessageId);
      Message.error(t('import.employees.processing.error-upload') + ' ' + (error instanceof Error) ? (error as Error).message : '');
      setProcessingImport(false);
      return;
    }
    Message.dismiss(processMessageId);
    setProcessingImport(false);
  }

  function handleOnClickSelectFile() {
    if (inputFileRef.current) {
      inputFileRef.current?.click();
    } else {
      console.error('ERROR: File input reference not present.');
    }
  }

  async function handleOnSelectFile(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setProcessingImport(true);
      setOriginFilenameSelected(file?.name);
      const processMessageId = Message.loading(t('import.employees.processing.start-upload', 'Start uploading import file.'));
      setFilenameUploaded('');
      try {
        const s3Filename = await companyService.uploadFileImport(importFileType, file);
        setFilenameUploaded(s3Filename);
        Message.dismiss(processMessageId);
        Message.success(t('import.employees.processing.uploaded', 'File uploaded. Now you can click on confirm to process.'));
        setProcessingImport(false);
      } catch (error) {
        console.error('Error uploading employee import file.', error);
        setProcessingImport(false);
        Message.dismiss(processMessageId);
        Message.error(t('import.employees.processing.error-upload', 'Error uploading file.') + ' ' + (error instanceof Error) ? (error as Error).message : '');
        return;
      }
      Message.dismiss(processMessageId);
      setProcessingImport(false);
    }
  }

  return (
    <Grid>
      <GridCell span={12}>
        <Breadcrumb routeSegments={[{ name: t('navlink.imports') }, { name: t('navlink.import.employees') }]} />
      </GridCell>
      <GridCell span={12}>
        <h2>
          <Trans i18nKey="import.employees.title">Import Employees</Trans>
        </h2>
      </GridCell>
      <GridCell span={12}>
        <EllaMessage title={t('import.employees.intro.title')} message={t('import.employees.intro.text')} />
      </GridCell>
      {totalProcessed > 0 && (
        <GridCell span={12} style={{ textAlign: 'center' }}>
          <p>
            <Trans i18nKey="import.employees.start-confirm-file">File selected:</Trans> {originFilenameSelected}
          </p>
          <p>{t('import.employees.processing.success', 'File imported and {{total}} lines were processed with success.', { total: totalProcessed })}</p>

          <Button raised tag={Link} {...{ to: '/employees' }} style={{ marginLeft: '15px' }}>
            <Trans i18nKey="navlink.employees">Employees</Trans>
          </Button>
        </GridCell>
      )}
      {!totalProcessed && (
        <GridCell span={12} style={{ textAlign: 'center' }}>
          <label htmlFor="file_import" style={{ cursor: 'pointer' }}>
            <Button outlined icon="upload_file" onClick={handleOnClickSelectFile} disabled={processingImport}>
              <Trans i18nKey="import.employees.start-select">Select import file for</Trans> {importFileType}
            </Button>
            <input type="file" ref={inputFileRef} style={{ display: 'none' }} id="file_import" accept="text/csv" onChange={handleOnSelectFile} disabled={processingImport} />
          </label>
        </GridCell>
      )}
      {!totalProcessed && (
        <GridCell span={12} style={{ textAlign: 'center' }}>
          {originFilenameSelected && filenameUploaded && (
            <>
              <p>
                <Trans i18nKey="import.employees.start-confirm-message">
                  Confirm the file name and start to process? This action will create and update employee data and can not be undone.
                </Trans>
              </p>
              <p>
                <Trans i18nKey="import.employees.start-confirm-file">File selected:</Trans> {originFilenameSelected}
              </p>
              <p>
                <Button raised icon="save_alt" onClick={handleOnClickConfirmProcessing} disabled={processingImport}>
                  <Trans i18nKey="import.employees.start-confirm">Confirm and process import</Trans>
                </Button>
              </p>
            </>
          )}
        </GridCell>
      )}
    </Grid>
  );
}

export default ImportEmployees;
