import Welcoming from '../Ilustrations/welcoming.svg';
import Checklist from '../Ilustrations/checklist.svg';
import UpdatedResume from '../Ilustrations/updated_resume.svg';
import SharedGoals from '../Ilustrations/shared_goals.svg';
import Responsive from '../Ilustrations/responsive.svg';
import SocialFriends from '../Ilustrations/social_friends.svg';
import VideoCall from '../Ilustrations/video_call.svg';
import TeamPage from '../Ilustrations/team_page.svg';
import Balloons from '../Ilustrations/balloons.svg';
import TouristMap from '../Ilustrations/tourist_map.svg';
import HappyAnnouncement from '../Ilustrations/happy_announcement.svg';
import AppData from '../Ilustrations/app_data.svg';
import AllTheData from '../Ilustrations/all_the_data.svg';
import AccessAccount from '../Ilustrations/access_account.svg';
import PizzaSharing from '../Ilustrations/pizza_sharing.svg';
import Meeting from '../Ilustrations/meeting.svg';
import Schedule from '../Ilustrations/schedule.svg';
import TaskList from '../Ilustrations/task_list.svg';
import CreationProcess from '../Ilustrations/creation_process.svg';
import BookLover from '../Ilustrations/book_lover.svg';
import Goals from '../Ilustrations/goals.svg';

export interface StepJourneyTemplate {
  id: string;
  image: string;
  name: string;
  text: string;
}

class StepJourneyTemplateService {
  public templatesBeforeFistDay(): StepJourneyTemplate[] {
    return [
      {
        id: 'before_welcoming',
        image: Welcoming,
        name: 'Mensagem de Boas Vindas',
        text: 'Olá @novo colaborador, Boas vindas ao nosso time. Estamos ansiosos para te conh...'
      },
      {
        id: 'before_offer',
        image: Checklist,
        name: 'Oferta aceita!',
        text: 'Olá @Líder temos uma ótima notícia. Vocês terão um novo membro no time. O @novo cola..'
      },
      {
        id: 'before_form_profile',
        image: UpdatedResume,
        name: 'Formulário de perfil',
        text: 'Olá @novo colaborador. Para que eu consiga te ajudar ainda mais, você responde uma pesq...'
      },
      {
        id: 'before_culture_guide',
        image: SharedGoals,
        name: 'Guia de Cultura, Missão e Visão',
        text: 'Olá @novo colaborador. Venha saber mais sobre a nossa cultura, missão e visão da empresa....'
      },
      {
        id: 'before_desk',
        image: Responsive,
        name: 'Mesa de trabalho',
        text: 'Olá @TI e @Líder. Prepare a área de trabalho para o novo colaborador, veja o que é preciso...'
      },
      {
        id: 'before_buddy',
        image: SocialFriends,
        name: 'Preparação do Padrinho',
        text: 'Olá @Padrinho, Você foi promovido para ser o Padrinho do novo colaborador. Vamos te ajud...'
      },
      {
        id: 'before_meet_buddy',
        image: VideoCall,
        name: 'Conheça seu padrinho!',
        text: 'Olá @novo colaborador, Apresento o seu padrinho que irá te acomp...'
      },
      {
        id: 'before_meet_team',
        image: TeamPage,
        name: 'Conheça o seu time',
        text: 'Olá @novo colaborador. O time está muito feliz com a sua vinda e preparamos um vídeo do ti...'
      }
    ];
  }

  public templatesFirstDay(): StepJourneyTemplate[] {
    return [
      {
        id: 'firstday_desk',
        image: Balloons,
        name: 'Mesa e material de trabalho',
        text: 'Olá @Padrinho e @RH, Apresente a mesa, material de trabalho e kit de boas vindas...'
      },
      {
        id: 'firstday_tour',
        image: TouristMap,
        name: 'Tour da empresa',
        text: 'Olá @Padrinho, apresente todos os setores da empresa, suas funções e objetivos.'
      },
      {
        id: 'firstday_buddy',
        image: HappyAnnouncement,
        name: 'Hoje começa o novo colaborador',
        text: 'Olá @Padrinho, Ansioso para conhecer o novo membro do time? Vim aqui lembrar você e o ...'
      },
      {
        id: 'firstday_pulse',
        image: AppData,
        name: 'Pesquisa da 1º dia',
        text: 'Olá @novo colaborador. Gostaria de saber como foi a seu primeira dia e se faltou alguma coisa ...'
      },
      {
        id: 'firstday_week',
        image: AllTheData,
        name: 'Resposta da 1º semana',
        text: 'Olá @Líder e @RH acesse o link e veja como foi a primeira semana do novo colaborador...'
      },
      {
        id: 'firstday_benefits',
        image: AccessAccount,
        name: 'Benefícios',
        text: 'Olá @RH, entrega do plano de saúde, conta bancária, auxílio alimentação, auxílio estudo...'
      },
      {
        id: 'firstday_lunch',
        image: PizzaSharing,
        name: 'Almoço com o time',
        text: 'Olá @Padrinho, Hoje é o 1º dia do novo colaborador e lembre o time para que todos possam...'
      },
      {
        id: 'firstday_schedule',
        image: Meeting,
        name: 'Cronograma da 1º semana',
        text: 'Olá @novo colaborador, Acredito que você está muito ansioso e para te acalmar preparamos...'
      }
    ];
  }

  public templatesAfterFistDay(): StepJourneyTemplate[] {
    return [
      {
        id: 'after_week_schedule',
        image: Schedule,
        name: 'Cronograma da semana',
        text: 'Olá @Líder, prepare o cronograma da semana para que todos do time fiquem menos ansio...'
      },
      {
        id: 'after_tasks',
        image: TaskList,
        name: 'Tarefas da semana',
        text: 'Olá @Time. Aqui a tarefa da semana para que todos estejam alinhados e qualquer dúvida... '
      },
      {
        id: 'after_retro',
        image: CreationProcess,
        name: 'Prospecção do mês',
        text: 'Olá @time. Hoje teremos uma reunião de máx 1h para analisarmos como foi o nosso mês.'
      },
      {
        id: 'after_week_pulse',
        image: AppData,
        name: 'Pesquisa da 1º semana',
        text: 'Olá @novo colaborador, Como você está? Gostariamos de saber como...'
      },
      {
        id: 'after_readings',
        image: BookLover,
        name: 'Indicação de cursos e livros',
        text: 'Olá @time compartilhe aqui cursos e livros que foram importantes para você...'
      },
      {
        id: 'after_goal',
        image: Goals,
        name: 'Meta do mês',
        text: 'Olá @time, para que todos estejam alinhado com as tarefas, compartilho aqui quais são...'
      }
    ];
  }
}

export default StepJourneyTemplateService;
