import React from 'react';

import LoadingModal from '../LoadingModal';

const backgroundStyle = {
  display: 'flex',
  justifyContent: 'center',
  height: '100vh',
  padding: '30px'
};

function AppLoading(): JSX.Element {
  return (
    <div style={backgroundStyle}>
      <LoadingModal open={true} />
    </div>
  );
}

export default AppLoading;
