/* eslint-disable react/no-unknown-property */
import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { useReactToPrint } from 'react-to-print';
import * as html2pdf from 'html2pdf.js';
import RepostsDashboardsService from '../pages/Analytics/ReportsDashboardsService';
import { ReportDashboardType } from '../pages/Analytics/ResportsDashboardsRepository';
import ErrorMessage from '../ErrorMessage';
import LoadingModal from '../LoadingModal';

function MetabaseAnalytics({ dashboardName, showPrintExport }: { dashboardName: ReportDashboardType; showPrintExport: boolean }): JSX.Element {
  const { t } = useTranslation();
  const iframeRef = React.useRef<HTMLIFrameElement>(null);
  const filename = `Inboarding relatorio ${new Date().toJSON().slice(0, 10)}.pdf`;

  const repostsDashboardsService = new RepostsDashboardsService();

  const handlePrintIframe = useReactToPrint({
    documentTitle: filename,
    content: () => iframeRef.current,
    copyStyles: true,
    print: async (printIframe: HTMLIFrameElement) => {
      const document = printIframe.contentDocument;
      if (document) {
        const html = document.getElementsByTagName('html')[0];
        await html2pdf()
          .set({
            margin: [10, 10, 20, 10],
            filename: filename,
            image: { type: 'jpeg', quality: 1 }
            // html2canvas: { dpi: 192, letterRendering: true },
            // jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' }
          })
          .from(html)
          // .save();
          .toPdf()
          .get('pdf')
          .then(function (pdf: { output: (p: string) => string }) {
            window.open(pdf.output('bloburl'), '_blank');
          });
      }
    }
  });

  const { data, isFetching, isError, error } = repostsDashboardsService.useGet(dashboardName);

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching) {
    return <LoadingModal open={true} />;
  }

  if (!data?.link) {
    return <ErrorMessage></ErrorMessage>;
  }

  const dashboardUrl = data.link;
  console.log('dashboardUrl = ', dashboardUrl);

  const handleDashboardPrint = async () => {
    alert(t('analytics.export.print-help', 'Click on print from the new tab to print or save as PDF.'));
    const windowRef = window.open(dashboardUrl);
    await setTimeout(() => {
      // await
    }, 2000);
    try {
      windowRef?.print();
    } catch (error) {
      console.error('Error on print dahboard', error);
    }
  };

  return (
    <Grid>
      <GridCell span={12} style={{ justifySelf: 'end' }}>
        <Button icon="picture_as_pdf" onClick={handlePrintIframe} style={{ marginLeft: '10px', display: 'none' }}>
          <Trans i18nKey="analytics.export.pdf">Save</Trans>
        </Button>
        {showPrintExport ? (
          <Button icon="download" onClick={handleDashboardPrint}>
            <Trans i18nKey="analytics.export.print">Export</Trans>
          </Button>
        ) : null}
      </GridCell>
      <GridCell span={12}>
        <iframe
          src={dashboardUrl}
          width="800"
          height="600"
          frameBorder="0"
          allowTransparency={true}
          allowFullScreen={true}
          title="Dashboard"
          style={{ border: 'none', width: '100%' }}
          ref={iframeRef}
        ></iframe>
      </GridCell>
    </Grid>
  );
}

export default MetabaseAnalytics;
