import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import Ella from './ella.svg';

import { Avatar } from '@rmwc/avatar';
import '@rmwc/avatar/avatar.css';

function EllaImage({ avatarSize }: InferProps<typeof EllaImage.propTypes>): JSX.Element {
  return <Avatar name="Ella" size="xlarge" src={Ella} {...(avatarSize ? { style: { fontSize: avatarSize } } : {})} />;
}

EllaImage.propTypes = {
  avatarSize: PropTypes.string
};

export default EllaImage;
