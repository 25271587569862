import React from 'react';
import { Trans } from 'react-i18next';

import { Grid, GridRow, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { List } from '@rmwc/list';
import '@rmwc/list/styles';

import { DataTable, DataTableContent, DataTableHead, DataTableHeadCell, DataTableBody, DataTableRow, DataTableCell } from '@rmwc/data-table';
import '@rmwc/data-table/styles';

import EmployeeService from './EmployeeService';
import { Employee } from './EmployeeRepository';
import { dateTimeFormat } from '../common/DatesFormat/dateTimeFormat';

import LoadingModal from '../LoadingModal';
import ErrorMessage from '../ErrorMessage';
import { LogsIntegrationName, LogsIntegrationStatus } from '../Settings/LogsSettings/LogsSettingsHome';
import { LogsIntegration, LogsIntegrationDirection } from '../Settings/LogsSettings/LogsIntegrationInboundRepository';

const textColumnStyle = {
  textOverflow: 'unset',
  wordWrap: 'break-word',
  overflowWrap: 'break-word',
  whiteSpace: 'normal'
} as React.CSSProperties;

function EmployeeViewLogs({ employee }: { employee: Employee }): JSX.Element {
  const employeeService = new EmployeeService();

  const { data, isFetching, isError, error } = employeeService.useListLogs(employee?.id || '');

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching) {
    return <LoadingModal open={true} />;
  }

  return (
    <Grid>
      <GridCell span={12}>
        <EmployeeLogsData logs={data as LogsIntegration[]} />
      </GridCell>
    </Grid>
  );
}

function EmployeeLogsData({ logs }: { logs?: LogsIntegration[] }): JSX.Element {
  if (!logs || !logs.length) {
    return <EmployeeLogsListEmpty />;
  }

  return (
    <>
      <GridRow>
        <GridCell span={12}>
          <List>
            <EmployeeLogsList logs={logs} />
          </List>
        </GridCell>
      </GridRow>
    </>
  );
}

function EmployeeLogsListEmpty(): JSX.Element {
  return (
    <p>
      <Trans i18nKey="employee.logs.no-data">No logs saved for this employee.</Trans>
    </p>
  );
}

function EmployeeLogsList({ logs }: { logs: LogsIntegration[] }): JSX.Element {
  return (
    <DataTable style={{ width: '100%' }}>
      <DataTableContent>
        <DataTableHead>
          <DataTableRow>
            <DataTableHeadCell>
              <Trans i18nKey="employee.logs.date">Date</Trans>
            </DataTableHeadCell>
            <DataTableHeadCell>
              <Trans i18nKey="employee.logs.direction">Direction</Trans>
            </DataTableHeadCell>
            <DataTableHeadCell>
              <Trans i18nKey="employee.logs.integration">Integration</Trans>
            </DataTableHeadCell>
            <DataTableHeadCell>
              <Trans i18nKey="employee.logs.status">Status</Trans>
            </DataTableHeadCell>
            <DataTableHeadCell>
              <Trans i18nKey="employee.logs.details">Details</Trans>
            </DataTableHeadCell>
          </DataTableRow>
        </DataTableHead>
        <DataTableBody>
          {logs.map((log) => (
            <DataTableRow key={log.id}>
              <DataTableCell style={textColumnStyle}>{dateTimeFormat(log.createdAt)}</DataTableCell>
              <DataTableCell style={textColumnStyle}>
                <EmployeeLogIntegrationDirection log={log} />
              </DataTableCell>
              <DataTableCell style={textColumnStyle}>
                <LogsIntegrationName log={log} />
              </DataTableCell>
              <DataTableCell style={textColumnStyle}>
                <LogsIntegrationStatus log={log} />
              </DataTableCell>
              <DataTableCell style={textColumnStyle}>{log.error || log.source_ip}</DataTableCell>
            </DataTableRow>
          ))}
        </DataTableBody>
      </DataTableContent>
    </DataTable>
  );
}

function EmployeeLogIntegrationDirection({ log }: { log: LogsIntegration }): JSX.Element {
  const direction = log.direction;

  if (direction === LogsIntegrationDirection.OutboundFromInbording) {
    return <Trans i18nKey="employee.logs.direction-out">From Inboaring to External</Trans>;
  }

  if (direction === LogsIntegrationDirection.InboundFromExternal) {
    return <Trans i18nKey="employee.logs.direction-in">External to Inboarding</Trans>;
  }

  return <>{direction}</>;
}

export default EmployeeViewLogs;
