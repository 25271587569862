import React from 'react';
import { Trans } from 'react-i18next';

import WelcomeSection from '../../WelcomeSection';

function ContentsFilesHome(): JSX.Element {
  return (
    <WelcomeSection>
      <p>
        <Trans i18nKey="contents.files.beta-feature">
          With the contents files you will be able to upload documents and presentations, which will be available for search and used by the virtual assistant with Artificial
          Intelligence, to make this files available to employee as answers and conversations, e.g.: Employee Handbook.
        </Trans>
      </p>
      <p>
        <Trans i18nKey="contents.files.beta">
          We are in beta. If you would like to have early access to the Content Files feature, please contact us on
          <a href="mailto:hello@inboarding.co">hello@inboarding.co</a>.
        </Trans>
      </p>
    </WelcomeSection>
  );
}

export default ContentsFilesHome;
