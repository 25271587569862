/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { command, PlainExtension } from '@remirror/core';
import { CommandFunction, CreateExtensionPlugin } from 'remirror';

const clearFormattingOptions: Remirror.CommandDecoratorOptions = {
  icon: 'formatClear',
  label: 'Limpar formato',
  description: 'Limpar formato do texto selecionado'
};

export class ClearFormattingExtension extends PlainExtension {
  get name() {
    return 'clearFormatting' as const;
  }

  @command(clearFormattingOptions)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  clearFormatting(props: unknown): CommandFunction {
    return ({ dispatch, tr, state }): boolean => {
      const { empty, ranges } = state.selection;

      if (empty) {
        return false;
      }

      Object.entries(state.schema.marks).forEach(([, mark]) => {
        ranges.forEach((range: { $from: { pos: any }; $to: { pos: any } }) => {
          dispatch?.(tr.removeMark(range.$from.pos, range.$to.pos, mark as any));
        });
      });
      return true;
    };
  }
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Remirror {
    interface AllExtensions {
      clearFormatting: CreateExtensionPlugin;
    }
  }
}
