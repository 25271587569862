import React from 'react';
import { Control, FieldErrors, useFieldArray, Controller } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { Typography, Button, Switch } from 'rmwc';
import { ArrayFieldsActions } from '../../StepJourneyForm';
import { MessageBlockFormField } from '../BlockTypes/FormBlockType';
import { CustomizableOption } from './CustomizableOption';

export function MultipleChoiceOptions({
  control,
  block,
  index,
  groupIndex,
  errors,
  isLoading
}: {
  control: Control;
  block: MessageBlockFormField;
  index: number;
  groupIndex?: number;
  errors: FieldErrors;
  isLoading: boolean;
}): JSX.Element {
  const { t } = useTranslation();

  const groupMultipleChoiceName = groupIndex !== undefined ? `group.${groupIndex}.` : '';

  const oldModelOptions = block.options && typeof block.options[0] == 'string' ? block.options : undefined;

  const {
    fields: blockFieldOptions,
    append: appendOption,
    remove: removeOption,
    move: moveOption
  } = useFieldArray({ name: `message_blocks.${index}.${groupMultipleChoiceName}options`, control });

  const handleMoveUpOption = (index: number) => {
    if (index > 0) {
      moveOption(index, index - 1);
    }
  };

  const handleMoveDownOption = (index: number) => {
    if (index >= 0) {
      moveOption(index, index + 1);
    }
  };

  return (
    <>
      <Typography use="body1" tag="p">
        <Trans i18nKey="journey-step.form.blocks.form.options.label" tag="label">
          Options
        </Trans>
      </Typography>
      {blockFieldOptions.map((blockOption: MessageBlockFormField, optionIndex: number) => (
        <div key={blockOption.id}>
          <CustomizableOption
            control={control}
            defaultValue={oldModelOptions ? oldModelOptions[optionIndex] : undefined}
            controllerName={`message_blocks.${index}.${groupMultipleChoiceName}options.${optionIndex}.value`}
            requiredProps={{ i18nKey: 'journey-step.form.blocks.form.options.required', requiredText: 'Option text is required' }}
            invalid={
              groupIndex ? errors?.message_blocks?.[index]?.group?.[groupIndex]?.options?.[optionIndex]?.message : errors?.message_blocks?.[index]?.options?.[optionIndex]?.message
            }
            isLoading={isLoading}
            label={t('journey-step.form.blocks.form.options.field-label') + ` ${optionIndex + 1}`}
            style={{ marginTop: '10px' }}
          />
          <ArrayFieldsActions
            index={optionIndex}
            handleMoveUpBlock={handleMoveUpOption}
            handleMoveDownBlock={handleMoveDownOption}
            handleRemoveBlock={() => removeOption(optionIndex)}
          />
        </div>
      ))}
      <Button icon="add" onClick={() => appendOption({ value: '' })} style={{ marginTop: '5px' }}>
        <Trans i18nKey="journey-step.form.blocks.form.options.add-option">Add option</Trans>
      </Button>
      <Controller
        control={control}
        defaultValue={block.multiple ?? false}
        name={`message_blocks.${index}.${groupMultipleChoiceName}multiple`}
        render={({ onChange, value, name }): React.ReactElement => (
          <>
            <Switch
              id={name}
              name={name}
              checked={value}
              onChange={(evt: { currentTarget: { checked: boolean } }): void => onChange(!!evt.currentTarget.checked)}
              style={{ display: 'flex', alignItems: 'center', paddingTop: '10px' }}
            >
              <div style={{ paddingLeft: '10px' }}>
                <Trans i18nKey="journey-step.form.blocks.form.options.multiple">Allow multiple options</Trans>
              </div>
            </Switch>
          </>
        )}
      />
    </>
  );
}
