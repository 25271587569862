import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import LoadingModal from '../LoadingModal';

import styles from './Start.module.css';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Theme } from '@rmwc/theme';
import { Typography } from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

import { Avatar } from '@rmwc/avatar';
import '@rmwc/avatar/avatar.css';

import { Elevation } from '@rmwc/elevation';
import '@material/elevation/dist/mdc.elevation.css';

import Ella from '../Brand/ella.svg';
import Manager from '../Ilustrations/manager.svg';
import HR from '../Ilustrations/hr.svg';
import Diversity from '../Ilustrations/diversity.svg';
import CEO from '../Ilustrations/ceo.svg';
import SelfEmployed from '../Ilustrations/self-employed.svg';
import Employee from '../Ilustrations/employee.svg';

import userService from '../../services/UserService';
import messageSnackbarQueue from '../MessagesSnackbar/MessagesSnackbarQueue';

function StartPersonType({ history }: InferProps<typeof StartPersonType.propTypes>): JSX.Element {
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation();

  function handleOnClick({ loading = true }): void {
    setLoading(loading);
  }

  return (
    <>
      <Helmet>
        <title>{t('start1.title')}</title>
      </Helmet>
      <LoadingModal open={loading} />

      <Grid className={styles.startHeader}>
        <GridCell phone={1} tablet={2} desktop={2} align="middle" className={styles.avatarCell}>
          <Avatar src={Ella} name="Ella" size="large" className={styles.ellaAvatar} />
        </GridCell>
        <GridCell phone={3} tablet={6} desktop={10} tabIndex={0}>
          <Typography use="headline5" tag="h1">
            <Trans i18nKey="start1.header">You are here, welcome!</Trans>
          </Typography>
          <p>
            <Trans i18nKey="start1.text">
              Now tell me what is your current position in the company? <br /> Choose the option that best represents you!
            </Trans>
          </p>
        </GridCell>
      </Grid>

      <Grid className={styles.answersGrid}>
        <GridCell phone={2} tablet={4} desktop={4}>
          <ElevationCardAnswer answerImage={Manager} answerKey="manager" answerText={t('start1.manager')} handleOnClick={handleOnClick} history={history} />
        </GridCell>
        <GridCell phone={2} tablet={4} desktop={4}>
          <ElevationCardAnswer answerImage={HR} answerKey="human-resource" answerText={t('start1.human-resource')} handleOnClick={handleOnClick} history={history} />
        </GridCell>
        <GridCell phone={2} tablet={4} desktop={4}>
          <ElevationCardAnswer
            answerImage={Diversity}
            answerKey="diversity-and-inclusion"
            answerText={t('start1.diversity-and-inclusion')}
            handleOnClick={handleOnClick}
            history={history}
          />
        </GridCell>
        <GridCell phone={2} tablet={4} desktop={4}>
          <ElevationCardAnswer answerImage={CEO} answerKey="c-level" answerText={t('start1.c-level')} handleOnClick={handleOnClick} history={history} />
        </GridCell>
        <GridCell phone={2} tablet={4} desktop={4}>
          <ElevationCardAnswer answerImage={SelfEmployed} answerKey="self-employed" answerText={t('start1.self-employed')} handleOnClick={handleOnClick} history={history} />
        </GridCell>
        <GridCell phone={2} tablet={4} desktop={4}>
          <ElevationCardAnswer answerImage={Employee} answerKey="employee" answerText={t('start1.employee')} handleOnClick={handleOnClick} history={history} />
        </GridCell>
        <GridCell span={12}>
          <Typography use="caption" tag="p" style={{ marginTop: '0' }}>
            <Trans i18nKey="start1.text-bottom">I&apos;ll use it to recommend onboarding types especially for you.</Trans>
          </Typography>
        </GridCell>
      </Grid>
    </>
  );
}

function ElevationCardAnswer({ answerImage, answerKey, answerText, handleOnClick, history }: InferProps<typeof ElevationCardAnswer.propTypes>): JSX.Element {
  async function handleAnswerOnClick(): Promise<void> {
    handleOnClick({ loading: true });
    try {
      await userService.updateProfileType(answerKey);
      handleOnClick({ loading: false });
      history.push('/start/company');
    } catch (error) {
      console.log('Error saving: ', error);
      const { message } = error as Error;
      messageSnackbarQueue.notifyError(message);
      handleOnClick({ loading: false });
    }
  }

  return (
    <Theme use="primary">
      <Elevation z={2} className={styles.answerCard} onClick={handleAnswerOnClick} onKeyPress={handleAnswerOnClick} tabIndex={0}>
        <img src={answerImage} aria-hidden="true" alt={answerText} className={styles.answerIlustration} />
        <p>{answerText}</p>
      </Elevation>
    </Theme>
  );
}

StartPersonType.propTypes = {
  history: PropTypes.any
};

ElevationCardAnswer.propTypes = {
  answerImage: PropTypes.string.isRequired,
  answerKey: PropTypes.string.isRequired,
  answerText: PropTypes.string.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  history: PropTypes.any
};

export default withRouter(StartPersonType);
