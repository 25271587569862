import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { TextField, Switch } from 'rmwc';
import { fullWithStyle } from '../../StepJourneyForm';

export function AskFollowUpQuestion({
  control,
  block,
  index,
  errors,
  isLoading
}: {
  control: Control;
  block: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  index: number;
  errors: FieldErrors;
  isLoading: boolean;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      defaultValue={block.ask_comment ?? false}
      name={`message_blocks.${index}.ask_comment`}
      render={({ onChange, value, name }): React.ReactElement => (
        <>
          <Switch
            id={name}
            name={name}
            checked={value}
            onChange={(evt: { currentTarget: { checked: boolean } }): void => onChange(!!evt.currentTarget.checked)}
            style={{ marginBlock: '1em' }}
          >
            <div style={{ marginLeft: '10px' }}>
              <Trans i18nKey="journey-step.form.blocks.poll.comment" style={{ marginLeft: '10px' }}>
                Ask follow-up question
              </Trans>
            </div>
          </Switch>

          {!!value && (
            <div style={{ marginBlockEnd: '1em' }}>
              <Controller
                control={control}
                defaultValue={block.ask_comment_text || ''}
                name={`message_blocks.${index}.ask_comment_text`}
                rules={{ required: t('journey-step.form.blocks.poll.comment_text.required', 'Text for follow-up question is required') as string }}
                render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
                  <TextField
                    type="text"
                    id={name}
                    name={name}
                    placeholder={t('journey-step.form.blocks.poll.comment_text.placeholder')}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    inputRef={ref}
                    invalid={errors?.message_blocks?.[index]?.ask_comment_text?.message ? true : false}
                    aria-invalid={errors?.message_blocks?.[index]?.ask_comment_text?.message ? true : false}
                    disabled={!!isLoading}
                    style={fullWithStyle}
                  />
                )}
              />
              {errors?.message_blocks?.[index]?.ask_comment_text?.message && <div role="alert">{errors?.message_blocks?.[index]?.ask_comment_text?.message}</div>}
            </div>
          )}
        </>
      )}
    />
  );
}
