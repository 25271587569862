import { AxiosError } from 'axios';
import { FieldValues } from 'react-hook-form';
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query';

import HttpBaseService from '../../services/HttpBaseService';
import StepJourneyRepository, { StepJourney, StepJourneyPeriod } from './StepJourneyRepository';

class StepJourneyService extends HttpBaseService<StepJourney> {
  constructor(private journeyId: string, private stepJourneyRepository = new StepJourneyRepository(journeyId)) {
    super(`journeys/${journeyId}`, stepJourneyRepository);
  }

  public useListOrdered(): UseQueryResult<StepJourney[]> {
    const sortDayConverted = (step: StepJourney): number => {
      if (step.period == StepJourneyPeriod.fixedDate) {
        return step.period_date ? new Date(step.period_date).getTime() : 0;
      }
      let day = +(step.period_days || 0);
      if (step.period == StepJourneyPeriod.beforeFirstDay) {
        day = day * -1;
      }
      return day;
    };

    const sortByTimePeriod = (a: StepJourney, b: StepJourney) => {
      const sortDayA = sortDayConverted(a);
      const sortDayB = sortDayConverted(b);
      if (sortDayA === sortDayB) {
        if ((a.period_time || '') < (b.period_time || '')) {
          return -1;
        }
      } else if (sortDayA < sortDayB) {
        return -1;
      }
      return 1;
    };

    return useQuery<StepJourney[]>(
      `journeys/${this.journeyId}`,
      async () => {
        return this.stepJourneyRepository.list();
      },
      {
        refetchOnWindowFocus: false,
        select: (steps) => steps.sort(sortByTimePeriod)
      }
    );
  }

  convertValuesToModel(values: FieldValues): StepJourney {
    return {
      name: values.name,
      to_whom: values.to_whom,
      to_whom_fixed_email: values.to_whom_fixed_email,
      to_whom_copy: values.to_whom_copy,
      to_whom_copy_fixed_email: values.to_whom_copy_fixed_email,
      to_whom_reply: values.to_whom_reply,
      to_whom_reply_fixed_email: values.to_whom_reply_fixed_email,
      subject: values.subject,
      message_html: values.message_html,
      message_source: values.message_source,
      message_blocks: values.message_blocks,
      period: values.period,
      period_days: this.convertToNumber(values.period_days),
      period_date: values.period_date,
      period_time: values.period_time,
      channel: values.channel,
      whatsapp_template: values.whatsapp_template
    } as StepJourney;
  }

  public useSendPreviewTest(): UseMutationResult<StepJourney, AxiosError, string> {
    return useMutation<StepJourney, AxiosError, string>((id: string) => this.stepJourneyRepository.sendPreviewTest(id));
  }
}

export default StepJourneyService;
