import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useForm, useWatch, Controller, FieldValues } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { GridRow, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { TextField } from '@rmwc/textfield';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';

import { Select } from '@rmwc/select';
import '@rmwc/select/styles';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/circular-progress.css';

import { Icon } from '@rmwc/icon';
import '@rmwc/icon/icon.css';

import { Typography } from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

import LogoImage from '../../Brand/LogoImage';
import AvatarImage from '../../Brand/AvatarImage';
import TooltipWrapper from '../../common/TooltipWrapper/TooltipWrapper';

const fullWithStyle = {
  width: '100%',
  backgroundColor: 'whitesmoke'
} as React.CSSProperties;

function CompanyForm({ onFormSubmit, onCancel, defaultValues, isLoading }: InferProps<typeof CompanyForm.propTypes>): JSX.Element {
  const { t } = useTranslation();

  const disableSentFromEmail = !!defaultValues?.sent_from_email;

  const { control, handleSubmit, errors } = useForm({ defaultValues });
  const companyNameValue: string | undefined = useWatch({ control, name: 'name' });
  const companyNamePronounValue: string | undefined = useWatch({ control, name: 'name_pronoun', defaultValue: defaultValues?.name_pronoun || 'a' });
  const logoUrlValue: string | undefined = useWatch({ control, name: 'logo_url' });
  const logoSmallUrlValue: string | undefined = useWatch({ control, name: 'logo_small_url' });
  const avatarNameValue: string | undefined = useWatch({ control, name: 'avatar_name' });
  const avatarNamePronounValue: string | undefined = useWatch({ control, name: 'avatar_name_pronoun', defaultValue: defaultValues?.avatar_name_pronoun || 'a' });
  const avatarUrlValue: string | undefined = useWatch({ control, name: 'avatar_url' });

  const onSubmit = async (values: FieldValues): Promise<void> => {
    onFormSubmit(values);
  };

  const formPronounOptions = [
    {
      label: t('company-settings.form.pronoun.a', 'A'),
      value: 'a'
    },
    {
      label: t('company-settings.form.pronoun.o', 'O'),
      value: 'o'
    }
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GridRow>
        <GridCell span={12}>
          <Controller
            control={control}
            defaultValue=""
            name="name"
            rules={{ required: t('company-settings.form.name.required', 'The name is required.') as string }}
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="company-settings.form.name.label">What is your company name?</Trans>
                  </label>
                </Typography>
                <GridRow>
                  <GridCell span={2}>
                    <Controller
                      control={control}
                      defaultValue="a"
                      name="name_pronoun"
                      render={({ onChange, onBlur, value, name }): React.ReactElement => (
                        <>
                          <Select enhanced options={formPronounOptions} id={name} name={name} onBlur={onBlur} onChange={onChange} value={value} disabled={!!isLoading} />
                        </>
                      )}
                    />
                  </GridCell>
                  <GridCell span={10}>
                    <TextField
                      type="text"
                      id={name}
                      name={name}
                      placeholder={t('company-settings.form.name.placeholder')}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      inputRef={ref}
                      invalid={errors?.name?.message ? true : false}
                      aria-invalid={errors?.name?.message ? true : false}
                      disabled={!!isLoading}
                      style={fullWithStyle}
                    />
                  </GridCell>
                </GridRow>
              </>
            )}
          />
          {errors?.name?.message && <div role="alert">{errors?.name?.message}</div>}
          <Typography use="caption">
            Exemplos: {companyNamePronounValue?.toUpperCase()} {companyNameValue} / d{companyNamePronounValue} {companyNameValue} / n{companyNamePronounValue} {companyNameValue}
          </Typography>
        </GridCell>
        <GridCell span={8}>
          <Controller
            control={control}
            defaultValue=""
            name="logo_url"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="company-settings.form.logo_url.label">Logo URL</Trans>
                  </label>
                </Typography>
                <TextField
                  type="url"
                  name={name}
                  placeholder={t('company-settings.form.logo_url.placeholder')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.logo_url?.message ? true : false}
                  aria-invalid={errors?.logo_url?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />
              </>
            )}
          />
          {errors?.logo_url?.message && <div role="alert">{errors?.logo_url?.message}</div>}
        </GridCell>
        <GridCell span={4} align="middle">
          <Typography use="body1" tag="p">
            <label htmlFor="logo_url_preview">
              <Trans i18nKey="company-settings.form.image_preview.label">Preview</Trans>
            </label>
          </Typography>
          <div id="logo_url_preview" style={{ height: '56px', maxWidth: '200px', margin: '0 auto' }}>
            <LogoImage companyName={companyNameValue} companyLogoUrl={logoUrlValue} />
          </div>
        </GridCell>
        <GridCell span={8}>
          <Controller
            control={control}
            defaultValue=""
            name="logo_small_url"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="company-settings.form.logo_small_url.label">Logo Symbol URL</Trans>
                  </label>
                </Typography>
                <TextField
                  type="url"
                  name={name}
                  placeholder={t('company-settings.form.logo_small_url.placeholder')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.logo_small_url?.message ? true : false}
                  aria-invalid={errors?.logo_small_url?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />
              </>
            )}
          />
          {errors?.logo_small_url?.message && <div role="alert">{errors?.logo_small_url?.message}</div>}
        </GridCell>
        <GridCell span={4} align="middle">
          {logoSmallUrlValue && (
            <>
              <Typography use="body1" tag="p">
                <label htmlFor="logo_small_url_preview">
                  <Trans i18nKey="company-settings.form.image_preview.label">Preview</Trans>
                </label>
              </Typography>
              <div id="logo_small_url_preview" style={{ height: '56px', maxWidth: '200px', margin: '0 auto' }}>
                <LogoImage companyName="Preview" companyLogoUrl={logoSmallUrlValue} />
              </div>
            </>
          )}
        </GridCell>
        <GridCell span={6}>
          <Controller
            control={control}
            defaultValue=""
            name="color_primary"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="company-settings.form.color_primary.label">Primary color</Trans>
                  </label>
                </Typography>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  placeholder={t('company-settings.form.color_primary.placeholder')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.color_primary?.message ? true : false}
                  aria-invalid={errors?.color_primary?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                >
                  <span style={{ backgroundColor: value ? value : '#F15A24', width: '60px' }}></span>
                </TextField>
              </>
            )}
          />
          {errors?.color_primary?.message && <div role="alert">{errors?.color_primary?.message}</div>}
        </GridCell>
        <GridCell span={6}>
          <Controller
            control={control}
            defaultValue=""
            name="color_secondary"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="company-settings.form.color_secondary.label">Primary color</Trans>
                  </label>
                </Typography>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  placeholder={t('company-settings.form.color_secondary.placeholder')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.color_secondary?.message ? true : false}
                  aria-invalid={errors?.color_secondary?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                >
                  <span style={{ backgroundColor: value ? value : '#4C2A82', width: '60px' }}></span>
                </TextField>
              </>
            )}
          />
          {errors?.color_secondary?.message && <div role="alert">{errors?.color_secondary?.message}</div>}
        </GridCell>
        <GridCell span={12}>
          <Controller
            control={control}
            defaultValue=""
            name="avatar_name"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="company-settings.form.avatar_name.label">Do you have a avatar? What is the avatar name?</Trans>
                  </label>
                </Typography>
                <GridRow>
                  <GridCell span={2}>
                    <Controller
                      control={control}
                      defaultValue="a"
                      name="avatar_name_pronoun"
                      render={({ onChange, onBlur, value, name }): React.ReactElement => (
                        <>
                          <Select enhanced options={formPronounOptions} id={name} name={name} onBlur={onBlur} onChange={onChange} value={value} disabled={!!isLoading} />
                        </>
                      )}
                    />
                  </GridCell>
                  <GridCell span={10}>
                    <TextField
                      type="text"
                      id={name}
                      name={name}
                      placeholder={t('company-settings.form.avatar_name.placeholder')}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      inputRef={ref}
                      invalid={errors?.avatar_name?.message ? true : false}
                      aria-invalid={errors?.avatar_name?.message ? true : false}
                      disabled={!!isLoading}
                      style={fullWithStyle}
                    />
                  </GridCell>
                </GridRow>
              </>
            )}
          />
          {errors?.avatar_name?.message && <div role="alert">{errors?.avatar_name?.message}</div>}
          <Typography use="caption">
            Exemplo: Aqui é {avatarNamePronounValue} {avatarNameValue || 'Ella'} d{companyNamePronounValue} {companyNameValue || 'Inboarding'}
          </Typography>
        </GridCell>
        <GridCell span={8}>
          <Controller
            control={control}
            defaultValue=""
            name="avatar_url"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="company-settings.form.avatar_url.label">Avatar Image URL</Trans>
                  </label>
                </Typography>
                <TextField
                  type="url"
                  name={name}
                  placeholder={t('company-settings.form.avatar_url.placeholder')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.avatar_url?.message ? true : false}
                  aria-invalid={errors?.avatar_url?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />
              </>
            )}
          />
          {errors?.avatar_url?.message && <div role="alert">{errors?.avatar_url?.message}</div>}
        </GridCell>
        <GridCell span={4} align="middle">
          <Typography use="body1" tag="p">
            <label htmlFor="avatar_url_preview">
              <Trans i18nKey="company-settings.form.image_preview.label">Preview</Trans>
            </label>
          </Typography>
          <div id="avatar_url_preview" style={{ height: '56px', maxWidth: '200px', margin: '0 auto' }}>
            <AvatarImage avatarName={avatarNameValue} avatarUrl={avatarUrlValue} />
          </div>
        </GridCell>
        <GridCell span={12}>
          <Controller
            control={control}
            defaultValue=""
            name="sent_from_email"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <InputWrapLockedTooltip inputLocked={disableSentFromEmail}>
                <>
                  <Typography use="body1" theme={disableSentFromEmail ? 'textDisabledOnBackground' : ''} tag="p">
                    <label htmlFor={name}>
                      <Trans i18nKey="company-settings.form.sent_from_email.label">Send email from</Trans>
                      {disableSentFromEmail && <Icon icon={{ icon: 'lock', size: 'xsmall' }} style={{ verticalAlign: 'middle', margin: '0 5px', paddingBottom: '2px' }} />}
                    </label>
                  </Typography>
                  <TextField
                    type="text"
                    name={name}
                    placeholder={t('company-settings.form.sent_from_email.placeholder')}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    inputRef={ref}
                    invalid={errors?.sent_from_email?.message ? true : false}
                    aria-invalid={errors?.sent_from_email?.message ? true : false}
                    disabled={disableSentFromEmail}
                    readOnly={disableSentFromEmail}
                    style={fullWithStyle}
                  />
                </>
              </InputWrapLockedTooltip>
            )}
          />
          {errors?.sent_from_email?.message && <div role="alert">{errors?.sent_from_email?.message}</div>}
        </GridCell>
        {defaultValues?.notify_integration_emails ? (
          <GridCell span={12}>
            <Controller
              control={control}
              defaultValue=""
              name="notify_integration_emails"
              render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
                <InputWrapLockedTooltip inputLocked={true}>
                  <>
                    <Typography use="body1" theme="textDisabledOnBackground" tag="p">
                      <label htmlFor={name}>
                        <Trans i18nKey="company-settings.form.notify_integration_emails.label">Integration notifications</Trans>
                        <Icon icon={{ icon: 'lock', size: 'xsmall' }} style={{ verticalAlign: 'middle', margin: '0 5px', paddingBottom: '2px' }} />
                      </label>
                    </Typography>
                    <TextField
                      type="text"
                      name={name}
                      placeholder=""
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      inputRef={ref}
                      disabled={true}
                      readOnly={true}
                      style={fullWithStyle}
                    />
                  </>
                </InputWrapLockedTooltip>
              )}
            />
          </GridCell>
        ) : null}
        {defaultValues?.import_file ? (
          <GridCell span={12}>
            <Controller
              control={control}
              defaultValue=""
              name="import_file"
              render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
                <InputWrapLockedTooltip inputLocked={true}>
                  <>
                    <Typography use="body1" theme="textDisabledOnBackground" tag="p">
                      <label htmlFor={name}>
                        <Trans i18nKey="company-settings.form.import_file.label">File Import</Trans>
                        <Icon icon={{ icon: 'lock', size: 'xsmall' }} style={{ verticalAlign: 'middle', margin: '0 5px', paddingBottom: '2px' }} />
                      </label>
                    </Typography>
                    <TextField
                      type="text"
                      name={name}
                      placeholder=""
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      inputRef={ref}
                      disabled={true}
                      readOnly={true}
                      style={fullWithStyle}
                    />
                  </>
                </InputWrapLockedTooltip>
              )}
            />
          </GridCell>
        ) : null}
        <GridCell span={12}>
          <Controller
            control={control}
            defaultValue=""
            name="timezone"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <InputWrapLockedTooltip inputLocked={true}>
                <>
                  <Typography use="body1" theme="textDisabledOnBackground" tag="p">
                    <label htmlFor={name}>
                      <Trans i18nKey="company-settings.form.timezone.label">Timezone</Trans>
                      <Icon icon={{ icon: 'lock', size: 'xsmall' }} style={{ verticalAlign: 'middle', margin: '0 5px', paddingBottom: '2px' }} />
                    </label>
                  </Typography>
                  <TextField
                    type="text"
                    name={name}
                    placeholder={t('company-settings.form.timezone.placeholder')}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    inputRef={ref}
                    invalid={errors?.timezone?.message ? true : false}
                    aria-invalid={errors?.timezone?.message ? true : false}
                    disabled={true}
                    readOnly={true}
                    style={fullWithStyle}
                  />
                </>
              </InputWrapLockedTooltip>
            )}
          />
          {errors?.timezone?.message && <div role="alert">{errors?.timezone?.message}</div>}
        </GridCell>
        <GridCell span={12} style={{ textAlign: 'right' }}>
          <Button type="button" onClick={onCancel} style={{ marginRight: '1.5rem' }}>
            <Trans i18nKey="crud.cancel">Cancel</Trans>
          </Button>
          <Button type="submit" raised {...(isLoading ? { icon: <CircularProgress theme="secondary" /> } : {})}>
            <Trans i18nKey="company-settings.save-update">Update company</Trans>
          </Button>
        </GridCell>
      </GridRow>
    </form>
  );
}

function InputWrapLockedTooltip({ children, inputLocked }: { children: JSX.Element; inputLocked: boolean }): JSX.Element {
  const { t } = useTranslation();
  const emailOnlyFieldText = t('company-settings.form.locked-field', 'To edit this value, send a message to support.');

  return (
    <TooltipWrapper showTooltip={!!inputLocked} tooltipText={emailOnlyFieldText}>
      {children}
    </TooltipWrapper>
  );
}

CompanyForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  defaultValues: PropTypes.any,
  isLoading: PropTypes.bool
};

export default CompanyForm;
