import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import WelcomeSection from '../WelcomeSection';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';
import Breadcrumb from '../common/Breadcrumb';

function Praise(): JSX.Element {
  const { t } = useTranslation();
  return (
    <Grid>
      <GridCell span={12}>
        <Breadcrumb routeSegments={[{ name: t('navlink.praise') }]} />
      </GridCell>
      <GridCell span={12}>
        <WelcomeSection>
          <Trans i18nKey="praise.beta">
            We are in beta. If you would like to have early access to the praise feature, please contact us on <a href="mailto:hello@inboarding.co">hello@inboarding.co</a>.
          </Trans>
        </WelcomeSection>
      </GridCell>
    </Grid>
  );
}

export default Praise;
