import HttpRepository from '../../../services/HttpRepository/HttpRepository';
import { Employee } from '../../Employee/EmployeeRepository';

class EmployeeSurveyAnswersRepository extends HttpRepository<Employee> {
  constructor() {
    super({ basePath: '/reports/people/survey-answers' });
  }
}

export default EmployeeSurveyAnswersRepository;
