import { AxiosError } from 'axios';
import { FieldValues } from 'react-hook-form';
import { useMutation, UseMutationResult } from 'react-query';
import HttpBaseService from '../../../services/HttpBaseService';
import AdminUsersRepository, { AdminUser, AdminUserPortalLink } from './AdminUsersRepository';

const QUERY_CACHE_KEY = 'admin-users';

class AdminUsersService extends HttpBaseService<AdminUser> {
  constructor(private adminUsersRepository = new AdminUsersRepository()) {
    super(QUERY_CACHE_KEY, adminUsersRepository);
  }

  convertValuesToModel(values: FieldValues): AdminUser {
    return {
      email: values.email,
      send_invite: Boolean(values.send_invite)
    } as AdminUser;
  }

  public useGetPortalLink(): UseMutationResult<AdminUserPortalLink, AxiosError, unknown> {
    return useMutation<AdminUserPortalLink, AxiosError, unknown>(() => this.adminUsersRepository.getPortalLink());
  }
}

export default AdminUsersService;
