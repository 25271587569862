import React, { forwardRef, useRef } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import './App.css';
import authService from './services/AuthService';

import Login from './components/Login';
import LoginCallback from './components/LoginCallback';
import NotFound from './components/NotFound';
import Start from './components/Start';
import AppLayout from './components/AppLayout';
import Home from './components/Home';
import Profile from './components/Profile';
import Awareness from './components/pages/Awareness';
import AwarenessStart from './components/pages/Awareness/AwarenessStart';
import AwarenessType from './components/pages/Awareness/Awareness';
import Praise from './components/Praise';
import Connection from './components/Connection';
import ConnectionNew from './components/Connection/ConnectionNew';
import Employee from './components/Employee';
import EmployeeNew from './components/Employee/EmployeeNew';
import EmployeeView from './components/Employee/EmployeeView';
import EmployeeEdit from './components/Employee/EmployeeEdit';
import EmployeeOffboarding from './components/Employee/EmployeeOffboarding';
import Settings from './components/Settings';
import Journey from './components/Journey/Journey';
import JourneyNew from './components/Journey/JourneyNew';
import JourneyEdit from './components/Journey/JourneyEdit';
import JourneyView from './components/Journey/JourneyView';
import Analytics from './components/pages/Analytics';
import StepJourneyNewSelect from './components/Journey/StepJourneyNewSelect';
import StepJourneyNew from './components/Journey/StepJourneyNew';
import StepJourneyEdit from './components/Journey/StepJourneyEdit';
import ContentsGlossaryHome from './components/pages/ContentsGlossary/ContentsGlossaryHome';
import ContentsGlossaryNew from './components/pages/ContentsGlossary/ContentsGlossaryNew';
import ContentsGlossaryEdit from './components/pages/ContentsGlossary/ContentsGlossaryEdit';
import ContentsQuestionsHome from './components/pages/ContentsQuestions/ContentsQuestionsHome';
import ContentsQuestionsNew from './components/pages/ContentsQuestions/ContentsQuestionsNew';
import ContentsQuestionsEdit from './components/pages/ContentsQuestions/ContentsQuestionsEdit';
import ContentsFilesHome from './components/pages/ContentsFiles';
import SupportHome from './components/pages/Support';
import AutomationHome from './components/pages/Automation';
import UsersSettingsNew from './components/Settings/UsersSettings/UsersSettingsNew';
import SlackAppInstalled from './components/SlackAppInstalled';
import MessagesDelivery from './components/pages/Analytics/MessagesDelivery';
import SurveyAnswers from './components/pages/Analytics/SurveyAnswers';
import FormAnswers from './components/pages/Analytics/FormAnswers';
import AnalyticsTurnover from './components/pages/Analytics/AnalyticsTurnover';
import EmployeeStartJourney from './components/Employee/EmployeeStartJourney';
import ImportEmployees from './components/pages/ImportEmployees';
import CelebrationsBirthHome from './components/pages/CelebrationsBirth/CelebrationsBirthHome';
import CelebrationsWorkHome from './components/pages/CelebrationsWork/CelebrationsWorkHome';
import JourneyStart from './components/Journey/JourneyStart';
import CelebrationsDiversityHome from './components/pages/CelebrationsDiversity/CelebrationsDiversityHome';

function PrivateRoute({ component: Component, addAppLayout, ...rest }: InferProps<typeof PrivateRoute.propTypes>): JSX.Element {
  const contentDivRef = useRef<HTMLDivElement>(null);

  return (
    <Route
      {...rest}
      render={(props): JSX.Element =>
        authService.isAuthenticated() ? (
          addAppLayout ? (
            <AppLayout contentDivRef={contentDivRef}>
              <ForwardRefContentDiv ref={contentDivRef}>
                <Component {...props} />
              </ForwardRefContentDiv>
            </AppLayout>
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  );
}

type DivProps = React.HTMLProps<HTMLDivElement>;

const ForwardRefContentDiv = forwardRef<HTMLDivElement, DivProps>(function ForwardRefComponent({ children }, ref) {
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <div className="Home" tabIndex={0} ref={ref}>
      {children}
    </div>
  );
});

function Routes(): JSX.Element {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login/callback" component={LoginCallback} />
        <Route path="/login" component={Login} />
        <Route path="/slack/app/installed/:id" component={SlackAppInstalled} />
        <PrivateRoute exact path="/" component={Home} addAppLayout={true} />
        <PrivateRoute path="/start" component={Start} addAppLayout={false} />
        <PrivateRoute exact path="/support" component={SupportHome} addAppLayout={true} />
        <PrivateRoute exact path="/profile" component={Profile} addAppLayout={true} />
        <PrivateRoute exact path="/employees" component={Employee} addAppLayout={true} />
        <PrivateRoute exact path="/offboarding/employees" component={EmployeeOffboarding} addAppLayout={true} />
        <PrivateRoute exact path="/employees/new" component={EmployeeNew} addAppLayout={true} />
        <PrivateRoute exact path="/employees/:id/edit" component={EmployeeEdit} addAppLayout={true} />
        <PrivateRoute exact path="/employees/:id" component={EmployeeView} addAppLayout={true} />
        <PrivateRoute exact path="/employees/:id/start/:journeyId" component={EmployeeStartJourney} addAppLayout={true} />
        <PrivateRoute exact path="/employees/:id/:tab" component={EmployeeView} addAppLayout={true} />
        <PrivateRoute exact path="/import/employees" component={ImportEmployees} addAppLayout={true} />
        <PrivateRoute exact path="/journeys" component={Journey} addAppLayout={true} />
        <PrivateRoute exact path="/journeys/:journeyId/steps/select" component={StepJourneyNewSelect} addAppLayout={true} />
        <PrivateRoute exact path="/journeys/:journeyId/steps/new" component={StepJourneyNew} addAppLayout={true} />
        <PrivateRoute exact path="/journeys/:journeyId/steps/:id/edit" component={StepJourneyEdit} addAppLayout={true} />
        <PrivateRoute exact path="/journeys/:id/edit" component={JourneyEdit} addAppLayout={true} />
        <PrivateRoute exact path="/journeys/new" component={JourneyNew} addAppLayout={true} />
        <PrivateRoute exact path="/journeys/:journeyId/start" component={JourneyStart} addAppLayout={true} />
        <PrivateRoute exact path="/journeys/:id" component={JourneyView} addAppLayout={true} />
        <PrivateRoute exact path="/awareness" component={Awareness} addAppLayout={true} />
        <PrivateRoute path="/awareness/:slug/start" component={AwarenessStart} addAppLayout={true} />
        <PrivateRoute path="/awareness/:slug" component={AwarenessType} addAppLayout={true} />
        <PrivateRoute exact path="/engagement" component={Connection} addAppLayout={true} />
        <PrivateRoute exact path="/engagement/connection/new" component={ConnectionNew} addAppLayout={true} />
        <PrivateRoute exact path="/engagement/connection" component={Connection} addAppLayout={true} />
        <PrivateRoute exact path="/engagement/praise" component={Praise} addAppLayout={true} />
        <PrivateRoute exact path="/settings/users/new" component={UsersSettingsNew} addAppLayout={true} />
        <PrivateRoute exact path="/settings/:type" component={Settings} addAppLayout={true} />
        <PrivateRoute exact path="/settings" component={Settings} addAppLayout={true} />
        <PrivateRoute exact path="/analytics" component={Analytics} addAppLayout={true} />
        <PrivateRoute exact path="/analytics/messages-delivery" component={MessagesDelivery} addAppLayout={true} />
        <PrivateRoute exact path="/analytics/survey-answers" component={SurveyAnswers} addAppLayout={true} />
        <PrivateRoute exact path="/analytics/form-answers" component={FormAnswers} addAppLayout={true} />
        <PrivateRoute exact path="/analytics/turnover" component={AnalyticsTurnover} addAppLayout={true} />
        <PrivateRoute exact path="/automation" component={AutomationHome} addAppLayout={true} />
        <PrivateRoute exact path="/contents/questions" component={ContentsQuestionsHome} addAppLayout={true} />
        <PrivateRoute exact path="/contents/questions/new" component={ContentsQuestionsNew} addAppLayout={true} />
        <PrivateRoute exact path="/contents/questions/:id/edit" component={ContentsQuestionsEdit} addAppLayout={true} />
        <PrivateRoute exact path="/contents/questions/:id" component={ContentsQuestionsEdit} addAppLayout={true} />
        <PrivateRoute exact path="/contents/glossary" component={ContentsGlossaryHome} addAppLayout={true} />
        <PrivateRoute exact path="/contents/glossary/new" component={ContentsGlossaryNew} addAppLayout={true} />
        <PrivateRoute exact path="/contents/glossary/:id/edit" component={ContentsGlossaryEdit} addAppLayout={true} />
        <PrivateRoute exact path="/contents/glossary/:id" component={ContentsGlossaryEdit} addAppLayout={true} />
        <PrivateRoute exact path="/contents/files" component={ContentsFilesHome} addAppLayout={true} />
        <PrivateRoute exact path="/celebrations/birth" component={CelebrationsBirthHome} addAppLayout={true} />
        <PrivateRoute exact path="/celebrations/work" component={CelebrationsWorkHome} addAppLayout={true} />
        <PrivateRoute exact path="/celebrations/diversity" component={CelebrationsDiversityHome} addAppLayout={true} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  addAppLayout: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.any
};

export default Routes;
