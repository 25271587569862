import { Auth } from 'aws-amplify';
import { CognitoUserSession, CognitoIdToken } from 'amazon-cognito-identity-js';
import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';

import store from '../StoreService';

export interface AuthUserAtrributes {
  sub: string;
  email: string;
  username: string;
  name: string;
  given_name: string;
  family_name: string;
  picture: string;
  'custom:tenant_id': string;
  identities?: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
}

class AuthService {
  isAuthenticated(): boolean {
    return !!store.get('login');
  }

  logout(): void {
    Auth.signOut();
    store.remove('authUser');
    store.remove('authUserTenantId');
    store.remove('login');
    window.location.href = '/login';
  }

  tenantId(): string {
    let authUserTenantId = store.get('authUserTenantId');
    if (!authUserTenantId) {
      const authUser = this.getAuthUser();
      authUserTenantId = authUser['custom:tenant_id'];
    }
    return authUserTenantId || '';
  }

  setAuthUser(authUser: AuthUserAtrributes): void {
    store.set('authUser', authUser ? JSON.stringify(authUser) : undefined);
    store.set('authUserTenantId', authUser ? authUser['custom:tenant_id'] : undefined);
    this.setSessionMonitorUser(authUser);
  }

  getAuthUser(): AuthUserAtrributes {
    const authUser = store.get('authUser');
    return authUser ? JSON.parse(authUser) : undefined;
  }

  async jwtToken(): Promise<string> {
    const currentSession: CognitoUserSession = await Auth.currentSession();
    const idToken: CognitoIdToken = currentSession.getIdToken();
    return idToken.getJwtToken();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setSessionMonitorUser(user: any): void {
    if (user?.email) {
      // Sentry error monitoring
      try {
        Sentry.setUser(user);
      } catch (error) {
        console.log('[Warning] not sending session monitoring data to Sentry.');
      }

      // LogRocket error sessions monitoring
      try {
        LogRocket.identify(user.email, {
          name: user.name,
          email: user.email
        });
      } catch (error) {
        console.log('[Warning] not sending session monitoring data to LogRocket.');
      }
    }
  }
}

export default new AuthService();
