import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes, { InferProps } from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { FieldValues } from 'react-hook-form';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import Breadcrumb from '../common/Breadcrumb';
import StepJourneyForm from './StepJourneyForm';
import Message from '../../services/Message';
import LoadingModal from '../LoadingModal';
import ErrorMessage from '../ErrorMessage';
import StepJourneyService from './StepJourneyService';
import JourneyService from './JourneyService';

function StepJourneyEdit({ history }: InferProps<typeof StepJourneyEdit.propTypes>): JSX.Element {
  const { journeyId, id } = useParams() as { journeyId: string; id: string };
  const { t } = useTranslation();

  const journeyService = new JourneyService();
  const stepJourneyService = new StepJourneyService(journeyId);
  const useUpdateStepJourney = stepJourneyService.useUpdate();

  const { data: dataJourney, isFetching: isFetchingJourbye, isError: isErrorJournye, error: errorJourney } = journeyService.useGet(journeyId);
  const { data, isFetching, isError, error } = stepJourneyService.useGet(id);

  const onSubmit = async (values: FieldValues): Promise<void> => {
    try {
      await useUpdateStepJourney.mutateAsync({ id, ...values });
      Message.success(t('journey-step.updated'));
      history.push(`/journeys/${journeyId}`);
    } catch (error) {
      Message.error(t('journey-step.updated-error'));
      console.error('Error on save: ', error);
    }
  };
  const onCancel = async (): Promise<void> => {
    history.goBack();
  };

  if (isErrorJournye) {
    return <ErrorMessage error={errorJourney}></ErrorMessage>;
  }

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetchingJourbye || isFetching) {
    return <LoadingModal open={true} />;
  }

  return (
    <Grid>
      <GridCell span={12}>
        <Breadcrumb
          routeSegments={[
            { name: t('navlink.journeys'), path: '/journeys' },
            { name: t('journey.view-title'), path: `/journeys/${journeyId}` },
            { name: t('journey-step.edit-title') }
          ]}
        />
      </GridCell>

      <GridCell span={12}>
        <h1>
          <Trans i18nKey="journey-step.edit-title">Edit step</Trans>
        </h1>
      </GridCell>

      <GridCell span={12}>
        <StepJourneyForm
          journeyId={journeyId}
          journey={dataJourney}
          stepId={id}
          onFormSubmit={onSubmit}
          onCancel={onCancel}
          isLoading={useUpdateStepJourney.isLoading}
          defaultValues={data}
        ></StepJourneyForm>
      </GridCell>
    </Grid>
  );
}

StepJourneyEdit.propTypes = {
  history: PropTypes.any
};

export default StepJourneyEdit;
