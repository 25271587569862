import { FieldValues } from 'react-hook-form';
import HttpBaseService from '../../services/HttpBaseService';
import ConnectionRepository, { Connection } from './ConnectionRepository';

class ConnectionService extends HttpBaseService<Connection> {
  constructor(private connectionRepository = new ConnectionRepository()) {
    super('connections', connectionRepository);
  }

  convertValuesToModel(values: FieldValues): Connection {
    return {
      name: values.name
    } as Connection;
  }
}

export default ConnectionService;
