import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import WelcomeSection from '../../WelcomeSection';

function Analytics(): JSX.Element {
  const { t } = useTranslation();

  return (
    <WelcomeSection betaFeatureForm={t('analytics.beta-name', 'Analytics')}>
      <Trans i18nKey="analytics.beta">
        We are in beta. If you would like to have early access to the Analytics feature, please contact us on
        <a href="mailto:hello@inboarding.co">hello@inboarding.co</a>.
      </Trans>
    </WelcomeSection>
  );
}

export default Analytics;
