import React from 'react';
import { Trans } from 'react-i18next';

function JourneyStepMenitonNameTrans({ name }: { name: string | undefined }): JSX.Element {
  if (!name) {
    return <></>;
  }

  switch (name) {
    case 'new_hire':
      return <Trans i18nKey="mentions.entity.new_hire">@NewHire</Trans>;
    case 'leader':
      return <Trans i18nKey="mentions.entity.leader">@Leader</Trans>;
    case 'buddy':
      return <Trans i18nKey="mentions.entity.buddy">@Buddy</Trans>;
    case 'fixed_email':
      return <Trans i18nKey="mentions.entity.fixed_email">Fixed email</Trans>;
    case 'new_hire_personal_email':
      return <Trans i18nKey="mentions.entity.new_hire_personal_email">@NewHire.PersonalEmail</Trans>;
    case 'new_hire_work_email':
      return <Trans i18nKey="mentions.entity.new_hire_work_email">@NewHire.WorkEmail</Trans>;
  }
  return <></>;
}

export default JourneyStepMenitonNameTrans;
