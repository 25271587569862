import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { Journey, JourneyFlowType } from './JourneyRepository';
import { StepJourney, StepJourneyChannel } from './StepJourneyRepository';
import { StepJourneyPeriod } from './StepJourneyRepository';

import JourneyService from './JourneyService';
import StepJourneyService from './StepJourneyService';
import parseExtractHashId from '../../services/HttpRepository/parseExtractHashId';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { GridList, GridTile } from '@rmwc/grid-list';
import '@rmwc/grid-list/styles';

import { Card, CardPrimaryAction, CardActions, CardActionButtons } from '@rmwc/card';
import { CardActionIcons, CardActionIcon } from '@rmwc/card';
import '@rmwc/card/styles';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { IconButton } from '@rmwc/icon-button';
import '@material/icon-button/dist/mdc.icon-button.css';

import { Typography } from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

import { Badge } from '@rmwc/badge';
import '@rmwc/badge/badge.css';

import { Tooltip } from '@rmwc/tooltip';
import '@rmwc/tooltip/styles';

import { Theme } from '@rmwc/theme';
import { ThemeProvider } from '@rmwc/theme';
import '@material/theme/dist/mdc.theme.css';
import '@rmwc/theme/theme.css';

import { Icon } from '@rmwc/icon';
import '@rmwc/icon/icon.css';

import LoadingModal from '../LoadingModal';
import ErrorMessage from '../ErrorMessage';
import Breadcrumb from '../common/Breadcrumb';
import EllaMessage from '../common/EllaMessage';
import CardDivider from '../common/CardDivider';

import stepStyles from './StepJourney.module.css';
import StepJourneyPreviewDialog from './StepJourneyPreviewDialog';
import JourneyStepMenitonNameTrans from './JourneyStepMenitonNameTrans';
import ContentTooltipWrapper from '../common/ContentTooltipWrapper';
import TooltipWrapper from '../common/TooltipWrapper/TooltipWrapper';
import { StepJourneyChannelHeader, StepJourneyBlockType } from './StepJourneyMessagePreview';
import { dateFormat } from '../common/DatesFormat/dateTimeFormat';

function JourneyView(): JSX.Element {
  const { id } = useParams() as { id: string };
  const { t } = useTranslation();

  const journeyService = new JourneyService();
  const { data, isFetching, isError, error } = journeyService.useGet(id);

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching) {
    return <LoadingModal open={true} />;
  }

  return (
    <Grid>
      <GridCell span={12}>
        <Breadcrumb routeSegments={[{ name: t('navlink.journeys'), path: '/journeys' }, { name: t('journey.view-title') }]} />
      </GridCell>
      <GridCell span={4} align="middle">
        <h2 style={{ marginBottom: 0 }}>
          <Trans i18nKey="journey.view-title">Journey</Trans>
        </h2>
      </GridCell>
      <JourneyDetails dataId={id} journey={data}></JourneyDetails>
    </Grid>
  );
}

function FirstStepJourneyInstruction({ dataId, flowType }: InferProps<typeof FirstStepJourneyInstruction.propTypes>): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <GridCell span={12}>
        <EllaMessage title={t('journey.ella-view-title', 'Quase lá!')} message={t('journey.ella-view-message', 'Sua jornada está criada, agora crie a sua primeira etapa')} />
      </GridCell>
      <GridCell span={12} style={{ textAlign: 'center' }}>
        <Button raised icon="add" tag={Link} {...{ to: flowType === 'COMMUNICATION' ? `/journeys/${dataId}/steps/new` : `/journeys/${dataId}/steps/select` }}>
          <Trans i18nKey="journey.create-step">New step</Trans>
        </Button>
      </GridCell>
    </>
  );
}

function JourneyStepCard({ step }: { step: StepJourney }): JSX.Element {
  const [openPreview, setOpenPreview] = React.useState(false);
  const { t } = useTranslation();

  function onPreviewStep(): void {
    setOpenPreview(true);
  }

  if (step.channel !== StepJourneyChannel.Email && step.message_html) {
    step.message_html = step.message_html.replace(/<\/?span[^>]*>/g, '');
  }

  return (
    <>
      {openPreview && <StepJourneyPreviewDialog step={step} onClose={(): void => setOpenPreview(false)} />}
      <Card style={{ maxWidth: '21rem', height: '100%', borderTop: 'solid var(--mdc-theme-primary) 5px', cursor: 'pointer' }} onClick={onPreviewStep}>
        <StepJourneyChannelHeader channel={step.channel} />
        <StepJourneyPeriodTime step={step} />
        <Typography use="subtitle2" style={{ padding: '0 0.5rem 0 0.5rem' }} theme="primary">
          <Trans i18nKey="journey-step.preview.to">To:</Trans>
        </Typography>
        <Typography use="subtitle1" style={{ padding: '0 0.5rem 0.5rem 0.5rem' }}>
          <JourneyStepMenitonNameTrans name={step.to_whom} />
        </Typography>
        <CardDivider />
        <Typography use="subtitle2" style={{ padding: '0.5rem 0.5rem 0 0.5rem' }} theme="primary">
          <Trans i18nKey="journey-step.preview.step-name">Step name:</Trans>
        </Typography>
        <ContentTooltipWrapper contentText={step.name} maxLength={30}>
          <Typography use="subtitle1" style={{ padding: '0 0.5rem 0.5rem 0.5rem', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', paddingBottom: '30px' }}>
            {step.name}
          </Typography>
        </ContentTooltipWrapper>
        <CardDivider />
        <CardPrimaryAction style={{ height: '100%', maxHeight: '230px' }} onClick={onPreviewStep}>
          <div style={{ padding: '0.5rem' }}>
            <Typography use="body2" tag="div" theme="textSecondaryOnBackground" className={stepStyles.stepsPreview}>
              <div dangerouslySetInnerHTML={{ __html: step.message_html as string }} />
            </Typography>
          </div>
        </CardPrimaryAction>
        <CardActions>
          <CardActionButtons>
            <StepJourneyBlockType type={step.message_blocks?.[0]?.type} />
          </CardActionButtons>
          <CardActionIcons>
            <Tooltip content={t('journey.step.preview', 'Preview')}>
              <CardActionIcon label={t('journey.step.preview', 'Preview')} icon="preview" style={{ alignSelf: 'end' }} onClick={onPreviewStep} />
            </Tooltip>
            <Tooltip content={t('journey.step.edit', 'Edit')}>
              <CardActionIcon
                tag={Link}
                {...{ to: `/journeys/${step.journey_id}/steps/${parseExtractHashId(step.id || '')}/edit` }}
                label={t('journey.step.edit', 'Edit')}
                icon="edit"
                style={{ alignSelf: 'end' }}
                onClick={(e: React.MouseEvent) => e.stopPropagation()}
              />
            </Tooltip>
          </CardActionIcons>
        </CardActions>
      </Card>
    </>
  );
}

function JourneyStepCardDiagram({ step, showNextIcon }: { step: StepJourney; showNextIcon: boolean }): JSX.Element {
  const [openPreview, setOpenPreview] = React.useState(false);

  function onPreviewStep(): void {
    setOpenPreview(true);
  }

  return (
    <>
      {openPreview && <StepJourneyPreviewDialog step={step} onClose={(): void => setOpenPreview(false)} />}
      <Typography tag="h3" use="headline6" theme="primary" style={{ textAlign: 'center', margin: '0' }}>
        <StepJourneyFormWhenPeriodPreview period={step.period} days={step.period_days} date={step.period_date} />
      </Typography>
      <Typography tag="p" use="subtitle1" theme="textSecondaryOnBackground" style={{ textAlign: 'center', margin: '0 0 10px 0' }}>
        {step.period_time}
      </Typography>

      {showNextIcon && <Icon icon="east" style={{ position: 'absolute', top: '15px', right: '-5px' }} />}
      <Card style={{ maxWidth: '21rem', borderTop: 'solid var(--mdc-theme-primary) 5px', cursor: 'pointer' }} onClick={onPreviewStep}>
        <StepJourneyChannelHeader channel={step.channel} />
        <Typography use="subtitle2" style={{ padding: '0 0.5rem 0 0.5rem' }} theme="primary" tag="span">
          <Trans i18nKey="journey-step.preview.to">To:</Trans>
        </Typography>
        <Typography use="subtitle1" style={{ padding: '0 0.5rem 0.5rem 0.5rem' }} tag="span">
          <JourneyStepMenitonNameTrans name={step.to_whom} />
        </Typography>
        <CardDivider />
        <Typography use="subtitle2" style={{ padding: '0.5rem 0.5rem 0 0.5rem' }} theme="primary">
          <Trans i18nKey="journey-step.preview.step-name">Step name:</Trans>
        </Typography>
        <Typography
          use="subtitle1"
          style={{ padding: '0.5rem', height: '56px', textOverflow: 'ellipsis', overflow: 'hidden', WebkitLineClamp: '2', display: '-webkit-box', WebkitBoxOrient: 'vertical' }}
        >
          {step.name}
        </Typography>
      </Card>
    </>
  );
}

function StepJourneyPeriodTime({ step }: { step: StepJourney }): JSX.Element {
  return (
    <ThemeProvider options={{ primary: 'var(--mdc-theme-background-light)', onPrimary: 'var(--mdc-theme-text-secondary-on-background)' }} tag="span" style={{ textAlign: 'right' }}>
      <Badge
        label={<StepJourneyFormWhenPeriodPreview period={step.period} days={step.period_days} date={step.period_date} />}
        align="inline"
        theme={['primaryBg', 'onPrimary']}
        style={{ padding: '0 15px', marginBottom: '0', marginLeft: '0', textTransform: 'none' }}
      />
      <Badge label={step.period_time} align="inline" theme={['primaryBg', 'onPrimary']} style={{ padding: '0 15px', marginBottom: '0', marginLeft: '0' }} />
    </ThemeProvider>
  );
}

function StepJourneyFormWhenPeriodPreview({ period, days, date }: { period: string | undefined; days: number | undefined; date: string | undefined }): JSX.Element {
  switch (period) {
    case 'fixed-date':
      return (
        <>
          <Trans i18nKey="journey-step.preview.period-fixed-date">No dia</Trans> {dateFormat(date)}
        </>
      );
    case 'first-day':
      return (
        <Trans i18nKey="journey-step.preview.period-days-first" count={days}>
          No 1º dia
        </Trans>
      );
    case 'after-first-day':
      return (
        <Trans i18nKey="journey-step.preview.period-days-after" count={days}>
          {{ count: days }} dias depois
        </Trans>
      );
    default:
      return (
        <Trans i18nKey="journey-step.preview.period-days-before" count={days}>
          {{ count: days }} dias antes
        </Trans>
      );
  }
}

const gridTileStyle = {
  width: '255px',
  padding: '10px'
};

function JourneyStepViewTypeButton({ cardView, onChangeCardView }: { cardView: boolean; onChangeCardView: (value: boolean) => void }): JSX.Element {
  const { t } = useTranslation();
  return (
    <Theme use="textSecondaryOnBackground">
      <TooltipWrapper tooltipText={t('table.view-type.cards')}>
        <IconButton icon="view_module" label={t('table.view-type.cards')} disabled={cardView} onClick={() => onChangeCardView(true)} />
      </TooltipWrapper>
      <TooltipWrapper tooltipText={t('table.view-type.diagrams')}>
        <IconButton icon="schema" label={t('table.view-type.diagrams')} disabled={!cardView} onClick={() => onChangeCardView(false)} style={{ marginRight: '15px' }} />
      </TooltipWrapper>
    </Theme>
  );
}

function JourneyStepDetails({ steps, cardView, flowType }: InferProps<typeof JourneyStepDetails.propTypes>): JSX.Element {
  if (!steps) {
    return <></>;
  }

  return <>{cardView ? <JourneyStepDetailsCards steps={steps} flowType={flowType} /> : <JourneyStepDetailsDiagramSequence steps={steps} />}</>;
}

function JourneyStepDetailsCards({ steps, flowType }: InferProps<typeof JourneyStepDetailsCards.propTypes>): JSX.Element {
  if (flowType === JourneyFlowType.Communication) {
    return <JourneyStepDetailsCardsCommunication steps={steps}></JourneyStepDetailsCardsCommunication>;
  }

  if (flowType === JourneyFlowType.Offboarding) {
    return <JourneyStepDetailsCardsCommunication steps={steps}></JourneyStepDetailsCardsCommunication>;
  }

  return <JourneyStepDetailsCardsOnboarding steps={steps}></JourneyStepDetailsCardsOnboarding>;
}

function JourneyStepDetailsCardsOffboarding({ steps }: InferProps<typeof JourneyStepDetailsCardsOffboarding.propTypes>): JSX.Element {
  return <JourneyStepDetailsCardsOnboarding steps={steps}></JourneyStepDetailsCardsOnboarding>;
}

function JourneyStepDetailsCardsOnboarding({ steps }: InferProps<typeof JourneyStepDetailsCardsOnboarding.propTypes>): JSX.Element {
  const stepsBeforeFirstDay = [];
  const stepsOnAfterFirstDay = [];
  for (const step of steps) {
    if (step.period == StepJourneyPeriod.beforeFirstDay) {
      stepsBeforeFirstDay.push(step);
    } else {
      stepsOnAfterFirstDay.push(step);
    }
  }
  return (
    <>
      <GridCell span={6}>
        <Typography use="headline6" tag="h3" style={{ textAlign: 'center', padding: '10px', margin: '10px', borderRadius: '5px', backgroundColor: 'whitesmoke' }} theme="primary">
          <Trans i18nKey="journey-step.view.before-first-day">Before first day</Trans>
        </Typography>
        <GridList tileAspect="2x3">
          {stepsBeforeFirstDay.map((stepData, index) => (
            <GridTile key={index} style={gridTileStyle}>
              <JourneyStepCard key={index} step={stepData}></JourneyStepCard>
            </GridTile>
          ))}
        </GridList>
      </GridCell>
      <GridCell span={6}>
        <Typography use="headline6" tag="h3" style={{ textAlign: 'center', padding: '10px', margin: '10px', borderRadius: '5px', backgroundColor: 'whitesmoke' }} theme="primary">
          <Trans i18nKey="journey-step.view.after-first-day">After first day</Trans>
        </Typography>
        <GridList tileAspect="2x3">
          {stepsOnAfterFirstDay.map((stepData, index) => (
            <GridTile key={index} style={gridTileStyle}>
              <JourneyStepCard key={index} step={stepData}></JourneyStepCard>
            </GridTile>
          ))}
        </GridList>
      </GridCell>
    </>
  );
}

function JourneyStepDetailsCardsCommunication({ steps }: InferProps<typeof JourneyStepDetailsCardsCommunication.propTypes>): JSX.Element {
  return (
    <GridCell span={12}>
      <Typography use="headline6" tag="h3" style={{ textAlign: 'center', padding: '10px', margin: '10px', borderRadius: '5px', backgroundColor: 'whitesmoke' }} theme="primary">
        <Trans i18nKey="journey-step.view.communication-fixed-date">Communication steps</Trans>
      </Typography>
      <GridList tileAspect="2x3">
        {steps.map((stepData, index) => (
          <GridTile key={index} style={gridTileStyle}>
            <JourneyStepCard key={index} step={stepData}></JourneyStepCard>
          </GridTile>
        ))}
      </GridList>
    </GridCell>
  );
}

function JourneyStepDetailsDiagramSequence({ steps }: InferProps<typeof JourneyStepDetailsCards.propTypes>): JSX.Element {
  const stepsLength = steps.length;
  return (
    <>
      <GridCell span={12}>
        <GridList tileAspect="4x3">
          {steps.map((stepData, index) => {
            const showNextArrowIcon = !!stepsLength && index < stepsLength;
            return (
              <GridTile key={index} style={gridTileStyle}>
                <JourneyStepCardDiagram key={index} step={stepData} showNextIcon={showNextArrowIcon}></JourneyStepCardDiagram>
              </GridTile>
            );
          })}
        </GridList>
      </GridCell>
    </>
  );
}

function JourneyDetails({ dataId, journey }: { dataId: string; journey: Journey | undefined }): JSX.Element {
  if (!journey) return <> </>;

  const stepJourneyService = new StepJourneyService(dataId);
  const { data, isFetching, isError, error } = stepJourneyService.useListOrdered();
  const [cardView, setCardView] = React.useState(true);

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching) {
    return <LoadingModal open={true} />;
  }

  const hasDataCreated = data && data.length > 0;

  return (
    <>
      <GridCell span={8} align="middle" style={{ textAlign: 'right' }}>
        {hasDataCreated && (
          <>
            <JourneyStepViewTypeButton cardView={cardView} onChangeCardView={(value: boolean) => setCardView(value)} />
            <Button outlined icon="auto_graph" tag={Link} {...{ to: `/journeys/${dataId}/start` }} style={{ marginTop: '-12px', marginRight: '15px' }}>
              <Trans i18nKey="employee.journey-start">Start journey</Trans>
            </Button>
            <Button
              raised
              icon="add"
              tag={Link}
              {...{ to: journey.flow_type === 'COMMUNICATION' ? `/journeys/${dataId}/steps/new` : `/journeys/${dataId}/steps/select` }}
              style={{ marginTop: '-12px' }}
            >
              <Trans i18nKey="journey.create-step">New step</Trans>
            </Button>
          </>
        )}
      </GridCell>
      <GridCell span={12}>
        <h2 style={{ marginBottom: 0, marginTop: 0 }}>
          <Typography use="headline6">{journey.name}</Typography>
          <ThemeProvider options={{ primary: '#C4C4C4', onPrimary: '#000' }} tag="span">
            <JourneyFlowTypeBadge type={journey.flow_type}></JourneyFlowTypeBadge>
          </ThemeProvider>
        </h2>
        <p style={{ marginTop: 0 }}>
          <Typography use="subtitle2">{journey.description}</Typography>
        </p>
      </GridCell>
      {hasDataCreated ? (
        <JourneyStepDetails steps={data} cardView={cardView} flowType={journey.flow_type}></JourneyStepDetails>
      ) : (
        <FirstStepJourneyInstruction dataId={dataId} flowType={journey.flow_type}></FirstStepJourneyInstruction>
      )}
    </>
  );
}

function JourneyFlowTypeBadge({ type }: { type: JourneyFlowType | undefined }): JSX.Element {
  const { t } = useTranslation();
  if (!type) {
    return <></>;
  }

  if (type === JourneyFlowType.Offboarding) {
    return <Badge label={t('journey.form.flow_type.offboarding')} align="inline" theme={['primaryBg', 'onPrimary']} style={{ padding: '0 15px', textTransform: 'none' }} />;
  }

  if (type === JourneyFlowType.Communication) {
    return <Badge label={t('journey.form.flow_type.communication')} align="inline" theme={['primaryBg', 'onPrimary']} style={{ padding: '0 15px', textTransform: 'none' }} />;
  }

  return <Badge label={t('journey.form.flow_type.onboarding')} align="inline" theme={['primaryBg', 'onPrimary']} style={{ padding: '0 15px', textTransform: 'none' }} />;
}

JourneyStepDetails.propTypes = {
  steps: PropTypes.array,
  cardView: PropTypes.bool,
  flowType: PropTypes.string
};

JourneyStepDetailsCards.propTypes = {
  steps: PropTypes.array.isRequired,
  flowType: PropTypes.string
};

JourneyStepDetailsCardsOnboarding.propTypes = {
  steps: PropTypes.array.isRequired
};

JourneyStepDetailsCardsOffboarding.propTypes = {
  steps: PropTypes.array.isRequired
};

JourneyStepDetailsCardsCommunication.propTypes = {
  steps: PropTypes.array.isRequired
};

JourneyStepCard.propTypes = {
  step: PropTypes.object.isRequired
};

JourneyDetails.propTypes = {
  dataId: PropTypes.string,
  journey: PropTypes.object
};

FirstStepJourneyInstruction.propTypes = {
  dataId: PropTypes.string,
  flowType: PropTypes.string
};

export default JourneyView;
