import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { FieldValues } from 'react-hook-form';

import LoadingModal from '../LoadingModal';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import EmployeeService from './EmployeeService';

import EmployeeForm from './EmployeeForm';
import ErrorMessage from '../ErrorMessage';
import Message from '../../services/Message';
import Breadcrumb from '../common/Breadcrumb';

function EmployeeEdit({ history }: InferProps<typeof EmployeeEdit.propTypes>): JSX.Element {
  const { id } = useParams() as { id: string };
  const { t } = useTranslation();

  const employeeService = new EmployeeService();
  const useUpdateEmployee = employeeService.useUpdate();

  console.log('Employee id = ', id);

  const { data, isFetching, isError, error } = employeeService.useGet(id);

  const onSubmit = async (values: FieldValues): Promise<void> => {
    try {
      const employeeUpdated = await useUpdateEmployee.mutateAsync({ id, ...values });
      Message.success(t('employee.updated'));
      history.push(`/employees/${employeeUpdated.id}`);
    } catch (error) {
      Message.error(t('employee.updated-error'));
      console.error('Error on save: ', error);
    }
  };

  const onCancel = async (): Promise<void> => {
    history.goBack();
  };

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching) {
    return <LoadingModal open={true} />;
  }

  return (
    <Grid>
      <GridCell span={12}>
        <Breadcrumb routeSegments={[{ name: t('navlink.employees'), path: '/employees' }, { name: t('employee.edit-title') }]} />
      </GridCell>
      <GridCell span={12}>
        <h1>
          <Trans i18nKey="employee.edit-title">Update employee</Trans>
        </h1>
      </GridCell>
      <GridCell span={12}>
        <EmployeeForm onFormSubmit={onSubmit} onCancel={onCancel} isLoading={useUpdateEmployee.isLoading} defaultValues={data}></EmployeeForm>
      </GridCell>
    </Grid>
  );
}

EmployeeEdit.propTypes = {
  history: PropTypes.any
};

export default EmployeeEdit;
