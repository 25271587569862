import React from 'react';
import { withRouter } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import CelebrationsDiversityTable from './CelebrationsDiversityTable';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { TextField } from '@rmwc/textfield';
import '@rmwc/textfield/styles';

import { IconButton } from '@rmwc/icon-button';
import '@rmwc/icon-button/styles';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import Breadcrumb from '../../common/Breadcrumb';
import EllaMessage from '../../common/EllaMessage';

import { dateExtractMonth } from '../../common/DatesFormat/dateExtractMonth';
import { dateAddMonths } from '../../common/DatesFormat/dateAddMonths';
import { dateFormatMonthInput } from '../../common/DatesFormat/dateFormatMonthInput';

export interface DiversitySpecialDate {
  title: string;
  date?: string;
  description?: string;
  link?: string;
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const allDatesData = require('./diversityCalendarDates.json');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const allMonthsData = require('./diversityCalendarMonths.json');

function CelebrationsDiversityHome(): JSX.Element {
  const { t } = useTranslation();

  const [currentDate, setCurrentDate] = React.useState(new Date());
  const [selectedMonth, setSelectedMonth] = React.useState(convertDateToMonthName(dateExtractMonth(currentDate)));

  const specialDatesByDay = allDatesData[selectedMonth];
  const specialDatesByMonth = allMonthsData[selectedMonth];
  const allSpecialDates: DiversitySpecialDate[] = specialDatesByMonth.length > 0 ? specialDatesByMonth.concat(specialDatesByDay) : specialDatesByDay;

  function handleOnDataInput(event: React.SyntheticEvent<HTMLInputElement>): void {
    const dateValue = event.currentTarget.value;
    const parsedDate = new Date(dateValue);
    setCurrentDate(parsedDate);
    setSelectedMonth(convertDateToMonthName(dateExtractMonth(parsedDate)));
  }

  function handleSetNextMonth() {
    const nextMonth = dateAddMonths(currentDate, 1);
    setCurrentDate(nextMonth);
    setSelectedMonth(convertDateToMonthName(dateExtractMonth(nextMonth)));
  }

  function handleSetPreviousMonth() {
    const nextMonth = dateAddMonths(currentDate, -1);
    setCurrentDate(nextMonth);
    setSelectedMonth(convertDateToMonthName(dateExtractMonth(nextMonth)));
  }

  function handlePrintPage() {
    window.print();
  }

  return (
    <Grid>
      <GridCell span={12}>
        <Breadcrumb routeSegments={[{ name: t('navlink.celebrations') }, { name: t('navlink.celebration.diversity') }]} />
      </GridCell>
      <GridCell span={12}>
        <h2>
          <Trans i18nKey="celebrations.diversity.title">Diversity and Inclusion Calendar</Trans>
        </h2>
      </GridCell>

      <GridCell span={6} align="middle">
        <IconButton label={t('celebrations.date.previous', 'Previous month')} icon="arrow_back_ios" onClick={handleSetPreviousMonth} />
        <TextField label={t('celebrations.date.month', 'Month')} type="month" value={dateFormatMonthInput(currentDate)} onChange={handleOnDataInput} />
        <IconButton label={t('celebrations.date.next', 'Next month')} icon="arrow_forward_ios" onClick={handleSetNextMonth} />
      </GridCell>
      <GridCell className="no-print" span={6} align="middle" style={{ textAlign: 'right', marginRight: '15px' }}>
        <Button icon="picture_as_pdf" onClick={handlePrintPage}>
          <Trans i18nKey="celebrations.export.print">Print</Trans>
        </Button>
      </GridCell>

      {allSpecialDates.length > 0 ? (
        <GridCell span={12}>
          <CelebrationsDiversityTable dates={allSpecialDates} month={selectedMonth}></CelebrationsDiversityTable>
        </GridCell>
      ) : (
        <CelebrationsDiversityNoData></CelebrationsDiversityNoData>
      )}
    </Grid>
  );
}

function convertDateToMonthName(month: string): string {
  switch (month) {
    case '01':
      return 'january';

    case '02':
      return 'february';

    case '03':
      return 'march';

    case '04':
      return 'april';

    case '05':
      return 'may';

    case '06':
      return 'june';

    case '07':
      return 'july';

    case '08':
      return 'august';

    case '09':
      return 'september';

    case '10':
      return 'october';

    case '11':
      return 'november';

    case '12':
      return 'december';
  }

  return '';
}

function CelebrationsDiversityNoData(): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <GridCell span={12} align="middle">
        <EllaMessage title={t('celebrations.diversity.nodata.title')} message={t('celebrations.diversity.nodata.text')} />
      </GridCell>
    </>
  );
}

export default withRouter(CelebrationsDiversityHome);
