import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import { Trans, useTranslation } from 'react-i18next';

import SimpleTable from '../Table/SimpleTable';
import { Cell } from 'react-table';

import { Journey, JourneyFlowType } from './JourneyRepository';

function JourneyNameCell({ cell }: { cell: Cell }): JSX.Element {
  return <JourneyNameCellComponent journey={cell.row.original as Journey}></JourneyNameCellComponent>;
}

function JourneyNameCellComponent({ journey }: { journey: Journey }): JSX.Element {
  return <div>{journey.name}</div>;
}

function JourneyTypeCell({ cell }: { cell: Cell }): JSX.Element {
  return <JourneyTypeCellComponent journey={cell.row.original as Journey}></JourneyTypeCellComponent>;
}

function JourneyTypeCellComponent({ journey }: { journey: Journey }): JSX.Element {
  switch (journey.flow_type) {
    case JourneyFlowType.Offboarding:
      return <Trans i18nKey="journey.form.flow_type.offboarding">Offboarding</Trans>;

    case JourneyFlowType.Communication:
      return <Trans i18nKey="journey.form.flow_type.communication">Communication</Trans>;
  }
  return <Trans i18nKey="journey.form.flow_type.onboarding">Onboarding</Trans>;
}

function JourneyTable({ journeys, onRowClick, onDelete, onEdit }: InferProps<typeof JourneyTable.propTypes>): JSX.Element {
  const { t } = useTranslation();
  const columns = React.useMemo(
    () => [
      {
        Header: t('journey.journey'),
        id: 'journeyName',
        Cell: JourneyNameCell
      },
      {
        Header: t('journey.description'),
        accessor: 'description'
      },
      {
        Header: t('journey.type'),
        id: 'journeyType',
        Cell: JourneyTypeCell
      },
      {
        Header: t('journey.created_date'),
        accessor: 'createdAt',
        Cell: ({ value }: { value: string }): string => (value ? new Date(value).toLocaleDateString() : '')
      }
    ],
    [t]
  );
  return (
    <>
      <SimpleTable columns={columns} data={journeys} onRowClick={onRowClick} onDelete={onDelete} onEdit={onEdit}></SimpleTable>
    </>
  );
}

JourneyTable.propTypes = {
  journeys: PropTypes.array,
  onRowClick: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func
};

export default JourneyTable;
