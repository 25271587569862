import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Trans } from 'react-i18next';

import { Link } from 'react-router-dom';
import { GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

function ErrorReturnHomeCell({ children }: InferProps<typeof ErrorReturnHomeCell.propTypes>): JSX.Element {
  return (
    <GridCell span={12} align="middle" style={{ textAlign: 'center' }}>
      {children}
      <Button raised tag={Link} {...{ to: '/' }}>
        <Trans i18nKey="navlink.home">Go to homepage</Trans>
      </Button>
    </GridCell>
  );
}

ErrorReturnHomeCell.propTypes = {
  children: PropTypes.element
};

export default ErrorReturnHomeCell;
