/* eslint-disable @typescript-eslint/no-explicit-any */
const awarenessContent = {
  deaf: {
    title: 'Deaf or Hard of Hearing',
    overview: `This section introduces D/deaf and Hard of Hearing. 
    We will help you how to create an inclusive workplace for them and your team. 
    Hearing loss is measured in decibels hearing level (dBHL). 
    A person who can hear sounds across a range of frequencies at 0 to 28dB is considered to have normal hearing. 
    <br/>The thresholds for the different types of hearing are as follows:`,
    common: `Deaf or Hard of Hearing people is common  and it is 
    estimated that in <strong>Ireland 17% of the population</strong> have 
    some sort of hearing loss. Each year in <strong>Ireland</strong>, around 
    <strong>100 babies are born</strong> with a significant hearing loss.`,
    levels: [
      {
        description: 'Can sometimes make following speech difficult, particularly in noisy situations.',
        title: 'Mild',
        subtitle: '25-39 dB'
      },
      {
        description: 'May have difficulty following speech without a hearing aids.',
        title: 'Moderate',
        subtitle: '41-69 dB'
      },
      {
        description: 'Usually need to lip-read or use sign language, even with hearing aids.',
        title: 'Severe',
        subtitle: '70-94 dB'
      },
      {
        description: 'Usually need to lip-read or use sign language.',
        title: 'Profund',
        subtitle: '95 or greater dB'
      }
    ],
    content: {
      overview: [
        {
          category: 'Overview',
          title: 'Is there one universal Sign Language?',
          source: 'DESIGNS Project',
          soruceURL: 'http://www.designsproject.eu',
          shortDescription: 'Short description here with a orverview of the content...',
          contents: ['line1', 'line2']
        },
        {
          category: 'Overview',
          title: 'Why should I consider hiring a Deaf individual?',
          source: 'DESIGNS Project',
          soruceURL: 'http://www.designsproject.eu',
          shortDescription: '',
          contents: ['line1', 'line2']
        },
        {
          category: 'Overview',
          title: 'How do I book an Interpreter?',
          source: 'DESIGNS Project',
          soruceURL: 'http://www.designsproject.eu',
          shortDescription: '',
          contents: ['line1', 'line2']
        },
        {
          category: 'Overview',
          title: 'Are Deaf or hard of hearing people disabled?',
          source: 'DESIGNS Project',
          soruceURL: 'http://www.designsproject.eu',
          shortDescription: '',
          contents: ['line1', 'line2']
        }
      ],
      abilitie: [
        {
          category: 'Super Abilities',
          title: 'Are Deaf or hard of hearing people disabled?',
          source: 'DESIGNS Project',
          soruceURL: 'http://www.designsproject.eu',
          shortDescription: '',
          contents: ['line1', 'line2']
        }
      ],
      compliance: [
        {
          category: 'Compliance',
          title: 'Why should I consider hiring a Deaf individual?',
          source: 'DESIGNS Project',
          soruceURL: 'http://www.designsproject.eu',
          shortDescription: '',
          contents: ['line1', 'line2']
        }
      ],
      workplace: [
        {
          category: 'Workplace',
          title: 'How do I book an Interpreter?',
          source: 'DESIGNS Project',
          soruceURL: 'http://www.designsproject.eu',
          shortDescription: '',
          contents: ['line1', 'line2']
        }
      ]
    }
  }
} as any;

function AwarenessService(): any {
  function getSafeValue(slug: string, content: string): any {
    return awarenessContent[slug] && awarenessContent[slug][content];
  }

  function getTitle(slug: string): string {
    return getSafeValue(slug, 'title');
  }

  function getOverview(slug: string): string {
    return getSafeValue(slug, 'overview');
  }

  function getHowCommon(slug: string): string {
    return getSafeValue(slug, 'common');
  }

  function getLevelsList(slug: string): string[] {
    return getSafeValue(slug, 'levels');
  }

  function getContentCards(slug: string, tabCategory: string): any {
    const cardContents = getSafeValue(slug, 'content');
    return cardContents && cardContents[tabCategory];
  }

  return {
    getTitle: getTitle,
    getOverview: getOverview,
    getHowCommon: getHowCommon,
    getLevelsList: getLevelsList,
    getContentCards: getContentCards
  };
}

export default AwarenessService();
