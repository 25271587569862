import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify } from 'aws-amplify';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import * as Sentry from '@sentry/react';
import { Integration } from '@sentry/types';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';

import './index.css';

import * as serviceWorker from './serviceWorker';
import config from './config';
import App from './App';

// console.log('config = ', config);

// https://github.com/aws-amplify/amplify-js/blob/df95ea3724eb6406f64b03f25086cd3e8644cb5f/vscode/snippets/auth.code-snippets
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    oauth: {
      domain: config.cognito.IDENTITY_POOL_DOMAIN,
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: config.cognito.IDENTITY_POOL_REDIRECT_SIGN_IN,
      redirectSignOut: config.cognito.IDENTITY_POOL_REDIRECT_SIGN_OUT,
      responseType: 'code'
    }
  }
  // Storage: {
  //   region: config.s3.REGION,
  //   bucket: config.s3.BUCKET,
  //   identityPoolId: config.cognito.IDENTITY_POOL_ID
  // }
});

LogRocket.init('ixgsd2/inboarding-web-app');
setupLogRocketReact(LogRocket);

Sentry.init({
  dsn: 'https://9a9aa80a1850447fa85de3f56a94fb9d@o1139190.ingest.sentry.io/6194564',
  integrations: [new BrowserTracing(), new CaptureConsole({ levels: ['error'] }) as Integration],
  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
  beforeSend: function (event: Sentry.Event) {
    const logRocketSession = LogRocket.sessionURL;
    if (logRocketSession) {
      if (!event.extra) {
        event.extra = {};
      }
      event.extra['LogRocket'] = logRocketSession;
      return event;
    } else {
      return event;
    }
  }
});

LogRocket.getSessionURL((sessionURL: string) => {
  Sentry.configureScope((scope) => {
    scope.setExtra('sessionURL', sessionURL);
  });
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
