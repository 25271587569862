import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { FieldValues } from 'react-hook-form';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import JourneyService from './JourneyService';

import JourneyForm from './JourneyForm';
import Message from '../../services/Message';
import Breadcrumb from '../common/Breadcrumb';
import EllaMessage from '../common/EllaMessage/EllaMessage';

function JourneyNew({ history }: InferProps<typeof JourneyNew.propTypes>): JSX.Element {
  const { t } = useTranslation();
  const journeyService = new JourneyService();
  const useCreateJourney = journeyService.useCreate();

  const onSubmit = async (values: FieldValues): Promise<void> => {
    try {
      const journeyCreated = await useCreateJourney.mutateAsync(values);
      Message.success(t('journey.created'));
      history.push(`/journeys/${journeyCreated.id}`);
    } catch (error) {
      Message.error(t('journey.created-error'));
      console.error('Error on save: ', error);
    }
  };

  const onCancel = async (): Promise<void> => {
    history.goBack();
  };

  return (
    <Grid>
      <GridCell span={12}>
        <Breadcrumb routeSegments={[{ name: t('navlink.journeys'), path: '/journeys' }, { name: t('journey.new-title') }]} />
      </GridCell>
      <GridCell span={12}>
        <h1>
          <Trans i18nKey="journey.new-title">New journey</Trans>
        </h1>
      </GridCell>
      <GridCell span={12}>
        <EllaMessage title={t('journey.ella-start-title', 'Vamos lá!')} message={t('journey.ella-start-message', 'Crie a jornada do colaborador')} />
      </GridCell>

      <GridCell span={3}></GridCell>
      <GridCell span={6}>
        <JourneyForm onFormSubmit={onSubmit} onCancel={onCancel} isLoading={useCreateJourney.isLoading}></JourneyForm>
      </GridCell>
      <GridCell span={3}></GridCell>
    </Grid>
  );
}

JourneyNew.propTypes = {
  history: PropTypes.any
};

export default JourneyNew;
