import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from 'rmwc';
import { fullWithStyle } from '../../StepJourneyForm';
import { RequiredProps } from './TextQuestion';

export function CustomizableOption({
  control,
  defaultValue,
  controllerName,
  requiredProps,
  placeholderTag,
  invalid,
  isLoading,
  label,
  style
}: {
  control: Control;
  controllerName: string;
  requiredProps: RequiredProps;
  invalid: string;
  isLoading: boolean;
  defaultValue?: string;
  placeholderTag?: string;
  label?: string;
  style?: React.CSSProperties;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={controllerName}
        rules={requiredProps ? { required: t(requiredProps.i18nKey, requiredProps.requiredText) as string } : {}}
        render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
          <TextField
            type="text"
            id={name}
            name={name}
            label={label ?? ''}
            placeholder={placeholderTag ? t(placeholderTag) : ''}
            onBlur={onBlur}
            onChange={onChange}
            value={value || ''}
            inputRef={ref}
            invalid={invalid ? true : false}
            aria-invalid={invalid ? true : false}
            disabled={!!isLoading}
            style={style ?? fullWithStyle}
          />
        )}
      />
      {invalid && <div role="alert">{invalid}</div>}
    </>
  );
}
