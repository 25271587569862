import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import { Employee, EmployeeFormAnswersLegacy, EmployeeFormAnswerValues, EmployeeFormAsnwer, EmployeeFormAsnwerStatus } from './EmployeeRepository';

import { Grid, GridRow, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { List, CollapsibleList, SimpleListItem } from '@rmwc/list';
import '@rmwc/list/styles';

import { Chip } from '@rmwc/chip';
import '@rmwc/chip/styles';

import { DataTable, DataTableContent, DataTableBody, DataTableRow, DataTableCell } from '@rmwc/data-table';
import '@rmwc/data-table/styles';
import { dateTimeFormat } from '../common/DatesFormat/dateTimeFormat';

const questionColumnStyle = {
  textOverflow: 'unset',
  wordWrap: 'break-word',
  overflowWrap: 'break-word',
  whiteSpace: 'normal'
} as React.CSSProperties;

function EmployeeViewForms({ employee }: { employee: Employee }): JSX.Element {
  return (
    <Grid>
      <GridCell span={12}>
        <EmployeeFormsAnswers employee={employee}></EmployeeFormsAnswers>
      </GridCell>
    </Grid>
  );
}

function EmployeeFormsAnswers({ employee }: { employee: Employee }): JSX.Element {
  if (!employee || !employee.form_answers || !employee.form_answers.length) {
    return <EmployeeFormListEmpty />;
  }

  return (
    <>
      <GridRow>
        <GridCell span={12}>
          <List>
            {employee.form_answers.map((formAnswers) => (
              <EmployeeFormList key={formAnswers.id} formAnswers={formAnswers} />
            ))}
          </List>
        </GridCell>
      </GridRow>
    </>
  );
}

function formAnswersStatusTrans(formAnswersStatus: EmployeeFormAsnwerStatus): string {
  const { t } = useTranslation();
  if (formAnswersStatus === EmployeeFormAsnwerStatus.Submitted) {
    return t('employee.forms.answers.status.submitted', 'Submitted');
  }
  return t('employee.forms.answers.status.draft', 'Draft');
}

function EmployeeFormList({ formAnswers }: { formAnswers: EmployeeFormAsnwer }): JSX.Element {
  const parsedAnswers = parseAnswersFromObjectOrArray(formAnswers.answers);

  function buildFormTitle(formAnswers: EmployeeFormAsnwer) {
    if (formAnswers.status) {
      const statusTrans = formAnswersStatusTrans(formAnswers.status);
      const submittedDate = dateTimeFormat(formAnswers.submitted);
      return (
        <div>
          {formAnswers.name} - {submittedDate}
          <Chip style={{ marginLeft: '10px' }}>{statusTrans}</Chip>
        </div>
      );
    }

    return `${formAnswers.name} - ${formAnswers.submitted}`;
  }

  const formAnswerTitle = buildFormTitle(formAnswers);

  return (
    <CollapsibleList
      defaultOpen
      handle={<SimpleListItem text={formAnswerTitle} metaIcon="chevron_right" />}
      style={{ borderWidth: '1px', borderStyle: 'solid', borderColor: 'rgba(0, 0, 0, 0.12)', borderRadius: '5px' }}
    >
      <DataTable style={{ width: '100%', borderTopLeftRadius: '0', borderTopRightRadius: '0' }}>
        <DataTableContent>
          <DataTableBody>
            {parsedAnswers.map(({ label, value }) => (
              <DataTableRow key={label}>
                <DataTableCell style={questionColumnStyle}>
                  <p>{label}</p>
                  <p style={{ fontSize: '16px' }}>
                    <strong>{parseTranslateAnswer(value)}</strong>
                  </p>
                </DataTableCell>
              </DataTableRow>
            ))}
          </DataTableBody>
        </DataTableContent>
      </DataTable>
    </CollapsibleList>
  );
}

function parseTranslateAnswer(answer: string | string[] | undefined): string {
  if (!answer) {
    return '-';
  }

  if (Array.isArray(answer)) {
    return answer.join(', ');
  }

  switch (answer) {
    case 'yes':
      return 'Sim';
    case 'no':
      return 'Não';
    case 'uploaded':
      return 'Enviada';
  }

  return answer;
}

function parseAnswersFromObjectOrArray(answers: string): EmployeeFormAnswerValues[] {
  const parsedAnswers: EmployeeFormAnswerValues[] | EmployeeFormAnswersLegacy = JSON.parse(answers); // Object || Array of Objects
  if (Array.isArray(parsedAnswers)) {
    return parsedAnswers;
  }

  return Object.entries(parsedAnswers).map(
    ([question, answer]) =>
      ({
        label: question,
        value: answer
      } as EmployeeFormAnswerValues)
  ) as EmployeeFormAnswerValues[];
}

function EmployeeFormListEmpty(): JSX.Element {
  return (
    <p>
      <Trans i18nKey="employee.forms.no-data">No answers saved for this employee.</Trans>
    </p>
  );
}

EmployeeFormsAnswers.propTypes = {
  employee: PropTypes.object
};

export default EmployeeViewForms;
