/* eslint-disable @typescript-eslint/no-explicit-any */
import { StringHandler } from 'remirror';
import HttpRepository from '../../services/HttpRepository/HttpRepository';
import { LogsIntegration } from '../Settings/LogsSettings/LogsIntegrationInboundRepository';
import { SearchFilter } from '../../services/HttpRepository/SearchFilter';
import { MessageBlocksTypes } from '../Journey/StepJourneyForm';

export interface EmployeeFormAnswersLegacy {
  [key: string]: string; // question: answer value
}

export interface EmployeeFormAnswerValues {
  id: string;
  label: string;
  description?: string;
  value?: string | string[];
  blockFieldType: string;
}

export enum EmployeeFormAsnwerStatus {
  Draft = 'DRAFT',
  Submitted = 'SUBMITTED'
}

export interface EmployeeFormAsnwer {
  id?: string;
  name: string;
  submitted: string;
  answers: string; // Objetct or Array of Objects
  status?: EmployeeFormAsnwerStatus;
}

export interface EmployeeSurveyAsnwer {
  id?: string;
  name: string;
  submitted: string;
  answers: string; // Objetct or Array of Objects
  status?: EmployeeFormAsnwerStatus;
}

export interface EmployeeCustomFields {
  label: string;
  subtitle?: string | undefined;
  value?: string | undefined;
}

export interface PeopleAddress {
  origin_id?: string;
  zip_code?: string;
  address?: string;
  number?: string;
  complement?: string;
  district?: string;
  state?: string;
  city?: string;
}

export enum PeopleIntegrationUserStatus {
  Active = 'ACTIVE',
  Disabled = 'DISABLED'
}

export enum PeopleOriginType {
  Manual = 'MANUAL',
  File = 'FILE',
  Convenia = 'CONVENIA',
  Gupy = 'GUPY',
  Senior = 'SENIOR',
  ADP = 'ADP',
  Dominio = 'DOMINIO',
  Sap = 'SAP',
  Totvs = 'TOTVS'
}

export interface PeopleIntegrationUser {
  type: string;
  origin_id: string;
  status: PeopleIntegrationUserStatus;
  data?: string;
}

export interface PeopleIntegrationUsers {
  [type: string]: PeopleIntegrationUser;
}

export enum DismissedReasonType {
  Company = 'COMPANY',
  Person = 'PERSON',
  Both = 'BOTH'
}

export enum SendStepStatus {
  Success = 'SUCCESS',
  Error = 'ERROR',
  Unchecked = 'UNCHECKED'
}

export interface Employee {
  id?: string;
  first_name: string;
  social_name?: string;
  last_name?: string;
  full_name?: string;
  short_name?: string;
  fake_name?: string;
  photo_url?: string;
  order_date?: string;
  start_date?: string;
  start_date_month?: string;
  last_date?: string;
  dismissed_reason?: string;
  dismissed_type?: DismissedReasonType;
  dismissed_category?: string;
  birth_date?: string;
  birth_date_month?: string;
  birth_date_celebrate?: string;
  personal_email?: string;
  work_email?: string;
  mobile_number?: string;
  phone_number?: string;
  role?: string;
  gender_pronouns?: string;
  gender?: string;
  ethnicity?: string;
  department?: string;
  job_title?: string;
  cost_center?: string;
  cost_category?: string;
  contract_type?: string;
  experience_period_days?: string;
  leader_id?: string;
  leader?: Employee;
  co_leader_id?: string;
  co_leader?: Employee;
  buddy_id?: string;
  buddy?: Employee;
  education?: string;
  timezone?: string;
  origin_type?: string;
  origin_id?: string;
  registration_id?: string;
  has_form_answers?: string;
  form_answers?: EmployeeFormAsnwer[];
  has_survey_answers?: string;
  survey_answers?: EmployeeSurveyAsnwer[];
  custom_fields?: EmployeeCustomFields[];
  notes_software?: string;
  notes_equipament?: string;
  notes_other?: string;
  address?: PeopleAddress;
  instagram_handle?: string;
  linkedin_url?: string;
  createdAt?: string;
  updatedAt?: string;
  integration_users?: PeopleIntegrationUsers;
  pwd_disability?: string | null;
  inclusive_notes?: string | null;
}

export interface StepEmailMessage {
  from: string;
  to: string[];
  cc?: string[];
  replyTo?: string[];
  subject?: string;
  html?: string;
}

export interface StepSlackMessage {
  text?: string;
  blocks?: unknown[];
  username?: string;
  thread_ts?: string;
}

export interface StepWhatsappMessage {
  to: { phoneNumber: string };
  message?: unknown;
}

interface EmployeeJourneyStepStatusHistory {
  status: StringHandler;
  timestamp: string;
  data: unknown;
}

export enum EmployeeJourneyStepStatus {
  Removed = 'REMOVED', // Manually marked as remove when start journey
  Scheduled = 'SCHEDULED',
  Sending = 'SENDING',
  Sent = 'SENT',
  Delivered = 'DELIVERED',
  Opened = 'OPENED',
  Clicked = 'CLICKED',
  Rejected = 'REJECTED', // Eg: inbox full or out of space
  Bounced = 'BOUNCED', // invalid email or blocked by the server
  Error = 'ERROR', // general error
  ManualRead = 'MANUAL_READ', // Manually confirmed as Read in the portal
  Confirmed = 'CONFIRMED', // Task completed (submmited form, survey, sequence)
  Disabled = 'DISABLED' // Disabled when person is disabled or email bounced
}

export enum EmployeeJourneyStepToPerson {
  NewHire = 'new_hire',
  NewHirePersonalEmail = 'new_hire_personal_email',
  NewHireWorkEmail = 'new_hire_work_email',
  Leader = 'leader',
  Buddy = 'buddy',
  FixedEmail = 'fixed_email'
}

export interface EmployeeJourneyStep {
  id: string;
  step_id: string;
  people_id: string;
  journey_id: string;
  step_name: string;
  step_channel: string;
  step_period: string;
  step_period_days: string;
  step_period_date: string;
  step_period_time: string;
  createdAt: string;
  order_date: string;
  schedule_date: string;
  schedule_date_original: string;
  sent_date: string;
  status: EmployeeJourneyStepStatus;
  status_history: EmployeeJourneyStepStatusHistory[];
  message_ses_id: string;
  message_sent: StepEmailMessage | StepSlackMessage | StepWhatsappMessage;
  to_whom: EmployeeJourneyStepToPerson;
  to_whom_copy: string;
  message_block_type: MessageBlocksTypes | undefined;
  message_html: StepMessageHtml;
}

export interface StepMessageHtml {
  text: string;
  actions: string;
}

class EmployeeRepository extends HttpRepository<Employee> {
  constructor() {
    super({ basePath: '/people' });
  }

  public async listSteps(id: string): Promise<EmployeeJourneyStep[]> {
    const { data } = await super.getPath(`${id}/steps`);
    return data;
  }

  public async listLogs(id: string): Promise<LogsIntegration[]> {
    const { data } = await super.getPath(`${id}/logs`);
    return data;
  }

  public async sendStep(id: string, stepId: string): Promise<any> {
    const { data } = await super.postPath(`${id}/steps/${stepId}/send`, {});
    return data;
  }

  public async reSendStep(id: string, stepId: string): Promise<any> {
    const { data } = await super.postPath(`${id}/steps/${stepId}/resend`, {});
    return data;
  }

  public async listStartJourney(id: string, journeyId: string): Promise<EmployeeJourneyStep[]> {
    const { data } = await super.getPath(`${id}/start/${journeyId}`);
    return data;
  }

  public async createStartJourney(id: string, journeyId: string, employeeJourneySteps: EmployeeJourneyStep[]): Promise<EmployeeJourneyStep[]> {
    const { data } = await super.postPath(`${id}/start/${journeyId}`, {
      data: employeeJourneySteps
    });
    return data;
  }

  public async listOffboardingSearch(searchFilter: SearchFilter): Promise<Employee[]> {
    return super.listSearchPath('turnover', searchFilter);
  }
}

export default EmployeeRepository;
