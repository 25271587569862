/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Auth } from 'aws-amplify';

import styles from './Login.module.css';
import 'slick-carousel/slick/slick.css';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';

import store from '../../services/StoreService';
import { withRouter } from 'react-router-dom';

import LogoImage from '../Brand/LogoImage';
import EllaImage from '../Brand/EllaImage';
import Google from './google.svg';
import Microsoft from './microsoft.svg';
import Microlearning from './microlearning.svg';
import Onboarding from './onboarding.svg';

import { Elevation } from '@rmwc/elevation';
import '@material/elevation/dist/mdc.elevation.css';

import { Typography } from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/circular-progress.css';

import { TextField } from '@rmwc/textfield';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';

import { Snackbar, SnackbarAction } from '@rmwc/snackbar';
import '@material/snackbar/dist/mdc.snackbar.css';

function Login({ history }: InferProps<typeof Login.propTypes>): JSX.Element {
  const [processing, setProcessing] = React.useState(false);
  const [enableLogin, setEnableLogin] = React.useState(false);
  const [enableLoginMicrosoftEmail, setEnableLoginMicrosoftEmail] = React.useState(false);
  const [newPassoword, setNewPassoword] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [passwordType, setPasswordType] = React.useState('password');
  const [loginError, setLoginError] = React.useState('');
  const [loginAuthResult, setLoginAuthResult] = React.useState(undefined);

  const loginSliderIndex = 5;
  let sliderRef: Slider | null;

  try {
    Auth.currentAuthenticatedUser()
      .then((authUser) => {
        console.log('currentAuthenticatedUser = ', authUser);
      })
      .catch(() => {
        // ignore error for now
      });
  } catch (error) {
    // ignore error for now
  }

  async function tryLoginWithEmailAndPassword() {
    const authResult = await Auth.signIn(email, password);
    if (authResult?.challengeName === 'NEW_PASSWORD_REQUIRED') {
      setLoginAuthResult(authResult);
      setNewPassoword(true);
      setProcessing(false);
    } else {
      store.set('login', true);
      setProcessing(false);
      if (history) {
        history.push('/start');
      }
    }
  }

  async function handleOnSubmitLogin(e: any): Promise<void> {
    e.persist();
    e.preventDefault();
    setProcessing(true);
    setLoginError('');
    try {
      await tryLoginWithEmailAndPassword();
    } catch (error) {
      const { message } = error as Error;
      if (message) {
        setLoginError(message);
      } else {
        setLoginError('Please try again in a few seconds!');
      }
      setProcessing(false);
    }
  }

  async function handleOnSubmitPassword(e: any): Promise<void> {
    e.persist();
    e.preventDefault();
    setProcessing(true);
    setLoginError('');
    try {
      const result = await Auth.completeNewPassword(loginAuthResult, newPassword);
      if (result) {
        await tryLoginWithEmailAndPassword();
      } else {
        setLoginError('Ocorreu algum problema ao alterar sua senha.');
        setProcessing(false);
      }
    } catch (error) {
      const { message } = error as Error;
      if (message) {
        setLoginError(message);
      } else {
        setLoginError('Tente novamente em alguns segundos!');
      }
      setProcessing(false);
    }
  }

  function handleEmailChange(event: any): void {
    setEmail(event.target.value);
  }

  function handlePasswordChange(event: any): void {
    setPassword(event.target.value);
  }

  function handleNewPasswordChange(event: any): void {
    setNewPassword(event.target.value);
  }

  function handleShowPassword(event: Event): void {
    event.stopPropagation();
    setPasswordType(passwordType === 'password' ? 'text' : 'password');
  }

  async function handleGoogleLogin(): Promise<any> {
    return await Auth.federatedSignIn({ provider: 'Google' } as any);
  }

  async function handleMicrosoftLogin(): Promise<any> {
    // return await Auth.federatedSignIn({ provider: 'Microsoft-Azure-AD' } as any);
    setEnableLoginMicrosoftEmail(true);
  }

  function handleSkipButton(): void {
    sliderRef && sliderRef.slickGoTo(loginSliderIndex);
    setEnableLogin(true);
  }

  function handleNextButton(): void {
    sliderRef && sliderRef.slickNext();
  }

  function handleSliderChange(index: number): void {
    if (index >= 4) {
      setEnableLogin(true);
    }
  }

  return (
    <div className={styles.login}>
      <Helmet>
        <title>Login | Inboarding</title>
      </Helmet>
      <Elevation z={5} className={styles.loginContainer}>
        <Slider
          dots={true}
          accessibility={true}
          arrows={false}
          className={styles.loginSlider}
          dotsClass={'slick-dots ' + styles.loginSliderDots}
          afterChange={handleSliderChange}
          ref={(slider): Slider | null => (sliderRef = slider)}
          infinite={false}
        >
          <SliderOne />
          <SliderTwo />
          <SliderThree />
          <SliderFour />
          <SliderLogin />
        </Slider>

        <LoginErrorMessage message={loginError} />

        <div style={{ minHeight: '72px' }}>
          {enableLogin ? (
            <>
              {!enableLoginMicrosoftEmail && <LoginButton handleGoogleLogin={handleGoogleLogin} handleMicrosoftLogin={handleMicrosoftLogin} />}
              {/* Login with email for Microsoft */}
              {enableLoginMicrosoftEmail && (
                <>
                  {!newPassoword && (
                    <>
                      <form onSubmit={handleOnSubmitLogin} className={styles.loginForm}>
                        <TextField type="email" outlined label="Email" value={email} onChange={handleEmailChange} required={true} autoComplete="email" />
                        <TextField
                          outlined
                          label="Senha"
                          type={passwordType}
                          value={password}
                          onChange={handlePasswordChange}
                          required={true}
                          trailingIcon={{
                            icon: 'visibility',
                            tabIndex: 0,
                            onClick: handleShowPassword,
                            className: styles.passwordIcon
                          }}
                        />
                        <Button type="submit" raised {...(processing ? { icon: <CircularProgress theme="secondary" /> } : {})}>
                          Entrar
                        </Button>
                      </form>
                      <Button onClick={() => setEnableLoginMicrosoftEmail(false)}>Voltar</Button>
                    </>
                  )}
                  {/* Set password first time */}
                  {!!newPassoword && (
                    <>
                      <p>Altere sua senha para o primeiro acesso</p>
                      <form onSubmit={handleOnSubmitPassword} className={styles.loginForm}>
                        <TextField
                          outlined
                          label="Senha"
                          type={passwordType}
                          value={password}
                          onChange={handlePasswordChange}
                          required={true}
                          trailingIcon={{
                            icon: 'visibility',
                            tabIndex: 0,
                            onClick: handleShowPassword,
                            className: styles.passwordIcon
                          }}
                        />
                        <TextField
                          outlined
                          label="Nova Senha"
                          type={passwordType}
                          value={newPassword}
                          onChange={handleNewPasswordChange}
                          required={true}
                          trailingIcon={{
                            icon: 'visibility',
                            tabIndex: 0,
                            onClick: handleShowPassword,
                            className: styles.passwordIcon
                          }}
                        />
                        <Button type="submit" raised {...(processing ? { icon: <CircularProgress theme="secondary" /> } : {})}>
                          Alterar senha
                        </Button>
                      </form>
                      <Button onClick={() => setNewPassoword(false)}>Voltar</Button>
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <div>
                <NextButton handleNextButton={handleNextButton} />
              </div>
              <div>
                <SkipButton handleSkipButton={handleSkipButton} />
              </div>
            </>
          )}
        </div>
      </Elevation>
    </div>
  );
}

function SkipButton({ handleSkipButton }: InferProps<typeof SkipButton.propTypes>): JSX.Element {
  const { t } = useTranslation();
  return (
    <Button onClick={handleSkipButton} aria-label={t('login.skip-label', 'Skip tutorial')}>
      <Trans i18nKey="login.skip">Skip</Trans>
    </Button>
  );
}

function NextButton({ handleNextButton }: InferProps<typeof NextButton.propTypes>): JSX.Element {
  return (
    <Button onClick={handleNextButton} unelevated>
      <Trans i18nKey="login.next">Continue tutorial</Trans>
    </Button>
  );
}

function LoginButton({ handleGoogleLogin, handleMicrosoftLogin }: InferProps<typeof LoginButton.propTypes>): JSX.Element {
  return (
    <>
      <Button outlined icon={<GoogleIcon />} onClick={handleGoogleLogin} style={{ minWidth: '230px' }}>
        <Trans i18nKey="login.enterWithGoogle">Enter with Google</Trans>
      </Button>

      <Button outlined icon={<MicrosoftIcon />} onClick={handleMicrosoftLogin} style={{ minWidth: '230px', marginTop: '5px' }}>
        <Trans i18nKey="login.enterWithMicrosoft">Enter with Microsoft</Trans>
      </Button>
    </>
  );
}

function GoogleIcon(): JSX.Element {
  return <img src={Google} alt="Google" className={styles.googleImage} />;
}

function MicrosoftIcon(): JSX.Element {
  return <img src={Microsoft} alt="Microsoft" className={styles.googleImage} />;
}

function LoginErrorMessage({ message }: InferProps<typeof LoginErrorMessage.propTypes>): JSX.Element | null {
  return message ? <Snackbar open={true} message={message} action={<SnackbarAction label="Dismiss" />} timeout={10000} /> : null;
}

function SliderOne(): JSX.Element {
  return (
    <div>
      <div className={styles.loginLogo}>
        <div style={{ paddingTop: '20px' }}>
          <LogoImage />
        </div>
      </div>
      <Typography use="headline6" tag="h1">
        <Trans i18nKey="login.welcome">
          Your company, more human, <br />
          happy and inclusive in minutes.
        </Trans>
      </Typography>
      <Typography use="body2" tag="p">
        <Trans i18nKey="login.welcomeMessage">Creating a complete and happy experience can be simple. Discover everything we can do together and get started right now!</Trans>
      </Typography>
    </div>
  );
}

function SliderTwo(): JSX.Element {
  return (
    <div>
      <div className={styles.loginLogo}>
        <EllaImage avatarSize="90px" />
      </div>
      <Typography use="headline6" tag="h1">
        <Trans i18nKey="login.slider2Title">Meet Ella! A virtual assistant to help you 24 hours.</Trans>
      </Typography>
      <Typography use="body2" tag="p">
        <Trans i18nKey="login.slider2Text">
          Ella was created to facilitate your entire journey at Inboarding. Whether sending messages, surveys, reports and more. With Ella everything is simpler.
        </Trans>
      </Typography>
    </div>
  );
}

function SliderThree(): JSX.Element {
  return (
    <div>
      <div className={styles.loginIllustration}>
        <img src={Onboarding} alt="Inclusive Onboarding" aria-hidden={true} />
      </div>
      <Typography use="headline6" tag="h1">
        <Trans i18nKey="login.slider3Title">Personalizing your talent experience just got easier!</Trans>
      </Typography>
      <Typography use="body2" tag="p">
        <Trans i18nKey="login.slider3Text">
          The need of those who start in your company today is different from those who have been in it for 3 months. Now imagine being able to customize all these steps in
          minutes. That&aposs what you&aposll find here!
        </Trans>
      </Typography>
    </div>
  );
}

function SliderFour(): JSX.Element {
  return (
    <div>
      <div className={styles.loginIllustration}>
        <img src={Microlearning} alt="Surveys and People Analytics" aria-hidden={true} />
      </div>
      <Typography use="headline6" tag="h1">
        <Trans i18nKey="login.slider4Title">No time? Relax, we create surveys and analytics for you!</Trans>
      </Typography>
      <Typography use="body2" tag="p">
        <Trans i18nKey="login.slider4Text">With our personalized surveys and reports, you discover what needs to be done to make your talents happier. All this in minutes!</Trans>
      </Typography>
    </div>
  );
}

function SliderLogin(): JSX.Element {
  return (
    <div>
      <div className={styles.loginLogo}>
        <EllaImage avatarSize="90px" />
      </div>
      <Typography use="headline6" tag="h1">
        <Trans i18nKey="login.slider6Title">The time to retain more talent has arrived. Log in and get started now!</Trans>
      </Typography>
      <Typography use="body2" tag="p">
        <Trans i18nKey="login.slider6Text">You&aposve already discovered everything we can do together. Now just log in and start and enjoy. Let&aposs go?</Trans>
      </Typography>
    </div>
  );
}

Login.propTypes = {
  history: PropTypes.any
};

LoginErrorMessage.propTypes = {
  message: PropTypes.string.isRequired
};

SkipButton.propTypes = {
  handleSkipButton: PropTypes.func.isRequired
};

NextButton.propTypes = {
  handleNextButton: PropTypes.func.isRequired
};

LoginButton.propTypes = {
  handleGoogleLogin: PropTypes.func.isRequired,
  handleMicrosoftLogin: PropTypes.func.isRequired
};

export default withRouter(Login);
