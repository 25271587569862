import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { FieldValues } from 'react-hook-form';

import ContentsQuestionsService from './ContentsQuestionsService';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import ContentsQuestionsForm from './ContentsQuestionsForm';
import Message from '../../../services/Message';
import Breadcrumb from '../../common/Breadcrumb';

function ContentsQuestionsNew({ history }: InferProps<typeof ContentsQuestionsNew.propTypes>): JSX.Element {
  const { t } = useTranslation();
  const questionService = new ContentsQuestionsService();
  const useCreateQuestion = questionService.useCreate();

  const onSubmit = async (values: FieldValues): Promise<void> => {
    try {
      await useCreateQuestion.mutateAsync(values);
      Message.success(t('contents.questions.created'));
      history.push('/contents/questions');
    } catch (error) {
      Message.error(t('contents.questions.created-error'));
      console.error('Error on save question: ', error);
    }
  };

  const onCancel = async (): Promise<void> => {
    history.goBack();
  };

  return (
    <Grid>
      <GridCell span={12}>
        <Breadcrumb
          routeSegments={[
            { name: t('navlink.contents'), path: '/contents/questions/' },
            { name: t('navlink.content.questions'), path: '/contents/questions/' },
            { name: t('contents.questions.new-title') }
          ]}
        />
      </GridCell>
      <GridCell span={12}>
        <h1>
          <Trans i18nKey="contents.questions.new-title">New Frequently Asked Question</Trans>
        </h1>
      </GridCell>
      <GridCell span={12}>
        <ContentsQuestionsForm onFormSubmit={onSubmit} onCancel={onCancel} isLoading={useCreateQuestion.isLoading}></ContentsQuestionsForm>
      </GridCell>
    </Grid>
  );
}

ContentsQuestionsNew.propTypes = {
  history: PropTypes.any
};

export default ContentsQuestionsNew;
