import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import EmployeeHistoryService from './EmployeeHistoryService';
import { SearchFilter } from '../../services/HttpRepository/SearchFilter';
import { Employee } from './EmployeeRepository';
import { EmployeeHistory } from './EmployeeHistoryRepository';

import { Grid, GridRow, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { List } from '@rmwc/list';
import '@rmwc/list/styles';

import { DataTable, DataTableContent, DataTableHead, DataTableHeadCell, DataTableBody, DataTableRow, DataTableCell } from '@rmwc/data-table';
import '@rmwc/data-table/styles';

import ErrorMessage from '../ErrorMessage';
import LoadingModal from '../LoadingModal';

const questionColumnStyle = {
  textOverflow: 'unset',
  wordWrap: 'break-word',
  overflowWrap: 'break-word',
  whiteSpace: 'normal'
} as React.CSSProperties;

const answerColumnStyle = {
  textOverflow: 'unset',
  wordWrap: 'break-word',
  overflowWrap: 'break-word',
  whiteSpace: 'normal'
} as React.CSSProperties;

const LIST_SIZE = 30;

function EmployeeViewHistory({ employee }: { employee: Employee }): JSX.Element {
  const [searchFilter] = React.useState<SearchFilter>({ limit: LIST_SIZE, query: '' });

  const employeeHistoryService = new EmployeeHistoryService(employee.id || '');
  const { data, isFetching, isError, error } = employeeHistoryService.useListSearch(searchFilter, LIST_SIZE);
  const historyData = data?.pages.flat();

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching) {
    return <LoadingModal open={true} />;
  }

  return (
    <Grid>
      <GridCell span={12}>
        <EmployeeHistoryLogs history={historyData}></EmployeeHistoryLogs>
      </GridCell>
    </Grid>
  );
}

function EmployeeHistoryLogs({ history }: { history?: EmployeeHistory[] }): JSX.Element {
  if (!history || !history.length) {
    return <EmployeeHistoryListEmpty />;
  }

  return (
    <>
      <GridRow>
        <GridCell span={12}>
          <List>
            <EmployeeHistoryList history={history} />
          </List>
        </GridCell>
      </GridRow>
    </>
  );
}

function EmployeeHistoryList({ history }: { history: EmployeeHistory[] }): JSX.Element {
  function dateTimeFormat(date: string | undefined): string {
    if (!date) {
      return '';
    }
    const parsedDate = new Date(date);
    return `${parsedDate.toLocaleDateString('pt-BR')} ${parsedDate.toLocaleTimeString('pt-BR')}`;
  }

  return (
    <DataTable style={{ width: '100%' }}>
      <DataTableContent>
        <DataTableHead>
          <DataTableRow>
            <DataTableHeadCell>
              <Trans i18nKey="employee.history.date">Date/Time</Trans>
            </DataTableHeadCell>
            <DataTableHeadCell>
              <Trans i18nKey="employee.history.operation-type">Operation</Trans>
            </DataTableHeadCell>
            <DataTableHeadCell>
              <Trans i18nKey="employee.history.usuario">User</Trans>
            </DataTableHeadCell>
            <DataTableHeadCell>
              <Trans i18nKey="employee.history.id-address">IP</Trans>
            </DataTableHeadCell>
          </DataTableRow>
        </DataTableHead>
        <DataTableBody>
          {history.map((historyItem) => (
            <DataTableRow key={historyItem.id}>
              <DataTableCell style={questionColumnStyle}>{dateTimeFormat(historyItem.createdAt)}</DataTableCell>
              <DataTableCell style={answerColumnStyle}>
                <HistoryOperationText operation={historyItem.operation_type} />
              </DataTableCell>
              <DataTableCell style={answerColumnStyle}>
                <div>{historyItem.user_name}</div>
                <div>{historyItem.user_email}</div>
              </DataTableCell>
              <DataTableCell style={answerColumnStyle}>{historyItem.user_ip}</DataTableCell>
            </DataTableRow>
          ))}
        </DataTableBody>
      </DataTableContent>
    </DataTable>
  );
}

function EmployeeHistoryListEmpty(): JSX.Element {
  return (
    <p>
      <Trans i18nKey="employee.history.no-data">No permission to access employee history.</Trans>
    </p>
  );
}

function HistoryOperationText({ operation }: { operation: string | undefined }): JSX.Element {
  switch (operation) {
    case 'IMPORT':
      return <Trans i18nKey="employee.history.operation.import">Import</Trans>;
    case 'CREATE':
      return <Trans i18nKey="employee.history.operation.create">Create</Trans>;
    case 'UPDATE':
      return <Trans i18nKey="employee.history.operation.update">Update</Trans>;
    case 'DELETE':
      return <Trans i18nKey="employee.history.operation.delete">Delete</Trans>;
  }
  return <span>{operation || ''}</span>;
}

EmployeeViewHistory.propTypes = {
  employee: PropTypes.object
};

export default EmployeeViewHistory;
