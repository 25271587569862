import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import { Tooltip } from '@rmwc/tooltip';
import '@rmwc/tooltip/styles';

function ContentTooltipWrapper({ contentText, maxLength, children }: InferProps<typeof ContentTooltipWrapper.propTypes>): JSX.Element {
  if (!children) {
    return <></>;
  }

  if (!contentText || !maxLength || contentText.length < maxLength) {
    return <>{children}</>;
  }

  return <Tooltip content={contentText}>{children}</Tooltip>;
}

ContentTooltipWrapper.propTypes = {
  contentText: PropTypes.string,
  maxLength: PropTypes.number,
  children: PropTypes.element
};

export default ContentTooltipWrapper;
