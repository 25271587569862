import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Cell } from 'react-table';

import SimpleTable from '../../Table/SimpleTable';
import { ContentsTagsChipsTableCell } from '../../common/ContentsTagsChips/ContentsTagsChips';

function ContentsGlossaryTable({ glossary, onRowClick, onDelete, onEdit }: InferProps<typeof ContentsGlossaryTable.propTypes>): JSX.Element {
  const { t } = useTranslation();
  const columns = React.useMemo(
    () => [
      {
        Header: t('contents.glossary.glossary'),
        accessor: 'title'
      },
      {
        Header: t('contents.glossary.categories'),
        id: 'contentTags',
        Cell: ({ cell }: { cell: Cell }): JSX.Element => ContentsTagsChipsTableCell({ cell, max: 1 })
      },
      {
        Header: t('contents.glossary.answer'),
        accessor: 'description'
      },
      {
        Header: t('contents.glossary.created-date'),
        accessor: 'createdAt',
        Cell: ({ value }: { value: string }): string => (value ? new Date(value).toLocaleDateString() : '')
      },
      {
        Header: t('contents.glossary.updated-date'),
        accessor: 'updatedAt',
        Cell: ({ value }: { value: string }): string => (value ? new Date(value).toLocaleDateString() : '')
      }
    ],
    [t]
  );
  return (
    <>
      <SimpleTable columns={columns} data={glossary} onRowClick={onRowClick} onDelete={onDelete} onEdit={onEdit}></SimpleTable>
    </>
  );
}

ContentsGlossaryTable.propTypes = {
  glossary: PropTypes.array,
  onRowClick: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func
};

export default ContentsGlossaryTable;
