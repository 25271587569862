/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { CompanyConfigurationContext } from '../common/Contexts/appContexts';
import { MessageBlocksTypes } from './StepJourneyForm';
import { MessageBlocksPollTypes } from './MessageBlocksComponents/BlockTypes/PollBlockType';
import { MessageBlocksFormTypes } from './MessageBlocksComponents/BlockTypes/FormBlockType';

import HtmlSanitizer from '../common/HtmlSanitizer';

import AvatarImage from '../Brand/AvatarImage';

import Email from '../Settings/IntegrationSettings/Icons/email.png';
import Slack from '../Settings/IntegrationSettings/Icons/slack.png';
import Beedoo from '../Settings/IntegrationSettings/Icons/beedoo.png';
import Whatsapp from '../Settings/IntegrationSettings/Icons/whatsapp.png';
import Teams from '../Settings/IntegrationSettings/Icons/teams.png';

import { Typography } from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

import { GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { DataTable, DataTableCell, DataTableHeadCell, DataTableRow, DataTableBody, DataTableContent, DataTableHead } from '@rmwc/data-table';
import '@rmwc/data-table/styles';

import { TextField } from '@rmwc/textfield';
import '@rmwc/textfield/styles';

import { Checkbox } from '@rmwc/checkbox';
import '@rmwc/checkbox/styles';

import { Radio } from '@rmwc/radio';
import '@rmwc/radio/styles';

import { Tooltip } from '@rmwc/tooltip';
import '@rmwc/tooltip/styles';

import { Icon } from '@rmwc/icon';
import '@rmwc/icon/icon.css';

import { Avatar, Select } from 'rmwc';

import JourneyStepMenitonNameTrans from './JourneyStepMenitonNameTrans';
import parseExtractHashId from '../../services/HttpRepository/parseExtractHashId';
import Message from '../../services/Message';
import AdminUsersService from '../Settings/UsersSettings/AdminUsersService';
import { StepJourneyChannel, WhatsappTemplate } from './StepJourneyRepository';
import AvatarInboardingLogo from '../Brand/AvatarInboardingLogo';
import { Company } from '../Settings/CompanySettings/CompanyRepository';
import { parseOptionsDefaultValues } from './MessageBlocksComponents/FieldTypes/ScoreOptions';

const INBOARDING_PORTAL_URL = process.env.REACT_APP_INBOARDING_PORTAL_URL;

function StepJourneyMessagePreview({
  journeyId,
  stepId,
  messageChannel,
  messageToWhom,
  messageToWhomCopy,
  messageSubject,
  messageHtml,
  messageBlocks,
  loadFromFormEdit,
  whatsappTemplate
}: {
  journeyId: string;
  stepId: string | undefined | null;
  messageChannel: StepJourneyChannel | string | undefined;
  messageToWhom: string | undefined;
  messageToWhomCopy: string | undefined;
  messageSubject: string | undefined;
  messageHtml: string;
  messageBlocks: any[] | undefined;
  loadFromFormEdit: boolean;
  whatsappTemplate?: string | undefined;
}): JSX.Element {
  const { t } = useTranslation();
  const companyConfigurationValue = React.useContext(CompanyConfigurationContext);

  const emailMessage = messageChannel === StepJourneyChannel.Email;
  const whatsappMessage = messageChannel === StepJourneyChannel.Whatsapp;
  const messageBlocksFirst = messageBlocks?.[0];

  const messageBlocksType = messageBlocksFirst?.type;
  const messageBlocksShowPortal = [MessageBlocksTypes.Task, MessageBlocksTypes.Poll, MessageBlocksTypes.Form].includes(messageBlocksType);
  const messageBlockStartButton: string | undefined = messageBlocksFirst?.start_button;
  const messageBlockQuestion: string | undefined = messageBlocksFirst?.question;
  const messageBlockLink: string | undefined = messageBlocksFirst?.link;

  if (!emailMessage) {
    messageHtml = messageHtml.replace(/<\/?span[^>]*>/g, '');
  }

  return (
    <GridCell span={12}>
      <Typography use="body1" tag="p">
        <Trans i18nKey="journey-step.preview.title">Preview</Trans>
      </Typography>
      <DataTable style={{ minWidth: '100%' }}>
        <DataTableContent>
          <DataTableHead>
            <DataTableRow>
              <DataTableHeadCell style={{ backgroundColor: 'whitesmoke', padding: '0 10px', height: '35px' }}>
                <StepJourneyChannelImage channel={messageChannel} />
              </DataTableHeadCell>
            </DataTableRow>
          </DataTableHead>
          <DataTableBody>
            <DataTableRow>
              <DataTableCell style={{ paddingLeft: '5px' }}>
                <MessageFromAvatar messageChannel={messageChannel} companyConfigurationValue={companyConfigurationValue} />
              </DataTableCell>
            </DataTableRow>
            <DataTableRow>
              <DataTableCell>
                <div>
                  <Trans i18nKey="journey-step.preview.to">To:</Trans> {messageToWhom ? <JourneyStepMenitonNameTrans name={messageToWhom} /> : '...'}
                </div>
                {emailMessage && messageToWhomCopy ? (
                  <div>
                    <Trans i18nKey="journey-step.preview.cc">Cc:</Trans> {messageToWhomCopy ? <JourneyStepMenitonNameTrans name={messageToWhomCopy} /> : '...'}
                  </div>
                ) : (
                  ''
                )}
              </DataTableCell>
            </DataTableRow>
            {emailMessage && (
              <DataTableRow>
                <DataTableCell>
                  <Trans i18nKey="journey-step.preview.subject">Subject:</Trans> {messageSubject ? messageSubject : '...'}
                </DataTableCell>
              </DataTableRow>
            )}
            {whatsappMessage && whatsappTemplate && (
              <DataTableRow>
                <DataTableCell style={{ whiteSpace: 'normal', padding: '16px' }}>
                  <MessageWhatsappTemplate whatsappTemplate={whatsappTemplate} messageSubject={messageSubject} companyConfigurationValue={companyConfigurationValue} />
                </DataTableCell>
              </DataTableRow>
            )}
            <DataTableRow>
              <DataTableCell style={{ whiteSpace: 'normal', padding: '16px' }}>
                <div dangerouslySetInnerHTML={{ __html: HtmlSanitizer.sanitize(messageHtml) }} />
                {messageBlocksType === MessageBlocksTypes.Poll && (
                  <MessageBlockPollFirstQuestion
                    stepId={stepId}
                    journeyId={journeyId}
                    messageBlocksFirst={messageBlocksFirst}
                    loadFromFormEdit={loadFromFormEdit}
                    messageChannel={messageChannel}
                  />
                )}
                {messageBlocksType === MessageBlocksTypes.CallToAction && (
                  <BlockButtonWrapper messageChannel={messageChannel}>
                    <BlockCallToActionButton messageBlockStartButton={messageBlockStartButton} messageBlockLink={messageBlockLink} messageChannel={messageChannel} />
                  </BlockButtonWrapper>
                )}
                {messageBlocksType === MessageBlocksTypes.PortalLink && (
                  <BlockButtonWrapper messageChannel={messageChannel}>
                    <BlockPortalLinkButton messageBlockStartButton={messageBlockStartButton} messageChannel={messageChannel} />
                  </BlockButtonWrapper>
                )}
                {messageBlocksType === MessageBlocksTypes.Task && (
                  <BlockButtonWrapper messageChannel={messageChannel}>
                    <Button unelevated disabled style={{ color: 'var(--mdc-theme-on-primary, #fff)', backgroundColor: 'var(--mdc-theme-primary, #469E93)' }}>
                      {messageBlockStartButton || t('journey-step.form.blocks.task.start_button.placeholder')}
                    </Button>
                  </BlockButtonWrapper>
                )}
                {messageBlocksType === MessageBlocksTypes.Form && (
                  <BlockButtonWrapper messageChannel={messageChannel}>
                    <>
                      {loadFromFormEdit ? (
                        <Button disabled unelevated style={{ color: 'var(--mdc-theme-on-primary, #fff)', backgroundColor: 'var(--mdc-theme-primary, #469E93)' }}>
                          {messageBlockStartButton || t('journey-step.form.blocks.form.start_button.placeholder')}
                        </Button>
                      ) : (
                        <BlockFormButtonOpenPortal
                          stepId={stepId || ''}
                          journeyId={journeyId}
                          loadFromFormEdit={loadFromFormEdit}
                          messageBlockStartButton={messageBlockStartButton}
                        />
                      )}
                    </>
                  </BlockButtonWrapper>
                )}
                {messageBlocksType === MessageBlocksTypes.Sequence && (
                  <BlockButtonWrapper messageChannel={messageChannel}>
                    <>
                      {loadFromFormEdit ? (
                        <Button disabled unelevated style={{ color: 'var(--mdc-theme-on-primary, #fff)', backgroundColor: 'var(--mdc-theme-primary, #469E93)' }}>
                          {messageBlockStartButton || t('journey-step.form.blocks.sequence.start_button.placeholder')}
                        </Button>
                      ) : (
                        <BlockSequenceButtonOpenPortal
                          stepId={stepId || ''}
                          journeyId={journeyId}
                          loadFromFormEdit={loadFromFormEdit}
                          messageBlockStartButton={messageBlockStartButton}
                        />
                      )}
                    </>
                  </BlockButtonWrapper>
                )}
              </DataTableCell>
            </DataTableRow>
          </DataTableBody>
        </DataTableContent>
      </DataTable>
      {messageBlocksShowPortal && (
        <DataTable style={{ minWidth: '100%', marginTop: '20px' }}>
          <DataTableContent>
            <DataTableHead>
              <DataTableRow>
                <DataTableHeadCell style={{ textAlign: 'center' }}>
                  <Typography use="body1" tag="p">
                    <Trans i18nKey="journey-step.preview.blocks-title">Portal Preview</Trans>
                  </Typography>
                </DataTableHeadCell>
              </DataTableRow>
            </DataTableHead>
            {messageBlocksType === MessageBlocksTypes.Poll && <MessageBlocksPollPortal messageBlocks={messageBlocks} emailMessage={emailMessage} />}
            {messageBlocksType === MessageBlocksTypes.Form && (
              <MessageBlocksFormPortal
                stepId={stepId || ''}
                journeyId={journeyId}
                messageBlockStartButton={messageBlockStartButton}
                messageBlocks={messageBlocks}
                loadFromFormEdit={loadFromFormEdit}
              />
            )}
            {messageBlocksType === MessageBlocksTypes.Task && (
              <DataTableBody>
                <DataTableRow>
                  <DataTableCell style={{ whiteSpace: 'normal', padding: '20px' }}>
                    <Typography use="body1" tag="p">
                      <Checkbox checked={false} readOnly label={messageBlockQuestion || t('journey-step.form.blocks.task.question.placeholder')} />
                    </Typography>
                  </DataTableCell>
                </DataTableRow>
              </DataTableBody>
            )}
          </DataTableContent>
        </DataTable>
      )}
    </GridCell>
  );
}

function MessageWhatsappTemplate({
  whatsappTemplate,
  messageSubject,
  companyConfigurationValue
}: {
  whatsappTemplate: string;
  messageSubject: string | undefined;
  companyConfigurationValue: Company;
}): JSX.Element {
  const avatarIntro = `${companyConfigurationValue.avatar_name_pronoun || 'a'} ${companyConfigurationValue.avatar_name || 'Ella'}, sou
  assistente virtual d${companyConfigurationValue.name_pronoun || 'a'} ${companyConfigurationValue.name || 'Inboarding'}`;

  if (whatsappTemplate === WhatsappTemplate.WelcomeFirstMessage) {
    return (
      <>
        <p>Oi, @Colaborador.PrimeiroNome. Como você tá? Aqui é {avatarIntro}.</p>
        <p>Vim te dar essa notícia muito especial! 🎁</p>
        <p>Vamos começar a sua jornada junto com a gente?</p>
        <Button outlined disabled style={{ marginTop: '5px' }}>
          Sim, vamos começar!
        </Button>
      </>
    );
  }

  if (whatsappTemplate === WhatsappTemplate.WelcomeSecondMessage) {
    return (
      <>
        <p>Oi, @Colaborador.PrimeiroNome. Como você tá? Aqui é {avatarIntro} para apoiar você na sua jornada.</p>
        <p>Sei que começar em um lugar novo é sempre um momento de muitas emoções, por isso eu estou aqui pra te ajudar com mensagens, lembretes e dúvidas que podem surgir. 💭</p>
        <p>
          <strong>Tenho uma mensagem para você:</strong> {messageSubject || '-'}
        </p>
        <Button outlined disabled style={{ marginTop: '5px' }}>
          Ler mensagem! Continuar.
        </Button>
      </>
    );
  }

  if (whatsappTemplate === WhatsappTemplate.GenericNotificationMessage) {
    return (
      <>
        <p>Oi, @Colaborador.PrimeiroNome. Como você tá? Aqui é {avatarIntro}.</p>
        <p>
          <strong>Tenho uma mensagem pendente para você:</strong> {messageSubject || '-'}
        </p>
        <Button outlined disabled style={{ marginTop: '5px' }}>
          Ler mensagem! Continuar.
        </Button>
      </>
    );
  }

  if (whatsappTemplate === WhatsappTemplate.TaskNotificationMessage) {
    return (
      <>
        <p>Oi, @Colaborador.PrimeiroNome. Como você tá? Aqui é {avatarIntro}.</p>
        <p>
          <strong>Tenho uma tarefa pendente para você:</strong> {messageSubject || '-'}
        </p>
        <Button outlined disabled style={{ marginTop: '5px' }}>
          Ler mensagem! Continuar.
        </Button>
      </>
    );
  }

  if (whatsappTemplate === WhatsappTemplate.UpdateNotificationMessage) {
    return (
      <>
        <p>Oi, @Colaborador.PrimeiroNome. Como você tá? Aqui é {avatarIntro}.</p>
        <p>Vou atualizar você por aqui sempre que tiver uma novidade ou atividades pendentes.</p>
        <p>
          <strong>Tenho uma mensagem para você:</strong> {messageSubject || '-'}
        </p>
        <Button outlined disabled style={{ marginTop: '5px' }}>
          Ler mensagem! Continuar.
        </Button>
      </>
    );
  }

  return <p>Selecione um template</p>;
}

function MessageFromAvatar({ messageChannel, companyConfigurationValue }: { messageChannel: string | undefined; companyConfigurationValue: Company }): JSX.Element {
  if (messageChannel === StepJourneyChannel.Beedoo) {
    return <span style={{ marginLeft: '11px' }}>Notificações</span>;
  }

  if (messageChannel === StepJourneyChannel.Whatsapp) {
    return (
      <>
        <AvatarInboardingLogo avatarSize="1.75rem" />
        <span style={{ marginLeft: '5px' }}>Inboarding notificações</span>
      </>
    );
  }

  return (
    <>
      <AvatarImage avatarName={companyConfigurationValue.avatar_name} avatarUrl={companyConfigurationValue.avatar_url} avatarSize="2.5rem" />
      <span style={{ marginLeft: '5px' }}>{companyConfigurationValue.avatar_name || 'Ella'}</span>
    </>
  );
}

function BlockSurveyButtonOpenPortal({
  stepId,
  journeyId,
  buttonLabel,
  answerValue
}: {
  stepId: string;
  journeyId: string;
  buttonLabel: string;
  answerValue: string;
}): JSX.Element {
  const { t } = useTranslation();

  const userService = new AdminUsersService();
  const useGetPortalLink = userService.useGetPortalLink();

  const buildPortalFormLinkPreview = async () => {
    if (!stepId) {
      return '';
    }

    const gettingMessageId = Message.loading(t('admin-users.portal-link.processing'));
    try {
      const result = await useGetPortalLink.mutateAsync({});
      const portalUrl = result?.url || '';
      const tokenIndex = portalUrl.indexOf('token=');
      const tokenParam = tokenIndex > 0 ? portalUrl.substr(tokenIndex) : '';
      Message.dismiss(gettingMessageId);
      if (tokenParam) {
        Message.success(t('admin-users.portal-link.sucess'));
        const portalFormUrl = INBOARDING_PORTAL_URL + `/survey/p/${journeyId}/${parseExtractHashId(stepId)}?answerValue=${answerValue}&${tokenParam}`;
        window.open(portalFormUrl, '_blank');
      } else {
        Message.error(t('admin-users.portal-link.error'));
      }
    } catch (error) {
      console.error('Error getting employee portal link: ', error);
      Message.error(t('admin-users.portal-link.error'));
    }
  };

  return (
    <Tooltip content={t('journey-step.form.blocks.poll.start_button.preview-portal')} align="top" showArrow={true}>
      <Button outlined onClick={buildPortalFormLinkPreview} style={{ color: 'rgba(0, 0, 0, 0.87)', marginTop: '5px' }}>
        {buttonLabel}
      </Button>
    </Tooltip>
  );
}

function BlockFormButtonOpenPortal({
  stepId,
  journeyId,
  loadFromFormEdit,
  messageBlockStartButton
}: {
  stepId: string;
  journeyId: string;
  loadFromFormEdit: boolean;
  messageBlockStartButton: string | undefined;
}): JSX.Element {
  const { t } = useTranslation();

  const userService = new AdminUsersService();
  const useGetPortalLink = userService.useGetPortalLink();

  const buildPortalFormLinkPreview = async () => {
    if (!stepId || loadFromFormEdit) {
      return '';
    }

    const gettingMessageId = Message.loading(t('admin-users.portal-link.processing'));
    try {
      const result = await useGetPortalLink.mutateAsync({});
      const portalUrl = result?.url || '';
      const tokenIndex = portalUrl.indexOf('token=');
      const tokenParam = tokenIndex > 0 ? portalUrl.substr(tokenIndex) : '';
      Message.dismiss(gettingMessageId);
      if (tokenParam) {
        Message.success(t('admin-users.portal-link.sucess'));
        const portalFormUrl = INBOARDING_PORTAL_URL + `/form/p/${journeyId}/${parseExtractHashId(stepId)}?${tokenParam}`;
        window.open(portalFormUrl, '_blank');
      } else {
        Message.error(t('admin-users.portal-link.error'));
      }
    } catch (error) {
      console.error('Error getting employee portal link: ', error);
      Message.error(t('admin-users.portal-link.error'));
    }
  };

  return (
    <Tooltip content={t('journey-step.form.blocks.form.start_button.preview-portal')} align="top" showArrow={true}>
      <Button unelevated onClick={buildPortalFormLinkPreview} style={{ color: 'var(--mdc-theme-on-primary, #fff)', backgroundColor: 'var(--mdc-theme-primary, #469E93)' }}>
        {messageBlockStartButton || t('journey-step.form.blocks.form.start_button.placeholder')}
      </Button>
    </Tooltip>
  );
}

function BlockSequenceButtonOpenPortal({
  stepId,
  journeyId,
  loadFromFormEdit,
  messageBlockStartButton
}: {
  stepId: string;
  journeyId: string;
  loadFromFormEdit: boolean;
  messageBlockStartButton: string | undefined;
}): JSX.Element {
  const { t } = useTranslation();

  const userService = new AdminUsersService();
  const useGetPortalLink = userService.useGetPortalLink();

  const buildPortalSequenceLinkPreview = async () => {
    if (!stepId || loadFromFormEdit) {
      return '';
    }

    const gettingMessageId = Message.loading(t('admin-users.portal-link.processing'));
    try {
      const result = await useGetPortalLink.mutateAsync({});
      const portalUrl = result?.url || '';
      const tokenIndex = portalUrl.indexOf('token=');
      const tokenParam = tokenIndex > 0 ? portalUrl.substr(tokenIndex) : '';
      Message.dismiss(gettingMessageId);
      if (tokenParam) {
        Message.success(t('admin-users.portal-link.sucess'));
        const portalSequenceUrl = INBOARDING_PORTAL_URL + `/learn/p/${journeyId}/${parseExtractHashId(stepId)}?${tokenParam}`;
        window.open(portalSequenceUrl, '_blank');
      } else {
        Message.error(t('admin-users.portal-link.error'));
      }
    } catch (error) {
      console.error('Error getting employee portal link: ', error);
      Message.error(t('admin-users.portal-link.error'));
    }
  };

  return (
    <Tooltip content={t('journey-step.form.blocks.sequence.start_button.preview-portal')} align="top" showArrow={true}>
      <Button unelevated onClick={buildPortalSequenceLinkPreview} style={{ color: 'var(--mdc-theme-on-primary, #fff)', backgroundColor: 'var(--mdc-theme-primary, #469E93)' }}>
        {messageBlockStartButton || t('journey-step.form.blocks.sequence.start_button.placeholder')}
      </Button>
    </Tooltip>
  );
}

function MessageBlocksFormPortal({
  stepId,
  journeyId,
  loadFromFormEdit,
  messageBlockStartButton,
  messageBlocks
}: {
  stepId: string;
  journeyId: string;
  loadFromFormEdit: boolean;
  messageBlockStartButton: string | undefined;
  messageBlocks: any[] | undefined;
}): JSX.Element {
  if (!messageBlocks) {
    return <></>;
  }

  const { t } = useTranslation();

  const showOnlyLinkToRealFromPreviewInPortal = !loadFromFormEdit;
  if (showOnlyLinkToRealFromPreviewInPortal) {
    return (
      <DataTableBody>
        <DataTableRow>
          <DataTableCell style={{ textAlign: 'center', whiteSpace: 'normal', padding: '20px' }}>
            <BlockFormButtonOpenPortal stepId={stepId || ''} journeyId={journeyId} loadFromFormEdit={loadFromFormEdit} messageBlockStartButton={messageBlockStartButton} />
          </DataTableCell>
        </DataTableRow>
      </DataTableBody>
    );
  }

  return (
    <DataTableBody>
      {messageBlocks.map((messageBlock: any) => (
        <React.Fragment key={messageBlock.id}>
          <DataTableRow>
            <DataTableCell style={{ whiteSpace: 'normal', padding: '20px' }}>
              <MessageBlockFormQuestion messageBlock={messageBlock} />
            </DataTableCell>
          </DataTableRow>
          <DataTableRow>
            <DataTableCell style={{ whiteSpace: 'normal', padding: '20px' }}>
              <Button outlined disabled style={{ marginTop: '10px' }}>
                {messageBlock.field_type === MessageBlocksFormTypes.Title && <Trans i18nKey="journey-step.preview.next-answer">Next</Trans>}
                {messageBlock.field_type !== MessageBlocksFormTypes.Title && <Trans i18nKey="journey-step.preview.send-answer">Send</Trans>}
              </Button>
            </DataTableCell>
          </DataTableRow>
          {!!messageBlock.ask_comment && (
            <DataTableRow>
              <DataTableCell style={{ whiteSpace: 'normal', padding: '20px' }}>
                <Typography use="body1" tag="p">
                  <label>{messageBlock.ask_comment_text || t('journey-step.form.blocks.poll.comment_text.placeholder')}</label>
                </Typography>
                <TextField textarea disabled readOnly outlined fullwidth rows={1} />
                <Button outlined disabled style={{ marginTop: '10px' }}>
                  {messageBlock.field_type === MessageBlocksFormTypes.Title && <Trans i18nKey="journey-step.preview.next-answer">Next</Trans>}
                  {messageBlock.field_type !== MessageBlocksFormTypes.Title && <Trans i18nKey="journey-step.preview.send-answer">Send</Trans>}
                </Button>
              </DataTableCell>
            </DataTableRow>
          )}
        </React.Fragment>
      ))}
      <DataTableRow>
        <DataTableCell style={{ whiteSpace: 'normal', padding: '20px' }}>
          <Typography use="body1" tag="p">
            <Trans i18nKey="journey-step.preview.form-success">
              Done! ✅ <br />
              We received your answers and everything is correct.
            </Trans>
          </Typography>
        </DataTableCell>
      </DataTableRow>
    </DataTableBody>
  );
}

function MessageBlockFormQuestion({ messageBlock }: { messageBlock: any }): JSX.Element {
  const { t } = useTranslation();

  const messageBlockFormFieldType = messageBlock.field_type || messageBlock.poll_type;
  const messageBlockQuestion: string | undefined = messageBlock.question;
  const messageBlockDescription: string | undefined = messageBlock.description;
  const messageBlockImageUrl: string | undefined = messageBlock.image_url;
  const messageBlockFormOptions = parseOptionsDefaultValues(messageBlock.options);

  let messageBlockRatioFirstOption: string;
  let messageBlockRatioSecondOption: string;
  if (messageBlock && messageBlock.options && messageBlock.options.length == 2 && messageBlock?.options?.[0].value && messageBlock?.options?.[1].value) {
    messageBlockRatioFirstOption = messageBlock?.options?.[0].value;
    messageBlockRatioSecondOption = messageBlock?.options?.[1].value;
  } else {
    messageBlockRatioFirstOption = t('journey-step.form.blocks.poll.options.one.value');
    messageBlockRatioSecondOption = t('journey-step.form.blocks.poll.options.two.value');
  }

  const pollFieldTypes = [MessageBlocksPollTypes.Likert, MessageBlocksPollTypes.Score, MessageBlocksPollTypes.ENPS];

  return (
    <>
      {messageBlockFormFieldType === MessageBlocksFormTypes.Title && (
        <>
          <Typography use="headline6" tag="p">
            {messageBlockQuestion || t('journey-step.form.blocks.form.question.placeholder')}
          </Typography>
          {!!messageBlockDescription && (
            <Typography use="body1" tag="p" style={{ whiteSpace: 'pre-line' }}>
              {messageBlockDescription}
            </Typography>
          )}
          {!!messageBlockImageUrl && (
            <div>
              <img src={messageBlockImageUrl} alt={messageBlockQuestion} style={{ maxWidth: '100%' }} />
            </div>
          )}
        </>
      )}
      {messageBlockFormFieldType !== MessageBlocksFormTypes.Title && !pollFieldTypes.includes(messageBlockFormFieldType) && (
        <>
          <Typography use="body1" tag="p">
            <label>{messageBlockQuestion || t('journey-step.form.blocks.form.question.placeholder')}</label>
            {!!messageBlockDescription && (
              <>
                <br />
                <Typography use="caption" style={{ whiteSpace: 'pre-line' }}>
                  {messageBlockDescription}
                </Typography>
              </>
            )}
          </Typography>
        </>
      )}
      {[MessageBlocksFormTypes.Text, MessageBlocksFormTypes.PersonShorName].includes(messageBlockFormFieldType) && <TextField type="text" disabled readOnly fullwidth />}
      {[MessageBlocksFormTypes.Date, MessageBlocksFormTypes.PersonBirthdate].includes(messageBlockFormFieldType) && <TextField type="date" disabled readOnly fullwidth />}
      {messageBlockFormFieldType === MessageBlocksFormTypes.Number && <TextField type="number" disabled readOnly fullwidth />}
      {messageBlockFormFieldType === MessageBlocksFormTypes.Email && <TextField type="email" disabled readOnly fullwidth />}
      {messageBlockFormFieldType === MessageBlocksFormTypes.Link && <TextField type="url" disabled readOnly fullwidth />}
      {messageBlockFormFieldType === MessageBlocksFormTypes.TextLong && <TextField textarea disabled readOnly outlined fullwidth rows={3} helpText={messageBlockQuestion} />}

      {[MessageBlocksFormTypes.SingleRatio, MessageBlocksFormTypes.PersonBirthdateCelebrate].includes(messageBlockFormFieldType) && (
        <div>
          <Radio disabled readOnly>
            {messageBlockRatioFirstOption}
          </Radio>
          <Radio disabled readOnly>
            {messageBlockRatioSecondOption}
          </Radio>
        </div>
      )}

      {messageBlockFormFieldType === MessageBlocksFormTypes.MultipleChoice && (
        <div>
          {messageBlockFormOptions &&
            messageBlockFormOptions.map((option: { id?: string; value: string }, index) => (
              <>
                <Radio key={option.id ?? index} disabled readOnly>
                  {option.value}
                </Radio>
              </>
            ))}
        </div>
      )}

      {messageBlockFormFieldType === MessageBlocksFormTypes.OptionsList && (
        <div style={{ width: '50%', padding: '10px' }}>
          <Select label={t('journey-step.form.blocks.form.options.options-list-label', 'Choose an option')} disabled outlined />
        </div>
      )}

      {messageBlockFormFieldType === MessageBlocksFormTypes.Group && (
        <>
          <FormsGroupPreview groupFields={messageBlock.group} />
        </>
      )}

      {messageBlockFormFieldType === MessageBlocksFormTypes.PersonProfilePicture && (
        <>
          <Avatar
            name="Avatar"
            size="xlarge"
            interactive
            style={{ backgroundColor: 'var(--mdc-theme-secondary)', fontSize: '7rem' }}
            title={t('employee.view.picture.edit', 'Click here to upload a new photo.')}
          />
        </>
      )}

      {[MessageBlocksPollTypes.Likert, MessageBlocksPollTypes.Score, MessageBlocksPollTypes.ENPS].includes(messageBlockFormFieldType) && (
        <>
          <MessageBlockPollQuestion messageBlock={messageBlock} />
          <PullButtonHintHelpText />
        </>
      )}
    </>
  );
}

function FormsGroupPreview({ groupFields }: { groupFields: any[] }): JSX.Element {
  return <>{groupFields && groupFields.map((messageBlock: any, index: number) => <MessageBlockFormQuestion messageBlock={messageBlock} key={index} />)}</>;
}

function MessageBlocksPollPortal({ messageBlocks, emailMessage }: { messageBlocks: any[] | undefined; emailMessage: boolean }): JSX.Element {
  const { t } = useTranslation();

  if (!messageBlocks) {
    return <></>;
  }

  const messageBlocksFirst = messageBlocks[0]; //Return first elemement and remove
  if (!messageBlocksFirst) {
    return <></>;
  }

  const messageBlocksSecondOn = messageBlocks.slice(1);

  return (
    <DataTableBody>
      {(!emailMessage || messageBlocksFirst.poll_type === MessageBlocksPollTypes.Text) && (
        <DataTableRow>
          <DataTableCell style={{ whiteSpace: 'normal', padding: '20px' }}>
            <MessageBlockPollQuestion messageBlock={messageBlocksFirst} />
            {messageBlocksFirst.poll_type === MessageBlocksPollTypes.Text && (
              <Button outlined disabled style={{ marginTop: '10px' }}>
                <Trans i18nKey="journey-step.preview.send-answer">Send</Trans>
              </Button>
            )}
          </DataTableCell>
        </DataTableRow>
      )}
      {!!messageBlocksFirst.ask_comment && (
        <DataTableRow>
          <DataTableCell style={{ whiteSpace: 'normal', padding: '20px' }}>
            <Typography use="body1" tag="p">
              <label>{messageBlocksFirst.ask_comment_text || t('journey-step.form.blocks.poll.comment_text.placeholder')}</label>
            </Typography>
            <TextField textarea disabled readOnly outlined fullwidth rows={1} />
            <Button outlined disabled style={{ marginTop: '10px' }}>
              <Trans i18nKey="journey-step.preview.send-answer">Send</Trans>
            </Button>
          </DataTableCell>
        </DataTableRow>
      )}
      {messageBlocksSecondOn.map((messageBlock: any) => (
        <>
          <DataTableRow>
            <DataTableCell style={{ whiteSpace: 'normal', padding: '20px' }}>
              <MessageBlockPollQuestion messageBlock={messageBlock} />
              {messageBlock.poll_type === MessageBlocksPollTypes.Text && (
                <Button outlined disabled style={{ marginTop: '10px' }}>
                  <Trans i18nKey="journey-step.preview.send-answer">Send</Trans>
                </Button>
              )}
            </DataTableCell>
          </DataTableRow>
          {!!messageBlock.ask_comment && (
            <DataTableRow>
              <DataTableCell style={{ whiteSpace: 'normal', padding: '20px' }}>
                <Typography use="body1" tag="p">
                  <label>{messageBlock.ask_comment_text || t('journey-step.form.blocks.poll.comment_text.placeholder')}</label>
                </Typography>
                <TextField textarea disabled readOnly outlined fullwidth rows={1} />
                <Button outlined disabled style={{ marginTop: '10px' }}>
                  <Trans i18nKey="journey-step.preview.send-answer">Send</Trans>
                </Button>
              </DataTableCell>
            </DataTableRow>
          )}
        </>
      ))}
      <DataTableRow>
        <DataTableCell style={{ whiteSpace: 'normal', padding: '20px' }}>
          <Typography use="body1" tag="p">
            <Trans i18nKey="journey-step.preview.poll-success">
              Done! ✅ <br />
              Your experience is very important to us. So we can keep improving.
            </Trans>
          </Typography>
        </DataTableCell>
      </DataTableRow>
    </DataTableBody>
  );
}

function MessageBlockPollQuestionPreviewButtons({ stepId, journeyId, messageBlock }: { stepId: string; journeyId: string; messageBlock: any }): JSX.Element {
  const { t } = useTranslation();

  const messageBlockPollType = messageBlock.poll_type;
  const messageBlockPollOptions = parseOptionsDefaultValues(messageBlock.options);
  const messageBlockPollOptionsLength = messageBlockPollOptions ? messageBlockPollOptions.length : 0;
  const messageBlockPollRatioFirstOption = messageBlock.options?.[0]?.value || messageBlock.options?.[0];
  const messageBlockPollRatioSecondOption = messageBlock.options?.[1]?.value || messageBlock.options?.[1];
  const messageBlockQuestion: string | undefined = messageBlock.question;

  return (
    <>
      <Typography use="body1" tag="p">
        <label>{messageBlockQuestion || t('journey-step.form.blocks.poll.question.placeholder')}</label>
      </Typography>

      {[MessageBlocksPollTypes.Score, MessageBlocksPollTypes.Likert].includes(messageBlockPollType) && messageBlockPollOptions && (
        <>
          {messageBlockPollOptions.map((option: { value: string; id?: string }, index: number) => (
            <div key={option.id ?? index}>
              <BlockSurveyButtonOpenPortal
                stepId={stepId || ''}
                journeyId={journeyId}
                buttonLabel={option.value}
                answerValue={`${messageBlockPollOptionsLength - index} - ${option.value}`}
              />
            </div>
          ))}
          <PullButtonHintHelpText />
        </>
      )}
      {messageBlockPollType === MessageBlocksPollTypes.SingleRatio && (
        <div>
          <div>
            <BlockSurveyButtonOpenPortal
              stepId={stepId || ''}
              journeyId={journeyId}
              buttonLabel={messageBlockPollRatioFirstOption || t('journey-step.form.blocks.poll.options.one.value')}
              answerValue="yes"
            />
          </div>
          <div>
            <BlockSurveyButtonOpenPortal
              stepId={stepId || ''}
              journeyId={journeyId}
              buttonLabel={messageBlockPollRatioSecondOption || t('journey-step.form.blocks.poll.options.two.value')}
              answerValue="yes"
            />
          </div>
          <PullButtonHintHelpText />
        </div>
      )}
      {messageBlockPollType === MessageBlocksPollTypes.ENPS && (
        <>
          <div>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value: number) => (
              <Button key={value} outlined disabled readOnly style={{ marginTop: '5px', marginRight: '3px', minWidth: '48px', width: '48px' }}>
                {value}
              </Button>
            ))}
          </div>
          <PullButtonHintHelpText />
        </>
      )}
    </>
  );
}

function PullButtonHintHelpText(): JSX.Element {
  return (
    <Typography use="caption" tag="p">
      Clique nas opções para confirmar a sua resposta.
    </Typography>
  );
}

function MessageBlockPollQuestion({ messageBlock, firstEmailPreview }: { messageBlock: any; firstEmailPreview?: boolean }): JSX.Element {
  const { t } = useTranslation();

  const messageBlockPollType = messageBlock.poll_type || messageBlock.field_type;
  const messageBlockPollOptions = parseOptionsDefaultValues(messageBlock.options);
  const messageBlockPollRatioFirstOption = messageBlock.options?.[0]?.value;
  const messageBlockPollRatioSecondOption = messageBlock.options?.[1]?.value;
  const messageBlockQuestion: string | undefined = messageBlock.question;

  return (
    <>
      <Typography use="body1" tag="p">
        <label>{messageBlockQuestion || t('journey-step.form.blocks.poll.question.placeholder')}</label>
      </Typography>
      {(!messageBlockPollType || messageBlockPollType === MessageBlocksPollTypes.Text) && <TextField textarea disabled readOnly outlined fullwidth rows={1} />}
      {[MessageBlocksPollTypes.Score, MessageBlocksPollTypes.Likert].includes(messageBlockPollType) && messageBlockPollOptions && (
        <>
          {messageBlockPollOptions.map((option: { value: string; id?: string }, index) => (
            <div key={option.id ?? index}>
              <Button outlined disabled readOnly style={{ marginTop: '5px' }}>
                {option.value}
              </Button>
            </div>
          ))}
          {firstEmailPreview ? <PullButtonHintHelpText /> : null}
        </>
      )}
      {messageBlockPollType === MessageBlocksPollTypes.SingleRatio && (
        <div>
          <Radio disabled readOnly>
            {messageBlockPollRatioFirstOption || t('journey-step.form.blocks.poll.options.one.value')}
          </Radio>
          <Radio disabled readOnly>
            {messageBlockPollRatioSecondOption || t('journey-step.form.blocks.poll.options.two.value')}
          </Radio>
          {firstEmailPreview ? <PullButtonHintHelpText /> : null}
        </div>
      )}
      {messageBlockPollType === MessageBlocksPollTypes.ENPS && (
        <>
          <div>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value: number) => (
              <Button key={value} outlined disabled readOnly style={{ marginTop: '5px', marginRight: '3px', minWidth: '48px', width: '48px' }}>
                {value}
              </Button>
            ))}
          </div>
          {firstEmailPreview ? <PullButtonHintHelpText /> : null}
        </>
      )}
    </>
  );
}

function MessageBlockPollFirstQuestion({
  stepId,
  journeyId,
  messageBlocksFirst,
  loadFromFormEdit,
  messageChannel
}: {
  stepId: string | undefined | null;
  journeyId: string;
  messageBlocksFirst: any;
  loadFromFormEdit: boolean;
  messageChannel: StepJourneyChannel | string | undefined;
}): JSX.Element {
  const { t } = useTranslation();

  const messageBlockPollType = messageBlocksFirst?.poll_type;
  const messageBlockStartButton: string | undefined = messageBlocksFirst?.start_button;

  const showOnlyStartButton = messageChannel !== StepJourneyChannel.Email || messageBlockPollType === MessageBlocksPollTypes.Text;

  return (
    <>
      {showOnlyStartButton && (
        <div style={{ textAlign: messageChannel === StepJourneyChannel.Slack ? 'left' : 'center', paddingTop: '10px' }}>
          {loadFromFormEdit ? (
            <Button unelevated style={{ color: 'var(--mdc-theme-on-primary, #fff)', backgroundColor: 'var(--mdc-theme-primary, #469E93)' }}>
              {messageBlockStartButton || t('journey-step.form.blocks.poll.start_button.placeholder')}
            </Button>
          ) : (
            <BlockSurveyButtonOpenPortal stepId={stepId || ''} journeyId={journeyId} buttonLabel={messageBlockStartButton || ''} answerValue="" />
          )}
        </div>
      )}

      {!showOnlyStartButton &&
        [MessageBlocksPollTypes.Likert, MessageBlocksPollTypes.ENPS, MessageBlocksPollTypes.Score, MessageBlocksPollTypes.SingleRatio].includes(messageBlockPollType) && (
          <>
            {loadFromFormEdit ? (
              <MessageBlockPollQuestion messageBlock={messageBlocksFirst} firstEmailPreview={true} />
            ) : (
              <MessageBlockPollQuestionPreviewButtons stepId={stepId || ''} journeyId={journeyId} messageBlock={messageBlocksFirst} />
            )}
          </>
        )}
    </>
  );
}

export function StepJourneyChannelHeader({ channel }: { channel: string | undefined }): JSX.Element {
  return (
    <span style={{ backgroundColor: 'whitesmoke', padding: '10px' }}>
      <StepJourneyChannelImage channel={channel} />
    </span>
  );
}

export function StepJourneyBlockType({ type }: { type: string | undefined }): JSX.Element {
  if (!type) {
    return <></>;
  }

  if (type === MessageBlocksTypes.Form) {
    return (
      <Typography use="caption">
        <Icon icon={{ icon: 'view_list', size: 'xsmall' }} style={{ verticalAlign: 'middle', marginBottom: '3px', color: 'var(--colors-hue-gray-8)' }} theme="onSurface" />{' '}
        <Trans i18nKey="journey-step.blocks.form.add-button">Form</Trans>
      </Typography>
    );
  }

  if (type === MessageBlocksTypes.CallToAction) {
    return (
      <Typography use="caption">
        <Icon icon={{ icon: 'add_link', size: 'xsmall' }} style={{ verticalAlign: 'middle', marginBottom: '3px', color: 'var(--colors-hue-gray-8)' }} theme="onSurface" />{' '}
        <Trans i18nKey="journey-step.blocks.call-to-action.add-button">Form</Trans>
      </Typography>
    );
  }

  if (type === MessageBlocksTypes.PortalLink) {
    return (
      <Typography use="caption">
        <Icon icon={{ icon: 'add_to_home_screen', size: 'xsmall' }} style={{ verticalAlign: 'middle', marginBottom: '3px', color: 'var(--colors-hue-gray-8)' }} theme="onSurface" />{' '}
        <Trans i18nKey="journey-step.blocks.portal.add-button">Portal</Trans>
      </Typography>
    );
  }

  if (type === MessageBlocksTypes.Task) {
    return (
      <Typography use="caption">
        <Icon icon={{ icon: 'checklist', size: 'xsmall' }} style={{ verticalAlign: 'middle', marginBottom: '3px', color: 'var(--colors-hue-gray-8)' }} theme="onSurface" />{' '}
        <Trans i18nKey="journey-step.blocks.task.add-button">Task</Trans>
      </Typography>
    );
  }

  if (type === MessageBlocksTypes.Poll) {
    return (
      <Typography use="caption">
        <Icon icon={{ icon: 'bar_chart', size: 'xsmall' }} style={{ verticalAlign: 'middle', marginBottom: '3px', color: 'var(--colors-hue-gray-8)' }} theme="onSurface" />{' '}
        <Trans i18nKey="journey-step.blocks.poll.add-button">Poll</Trans>
      </Typography>
    );
  }

  if (type === MessageBlocksTypes.Sequence) {
    return (
      <Typography use="caption">
        <Icon
          icon={{ icon: 'format_list_numbered', size: 'xsmall' }}
          style={{ verticalAlign: 'middle', marginBottom: '3px', color: 'var(--colors-hue-gray-8)' }}
          theme="onSurface"
        />{' '}
        <Trans i18nKey="journey-step.blocks.sequence.add-button">Sequence</Trans>
      </Typography>
    );
  }

  return <></>;
}

function BlockButtonWrapper({ messageChannel, children }: { messageChannel: StepJourneyChannel | string | undefined; children: JSX.Element }): JSX.Element {
  return <div style={{ textAlign: messageChannel === StepJourneyChannel.Email ? 'center' : 'left', marginTop: '15px' }}>{children}</div>;
}

function buttonStyleFromStepJourneyChannel(channel: StepJourneyChannel | string | undefined): {
  simpleLink: boolean;
  unelevated: boolean;
  outlined: boolean;
  textColor: string;
  backgroundColor: string;
} {
  const defaultButtonStyle = {
    simpleLink: false,
    unelevated: true,
    outlined: false,
    textColor: 'var(--mdc-theme-on-primary, #fff)',
    backgroundColor: 'var(--mdc-theme-primary, #469E93)'
  };

  if (channel === StepJourneyChannel.Slack) {
    defaultButtonStyle.unelevated = false;
    defaultButtonStyle.outlined = true;
    defaultButtonStyle.textColor = 'var(--mdc-theme-on-surface, #303030)';
    defaultButtonStyle.backgroundColor = 'var(--mdc-theme-background, #fff)';
  } else if (channel === StepJourneyChannel.Beedoo) {
    defaultButtonStyle.simpleLink = true;
    defaultButtonStyle.textColor = 'var(--mdc-theme-on-surface, #303030)';
    defaultButtonStyle.backgroundColor = 'var(--mdc-theme-background, #fff)';
  }

  return defaultButtonStyle;
}

function BlockCallToActionButton({
  messageBlockStartButton,
  messageBlockLink,
  messageChannel
}: {
  messageBlockStartButton: string | undefined;
  messageBlockLink: string | undefined;
  messageChannel: StepJourneyChannel | string | undefined;
}): JSX.Element {
  const { t } = useTranslation();

  let buttonText = messageBlockStartButton;

  if (messageChannel === StepJourneyChannel.Slack && buttonText && buttonText.length > 30) {
    buttonText = buttonText.substring(0, 30) + '...';
  }

  const buttonStyle = buttonStyleFromStepJourneyChannel(messageChannel);

  return (
    <Tooltip content={messageBlockLink} align="top" showArrow={true}>
      {buttonStyle.simpleLink ? (
        <a href={messageBlockLink} target="_blank" rel="noopener noreferrer">
          {buttonText || t('journey-step.form.blocks.call-to-action.start_button.placeholder')}
        </a>
      ) : (
        <Button
          unelevated={buttonStyle.unelevated}
          outlined={buttonStyle.outlined}
          tag="a"
          href={messageBlockLink}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: buttonStyle.textColor, backgroundColor: buttonStyle.backgroundColor }}
        >
          {buttonText || t('journey-step.form.blocks.call-to-action.start_button.placeholder')}
        </Button>
      )}
    </Tooltip>
  );
}

function BlockPortalLinkButton({
  messageBlockStartButton,
  messageChannel
}: {
  messageBlockStartButton: string | undefined;
  messageChannel: StepJourneyChannel | string | undefined;
}): JSX.Element {
  const { t } = useTranslation();
  const userService = new AdminUsersService();

  const useGetPortalLink = userService.useGetPortalLink();

  async function onOpenEmployeePortal(event: React.MouseEvent) {
    event.preventDefault();
    const gettingMessageId = Message.loading(t('admin-users.portal-link.processing'));
    try {
      const result = await useGetPortalLink.mutateAsync({});
      Message.dismiss(gettingMessageId);
      if (result?.url) {
        Message.success(t('admin-users.portal-link.sucess'));
        window.open(result.url, '_blank');
      } else {
        Message.error(t('admin-users.portal-link.error'));
      }
    } catch (error) {
      console.error('Error getting employee portal link: ', error);
      Message.error(t('admin-users.portal-link.error'));
    }
  }

  let buttonText = messageBlockStartButton;

  if (messageChannel === StepJourneyChannel.Slack && buttonText && buttonText.length > 30) {
    buttonText = buttonText.substring(0, 30) + '...';
  }

  const buttonStyle = buttonStyleFromStepJourneyChannel(messageChannel);

  return (
    <Tooltip content={t('journey-step.form.blocks.portal.start_button.tooltip')} align="top" showArrow={true}>
      {buttonStyle.simpleLink ? (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a href="#" onClick={onOpenEmployeePortal} rel="noopener noreferrer">
          {buttonText || t('journey-step.form.blocks.portal.start_button.placeholder')}
        </a>
      ) : (
        <Button
          unelevated={buttonStyle.unelevated}
          outlined={buttonStyle.outlined}
          onClick={onOpenEmployeePortal}
          style={{ color: buttonStyle.textColor, backgroundColor: buttonStyle.backgroundColor }}
        >
          {buttonText || t('journey-step.form.blocks.portal.start_button.placeholder')}
        </Button>
      )}
    </Tooltip>
  );
}

export function StepJourneyChannelImage({ channel }: { channel: string | undefined }): JSX.Element {
  const { t } = useTranslation();

  switch (channel) {
    case StepJourneyChannel.Email:
      return (
        <>
          <img
            src={Email}
            alt={t('journey-step.form.channel.email.label', 'Email')}
            title={t('journey-step.form.channel.email.label', 'Email')}
            style={{ height: '20px', marginRight: '8px', verticalAlign: 'middle' }}
          />
          <Typography use="caption">
            <Trans i18nKey="journey-step.form.channel.email.label">Email</Trans>
          </Typography>
        </>
      );

    case StepJourneyChannel.Slack:
      return (
        <>
          <img
            src={Slack}
            alt={t('journey-step.form.channel.slack.label', 'Slack')}
            title={t('journey-step.form.channel.slack.label', 'Slack')}
            style={{ height: '20px', marginRight: '8px', verticalAlign: 'middle' }}
          />
          <Typography use="caption">
            <Trans i18nKey="journey-step.form.channel.slack.label">Slack</Trans>
          </Typography>
        </>
      );

    case StepJourneyChannel.Beedoo:
      return (
        <>
          <img
            src={Beedoo}
            alt={t('journey-step.form.channel.beedoo.label', 'Beedoo')}
            title={t('journey-step.form.channel.beedoo.label', 'Beedoo')}
            style={{ height: '20px', marginRight: '8px', verticalAlign: 'middle' }}
          />
          <Typography use="caption">
            <Trans i18nKey="journey-step.form.channel.beedoo.label">Beedoo</Trans>
          </Typography>
        </>
      );

    case StepJourneyChannel.Teams:
      return (
        <>
          <img
            src={Teams}
            alt={t('journey-step.form.channel.teams.label', 'Teams')}
            title={t('journey-step.form.channel.teams.label', 'Teams')}
            style={{ height: '20px', marginRight: '8px', verticalAlign: 'middle' }}
          />
          <Typography use="caption">
            <Trans i18nKey="journey-step.form.channel.teams.label">Teams</Trans>
          </Typography>
        </>
      );

    case StepJourneyChannel.Whatsapp:
      return (
        <>
          <img
            src={Whatsapp}
            alt={t('journey-step.form.channel.whatsapp.label', 'WhatsApp')}
            title={t('journey-step.form.channel.whatsapp.label', 'WhatsApp')}
            style={{ height: '20px', marginRight: '8px', verticalAlign: 'middle' }}
          />
          <Typography use="caption">
            <Trans i18nKey="journey-step.form.channel.whatsapp.label">WhatsApp</Trans>
          </Typography>
        </>
      );
  }

  return <></>;
}

export default StepJourneyMessagePreview;
