import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import LoadingModal from '../../LoadingModal';

import ContentsQuestionsService from './ContentsQuestionsService';

import { Link } from 'react-router-dom';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import ContentsQuestionsTable from './ContentsQuestionsTable';
import ErrorMessage from '../../ErrorMessage';
import Message from '../../../services/Message';
import Breadcrumb from '../../common/Breadcrumb';
import EllaMessage from '../../common/EllaMessage';
import ContentsViewPortalButton from '../ContentsGlossary/ContentsViewPortalButton';

function ContentsQuestionsHome({ history }: InferProps<typeof ContentsQuestionsHome.propTypes>): JSX.Element {
  const { t } = useTranslation();
  const questionTranslation = t('contents.questions.model');
  const questionsService = new ContentsQuestionsService();

  const { data, isFetching, isError, error } = questionsService.useList();
  const useDeleteEmployee = questionsService.useDelete();

  async function onDeleteQuestion(values: { id: string; title: string }): Promise<void> {
    if (window.confirm(t('delete.confirm', { name: values.title }))) {
      const deletingMessageId = Message.loading(t('delete.processing', { model: questionTranslation }));
      await useDeleteEmployee.mutateAsync(encodeURIComponent(values.id));
      Message.dismiss(deletingMessageId);
      Message.success(t('contents.questions.deleted'));
    }
  }

  async function onEditQuestion(values: { id: string }): Promise<void> {
    if (history) history.push(`/contents/questions/${encodeURIComponent(values.id)}/edit`);
  }

  async function onClickQuestion(values: { id: string }): Promise<void> {
    if (history) history.push(`/contents/questions/${encodeURIComponent(values.id)}/edit`);
  }

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching) {
    return <LoadingModal open={true} />;
  }

  const hasDataCreated = data && data.length > 0;

  return (
    <Grid>
      <GridCell span={12}>
        <Breadcrumb routeSegments={[{ name: t('navlink.contents') }, { name: t('navlink.content.questions') }]} />
      </GridCell>
      <GridCell span={6}>
        <h2>
          <Trans i18nKey="contents.questions.title">Frequently Asked Questions - FAQ</Trans>
        </h2>
      </GridCell>
      <GridCell span={6} style={{ textAlign: 'right' }}>
        {hasDataCreated && (
          <>
            <ContentsViewPortalButton />
            <Button raised icon="add" tag={Link} {...{ to: '/contents/questions/new' }} style={{ marginLeft: '10px' }}>
              <Trans i18nKey="contents.questions.create">New Question</Trans>
            </Button>
          </>
        )}
      </GridCell>
      {hasDataCreated ? (
        <GridCell span={12}>
          <ContentsQuestionsTable questions={data} onRowClick={onClickQuestion} onDelete={onDeleteQuestion} onEdit={onEditQuestion}></ContentsQuestionsTable>
        </GridCell>
      ) : (
        <FirstQuestionInstruction></FirstQuestionInstruction>
      )}
    </Grid>
  );
}

function FirstQuestionInstruction(): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <GridCell span={12} align="middle">
        <EllaMessage title={t('contents.questions.nodata.title')} message={t('contents.questions.nodata.text')} />
      </GridCell>
      <GridCell span={12} style={{ textAlign: 'center' }}>
        <Button raised icon="add" tag={Link} {...{ to: '/contents/questions/new' }}>
          <Trans i18nKey="contents.questions.create">New Question</Trans>
        </Button>
      </GridCell>
    </>
  );
}

ContentsQuestionsHome.propTypes = {
  history: PropTypes.any
};

export default withRouter(ContentsQuestionsHome);
