import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import styles from './EllaMessage.module.css';

import EllaImage from '../../Brand/EllaImage';

import { Elevation } from '@rmwc/elevation';
import '@material/elevation/dist/mdc.elevation.css';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Theme } from '@rmwc/theme';

function EllaMessage({ title, message }: InferProps<typeof EllaMessage.propTypes>): JSX.Element {
  if (!title) {
    return <></>;
  }

  return (
    <Elevation z={3} className={styles.ellaMessageElevationCard}>
      <Grid>
        <GridCell span={2} phone={2}>
          <div className={styles.ellaAvatar}>
            <EllaImage avatarSize="4em" />
          </div>
        </GridCell>
        <GridCell span={10} phone={10}>
          <h3 style={{ marginTop: '5px' }}>
            <Theme use="primary">{title} </Theme>
          </h3>
          <p>{message}</p>
        </GridCell>
      </Grid>
    </Elevation>
  );
}

EllaMessage.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
};

export default EllaMessage;
