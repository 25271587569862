import HttpBaseService from '../../../services/HttpBaseService';

import { FieldValues } from 'react-hook-form';
import { UseQueryResult } from 'react-query';
import ReportsDashboardsRepository, { ReportDashboard } from './ResportsDashboardsRepository';

const queryKey = 'analytics/reports/dashboard';

class RepostsDashboardsService extends HttpBaseService<ReportDashboard> {
  constructor(private reportsDashboardsRepository = new ReportsDashboardsRepository()) {
    super(queryKey, reportsDashboardsRepository);
  }

  public useGetHomeLink(): UseQueryResult<ReportDashboard> {
    return this.useGet('home');
  }

  public useGetMessagesLink(): UseQueryResult<ReportDashboard> {
    return this.useGet('messages');
  }

  public useGetSurveysLink(): UseQueryResult<ReportDashboard> {
    return this.useGet('surveys');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  convertValuesToModel(_values: FieldValues): ReportDashboard {
    throw new Error('Method not implemented.');
  }
}

export default RepostsDashboardsService;
