import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { NavLink } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import styles from './Breadcrumb.module.css';

import { Icon } from '@rmwc/icon';
import '@rmwc/icon/icon.css';

import { Theme } from '@rmwc/theme';

function Breadcrumb({ routeSegments }: InferProps<typeof Breadcrumb.propTypes>): JSX.Element {
  const { t } = useTranslation();

  if (!routeSegments || !routeSegments.length) {
    return <></>;
  }

  return (
    <ol className={styles.breadcrumbWrap}>
      <li className={styles.breadcrumbItem} key="home">
        <NavLink to="/" title={t('navlink.home')} style={{ lineHeight: '10px' }} tabIndex={-1}>
          <Icon icon="home" alt={t('navlink.home')} className="mdc-theme--primary" />
        </NavLink>
      </li>
      {routeSegments.map((route, index: number) => (
        <React.Fragment key={index}>
          <li aria-hidden="true" className={styles.breadcrumbSeparator} key={'separator-' + index}>
            <Icon icon="navigate_next" className="mdc-theme--primary" />
          </li>
          <li className={styles.breadcrumbItem} key={index}>
            {route.path ? (
              <Theme use="primary">
                <NavLink to={route.path} tabIndex={-1}>
                  <span className="capitalize text-muted">{route.name}</span>
                </NavLink>
              </Theme>
            ) : (
              <Theme use="onSurface">
                <span className="capitalize text-muted">{route.name}</span>
              </Theme>
            )}
          </li>
        </React.Fragment>
      ))}
    </ol>
  );
}

Breadcrumb.propTypes = {
  routeSegments: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string
    }).isRequired
  ).isRequired
};

export default Breadcrumb;
