import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import { Tooltip } from '@rmwc/tooltip';
import '@rmwc/tooltip/styles';

function TooltipWrapper({ children, showTooltip = true, tooltipText }: InferProps<typeof TooltipWrapper.propTypes>): JSX.Element {
  if (showTooltip) {
    return (
      <Tooltip content={tooltipText} align="topLeft" showArrow={true}>
        <div title={tooltipText} style={{ display: 'inline' }}>
          {children}
        </div>
      </Tooltip>
    );
  }

  return <>{children}</>;
}

TooltipWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  showTooltip: PropTypes.bool,
  tooltipText: PropTypes.string.isRequired
};

export default TooltipWrapper;
