import { FieldValues } from 'react-hook-form';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';

import { SearchFilter } from '../../../services/HttpRepository/SearchFilter';
import HttpBaseService from '../../../services/HttpBaseService';
import CelebrationsBirthRepository from './CelebrationsBirthRepository';
import { Employee } from '../../Employee/EmployeeRepository';

const queryKey = 'celebrations-birth';

class CelebrationsBirthService extends HttpBaseService<Employee> {
  constructor(private celebrationsRepository = new CelebrationsBirthRepository()) {
    super(queryKey, celebrationsRepository);
  }

  convertValuesToModel(values: FieldValues): Employee {
    return {
      id: values.id
    } as Employee;
  }

  public useListSearch(searchFilter: SearchFilter, dataLimit: number): UseInfiniteQueryResult<Employee[]> {
    return useInfiniteQuery<Employee[]>(
      [queryKey, searchFilter.query],
      async ({ pageParam }) => {
        if (pageParam) {
          searchFilter.lastKey = pageParam;
        }
        return this.celebrationsRepository.listSearch(searchFilter);
      },
      {
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPageData) => {
          const dataLength = lastPageData?.length;
          if (!dataLength || dataLength < dataLimit) {
            return undefined;
          }
          const lastDataItem = lastPageData[dataLength - 1] as unknown as Employee;
          return {
            id: lastDataItem.id,
            birth_date_month: lastDataItem.birth_date_month
          };
        }
      }
    );
  }
}

export default CelebrationsBirthService;
