import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Link } from 'react-router-dom';

import { Trans, useTranslation } from 'react-i18next';
import { Cell } from 'react-table';

import { Avatar } from '@rmwc/avatar';
import '@rmwc/avatar/avatar.css';

import { Typography } from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

import SimpleTable from '../../Table/SimpleTable';
import { Employee } from '../../Employee/EmployeeRepository';
import { dateFormat } from '../../common/DatesFormat/dateTimeFormat';
import { CelebrationDateTag } from '../CelebrationsBirth/CelebrationsBirthTable';

const avatarStyle = {
  backgroundColor: 'var(--mdc-theme-secondary)',
  marginRight: '10px',
  fontSize: '2.3rem'
} as React.CSSProperties;

function EmployeeNameCell({ cell }: { cell: Cell }): JSX.Element {
  return <EmployeeNameCellComponent employee={cell.row.original as Employee}></EmployeeNameCellComponent>;
}

function EmployeeNameCellComponent({ employee }: { employee: Employee }): JSX.Element {
  return (
    <>
      <div style={{ display: 'flex' }}>
        <Avatar name={employee.social_name || employee.first_name} size="xlarge" style={avatarStyle} src={employee.photo_url || ''} />
      </div>
      <div style={{ marginLeft: '0.4rem', whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
        <Link to={`/employees/${employee.id}`} style={{ color: 'rgba(0, 0, 0, 0.87)', textDecoration: 'none' }} onClick={(event): void => event.stopPropagation()}>
          <Typography use="subtitle1" tag="h5" style={{ margin: 0, lineHeight: '85%', textDecoration: 'underline' }}>
            {employee.social_name || employee.first_name} {employee.last_name}
          </Typography>
          <Typography use="caption" tag="small">
            {employee.work_email || employee.personal_email || employee.mobile_number}
          </Typography>
        </Link>
      </div>
    </>
  );
}

function EmployeeDepartmentCell({ cell }: { cell: Cell }): JSX.Element {
  const employee = cell.row.original as Employee;

  return (
    <>
      {employee.job_title && <div>{employee.job_title}</div>}
      {employee.department && (
        <div>
          {employee.department} {employee.cost_center && <span> - {employee.cost_center}</span>}
        </div>
      )}
    </>
  );
}

function EmployeeStartDateCell({ cell }: { cell: Cell }): JSX.Element {
  const employee = cell.row.original as Employee;

  const startDateFormatted = dateFormat(employee.start_date);

  return (
    <>
      <div>{startDateFormatted}</div>
      <div>
        <CelebrationDateTag dateIso={employee.start_date}></CelebrationDateTag>
      </div>
    </>
  );
}

function EmployeeYearsStartDateCell({ value }: { value: string }): JSX.Element {
  const startDate = new Date(value);

  if (!startDate) {
    return <></>;
  }

  const today = new Date();
  const years = today.getFullYear() - startDate.getFullYear();

  if (!years) {
    return <></>;
  }

  return (
    <Trans i18nKey="celebrations.date.years" count={years}>
      {{ count: years }} year
    </Trans>
  );
}

function CelebrationsWorkTable({ people, onRowClick }: InferProps<typeof CelebrationsWorkTable.propTypes>): JSX.Element {
  const { t } = useTranslation();
  const columns = React.useMemo(
    () => [
      {
        Header: t('celebrations.work.name'),
        id: 'employeeName',
        Cell: ({ cell }: { cell: Cell }) => (
          <div style={{ display: 'flex', boxAlign: 'center', alignItems: 'center' }}>
            <EmployeeNameCell cell={cell} />
          </div>
        )
      },
      {
        Header: t('celebrations.work.department'),
        id: 'employeeDepartment',
        Cell: EmployeeDepartmentCell
      },
      {
        Header: t('celebrations.work.start-date'),
        id: 'employeeStartDate',
        Cell: EmployeeStartDateCell
      },
      {
        Header: t('celebrations.work.start-date-years'),
        accessor: 'start_date',
        Cell: EmployeeYearsStartDateCell
      }
    ],
    [t]
  );
  return (
    <>
      <SimpleTable columns={columns} data={people} onRowClick={onRowClick}></SimpleTable>
    </>
  );
}

CelebrationsWorkTable.propTypes = {
  people: PropTypes.array,
  onRowClick: PropTypes.func
};

export default CelebrationsWorkTable;
