/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import authService from '../../services/AuthService';

import { TopAppBar, TopAppBarRow, TopAppBarSection } from '@rmwc/top-app-bar';
import { TopAppBarNavigationIcon, TopAppBarFixedAdjust } from '@rmwc/top-app-bar';
import '@material/top-app-bar/dist/mdc.top-app-bar.css';

import { MenuSurfaceAnchor, MenuSurface, MenuItem } from '@rmwc/menu';
import '@material/menu/dist/mdc.menu.css';
import '@material/menu-surface/dist/mdc.menu-surface.css';
import '@material/list/dist/mdc.list.css';

import { IconButton } from '@rmwc/icon-button';
import '@material/icon-button/dist/mdc.icon-button.css';

import { ListDivider } from '@rmwc/list';
import '@material/list/dist/mdc.list.css';

import '@material/theme/dist/mdc.theme.css';

import { Avatar } from '@rmwc/avatar';
import '@rmwc/avatar/avatar.css';

import { Typography } from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

import AdminUsersService from '../Settings/UsersSettings/AdminUsersService';
import Message from '../../services/Message';

const appBarStyle = {
  left: '0'
} as any;

function Header({ isMobile, onClickMenu, logo }: InferProps<typeof Header.propTypes>): JSX.Element {
  const { t } = useTranslation();
  const userService = new AdminUsersService();
  const [profileMenuOpen, setProfileMenuOpen] = React.useState(false);
  const authUser = authService.getAuthUser();

  function handleLogout(): void {
    authService.logout();
  }

  const useGetPortalLink = userService.useGetPortalLink();

  async function onOpenEmployeePortal() {
    const gettingMessageId = Message.loading(t('admin-users.portal-link.processing'));
    setProfileMenuOpen(false);
    try {
      const result = await useGetPortalLink.mutateAsync({});
      Message.dismiss(gettingMessageId);
      if (result?.url) {
        Message.success(t('admin-users.portal-link.sucess'));
        window.open(result.url, '_blank');
      } else {
        Message.error(t('admin-users.portal-link.error'));
      }
    } catch (error) {
      console.error('Error getting employee portal link: ', error);
      Message.error(t('admin-users.portal-link.error'));
    }
  }

  if (!authUser) {
    return <></>;
  }

  return (
    <>
      <TopAppBar fixed theme="background" style={appBarStyle}>
        <TopAppBarRow>
          <TopAppBarMenuMobile isMobile={isMobile} onClickMenu={onClickMenu} logo={logo} />
          <TopAppBarSection alignEnd>
            <MenuSurfaceAnchor>
              <MenuSurface anchorCorner="bottomStart" open={profileMenuOpen} onClose={(): void => setProfileMenuOpen(false)}>
                <div style={{ padding: '15px' }}>
                  <div>{authUser.name || authUser.given_name}</div>
                  <Typography use="body1" tag="div" theme="textSecondaryOnBackground">
                    {authUser.email}
                  </Typography>
                </div>
                <ListDivider />
                <MenuItem tag={Link} {...{ to: '/profile' }} onClick={(): void => setProfileMenuOpen(false)}>
                  <Trans i18nKey="navlink.profile">Edit Profile</Trans>
                </MenuItem>
                <MenuItem onClick={onOpenEmployeePortal}>
                  <Trans i18nKey="navlink.portal">Access my portal</Trans>
                </MenuItem>
                <MenuItem tag={Link} {...{ to: '/settings' }} onClick={(): void => setProfileMenuOpen(false)}>
                  <Trans i18nKey="navlink.settings">Settings</Trans>
                </MenuItem>
                <MenuItem tag={Link} {...{ to: '/support' }} onClick={(): void => setProfileMenuOpen(false)}>
                  <Trans i18nKey="navlink.help">Help</Trans>
                </MenuItem>
                <ListDivider />
                <MenuItem onClick={handleLogout}>
                  <Trans i18nKey="navlink.logout">Logout</Trans>
                </MenuItem>
              </MenuSurface>

              {authUser && authUser.picture ? (
                <Avatar
                  interactive
                  src={authUser.picture}
                  name={authUser.name || authUser.given_name}
                  size="large"
                  onClick={(): void => setProfileMenuOpen(!profileMenuOpen)}
                  style={{ marginRight: '10px' }}
                />
              ) : (
                <IconButton theme="primary" icon="account_circle" onClick={(): void => setProfileMenuOpen(!profileMenuOpen)} />
              )}
            </MenuSurfaceAnchor>
          </TopAppBarSection>
        </TopAppBarRow>
      </TopAppBar>
      <TopAppBarFixedAdjust />
    </>
  );
}

function TopAppBarMenuMobile({ isMobile, onClickMenu, logo }: InferProps<typeof TopAppBarMenuMobile.propTypes>): JSX.Element | null {
  function handleOnClickMenu(): void {
    if (onClickMenu) onClickMenu();
  }

  if (isMobile) {
    return (
      <>
        <TopAppBarSection alignStart>
          <TopAppBarNavigationIcon theme="primary" icon="menu" tag="button" onClick={handleOnClickMenu} aria-hidden="false" aria-label="Menu" />
        </TopAppBarSection>
        <TopAppBarSection style={{ display: 'flex', justifyContent: 'center' }} tag={Link} {...{ to: '/' }}>
          <div style={{ minWidth: '120px', height: '100%' }}>{logo}</div>
        </TopAppBarSection>
      </>
    );
  }
  return null;
}

Header.propTypes = {
  history: PropTypes.object,
  isMobile: PropTypes.bool,
  onClickMenu: PropTypes.func,
  logo: PropTypes.element.isRequired
};

TopAppBarMenuMobile.propTypes = {
  logo: PropTypes.element.isRequired,
  isMobile: PropTypes.bool,
  onClickMenu: PropTypes.func
};

export default Header;
