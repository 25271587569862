/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import { Trans, useTranslation } from 'react-i18next';

import SimpleTable from '../../Table/SimpleTable';
import { Cell } from 'react-table';

import { Avatar } from '@rmwc/avatar';
import '@rmwc/avatar/avatar.css';

import { Chip } from '@rmwc/chip';
import '@rmwc/chip/styles';

import { AdminUser } from './AdminUsersRepository';

const avatarStyle = {
  backgroundColor: 'var(--mdc-theme-secondary)',
  marginRight: '10px'
} as React.CSSProperties;

function UserNameCell({ cell }: { cell: Cell }): JSX.Element {
  return <UserNameCellComponent user={cell.row.original as AdminUser}></UserNameCellComponent>;
}

function UserNameCellComponent({ user }: { user: AdminUser }): JSX.Element {
  return (
    <div>
      <Avatar name={user.name || user.email} size="large" style={avatarStyle} />
      {user.name}
    </div>
  );
}

function UserRoleCell(): JSX.Element {
  return <Trans i18nKey="users-settings.roles.admin">Admin</Trans>;
}

function UserEnabledStatusCell({ cell }: { cell: Cell }): JSX.Element {
  const user = cell.row.original as AdminUser;
  if (!user?.cognito_username) {
    return <Chip label={<Trans i18nKey="users-settings.status.invited">Invited</Trans>} />;
  }

  return <Chip style={{ backgroundColor: 'var(--mdc-theme-green-enabled)' }} label={<Trans i18nKey="users-settings.status.enabled">Enabled</Trans>} />;
}

function UsersSettingsTable({ users, onRowClick, onDelete, onEdit }: InferProps<typeof UsersSettingsTable.propTypes>): JSX.Element {
  const { t } = useTranslation();
  const columns = React.useMemo(
    () => [
      {
        Header: t('users-settings.name'),
        id: 'userName',
        Cell: UserNameCell
      },
      {
        Header: t('users-settings.email'),
        accessor: 'email'
      },
      {
        Header: t('users-settings.enabled'),
        id: 'userEnabledStatus',
        Cell: UserEnabledStatusCell
      },
      {
        Header: t('users-settings.role'),
        id: 'userRole',
        Cell: UserRoleCell
      }
    ],
    [t]
  );
  return (
    <>
      <SimpleTable columns={columns} data={users} onRowClick={onRowClick} onDelete={onDelete} onEdit={onEdit}></SimpleTable>
    </>
  );
}

UsersSettingsTable.propTypes = {
  users: PropTypes.array,
  onRowClick: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func
};

export default UsersSettingsTable;
