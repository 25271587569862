/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import { useTranslation } from 'react-i18next';

import SimpleTable from '../Table/SimpleTable';

function ConnectionTable({ connections, onDelete }: InferProps<typeof ConnectionTable.propTypes>): JSX.Element {
  const { t } = useTranslation();
  const columns = React.useMemo(
    () => [
      {
        Header: t('connection.id'),
        accessor: 'id'
      },
      {
        Header: t('connection.name'),
        accessor: 'name'
      }
    ],
    [t]
  );
  return (
    <>
      <SimpleTable columns={columns} data={connections} onDelete={onDelete}></SimpleTable>
    </>
  );
}

ConnectionTable.propTypes = {
  connections: PropTypes.array,
  onDelete: PropTypes.func
};

export default ConnectionTable;
