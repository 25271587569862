import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Trans } from 'react-i18next';

import authService from '../../services/AuthService';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { CollapsibleList } from '@rmwc/list';
import '@rmwc/list/styles';

import { Typography } from '@rmwc/typography';
import '@rmwc/typography/styles';

import ErrorStatusPageButton from './ErrorStatusPageButton';
import ErrorReturnHomeCell from './ErrorReturnHomeCell';

function authenticationError(error: unknown): boolean {
  return error === 'No current user' || error === 'not authenticated';
}

function notFoundStatusCode(errorResponse: { status: number } | undefined): boolean {
  if (errorResponse && errorResponse.status) {
    return errorResponse.status === 404;
  }
  return false;
}

function ErrorMessage({ error }: InferProps<typeof ErrorMessage.propTypes>): JSX.Element {
  console.error('Error message as JSON = ', JSON.stringify(error));
  console.error('Error error.response = ', JSON.stringify(error?.response));

  const errorUrl = error?.config?.url;
  const errorStatus = error?.response?.status;
  const errorHeaderCorrelation = error?.config?.headers && error?.config?.headers['X-Correlation-Id'];

  if (authenticationError(error)) {
    return <AuthErrorMessage />;
  }

  if (notFoundStatusCode(error?.response)) {
    return <NotFoundErrorMessage />;
  }

  return (
    <Grid>
      <GridCell span={12} align="middle" style={{ textAlign: 'center' }}>
        <Typography use="headline5" tag="h1">
          <Trans i18nKey="error.generic.title">OOPS! Our server is on a break</Trans>
        </Typography>
        <p>
          <Trans i18nKey="error.generic.text">Sorry, something went wrong on our end. If you continue to see this error, please contact us.</Trans>
        </p>
        {error && error.message && (
          <CollapsibleList
            handle={
              <Button>
                <Trans i18nKey="error.details-button">More advanced details</Trans>
              </Button>
            }
          >
            <p style={{ padding: '1rem' }}>
              <Trans i18nKey="error.details">Details:</Trans> {error.message}
              {errorUrl && (
                <>
                  <br />
                  URL: {errorUrl}
                </>
              )}
              {errorStatus && (
                <>
                  <br />
                  Status: {errorStatus}
                </>
              )}
              {errorHeaderCorrelation && (
                <>
                  <br />
                  Correlation: {errorHeaderCorrelation}
                </>
              )}
            </p>
          </CollapsibleList>
        )}
      </GridCell>

      <ErrorReturnHomeCell>
        <ErrorStatusPageButton></ErrorStatusPageButton>
      </ErrorReturnHomeCell>
    </Grid>
  );
}

function AuthErrorMessage(): JSX.Element {
  function handleLogout(): void {
    authService.logout();
  }

  return (
    <Grid>
      <GridCell span={12} align="middle" style={{ textAlign: 'center' }}>
        <Typography use="headline3" tag="h1">
          <Trans i18nKey="error.auth.title">Your authentication expired</Trans>
        </Typography>
        <p>
          <Trans i18nKey="error.auth.text">Sorry, you will need to login again clicking on the button bellow. If you continue to see this error, please contact us.</Trans>
        </p>
      </GridCell>
      <GridCell span={12} align="middle" style={{ textAlign: 'center' }}>
        <ErrorStatusPageButton />
        <Button raised onClick={handleLogout}>
          <Trans i18nKey="navlink.logout-auth">Logout and authenticate again</Trans>
        </Button>
      </GridCell>
    </Grid>
  );
}

export function AuthLoginNotAuthorizedMessage(): JSX.Element {
  function handleLogout(): void {
    authService.logout();
  }

  return (
    <Grid>
      <GridCell span={12} align="middle" style={{ textAlign: 'center' }}>
        <Typography use="headline3" tag="h1">
          <Trans i18nKey="error.not-authorized.title">Not authorized</Trans>
        </Typography>
        <p>
          <Trans i18nKey="error.not-authorized.text">You user is not authorized to access, if you are a user having issues to login, please contact us.</Trans>
        </p>
      </GridCell>
      <GridCell span={12} align="middle" style={{ textAlign: 'center' }}>
        <ErrorStatusPageButton />
        <Button raised onClick={handleLogout}>
          <Trans i18nKey="navlink.logout-auth">Logout and authenticate again</Trans>
        </Button>
      </GridCell>
    </Grid>
  );
}

function NotFoundErrorMessage(): JSX.Element {
  return (
    <Grid>
      <GridCell span={12} align="middle" style={{ textAlign: 'center' }}>
        <Typography use="headline3" tag="h1">
          <Trans i18nKey="error.notfound.title">Page not found</Trans>
        </Typography>
        <p>
          <Trans i18nKey="error.notfound.text">Sorry, we did not find the details you were looking for. If you continue to see this message, please contact us.</Trans>
        </p>
      </GridCell>
      <ErrorReturnHomeCell />
    </Grid>
  );
}

ErrorMessage.propTypes = {
  error: PropTypes.any.isRequired
};

export default ErrorMessage;
