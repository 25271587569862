import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';

import { SearchFilter } from '../../../services/HttpRepository/SearchFilter';
import HttpBaseService from '../../../services/HttpBaseService';

import MessagesDeliveryRepository from './MessagesDeliveryRepository';
import { EmployeeJourneyStep } from '../../Employee/EmployeeRepository';
import { FieldValues } from 'react-hook-form';

const queryKey = 'analytics/messages/emails';

class MessagesDeliveryService extends HttpBaseService<EmployeeJourneyStep> {
  constructor(private messagesDeliveryRepository = new MessagesDeliveryRepository()) {
    super(queryKey, messagesDeliveryRepository);
  }

  public useListSearch(searchFilter: SearchFilter, dataLimit: number): UseInfiniteQueryResult<EmployeeJourneyStep[]> {
    return useInfiniteQuery<EmployeeJourneyStep[]>(
      [queryKey, searchFilter.query],
      async ({ pageParam }) => {
        if (pageParam) {
          searchFilter.lastKey = pageParam;
        }
        return this.messagesDeliveryRepository.listSearch(searchFilter);
      },
      {
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPageData) => {
          const dataLength = lastPageData?.length;
          if (!dataLength || dataLength < dataLimit) {
            return undefined;
          }
          const lastDataItem = lastPageData[dataLength - 1] as unknown as EmployeeJourneyStep;
          return {
            id: lastDataItem.id
          };
        }
      }
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  convertValuesToModel(_values: FieldValues): EmployeeJourneyStep {
    throw new Error('Method not implemented.');
  }
}

export default MessagesDeliveryService;
