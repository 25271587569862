import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Typography } from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/circular-progress.css';

import { Tooltip } from '@rmwc/tooltip';
import '@rmwc/tooltip/styles';

import { Cell } from 'react-table';

import SimpleTable from '../../Table/SimpleTable';
import LoadingModal from '../../LoadingModal';
import ErrorMessage from '../../ErrorMessage';
import Breadcrumb from '../../common/Breadcrumb';

import EmployeeFormAnswersService from './EmployeeFormAnswersService';
import { Employee, EmployeeFormAsnwer } from '../../Employee/EmployeeRepository';
import { dateTimeFormat } from '../../common/DatesFormat/dateTimeFormat';
import { SurveyAnswersStatusChip } from '../../Employee/EmployeeViewSurveys';
import { EmployeeStartEndDateCell } from '../../Employee/EmployeeTable';

const LIST_SIZE = 30;

function FormAnswers(): JSX.Element {
  const { t } = useTranslation();
  const employeeFormAnswersService = new EmployeeFormAnswersService();

  const searchFilter = { limit: LIST_SIZE, query: '' };

  const { data, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage, isError, error } = employeeFormAnswersService.useListSearch(searchFilter, LIST_SIZE);

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching && !isFetchingNextPage && !searchFilter.query) {
    return <LoadingModal open={true} />;
  }

  const reportData = data?.pages.flat();
  const dataLength = reportData ? reportData.length : 0;

  return (
    <Grid>
      <GridCell span={12}>
        <Breadcrumb routeSegments={[{ name: t('navlink.analytics') }, { name: t('navlink.analytic.form') }]} />
      </GridCell>
      <GridCell span={12} align="middle">
        <h2>
          <Trans i18nKey="analytics.form-answers.title">Form Answers</Trans>
        </h2>
      </GridCell>
      <>
        <GridCell span={12}>
          <FormAnswersTable employees={reportData}></FormAnswersTable>
          {!dataLength && (
            <p>
              {isFetching ? (
                <CircularProgress theme="secondary" />
              ) : (
                <>
                  <Trans i18nKey="analytics.no-data-search">No data found.</Trans>
                </>
              )}
            </p>
          )}
        </GridCell>

        {hasNextPage && (
          <GridCell span={12} style={{ textAlign: 'center' }}>
            <Button
              outlined
              {...(isFetching ? { icon: <CircularProgress theme="secondary" /> } : {})}
              disabled={isFetching}
              onClick={(): void => {
                fetchNextPage();
              }}
            >
              <Trans i18nKey="analytics.load-more">Load More</Trans>
            </Button>
          </GridCell>
        )}
      </>
    </Grid>
  );
}

function EmployeeNameCell({ cell }: { cell: Cell }): JSX.Element {
  return <EmployeeNameCellComponent employee={cell.row.original as Employee}></EmployeeNameCellComponent>;
}

function EmployeeNameCellComponent({ employee }: { employee: Employee }): JSX.Element {
  return (
    <div style={{ marginLeft: '0.4rem', whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
      <Typography use="subtitle1" tag="h5" style={{ margin: 0, lineHeight: '85%' }}>
        <NavLink to={`/employees/${employee.id}/forms`} target="_blank">
          {employee.social_name || employee.first_name} {employee.last_name}
        </NavLink>
      </Typography>
      <Typography use="caption" tag="small">
        {employee.work_email || employee.personal_email || employee.mobile_number}
      </Typography>
    </div>
  );
}

function EmployeeDepartmentCell({ cell }: { cell: Cell }): JSX.Element {
  const employee = cell.row.original as Employee;

  return (
    <>
      {employee.job_title && <div>{employee.job_title}</div>}
      {employee.department && (
        <div>
          {employee.department} {employee.cost_center && <span> - {employee.cost_center}</span>}
        </div>
      )}
    </>
  );
}

function EmployeeFormQuantityCell({ cell }: { cell: Cell }): JSX.Element {
  const employee = cell.row.original as Employee;
  const forms = employee.form_answers;
  const totalAnswers = forms?.length || 0;
  return (
    <Typography use="subtitle1" tag="h5">
      <Trans i18nKey="analytics.form-answers.form-answers-total" count={totalAnswers}>
        {{ count: totalAnswers }} answers
      </Trans>
    </Typography>
  );
}

function EmployeeFormAnswersDataCell({ cell }: { cell: Cell }): JSX.Element {
  const employee = cell.row.original as Employee;
  const formAnswers = employee.form_answers;
  if (!formAnswers) {
    return <></>;
  }

  const tooltipAnswersContent = (
    <div
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        background: 'white',
        width: '30rem',
        minHeight: '4rem',
        color: 'black',
        borderRadius: '3px',
        margin: '-2px -5px',
        padding: '10px'
      }}
    >
      {formAnswers.map((answer: EmployeeFormAsnwer) => {
        return (
          <div key={answer.name} style={{ marginBottom: '5px' }}>
            <SurveyAnswersStatusChip status={answer.status} /> {dateTimeFormat(answer.submitted)} : {answer.name}
          </div>
        );
      })}
    </div>
  );

  return (
    <Tooltip content={tooltipAnswersContent} align="top" showArrow={true}>
      <Typography use="subtitle1" tag="h5" style={{ margin: 0, lineHeight: '85%' }}>
        <NavLink to={`/employees/${employee.id}/forms`} target="_blank">
          <Trans i18nKey="analytics.form-answers.form-answers-see">Read answers</Trans>
        </NavLink>
      </Typography>
    </Tooltip>
  );
}

function FormAnswersTable({ employees }: InferProps<typeof FormAnswersTable.propTypes>): JSX.Element {
  const { t } = useTranslation();
  const columns = React.useMemo(
    () => [
      {
        Header: t('analytics.form-answers.employee'),
        accessor: 'employee',
        Cell: EmployeeNameCell
      },
      {
        Header: t('analytics.form-answers.department'),
        accessor: 'department',
        Cell: EmployeeDepartmentCell
      },
      {
        Header: t('analytics.form-answers.form-sent'),
        id: 'formAnswerQuantity',
        Cell: EmployeeFormQuantityCell
      },
      {
        Header: t('analytics.form-answers.start_date'),
        id: 'employeeStartDate',
        Cell: EmployeeStartEndDateCell
      },
      {
        Header: '',
        id: 'formAnswerData',
        Cell: EmployeeFormAnswersDataCell
      }
    ],
    [t]
  );
  const isRowDisabled = (rowData: Employee): boolean => {
    return Boolean(rowData?.last_date);
  };
  return (
    <>
      <SimpleTable columns={columns} data={employees} isRowDisabled={isRowDisabled}></SimpleTable>
    </>
  );
}

FormAnswersTable.propTypes = {
  employees: PropTypes.array
};

export default FormAnswers;
