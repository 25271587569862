import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import { useTranslation } from 'react-i18next';

import SimpleTable from '../Table/SimpleTable';
import { Cell } from 'react-table';

import { Avatar } from '@rmwc/avatar';
import '@rmwc/avatar/avatar.css';

import { Typography } from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

import { Employee } from './EmployeeRepository';
import { Link } from 'react-router-dom';

const avatarStyle = {
  backgroundColor: 'var(--mdc-theme-secondary)',
  marginRight: '10px',
  fontSize: '2.3rem'
} as React.CSSProperties;

function EmployeeNameCell({ cell }: { cell: Cell }): JSX.Element {
  return <EmployeeNameCellComponent employee={cell.row.original as Employee}></EmployeeNameCellComponent>;
}

function EmployeeNameCellComponent({ employee }: { employee: Employee }): JSX.Element {
  return (
    <>
      <div style={{ display: 'flex' }}>
        <Avatar name={employee.social_name || employee.first_name} size="xlarge" style={avatarStyle} src={employee.photo_url || ''} />
      </div>
      <div style={{ marginLeft: '0.4rem', whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
        <Link to={`/employees/${employee.id}`} style={{ color: 'rgba(0, 0, 0, 0.87)', textDecoration: 'none' }} onClick={(event): void => event.stopPropagation()}>
          <Typography use="subtitle1" tag="h5" style={{ margin: 0, lineHeight: '85%', textDecoration: 'underline' }}>
            {employee.social_name || employee.first_name} {employee.last_name}
          </Typography>
          <Typography use="caption" tag="small">
            {employee.work_email || employee.personal_email || employee.mobile_number}
          </Typography>
        </Link>
      </div>
    </>
  );
}

export function EmployeeLastDateCell({ cell }: { cell: Cell }): JSX.Element {
  const employee = cell.row.original as Employee;

  return <>{employee.last_date && <div>{new Date(employee.last_date).toLocaleDateString('pt-BR', { timeZone: 'UTC' })}</div>}</>;
}

function EmployeeDepartmentCell({ cell }: { cell: Cell }): JSX.Element {
  const employee = cell.row.original as Employee;

  return (
    <>
      {employee.job_title && <div>{employee.job_title}</div>}
      {employee.department && (
        <div>
          {employee.department} {employee.cost_center && <span> - {employee.cost_center}</span>}
        </div>
      )}
    </>
  );
}

function EmployeeOffboardingTable({ employees, onRowClick, onDelete, onEdit }: InferProps<typeof EmployeeOffboardingTable.propTypes>): JSX.Element {
  const { t } = useTranslation();
  const columns = React.useMemo(
    () => [
      {
        Header: t('employee.employee'),
        id: 'employeeName',
        Cell: ({ cell }: { cell: Cell }) => (
          <div style={{ display: 'flex', boxAlign: 'center', alignItems: 'center' }}>
            <EmployeeNameCell cell={cell} />
          </div>
        )
      },
      {
        Header: t('employee.department'),
        id: 'employeeDepartment',
        Cell: EmployeeDepartmentCell
      },
      {
        Header: t('employee.last_date'),
        id: 'employeeLasttDate',
        Cell: EmployeeLastDateCell
      }
    ],
    [t]
  );
  const isRowDisabled = (rowData: Employee): boolean => {
    return Boolean(rowData?.last_date);
  };
  return (
    <>
      <SimpleTable columns={columns} data={employees} onRowClick={onRowClick} onDelete={onDelete} onEdit={onEdit} isRowDisabled={isRowDisabled}></SimpleTable>
    </>
  );
}

EmployeeOffboardingTable.propTypes = {
  employees: PropTypes.array,
  onRowClick: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func
};

export default EmployeeOffboardingTable;
