import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Auth } from 'aws-amplify';
import { Trans } from 'react-i18next';

import AppLoading from '../AppLoading';
import ErrorMessage from '../ErrorMessage';
import { AuthUserAtrributes } from '../../services/AuthService/AuthService';

import { ListDivider } from '@rmwc/list';
import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Theme } from '@rmwc/theme';
import { Typography } from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

import { Elevation } from '@rmwc/elevation';
import '@material/elevation/dist/mdc.elevation.css';

import { Avatar } from '@rmwc/avatar';
import '@rmwc/avatar/avatar.css';

const profileCardStyle = {
  textAlign: 'center',
  padding: '15px',
  backgroundColor: '#fff',
  borderRadius: '.3rem'
} as React.CSSProperties;

const detailsCardStyle = {
  padding: '15px',
  backgroundColor: '#fff',
  borderRadius: '.3rem'
} as React.CSSProperties;

const avatarStyle = {
  backgroundColor: 'var(--mdc-theme-secondary)'
} as React.CSSProperties;

function Profile(): JSX.Element {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<unknown>('');
  const [authUser, setAuthUser] = React.useState<AuthUserAtrributes>();

  async function loadProfile(): Promise<void> {
    if (authUser && (authUser.email || authUser.name)) return;
    try {
      const currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
      setAuthUser({
        sub: currentUser.attributes.sub,
        username: currentUser.username,
        email: currentUser.attributes.email,
        given_name: currentUser.attributes.given_name,
        family_name: currentUser.attributes.family_name,
        name: currentUser.attributes.name,
        picture: currentUser.attributes.picture,
        identities: currentUser.attributes.identities ? JSON.parse(currentUser.attributes.identities) : undefined,
        'custom:tenant_id': currentUser.attributes['custom:tenant_id']
      } as AuthUserAtrributes);
      setLoading(false);
    } catch (error) {
      console.log('Error', error);
      setError(error);
    }
  }

  loadProfile();

  if (error) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  return (
    <>
      <Helmet>
        <title>Profile | Inboarding</title>
      </Helmet>
      {loading ? <AppLoading /> : <ProfileDetails authUser={authUser} />}
    </>
  );
}

function ProfileDetails({ authUser }: { authUser: AuthUserAtrributes | undefined }): JSX.Element {
  if (!authUser) {
    return <></>;
  }

  return (
    <>
      <Grid>
        <GridCell span={12}>
          <Theme use="primary">
            <Typography use="headline5" tag="h2" style={{ margin: '0' }}>
              <strong>
                <Trans i18nKey="profile.hello">Hello</Trans>
              </strong>
            </Typography>
          </Theme>
          {authUser.given_name ? (
            <Typography use="headline4" tag="h2" style={{ margin: '0' }}>
              {authUser.given_name}
            </Typography>
          ) : (
            ''
          )}
        </GridCell>
        <GridCell span={12}>
          <Trans i18nKey="profile.description">Here you can find your profile and authentication details.</Trans>
        </GridCell>
      </Grid>
      <Grid>
        <GridCell desktop={4} tablet={12} phone={12}>
          <Elevation z={2} style={profileCardStyle}>
            <div>
              <Avatar src={authUser.picture} name={authUser.name || authUser.given_name} size="xlarge" style={avatarStyle} />
            </div>
            <h2>
              <Typography use="subtitle1">{authUser.name || authUser.given_name}</Typography>
            </h2>
            <h3>
              <Typography use="subtitle2">{authUser.email}</Typography>
            </h3>
            <ListDivider style={{ marginTop: '20px' }} />
            <p>App: {BUILD_PACKAGE_VERSION}</p>
            <p>Build: {new Date(BUILD_VERSION_DATETIME).toLocaleString()}</p>
          </Elevation>
        </GridCell>
        <GridCell desktop={8} tablet={12} phone={12}>
          <Elevation z={2} style={detailsCardStyle}>
            <Typography use="subtitle2" style={{ padding: '0.5rem 0.5rem 0 0.5rem' }} tag="div">
              <Trans i18nKey="profile.username">Username:</Trans>
            </Typography>
            <Typography use="subtitle1" style={{ padding: '0 0.5rem 0.5rem 0.5rem' }}>
              {authUser.username}
            </Typography>
            <Typography use="subtitle2" style={{ padding: '0.5rem 0.5rem 0 0.5rem' }} tag="div">
              <Trans i18nKey="profile.id">Unique user identifier:</Trans>
            </Typography>
            <Typography use="subtitle1" style={{ padding: '0 0.5rem 0.5rem 0.5rem' }}>
              {authUser.sub}
            </Typography>
            <Typography use="subtitle2" style={{ padding: '0.5rem 0.5rem 0 0.5rem' }} tag="div">
              <Trans i18nKey="profile.tenant-id">Unique account identifier:</Trans>
            </Typography>
            <Typography use="subtitle1" style={{ padding: '0 0.5rem 0.5rem 0.5rem' }}>
              {authUser['custom:tenant_id'] || '-'}
            </Typography>
            {!!authUser.identities && !!authUser.identities[0] && (
              <>
                <Typography use="subtitle2" style={{ padding: '0.5rem 0.5rem 0 0.5rem' }} tag="div">
                  <Trans i18nKey="profile.user-id">User ID:</Trans>
                </Typography>
                <Typography use="subtitle1" style={{ padding: '0 0.5rem 0.5rem 0.5rem' }}>
                  {authUser.identities[0].userId}
                </Typography>
                <Typography use="subtitle2" style={{ padding: '0.5rem 0.5rem 0 0.5rem' }} tag="div">
                  <Trans i18nKey="profile.provider">Provider:</Trans>
                </Typography>
                <Typography use="subtitle1" style={{ padding: '0 0.5rem 0.5rem 0.5rem' }}>
                  {authUser.identities[0].providerType}
                </Typography>
                <Typography use="subtitle2" style={{ padding: '0.5rem 0.5rem 0 0.5rem' }} tag="div">
                  <Trans i18nKey="profile.date-created">Created date:</Trans>
                </Typography>
                <Typography use="subtitle1" style={{ padding: '0 0.5rem 0.5rem 0.5rem' }}>
                  {new Date(authUser.identities[0].dateCreated).toLocaleString('pt-BR', { timeZone: 'UTC' })}
                </Typography>
              </>
            )}
          </Elevation>
        </GridCell>
      </Grid>
    </>
  );
}

ProfileDetails.propTypes = {
  authUser: PropTypes.object
};

export default Profile;
