import axios, { AxiosResponse } from 'axios';
import { FieldValues } from 'react-hook-form';

import HttpBaseService from '../../../services/HttpBaseService';
import CompanyRepository, { Company } from './CompanyRepository';
import ImportFileUploadRepository, { ImportEmployeesFileMetadata } from '../../pages/ImportEmployees/ImportFileUploadRepository';

import { PeopleOriginType } from '../../Employee/EmployeeRepository';
import ImportEmployeeFileRepository, { ImportFilenameMetadata } from '../../pages/ImportEmployees/ImportEmployeeFileRepository';

const SOLLO_TENANT_ID = '41884829-c062-4ab3-bc9f-de7a7ef91825';
const ASAAS_TENANT_ID = '2a3effe3-015a-4c8d-ad8c-331bb411ff0e';
const ALIFE_TENANT_ID = 'ddc6f8a7-9756-4de6-aac8-9a148858dc7d';
const IRCE_TENANT_ID = '0eb6372f-8c64-4fcd-9bc7-5419101d69ab';
const DEMO_INBOARDING_TENANT_ID = 'e02afc1c-3847-40ef-bcaa-1efc8217038f';

class CompanyService extends HttpBaseService<Company> {
  constructor(private companyRepository = new CompanyRepository()) {
    super('companies', companyRepository);
  }

  convertValuesToModel(values: FieldValues): Company {
    return {
      name: values.name,
      name_pronoun: values.name_pronoun,
      timezone: values.timezone,
      color_primary: values.color_primary,
      color_secondary: values.color_secondary,
      logo_url: values.logo_url,
      logo_small_url: values.logo_small_url,
      avatar_name: values.avatar_name,
      avatar_name_pronoun: values.avatar_name_pronoun,
      avatar_url: values.avatar_url,
      sent_from_email: values.sent_from_email
    } as Company;
  }

  static isDemo(tenantId: string): boolean {
    return tenantId === DEMO_INBOARDING_TENANT_ID;
  }

  static isSollo(tenantId: string): boolean {
    return tenantId === SOLLO_TENANT_ID;
  }

  static isAsaas(tenantId: string): boolean {
    return tenantId === ASAAS_TENANT_ID;
  }

  static isAlifeNino(tenantId: string): boolean {
    return tenantId === ALIFE_TENANT_ID;
  }

  static isIRCE(tenantId: string): boolean {
    return tenantId === IRCE_TENANT_ID;
  }

  public async uploadFileImport(originType: PeopleOriginType | undefined, file: File): Promise<string> {
    if (!originType) {
      console.error('Invalid originType to process import file.');
      return '';
    }

    const metadata: ImportEmployeesFileMetadata = {
      contentType: file.type,
      filename: file.name,
      description: `Importing file for ${originType}`
    };
    const importFileUploadRepository = new ImportFileUploadRepository(originType);
    const { uploadURL, filename } = await importFileUploadRepository.create(metadata);

    if (!uploadURL || !filename) {
      throw new Error('Erro ao tentar salvar o arquivo. Sem permissão para enviar o arquivo.');
    }

    try {
      await this.uploadFileToS3(uploadURL, file, metadata);
    } catch (error) {
      throw new Error('Erro ao salvar o arquivo. Entre em contato com o suporte para verificar suas configurações.');
    }

    return filename;
  }

  public async processFileImport(originType: PeopleOriginType | undefined, filename: string): Promise<ImportFilenameMetadata> {
    const metadata: ImportFilenameMetadata = {
      s3FileName: filename
    };

    if (!originType) {
      throw new Error('Integração por arquivo não configurada. Entre em contato com o suporte para habilitar essa funcionalidade.');
    }

    if (![PeopleOriginType.Dominio, PeopleOriginType.ADP, PeopleOriginType.File].includes(originType)) {
      throw new Error(`Integração por arquivo com ${originType} não está habilitada. Entre em contato com o suporte para habilitar essa funcionalidade.`);
    }

    const importEmployeeFileRepository = new ImportEmployeeFileRepository(originType);
    return await importEmployeeFileRepository.create(metadata);
  }

  private async uploadFileToS3(s3PutObjectUrl: string, file: Blob, metadata: ImportEmployeesFileMetadata): Promise<AxiosResponse> {
    return axios.put(s3PutObjectUrl, file, {
      headers: {
        'Content-Type': metadata.contentType
      }
    });
  }
}

export default CompanyService;
