import HttpRepository from '../../../services/HttpRepository/HttpRepository';
import { LogsIntegration } from './LogsIntegrationInboundRepository';

class LogsIntegrationOutboundRepository extends HttpRepository<LogsIntegration> {
  constructor() {
    super({ basePath: '/reports/logs/outbound' });
  }
}

export default LogsIntegrationOutboundRepository;
