/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { withRouter, useParams } from 'react-router-dom';

import styles from './Awareness.module.css';

import HowCommonIsImage from '../../Ilustrations/how-common-is.svg';

import AwarenessService from '../../../services/AwarenessService';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Card } from '@rmwc/card';
import '@material/card/dist/mdc.card.css';

import { Theme } from '@rmwc/theme';
import { Typography } from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

import { Elevation } from '@rmwc/elevation';
import '@material/elevation/dist/mdc.elevation.css';

import { Icon } from '@rmwc/icon';
import '@rmwc/icon/icon.css';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import WelcomeSection from '../../WelcomeSection';

function AwarenessStart({ history }: InferProps<typeof AwarenessStart.propTypes>): JSX.Element {
  let { slug } = useParams() as { slug: string };

  slug = 'deaf'; // TODO: Remove when implemented the others

  const title = AwarenessService.getTitle(slug);
  const overview = AwarenessService.getOverview(slug);
  const howCommonIs = AwarenessService.getHowCommon(slug);
  const levels = AwarenessService.getLevelsList(slug);

  function colorByLevelIndex(index: number): string {
    switch (index) {
      case 0:
        return 'var(--mdc-theme-primary)';
      case 1:
        return 'var(--mdc-theme-secondary)';
      case 2:
        return 'var(--mdc-theme-pink)';
      case 3:
        return 'var(--mdc-theme-dark-pink)';
      default:
        return 'var(--mdc-theme-primary)';
    }
  }

  return (
    <div className="AwarenessStart">
      <WelcomeSection>
        <AwarenessStartButton slug={slug} history={history} />
      </WelcomeSection>
      <Grid>
        <GridCell desktop={8} phone={12}>
          <Card className={styles.overviewCard}>
            <h3>
              <Theme use="primary">
                <Typography use="headline4" className={styles.overviewTitles}>
                  <strong>{title}</strong>
                </Typography>
              </Theme>
            </h3>
            <p>
              <Typography use="body1" dangerouslySetInnerHTML={{ __html: overview }}></Typography>
            </p>
            <Grid>
              {levels &&
                levels.map((level: any, index: number) => {
                  const levelIconStyle = { background: colorByLevelIndex(index) };
                  return (
                    <GridCell desktop={3} phone={12} key={index}>
                      <Elevation z={1} className={styles.levelCard}>
                        {level.description}
                      </Elevation>
                      <div className={styles.levelCardTitle}>
                        {level.title}
                        <Icon icon={<div style={levelIconStyle} className={styles.levelCardTitleIcon} />} />
                      </div>
                    </GridCell>
                  );
                })}
            </Grid>
            <Grid>
              {levels &&
                levels.map((level: any, index: number) => {
                  const levelStyle = { background: colorByLevelIndex(index) };
                  return (
                    <GridCell desktop={3} phone={12} key={index}>
                      <small>{level.subtitle}</small>
                      <div className={styles.levelLine} style={levelStyle}></div>
                    </GridCell>
                  );
                })}
            </Grid>
            <p style={{ textAlign: 'center' }}>
              <AwarenessStartButton slug={slug} history={history} />
            </p>
          </Card>
        </GridCell>
        <GridCell desktop={4} phone={12}>
          <Card className={styles.howCommonCard}>
            <h3>
              <Typography use="headline4" className={styles.overviewTitles}>
                <strong>
                  How <br /> common <br /> is
                </strong>
              </Typography>
            </h3>
            <p>
              <Typography use="body1" dangerouslySetInnerHTML={{ __html: howCommonIs }}></Typography>
            </p>
            <img src={HowCommonIsImage} alt="How common is illustration" className={styles.howCommonImage} />
          </Card>
        </GridCell>
      </Grid>
    </div>
  );
}

function AwarenessStartButton({ slug, history }: InferProps<typeof AwarenessStartButton.propTypes>): JSX.Element {
  function handleAnswerOnClick(): void {
    if (history) history.push('/awareness/' + slug);
  }

  return (
    <Button raised onClick={handleAnswerOnClick}>
      Continue
    </Button>
  );
}

AwarenessStartButton.propTypes = {
  slug: PropTypes.string.isRequired,
  history: PropTypes.any
};

AwarenessStart.propTypes = {
  history: PropTypes.any
};

export default withRouter(AwarenessStart);
