import { InternalAxiosRequestConfig } from 'axios';
import HttpClient from './HttpClient';
import authService from '../AuthService';

abstract class HttpClientProtected<T> extends HttpClient<T> {
  public constructor({ baseURL, basePath }: { baseURL: string; basePath: string }) {
    super({ baseURL, basePath });
    this.initializeRequestInterceptor();
  }

  private initializeRequestInterceptor(): void {
    this.instance.interceptors.request.use(this.handleRequest, this.handleError);
  }

  private async handleRequest(config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> {
    const accessToken = await authService.jwtToken();
    config.headers['Authorization'] = `Bearer ${accessToken}`;

    return config;
  }
}

export default HttpClientProtected;
