import React from 'react';
import PropTypes from 'prop-types';

import styles from './StepJourneyPreview.module.css';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Dialog, DialogTitle, DialogContent, DialogActions, DialogButton } from '@rmwc/dialog';
import '@rmwc/dialog/styles';

import { Typography } from '@rmwc/typography';
import '@rmwc/typography/styles';

import '@material/button/dist/mdc.button.css';

import '@rmwc/circular-progress/circular-progress.css';

import '@rmwc/icon/styles';
import HtmlSanitizer from '../common/HtmlSanitizer';
import ErrorMessage from '../ErrorMessage';
import LoadingModal from '../LoadingModal';
import EditorService from './EditorService';
import { TextAnalysis } from './EditorRepository';
import {
  CollapsibleList,
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableContent,
  DataTableHead,
  DataTableHeadCell,
  DataTableRow,
  Elevation,
  SimpleListItem,
  Theme,
  ThemeProvider
} from 'rmwc';
import { StepJourneyChannelImage } from './StepJourneyMessagePreview';
import { StepJourneyChannel } from './StepJourneyRepository';

function buildTitle(readScore: number | undefined): string {
  if (readScore === undefined) {
    return 'Nível de leitura do seu texto.';
  }

  if (readScore >= 80) {
    return 'Seu texto está muito fácil.';
  }

  if (readScore >= 60) {
    return 'Seu texto está fácil.';
  }

  if (readScore >= 40) {
    return 'Seu texto está mediano.';
  }

  if (readScore >= 20) {
    return 'Seu texto está difícil.';
  }

  return 'Seu texto está muito difícil.';
}

function buildReadLevel(readScore: number | undefined): string {
  if (readScore === undefined) {
    return '-';
  }

  if (readScore >= 80) {
    return 'pessoas com ensino do 1° ao 5° ano.';
  }

  if (readScore >= 60) {
    return 'pessoas com ensino do 6° ao 9° ano.';
  }

  if (readScore >= 40) {
    return 'pessoas com ensino médio.';
  }

  if (readScore >= 20) {
    return 'pessoas com ensino médio ou técnico.';
  }

  return 'pessoas com ensino superior.';
}

const formatReadTime = (seconds: number | undefined) => {
  if (seconds === undefined) {
    return '-';
  }

  if (seconds >= 60) {
    return `${Math.trunc(seconds / 60)} minutos e ${seconds % 60} segundos`;
  }

  return `${seconds} segundos`;
};

function TextAnalysisValidation({ title, children }: { title: string; children: JSX.Element }): JSX.Element {
  return (
    <div style={{ paddingBottom: '3px' }}>
      <Elevation z={2}>
        <CollapsibleList handle={<SimpleListItem text={title} metaIcon="chevron_right" />}>
          <div style={{ margin: '10px' }}>{children}</div>
        </CollapsibleList>
      </Elevation>
    </div>
  );
}

interface CompareTerm {
  wrong: string | JSX.Element;
  correct: string | JSX.Element;
}

function TableCompareTerms({ title, terms }: { title: string; terms: CompareTerm[] }): JSX.Element {
  return (
    <div style={{ paddingTop: '15px', display: 'flex', flexDirection: 'column' }}>
      <Typography use="body1" style={{ paddingBottom: '10px' }}>
        {title}
      </Typography>
      <DataTable>
        <DataTableContent>
          <DataTableHead>
            <DataTableRow>
              <DataTableHeadCell alignStart>
                <Typography use="subtitle1">Faça assim. ✅</Typography>
              </DataTableHeadCell>
              <DataTableHeadCell style={{ borderLeft: '1px solid rgb(224, 224, 224)' }} alignStart>
                <Typography use="subtitle1">Evite fazer. ❌</Typography>
              </DataTableHeadCell>
            </DataTableRow>
          </DataTableHead>
          <DataTableBody>
            {terms.map((term: CompareTerm, index: number) => (
              <DataTableRow key={index}>
                <DataTableCell alignStart>{term.correct}</DataTableCell>
                <DataTableCell style={{ borderLeft: '1px solid rgb(224, 224, 224)' }} alignStart>
                  {term.wrong}
                </DataTableCell>
              </DataTableRow>
            ))}
          </DataTableBody>
        </DataTableContent>
      </DataTable>
    </div>
  );
}

function TextAnalysisStartsWithLowercase({ contents }: { contents: string[] }): JSX.Element {
  const terms = [
    {
      wrong: 'convite pra reunião.',
      correct: 'Convite pra reunião.'
    }
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Comece frases com letra maiúscula. ">
      <div>
        <Typography use="body1">
          Você usou {wordsOrderedLength.length > 1 ? 'as frases' : 'a frase'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography use="body1">
            Começar o texto com letra minúscula é ruim pra quem usa leitor de tela, como pessoas cegas ou com baixa visão. Também não é bom pra pessoas neurodivergentes.
          </Typography>
        </div>
        <TableCompareTerms terms={terms} title="Exemplos de como iniciar uma frase:" />
      </div>
    </TextAnalysisValidation>
  );
}

function TextAnalysisComplexWords({ contents }: { contents: string[] }): JSX.Element {
  const terms = [
    {
      wrong: 'Consequentemente o treinamento será adiado.',
      correct: 'Por esse motivo o curso vai ser adiado.'
    }
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Palavras longas podem ser difíceis de entender.">
      <>
        <Typography use="body1">
          Você usou {wordsOrderedLength.length > 1 ? 'as palavras' : 'a palavra'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography use="body1">Palavras longas deixam o texto mais difícil. Escolha palavras mais simples e casuais para melhorar o nível de leitura do texto.</Typography>
        </div>
        <TableCompareTerms terms={terms} title="Exemplos sobre palavras difíceis:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisBold({ contents }: { contents: string[] }): JSX.Element {
  const terms = [
    {
      wrong: (
        <>
          <b>Complete</b> o treinamento de <b>segurança</b> até <b>amanhã.</b>
        </>
      ),
      correct: (
        <>
          Complete o treinamento de segurança <b>até amanhã</b>.
        </>
      )
    }
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);
  return (
    <TextAnalysisValidation title="Use negrito, mas sem excesso.">
      <>
        <Typography use="body1">
          Você usou {wordsOrderedLength.length > 1 ? 'as palavras em negrito' : 'a palavra em negrito'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography use="body1">
            Usar o negrito ajuda a destacar palavras importantes, mas se você usar em excesso o efeito é contrário. Além disso, esse uso torna o texto confuso pra pessoas com
            dislexia.
          </Typography>
        </div>
        <TableCompareTerms terms={terms} title="Exemplos de como usar o negrito:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisEmojis({ contents }: { contents: string[] }): JSX.Element {
  const terms = [
    {
      wrong: 'Eba! 🤩 Seu primeiro dia é amanhã',
      correct: 'Eba! Seu primeiro dia é amanhã. 🤩'
    }
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Use emojis apenas no fim da frase! 😉">
      <>
        <Typography use="body1">
          Você usou {wordsOrderedLength.length > 1 ? 'os emojis' : 'o emoji'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography use="body1">
            O conteúdo fica confuso pra pessoas cegas quando você coloca um emoji no meio da frase. Isso acontece porque o leitor de tela lê o texto alternativo do emoji.
          </Typography>
        </div>
        <TableCompareTerms terms={terms} title="Exemplos de como usar emojis:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisUppercase({ contents }: { contents: string[] }): JSX.Element {
  const terms = [
    {
      wrong: 'BAIXE O MATERIAL AQUI',
      correct: 'Baixe o material aqui'
    }
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Evite escrever frases ou palavras todas em maiúsculo.">
      <>
        <Typography use="body1">
          Você usou {wordsOrderedLength.length > 1 ? 'as palavras' : 'a palavra'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography use="body1">Isso prejudica a leitura de pessoas com dislexia. O melhor é deixar apenas a letra inicial em maiúsculo, menos no caso de siglas.</Typography>
        </div>
        <TableCompareTerms terms={terms} title="Exemplos de como usar letras maiúsculas:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisSentencesWithoutPronouns({ contents }: { contents: string[] }): JSX.Element {
  const terms = [
    {
      wrong: 'Resolva as pendências ainda hoje.',
      correct: 'Líder, você precisa resolver suas pendências hoje.'
    }
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Use pronome no texto.">
      <>
        <Typography use="body1">
          Você usou {wordsOrderedLength.length > 1 ? 'as sentenças sem pronome' : 'a sentença sem pronome'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography use="body1">
            É muito importante deixar claro com quem você está falando porque pessoas surdas podem ter dificuldade com textos que não usam a palavra “você”.
          </Typography>
        </div>
        <TableCompareTerms terms={terms} title="Exemplos sobre uso do pronome:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisItalic({ contents }: { contents: string[] }): JSX.Element {
  const terms = [
    {
      wrong: <i>Leia esse material antes de começar.</i>,
      correct: 'É importante ler esse material antes de começar.'
    }
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Evite usar itálico.">
      <>
        <Typography use="body1">
          Você usou {wordsOrderedLength.length > 1 ? 'as palavras em itálico' : 'a palavra em itálico'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography use="body1">Usar itálico nos textos prejudica o entendimento de pessoas com dislexia.</Typography>
        </div>
        <TableCompareTerms terms={terms} title="Exemplos sobre uso do itálico:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisUnderlined({ contents }: { contents: string[] }): JSX.Element {
  const terms = [
    {
      wrong: <u>Tenha atenção com as normas de segurança.</u>,
      correct: 'Tenha atenção com as normas de segurança.'
    }
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Evite sublinhar toda a frase.">
      <>
        <Typography use="body1">
          Você usou sublinhado {wordsOrderedLength.length > 1 ? 'nas palavras' : 'na palavra'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography use="body1">
            Sublinhar textos prejudica o entendimento de pessoas com dislexia. O uso é indicado apenas em hiperlinks (links dentro de palavras ou frases).{' '}
          </Typography>
        </div>
        <TableCompareTerms terms={terms} title="Exemplos sobre o uso do sublinhado:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisEmoticon({ contents }: { contents: string[] }): JSX.Element {
  const terms = [
    {
      wrong: 'Parabéns, você foi a pessoa aprovada. :)',
      correct: 'Parabéns, você foi a pessoa aprovada. 🙂'
    }
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Use emojis “🙂” no lugar de emoticons “:)”.">
      <>
        <Typography use="body1">
          Você usou {wordsOrderedLength.length > 1 ? 'os emoticons' : 'o emoticon'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography use="body1">
            Diferente dos emoticons, todo emoji tem um texto alternativo que pode ser lido pelo leitor de tela, o que ajuda pessoas cegas a entenderem o conteúdo.
          </Typography>
        </div>
        <TableCompareTerms terms={terms} title="Exemplos sobre uso de emoticons:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisLongSentences({ contents }: { contents: string[] }): JSX.Element {
  const terms = [
    {
      wrong: 'Seu primeiro dia na empresa será divulgado em breve para que você possa começar na empresa da melhor forma possível.',
      correct: 'Vamos divulgar a data do seu início na empresa em breve.'
    }
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Escreva frases curtas.">
      <>
        <Typography use="body1">
          Você usou {wordsOrderedLength.length > 1 ? 'as sentenças' : 'a sentença'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography use="body1">
            Textos muito longos prejudicam o entendimento de pessoas surdas, autistas, disléxicas e com baixa escolaridade. Busque escrever frases simples e curtas.
          </Typography>
        </div>
        <TableCompareTerms terms={terms} title="Exemplos de como escrever frases curtas:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisIdiomaticExpressions({ contents }: { contents: string[] }): JSX.Element {
  const terms = [
    {
      wrong: 'Comece o seu dia com o pé direito.',
      correct: 'Comece o seu dia bem.'
    }
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Cuidado com expressões idiomáticas. ">
      <>
        <Typography use="body1">
          Você usou {wordsOrderedLength.length > 1 ? 'as expressões idiomáticas' : 'a expressão idiomática'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography use="body1">
            Expressões como ‘começar com o pé direito’ afetam a acessibilidade do seu conteúdo. Pessoas surdas, autistas e de outros grupos entendem essas expressões de forma
            literal. Se você diz que é pra começar com o pé direito, elas podem entender que precisam andar iniciando com esse pé.
          </Typography>
        </div>
        <TableCompareTerms terms={terms} title="Exemplos sobre expressões idiomáticas:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisCapacitism({ contents }: { contents: string[] }): JSX.Element {
  const terms = [
    {
      wrong: 'Ele é um joão sem braço.',
      correct: 'Ele sempre foge das obrigações.'
    }
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Evite palavras capacitistas.">
      <>
        <Typography use="body1">
          Você usou {wordsOrderedLength.length > 1 ? 'as expressões capacitistas' : 'a expressão capacitista'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography use="body1">
            Falar que alguém é mudinho, que é um ‘joão sem braço’ ou que está ‘cego de raiva’ são exemplos que devem ser evitados pra tornar seu conteúdo mais inclusivo.
          </Typography>
        </div>
        <TableCompareTerms terms={terms} title="Exemplos sobre palavras capacitistas:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisRacism({ contents }: { contents: string[] }): JSX.Element {
  const terms = [
    {
      wrong: 'Ele quer denegrir meu trabalho.',
      correct: 'Ele quer prejudicar meu trabalho.'
    }
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Atenção com palavras racistas.">
      <>
        <Typography use="body1">
          Você usou {wordsOrderedLength.length > 1 ? 'as expressões racistas' : 'a expressão racista'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography use="body1">
            O racismo está em tudo, inclusive na nossa fala. Repensar as palavras que você usa é importante pra tornar seu conteúdo mais inclusivo. Usar ‘inveja branca’ pra se
            referir a algo positivo e usar denegrir como algo negativo são exemplos de expressões racistas.
          </Typography>
        </div>
        <TableCompareTerms terms={terms} title="Exemplos sobre palavras racistas:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisSymbolNeutralGender({ contents }: { contents: string[] }): JSX.Element {
  const terms = [
    {
      wrong: 'Bem-vind@. Vamos conversar com todes!',
      correct: 'Que bom ter você aqui. Vamos conversar com todo mundo.'
    }
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Evite neutralizar o texto com ‘@’ ‘x’ ou ‘e’.">
      <>
        <Typography use="body1">
          Você usou {wordsOrderedLength.length > 1 ? 'as palavras' : 'a palavra'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography use="body1">
            Escrever sem flexionar gênero torna o conteúdo mais inclusivo pra pessoas diversas, mas é importante ser acessível também. Quando usamos ‘@’ ou outros elementos
            estranhos prejudicamos a experiência de pessoas que usam leitor de tela, pessoas neurodivergentes e outras. O que você pode fazer é explorar o português pra neutralizar
            o texto.
          </Typography>
        </div>
        <TableCompareTerms terms={terms} title="Exemplos sobre texto neutro e acessível:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisNeutralGenderLanguage({ contents }: { contents: string[] }): JSX.Element {
  const terms = [
    {
      wrong: 'Os chefes são tranquilos.',
      correct: 'A chefia é tranquila.'
    }
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Tente escrever de forma neutra">
      <>
        <Typography use="body1">
          Você usou {wordsOrderedLength.length > 1 ? 'as palavras' : 'a palavra'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography use="body1">
            Flexionar sempre o gênero no texto não é inclusivo. Existem algumas formas de escrever sem demarcar o gênero masculino ou feminino. Claro que nem sempre é possível
            tornar o texto total neutro, mas com os recursos do português isso fica mais fácil.
          </Typography>
        </div>
        <TableCompareTerms terms={terms} title="Exemplos sobre texto neutro e acessível:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisLinkWithoutContext({ contents }: { contents: string[] }): JSX.Element {
  const linksTerms = [
    {
      wrong: 'Clique aqui.',
      correct: 'Clique aqui para conferir o manual.'
    }
  ];

  const butonsTerms = [
    {
      wrong: 'Saber mais',
      correct: 'Conferir manual'
    }
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Evite escrever botões e links como 'clique aqui' e 'saiba mais'.">
      <>
        <Typography use="body1">
          Você usou {wordsOrderedLength.length > 1 ? 'os links' : 'o link'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography use="body1">
            Pessoas que usam leitor de tela são prejudicadas quando você escreve botões genéricos como &apos;saiba mais&apos;. Ao invés disso, você pode falar sobre o que acontece
            se ela clicar no link ou botão.
          </Typography>
        </div>
        <TableCompareTerms terms={linksTerms} title="Exemplos sobre como escrever links:" />
        <Typography use="body1">No caso de botões, a aparência já é clicável, então não precisa usar o &apos;clique aqui&apos;.</Typography>
        <TableCompareTerms terms={butonsTerms} title="Exemplos sobre como escrever botões:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisFutureTenseVerbs({ contents }: { contents: string[] }): JSX.Element {
  const linksTerms = [
    {
      wrong: 'Você ganhará pontos.',
      correct: 'Você vai ganhar pontos.'
    }
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Tenha atenção ao conjugar verbos no futuro.">
      <>
        <Typography use="body1">
          Você usou {wordsOrderedLength.length > 1 ? 'os verbos no futuro' : 'o verbo no futuro'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography use="body1">
            Na Libras não se conjuga verbos como no português. Nem para o futuro e nem para o passado. Para tornar seu texto mais simples e claro para pessoas surdas, você pode
            adicionar &apos;vai&apos; ou &apos;foi&apos; que são verbos mais simples e que se assemelham a uma tradução livre.
          </Typography>
        </div>
        <TableCompareTerms terms={linksTerms} title="Exemplos de como escrever frases sem verbos no futuro:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisResults({ textResult }: { textResult: TextAnalysis | undefined }): JSX.Element {
  if (!textResult) {
    return <p></p>;
  }

  if (textResult.error) {
    return <p>{textResult.error}</p>;
  }

  if (!textResult.accessibility?.length) {
    return (
      <div style={{ marginTop: '-50px' }}>
        <p>
          <strong>Nenhum item de acessibilidade para ser revisado! 😎</strong>
        </p>
      </div>
    );
  }

  return (
    <>
      <div style={{ marginTop: '-25px', paddingBottom: '10px' }}>
        <Typography use="subtitle1">
          <strong>Lista de validações de acessibilidade para revisar:</strong>
        </Typography>
      </div>
      {textResult.accessibility.map((item) => {
        switch (item.name) {
          case 'complexWords':
            return <TextAnalysisComplexWords contents={item.contents} />;
          case 'bold':
            return <TextAnalysisBold contents={item.contents} />;
          case 'emojis':
            return <TextAnalysisEmojis contents={item.contents} />;
          case 'uppercase':
            return <TextAnalysisUppercase contents={item.contents} />;
          case 'startsWithLowercase':
            return <TextAnalysisStartsWithLowercase contents={item.contents} />;
          case 'sentencesWithoutPronouns':
            return <TextAnalysisSentencesWithoutPronouns contents={item.contents} />;
          case 'italic':
            return <TextAnalysisItalic contents={item.contents} />;
          case 'underlined':
            return <TextAnalysisUnderlined contents={item.contents} />;
          case 'emoticon':
            return <TextAnalysisEmoticon contents={item.contents} />;
          case 'longSentences':
            return <TextAnalysisLongSentences contents={item.contents} />;
          case 'idiomaticExpressions':
            return <TextAnalysisIdiomaticExpressions contents={item.contents} />;
          case 'capacitism':
            return <TextAnalysisCapacitism contents={item.contents} />;
          case 'racism':
            return <TextAnalysisRacism contents={item.contents} />;
          case 'symbolNeutralGender':
            return <TextAnalysisSymbolNeutralGender contents={item.contents} />;
          case 'neutralGenderLanguage':
            return <TextAnalysisNeutralGenderLanguage contents={item.contents} />;
          case 'linksWithoutContext':
            return <TextAnalysisLinkWithoutContext contents={item.contents} />;
          case 'futureTenseVerbs':
            return <TextAnalysisFutureTenseVerbs contents={item.contents} />;
        }
      })}
    </>
  );
}

function EditorPopup({ messageHtml, messageChannel, onClose }: { messageHtml: string; messageChannel?: string | undefined; onClose: () => void }): JSX.Element {
  if (!messageHtml) {
    messageHtml = '<p></p>';
  }

  if (!messageChannel) {
    messageChannel = StepJourneyChannel.Email;
  }

  const mentionsFind = /<span [^<>]*\bdata-mention-atom-ids*=s*"([^"]*)[^"]*"[^<>]*>([^<>]+)<\/span>/gim;
  const replacedMessageWithoutMentions = messageHtml.replaceAll(mentionsFind, 'Campo');

  const editorService = new EditorService();
  const usePostText = editorService.usePost();

  const [textAnalysis, setTextAnalysis] = React.useState<TextAnalysis>();
  const [open, setOpen] = React.useState(true);

  const loadTextAnalysis = React.useCallback(async (): Promise<void> => {
    if (textAnalysis) {
      return;
    }
    const textAnalysisResult = await usePostText.mutateAsync(replacedMessageWithoutMentions);
    setTextAnalysis(textAnalysisResult);
  }, [textAnalysis]);

  React.useEffect(() => {
    loadTextAnalysis();
  }, [messageHtml]);

  if (usePostText.isError) {
    return <ErrorMessage error={usePostText.error}></ErrorMessage>;
  }

  if (usePostText.isLoading || !textAnalysis) {
    return <LoadingModal open={true} />;
  }

  function onCloseDialog() {
    setOpen(false);
    onClose && onClose();
    return true;
  }

  return (
    <ThemeProvider
      options={{
        primary: 'black'
      }}
    >
      <Dialog open={open} onClosed={onCloseDialog} className={styles.textAnalysisPopup}>
        <DialogTitle>
          <Typography use="headline6">{buildTitle(textAnalysis?.readability?.score)}</Typography>
        </DialogTitle>
        <DialogContent>
          <Theme use="primary">
            <Grid>
              <GridCell span={8}>
                <DataTable style={{ width: '100%', minHeight: '100%' }}>
                  <DataTableContent style={{ width: '100%' }}>
                    <DataTableHead>
                      <DataTableRow>
                        <DataTableHeadCell style={{ backgroundColor: 'whitesmoke', padding: '0 10px', height: '35px' }}>
                          <StepJourneyChannelImage channel={messageChannel} />
                        </DataTableHeadCell>
                      </DataTableRow>
                    </DataTableHead>
                    <DataTableBody>
                      <DataTableRow>
                        <DataTableCell>
                          <div
                            style={{ padding: '15px 0px', whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
                            dangerouslySetInnerHTML={{ __html: HtmlSanitizer.sanitize(messageHtml) }}
                          />
                        </DataTableCell>
                      </DataTableRow>
                    </DataTableBody>
                  </DataTableContent>
                </DataTable>
              </GridCell>
              <GridCell span={4} style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography use="headline5">{buildTitle(textAnalysis?.readability?.score)}</Typography>
                <Typography use="headline6">
                  Nota do texto: {textAnalysis?.readability?.score !== undefined && textAnalysis?.readability?.score >= 0 ? textAnalysis?.readability?.score.toFixed(0) : '-'} / 100
                </Typography>
                <Typography use="body1">
                  Nível de leitura: <strong>{buildReadLevel(textAnalysis?.readability?.score)}</strong>
                </Typography>
                <Typography use="body1">
                  Número de caracteres: <strong>{!textAnalysis?.readability?.totalCharacters ? '-' : textAnalysis.readability.totalCharacters}</strong>
                </Typography>
                <Typography use="body1">
                  Número de palavras: <strong>{!textAnalysis?.readability?.totalWords ? '-' : textAnalysis.readability.totalWords}</strong>
                </Typography>
                <Typography use="body1">
                  Número de frases: <strong>{!textAnalysis?.readability?.totalSentences ? '-' : textAnalysis.readability.totalSentences}</strong>
                </Typography>
                <Typography use="body1">
                  Tempo de leitura: <strong>{formatReadTime(textAnalysis?.readability?.readingTime)}</strong>
                </Typography>
              </GridCell>
            </Grid>
            <Grid>
              <GridCell span={8}>
                <div style={{ paddingBottom: '5px' }}>
                  <TextAnalysisResults textResult={textAnalysis} />
                </div>
              </GridCell>
            </Grid>
          </Theme>
        </DialogContent>
        <DialogActions>
          <DialogButton action="close" style={{ color: '#009688', marginRight: '10px' }}>
            Fechar
          </DialogButton>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

EditorPopup.propTypes = {
  step: PropTypes.object,
  onClose: PropTypes.func
};

export default EditorPopup;
