import React from 'react';
import PropTypes from 'prop-types';

import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import PhoneNumber from 'awesome-phonenumber';
import useCopy from 'use-copy';

import { Employee, PeopleIntegrationUsers, PeopleIntegrationUser, PeopleIntegrationUserStatus, DismissedReasonType } from './EmployeeRepository';

import { ListDivider } from '@rmwc/list';
import { Grid, GridRow, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { Typography } from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

import { Elevation } from '@rmwc/elevation';
import '@material/elevation/dist/mdc.elevation.css';

import { Avatar } from '@rmwc/avatar';
import '@rmwc/avatar/avatar.css';

import { IconButton } from '@rmwc/icon-button';
import '@rmwc/icon-button/styles';

import { Icon } from '@rmwc/icon';
import '@rmwc/icon/styles';

import { DataTable, DataTableContent, DataTableBody, DataTableRow, DataTableCell } from '@rmwc/data-table';
import '@rmwc/data-table/styles';

import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/circular-progress.css';

import { Chip } from '@rmwc/chip';
import '@rmwc/chip/styles';

import { Badge } from '@rmwc/badge';
import '@rmwc/badge/badge.css';

import { Tooltip } from '@rmwc/tooltip';
import '@rmwc/tooltip/styles';

import ConveniaLogo from '../Settings/IntegrationSettings/Icons/convenia-symbol.png';
import SolidesLogo from '../Settings/IntegrationSettings/Icons/solides.png';
import GupyLogo from '../Settings/IntegrationSettings/Icons/gupy-symbol.svg';
import SeniorLogo from '../Settings/IntegrationSettings/Icons/senior-symbol.png';
import ADPLogo from '../Settings/IntegrationSettings/Icons/adp.png';
import FeedzLogo from '../Settings/IntegrationSettings/Icons/feedz.png';
import KeepsLogo from '../Settings/IntegrationSettings/Icons/keeps.png';
import DominioLogo from '../Settings/IntegrationSettings/Icons/dominio-thomson-reuters.png';
import InstagramIcon from '../common/SocialMediaIcons/instagram.svg';
import LinkedinIcon from '../common/SocialMediaIcons/linkedin.svg';
import SalvyLogo from '../Settings/IntegrationSettings/Icons/salvy.png';

// TODO: Check other libraries to replace ReactCrop, like: https://github.com/transloadit/uppy
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop, convertToPixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import EmployeeService from './EmployeeService';
import Message from '../../services/Message';
import EmployeeStartJourneyDialog from './EmployeeStartJourneyDialog';
import { IntegrationName } from '../Settings/IntegrationSettings/IntegrationsRepository';

const cardStyle = {
  padding: '15px',
  backgroundColor: '#fff',
  borderRadius: '.3rem'
} as React.CSSProperties;

const cellWordWrap = {
  textOverflow: 'unset',
  wordWrap: 'break-word',
  overflowWrap: 'break-word',
  whiteSpace: 'normal'
} as React.CSSProperties;

const avatarStyle = {
  backgroundColor: 'var(--mdc-theme-secondary)',
  fontSize: '4rem'
} as React.CSSProperties;

function EmployeeViewHome({ employee, onGoBack }: { employee: Employee; onGoBack: () => void }): JSX.Element {
  const { id } = useParams() as { id: string };
  const [openStartJourney, setOpenStartJourney] = React.useState(false);

  return (
    <>
      <Grid>
        <GridCell span={12} style={{ textAlign: 'right' }}>
          <Button type="button" onClick={(): void => onGoBack()}>
            <Trans i18nKey="crud.return">Go back</Trans>
          </Button>
          <Button outlined icon="edit" tag={Link} {...{ to: `/employees/${id}/edit` }} style={{ marginLeft: '1.5rem' }}>
            <Trans i18nKey="employee.edit">Edit</Trans>
          </Button>
          <Button
            raised
            icon="auto_graph"
            onClick={(): void => {
              setOpenStartJourney(true);
            }}
            style={{ marginLeft: '1.5rem' }}
          >
            <Trans i18nKey="employee.journey-start">Start journey</Trans>
          </Button>
        </GridCell>
        <GridCell span={12}>
          <EmployeeDetails employee={employee}></EmployeeDetails>
        </GridCell>
      </Grid>
      {openStartJourney ? <EmployeeStartJourneyDialog employee={employee} onClose={(): void => setOpenStartJourney(false)} /> : null}
    </>
  );
}

function buildEmployeeShortName(employee: Employee): string {
  return employee.short_name || employee.social_name || employee.first_name;
}

// Profile photo

const TO_RADIANS = Math.PI / 180;

async function canvasPreview(image: HTMLImageElement, canvas: HTMLCanvasElement, crop: PixelCrop, scale = 1, rotate = 0): Promise<void> {
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be true to the images natural size.
  const pixelRatio = window.devicePixelRatio;
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 3) Rotate around the origin
  ctx.rotate(rotateRads);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight);

  ctx.restore();
}

// eslint-disable-next-line @typescript-eslint/ban-types
function useDebounceEffect(fn: Function, waitTime: number, deps: unknown[]): void {
  React.useEffect(() => {
    const time = setTimeout(() => {
      fn(...deps);
    }, waitTime);
    return (): void => {
      clearTimeout(time);
    };
  }, deps);
}

// This is to demonstate how to make and center a % aspect crop. which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number): Crop {
  return centerCrop(makeAspectCrop({ unit: '%', width: 100 }, aspect, mediaWidth, mediaHeight), mediaWidth, mediaHeight);
}

function EmployeeAvatarImageAndName({ employee }: { employee: Employee }): JSX.Element {
  const employeeService = new EmployeeService();
  const { t } = useTranslation();
  const [isSavingImg, setIsSavingImg] = React.useState<boolean>(false);
  const [photoImgSrc, setPhotoImgSrc] = React.useState<string>('');
  const [photoCrop, setPhotoCrop] = React.useState<Crop>();
  const [completedCrop, setCompletedCrop] = React.useState<PixelCrop>();
  const photoPreviewCanvasRef = React.useRef<HTMLCanvasElement>(null);
  const photoImgRef = React.useRef<HTMLImageElement>(null);
  const aspect = 1; // square
  const scale = 1; // full size
  const rotate = 0;

  function onCancelPhotoUpdate(): void {
    setPhotoImgSrc('');
    setCompletedCrop(undefined);
    setIsSavingImg(false);
  }

  function onSelectPhotoFile(e: React.ChangeEvent<HTMLInputElement>): void {
    if (e.target.files && e.target.files.length > 0) {
      setPhotoCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener('load', () => setPhotoImgSrc(reader?.result ? reader.result.toString() || '' : ''));
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>): void {
    const { width, height } = e.currentTarget;
    const initialCrop = centerAspectCrop(width, height, aspect);
    setPhotoCrop(initialCrop);
    setCompletedCrop(convertToPixelCrop(initialCrop, width, height));
  }

  async function photoDataURItoBlob(dataURI: string): Promise<Blob> {
    const blob = await (await fetch(dataURI)).blob();
    return blob;
  }

  async function onSavePhoto(): Promise<void> {
    setIsSavingImg(true);
    if (!photoPreviewCanvasRef?.current) {
      Message.error('Imagem inválida para ser enviada.');
      return;
    }
    const photoBlob = await photoDataURItoBlob(photoPreviewCanvasRef.current.toDataURL());
    await employeeService.uploadPhoto(employee, photoBlob);
    setTimeout((): void => {
      const photoSrc = photoPreviewCanvasRef?.current?.toDataURL('image/jpeg', 0.8);
      if (photoSrc) {
        employee.photo_url = photoSrc;
      }
      onCancelPhotoUpdate();
      Message.success(t('employee.view.picture.uploaded'));
    }, 2000);
  }

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && photoImgRef.current && photoPreviewCanvasRef.current) {
        canvasPreview(photoImgRef.current, photoPreviewCanvasRef.current, completedCrop, scale, rotate);
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const photoPreviewUrl = photoImgSrc && photoPreviewCanvasRef ? photoPreviewCanvasRef?.current?.toDataURL('image/jpeg', 0.8) || '' : employee.photo_url || '';

  return (
    <>
      <label htmlFor="photo_url_file" style={{ cursor: 'pointer' }}>
        <Avatar
          name={employee.first_name}
          size="xlarge"
          interactive
          style={avatarStyle}
          src={photoPreviewUrl}
          title={t('employee.view.picture.edit', 'Click here to upload a new image.')}
        />
        {!!employee.photo_url && (
          <IconButton
            icon={{ icon: 'open_in_new', size: 'xsmall' }}
            label={t('employee.view.picture.open', 'Click here to open the image in a new tab.')}
            tag="a"
            target="_blank"
            href={photoPreviewUrl}
            rel="noopener noreferrer"
            style={{
              padding: 0,
              marginLeft: '-15px',
              position: 'relative',
              top: '-10px',
              backgroundColor: 'rgba(255,255,255,0.4)',
              borderRadius: '50%',
              fontSize: '1.125rem',
              width: '1em',
              height: '1em'
            }}
          />
        )}

        <IconButton
          icon={{ icon: 'file_upload', size: 'xsmall' }}
          label={t('employee.view.picture.edit', 'Click here to upload a new image.')}
          tag="a"
          style={{
            padding: 0,
            marginLeft: '-19px',
            position: 'relative',
            top: '25px',
            backgroundColor: 'rgba(255,255,255,0.4)',
            borderRadius: '50%',
            fontSize: '1.125rem',
            width: '1em',
            height: '1em'
          }}
        />
        <span style={{ display: 'inline', marginLeft: '10px' }}>
          <Typography use="h3" tag="h3" style={{ display: 'inline' }}>
            {buildEmployeeShortName(employee)}
          </Typography>
        </span>
        <input type="file" id="photo_url_file" style={{ display: 'none' }} accept="image/*" onChange={onSelectPhotoFile} />
      </label>
      {Boolean(photoImgSrc) && (
        <div style={{ padding: '1rem' }}>
          <ReactCrop
            crop={photoCrop}
            onChange={(_, percentCrop): void => setPhotoCrop(percentCrop)}
            onComplete={(c): void => setCompletedCrop(c)}
            aspect={aspect}
            circularCrop={true}
          >
            <img
              ref={photoImgRef}
              alt={t('employee.view.picture.crop', 'Crop me')}
              src={photoImgSrc}
              style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
          <Button onClick={onCancelPhotoUpdate} disabled={!!isSavingImg}>
            <Trans i18nKey="employee.view.picture.cancel">Cancel</Trans>
          </Button>
          <Button unelevated disabled={!!isSavingImg} onClick={onSavePhoto} {...(isSavingImg ? { icon: <CircularProgress theme="secondary" /> } : {})}>
            <Trans i18nKey="employee.view.picture.save">Save</Trans>
          </Button>
        </div>
      )}
      <div>
        {Boolean(completedCrop) && (
          <canvas
            ref={photoPreviewCanvasRef}
            style={{
              display: 'none',
              objectFit: 'contain',
              width: completedCrop?.width,
              height: completedCrop?.height
            }}
            aria-hidden={true}
          />
        )}
      </div>
    </>
  );
}

function EmployeeDetails({ employee }: { employee: Employee }): JSX.Element {
  const { t } = useTranslation();

  if (!employee) return <> </>;

  return (
    <GridRow>
      <GridCell desktop={4} tablet={12} phone={12}>
        <Elevation z={2} style={cardStyle}>
          <GridRow>
            {employee.last_date ? (
              <GridCell span={12} align="middle" style={{ textAlign: 'center' }}>
                <Tooltip content={`${t('employee.view.last-day.label')}: ${formattedDate(employee.last_date)}`}>
                  <div>
                    <Badge label={t('employee.view.offboarding-tag', 'Offboarding')} align="inline" style={{ padding: '0 15px', textTransform: 'none' }} />
                  </div>
                </Tooltip>
              </GridCell>
            ) : null}
            <GridCell span={12} align="middle" style={{ textAlign: 'center' }}>
              <EmployeeAvatarImageAndName employee={employee} />
            </GridCell>
            <GridCell span={12} align="middle" style={{ textAlign: 'center' }}>
              <Typography use="body1" tag="p" style={{ margin: '0' }}>
                <strong>
                  {employee.social_name || employee.first_name} {employee.last_name}
                </strong>
                {!!employee.gender_pronouns && (
                  <Typography use="caption" tag="span" style={{ display: 'inline' }}>
                    {' '}
                    ({employee.gender_pronouns})
                  </Typography>
                )}
              </Typography>

              <Typography use="body1" tag="p" style={{ margin: '0' }}>
                {employee.job_title}
              </Typography>
              <Typography use="subtitle2" tag="p" style={{ margin: '0' }}>
                {employee.department}
              </Typography>
            </GridCell>
            <GridCell span={12}>
              <ListDivider />
            </GridCell>
            {employee.start_date && (
              <>
                <GridCell span={12} style={cellWordWrap}>
                  <Typography use="subtitle1" tag="p" style={{ margin: '5px 0' }}>
                    <strong>
                      <Trans i18nKey="employee.view.dates">Dates</Trans>
                    </strong>
                  </Typography>
                  {employee.last_date && (
                    <>
                      <Typography use="caption" tag="div" style={{ marginTop: '1em' }}>
                        <Trans i18nKey="employee.view.last-day.label">Last day</Trans>
                      </Typography>
                      <Typography use="subtitle1" tag="p" style={{ marginBottom: '0.4em', marginTop: '0' }}>
                        {formattedDate(employee.last_date)}
                      </Typography>
                    </>
                  )}
                  <Typography use="caption" tag="div" style={{ marginTop: '1em' }}>
                    <Trans i18nKey="employee.view.first-day.label">First day</Trans>
                  </Typography>
                  <Typography use="subtitle1" tag="p" style={{ marginBottom: '0.4em', marginTop: '0' }}>
                    {formattedDate(employee.start_date)}
                  </Typography>
                  {!employee.last_date && employee.experience_period_days && (
                    <>
                      <Typography use="caption" tag="div" style={{ marginTop: '1em' }}>
                        <Trans i18nKey="employee.view.probation-period.label">Probation period</Trans>
                      </Typography>
                      <Typography use="subtitle1" tag="p" style={{ marginBottom: '0.4em', marginTop: '0' }}>
                        {formattedDateAddProbation(employee.start_date, employee.experience_period_days)} (
                        <Trans i18nKey="employee.view.probation-period.days" values={{ days: employee.experience_period_days }}>
                          {{ days: '0' }} days
                        </Trans>
                        )
                      </Typography>
                    </>
                  )}
                </GridCell>
                <GridCell span={12}>
                  <ListDivider />
                </GridCell>
              </>
            )}
            {employee.last_date && employee.dismissed_reason ? (
              <GridCell span={12} style={cellWordWrap}>
                <Typography use="subtitle1" tag="p" style={{ margin: '5px 0' }}>
                  <strong>
                    <Trans i18nKey="employee.view.offboaring">Offboarding details</Trans>
                  </strong>
                </Typography>
                <Typography use="caption" tag="div" style={{ marginTop: '1em' }}>
                  <Trans i18nKey="employee.view.last-day.label">Last day</Trans>
                </Typography>
                <Typography use="subtitle1" tag="p" style={{ marginBottom: '0.4em', marginTop: '0' }}>
                  {formattedDate(employee.last_date)}
                </Typography>
                {employee.dismissed_reason && (
                  <>
                    <Typography use="caption" tag="div" style={{ marginTop: '1em' }}>
                      <Trans i18nKey="employee.view.dismissed-reason.label">Turnoer reason</Trans>
                    </Typography>
                    <Typography use="subtitle1" tag="p" style={{ marginBottom: '0.4em', marginTop: '0' }}>
                      {employee.dismissed_reason}
                    </Typography>
                  </>
                )}
                {employee.dismissed_type && (
                  <>
                    <Typography use="caption" tag="div" style={{ marginTop: '1em' }}>
                      <Trans i18nKey="employee.view.dismissed-type.label">Turnover type</Trans>
                    </Typography>
                    <Typography use="subtitle1" tag="p" style={{ marginBottom: '0.4em', marginTop: '0' }}>
                      {parseDismissedType(employee.dismissed_type)}
                    </Typography>
                  </>
                )}
                {employee.dismissed_category && (
                  <>
                    <Typography use="caption" tag="div" style={{ marginTop: '1em' }}>
                      <Trans i18nKey="employee.view.dismissed-category.label">Turnover category</Trans>
                    </Typography>
                    <Typography use="subtitle1" tag="p" style={{ marginBottom: '0.4em', marginTop: '0' }}>
                      {employee.dismissed_category}
                    </Typography>
                  </>
                )}
              </GridCell>
            ) : null}
            <GridCell span={12} style={cellWordWrap}>
              <Typography use="subtitle1" tag="p" style={{ margin: '5px 0' }}>
                <strong>
                  <Trans i18nKey="employee.view.team">Team</Trans>
                </strong>
              </Typography>
              <Typography use="caption" tag="div" style={{ marginTop: '1em' }}>
                <Trans i18nKey="employee.view.leader.label">Leader / Manager</Trans>
              </Typography>
              <Typography use="subtitle1" tag="p" style={{ marginBottom: '0.4em', marginTop: '0' }}>
                <LeaderDetailsWithDataLink employee={employee} />
              </Typography>
              <Typography use="caption" tag="div" style={{ marginTop: '1em' }}>
                <Trans i18nKey="employee.view.buddy.label">Buddy</Trans>
              </Typography>
              <Typography use="subtitle1" tag="p" style={{ marginBottom: '0.4em', marginTop: '0' }}>
                <BuddyDetailsWithDataLink employee={employee} />
              </Typography>
            </GridCell>
            <GridCell span={12}>
              <ListDivider />
            </GridCell>
            <GridCell span={12} style={cellWordWrap}>
              <Typography use="subtitle1" tag="p" style={{ margin: '5px 0' }}>
                <strong>
                  <Trans i18nKey="employee.view.contact">Contact</Trans>
                </strong>
              </Typography>
              <Typography use="caption" tag="div" style={{ marginTop: '1em' }}>
                <Trans i18nKey="employee.view.work_email.label">Work email</Trans>
              </Typography>
              <p style={{ marginBottom: '0.4em', marginTop: '0' }}>
                <EmailWithCopyButton email={employee.work_email} />
              </p>
              <Typography use="caption" tag="div" style={{ marginTop: '1em' }}>
                <Trans i18nKey="employee.view.personal_email.label">Personal email</Trans>
              </Typography>
              <p style={{ marginBottom: '0.4em', marginTop: '0' }}>
                <EmailWithCopyButton email={employee.personal_email} />
              </p>
              <Typography use="caption" tag="div" style={{ marginTop: '1em' }}>
                <Trans i18nKey="employee.view.mobile_number.label">Mobile</Trans>
              </Typography>
              <p style={{ marginBottom: '0.4em', marginTop: '0' }}>
                <Typography use="subtitle2">{employee.mobile_number}</Typography>
                <MobileWhatsappLink mobileNumber={employee.mobile_number} />
              </p>
            </GridCell>
            <GridCell span={12}>
              <ListDivider />
            </GridCell>
            <AddressWithCopyButton employee={employee} />
            <SocialMedias employee={employee} />
            <IntegrationOriginDetails originType={employee.origin_type} originId={employee.origin_id} createdAt={employee.createdAt} updatedAt={employee.updatedAt} />
            <IntegrationUsersDetails integrationUsers={employee.integration_users} />
          </GridRow>
        </Elevation>
      </GridCell>
      <GridCell desktop={8} tablet={12} phone={12}>
        <Elevation z={2} style={cardStyle}>
          <GridRow>
            <GridCell span={12} style={cellWordWrap}>
              <Typography use="subtitle1" tag="p" style={{ margin: '0' }}>
                <strong>
                  <Trans i18nKey="employee.view.data.label">Personal data</Trans>
                </strong>
              </Typography>
              <PersonalDataDetailTable employee={employee} />
            </GridCell>
            <GridCell span={12}>
              <ListDivider />
            </GridCell>
            <GridCell span={12} style={cellWordWrap}>
              <Typography use="subtitle1" tag="p" style={{ margin: '0' }}>
                <strong>
                  <Trans i18nKey="employee.view.company.label">Company data</Trans>
                </strong>
              </Typography>
              <CompanyDataDetailTable employee={employee} />
            </GridCell>
            <GridCell span={12} style={cellWordWrap}>
              <Typography use="subtitle1" tag="p" style={{ margin: '0' }}>
                <strong>
                  <Trans i18nKey="employee.view.details.label">Preferences details</Trans>
                </strong>
              </Typography>
              <Button outlined tag={Link} {...{ to: `/employees/${employee.id}/details` }} style={{ margin: '1rem 0.5rem' }}>
                <Trans i18nKey="employee.view.details.button">Open details with preferences answeres</Trans>
              </Button>
            </GridCell>
          </GridRow>
        </Elevation>
      </GridCell>
    </GridRow>
  );
}

function IntegrationOriginDetails({
  originType,
  originId,
  createdAt,
  updatedAt
}: {
  originType: string | undefined;
  originId: string | undefined;
  createdAt: string | undefined;
  updatedAt: string | undefined;
}): JSX.Element {
  if (!originType) {
    return <></>;
  }

  if (!originId) {
    originId = '';
  }

  function dateTimeFormat(date: string | undefined): string {
    if (!date) {
      return '';
    }
    const parsedDate = new Date(date);
    return `${parsedDate.toLocaleDateString('pt-BR')} ${parsedDate.toLocaleTimeString('pt-BR')}`;
  }

  return (
    <GridCell span={12}>
      <Typography use="subtitle1" tag="p" style={{ margin: '5px 0' }}>
        <strong>
          <Trans i18nKey="employee.view.integration">Integration</Trans>
        </strong>
      </Typography>
      {originType === IntegrationName.Convenia && <IntegrationOriginConveniaDetails originId={originId} />}
      {originType === IntegrationName.Solides && <IntegrationOriginSolidesDetails originId={originId} />}
      {originType === IntegrationName.Gupy && <IntegrationOriginGupyDetails originId={originId} />}
      {originType === IntegrationName.Senior && <IntegrationOriginSeniorDetails originId={originId} />}
      {originType === IntegrationName.ADP && <IntegrationOriginADPDetails originId={originId} />}
      {originType === IntegrationName.Dominio && <IntegrationOriginDominioDetails originId={originId} />}
      {originType === IntegrationName.File && <IntegrationOriginFileDetails originId={originId} />}
      {originType === 'MANUAL' && (
        <p style={{ marginBottom: '0.4em', marginTop: '0.4em' }}>
          <Icon icon="edit_note" style={{ minHeight: '1.2em', verticalAlign: 'middle', marginRight: '10px' }} aria-hidden="true" />
          <Trans i18nKey="employee.view.integration-manual">Add manually</Trans>
        </p>
      )}
      <Typography use="subtitle2" tag="p" style={{ marginBottom: '0.4em', marginTop: '0.4em' }}>
        <Trans i18nKey="employee.view.created-at">Created</Trans>: {dateTimeFormat(createdAt)}
      </Typography>
      {updatedAt && (
        <Typography use="subtitle2" tag="p" style={{ marginBottom: '0.4em', marginTop: '0.4em' }}>
          <Trans i18nKey="employee.view.updated-at">Last update</Trans>: {dateTimeFormat(updatedAt)}
        </Typography>
      )}
    </GridCell>
  );
}

function IntegrationUsersDetails({ integrationUsers }: { integrationUsers: PeopleIntegrationUsers | undefined }): JSX.Element {
  if (!integrationUsers) {
    return <></>;
  }

  const feedzUser = integrationUsers['FEEDZ'];
  const keepsUser = integrationUsers['KEEPS'];
  const salvyUser = integrationUsers['SALVY'];
  if (!feedzUser && !keepsUser && !salvyUser) {
    return <></>;
  }

  return (
    <GridCell span={12}>
      <Typography use="subtitle1" tag="p" style={{ margin: '5px 0' }}>
        <strong>
          <Trans i18nKey="employee.view.integration-users">Users</Trans>
        </strong>
        <IntegrationUserFeedzDetails integrationUser={feedzUser} />
        <IntegrationUserKeepsDetails integrationUser={keepsUser} />
        <IntegrationUserSalvyDetails integrationUser={salvyUser} />
      </Typography>
    </GridCell>
  );
}

function IntegrationUserFeedzDetails({ integrationUser }: { integrationUser: PeopleIntegrationUser }): JSX.Element {
  if (!integrationUser) {
    return <></>;
  }

  const feedzId = integrationUser.origin_id;
  const feedzEmployeeLink = `https://app.feedz.com.br/colaboradores/${feedzId}/perfil`;

  return (
    <>
      <p style={{ marginBottom: '0.4em', marginTop: '0.4em' }}>
        <Icon icon={FeedzLogo} style={{ minHeight: '1.2em', verticalAlign: 'middle', marginRight: '10px' }} aria-hidden="true" />
        Feedz
        <IntegrationUserStatus status={integrationUser.status} />
      </p>
      <Button tag="a" href={feedzEmployeeLink} target="_blank" rel="noopener noreferrer" size={5} style={{ fontSize: '11px', height: '20px', padding: '0' }}>
        <Trans i18nKey="employee.view.feedz.button">Access user in Feedz</Trans>
      </Button>
    </>
  );
}

function IntegrationUserKeepsDetails({ integrationUser }: { integrationUser: PeopleIntegrationUser }): JSX.Element {
  if (!integrationUser) {
    return <></>;
  }

  const keepsId = integrationUser.origin_id;
  const keepsEmployeeLink = `https://analytics.keepsdev.com/user/${keepsId}`;

  return (
    <>
      <p style={{ marginBottom: '0.4em', marginTop: '0.4em' }}>
        <Icon icon={KeepsLogo} style={{ minHeight: '1.2em', verticalAlign: 'middle', marginRight: '10px', backgroundSize: 'contain' }} aria-hidden="true" />
        Keeps
        <IntegrationUserStatus status={integrationUser.status} />
      </p>
      <Button tag="a" href={keepsEmployeeLink} target="_blank" rel="noopener noreferrer" size={5} style={{ fontSize: '11px', height: '20px', padding: '0' }}>
        <Trans i18nKey="employee.view.keeps.button">Access user in Keeps</Trans>
      </Button>
    </>
  );
}
function IntegrationUserSalvyDetails({ integrationUser }: { integrationUser: PeopleIntegrationUser }): JSX.Element {
  if (!integrationUser) {
    return <></>;
  }

  const salvyId = integrationUser.origin_id;
  const salvyEmployeeLink = `https://beta.salvy.com.br/employee/${salvyId}`;

  return (
    <>
      <p style={{ marginBottom: '0.4em', marginTop: '0.4em' }}>
        <Icon icon={SalvyLogo} style={{ minHeight: '1.2em', verticalAlign: 'middle', marginRight: '10px', backgroundSize: 'contain' }} aria-hidden="true" />
        Salvy
        <IntegrationUserStatus status={integrationUser.status} />
      </p>
      <Button tag="a" href={salvyEmployeeLink} target="_blank" rel="noopener noreferrer" size={5} style={{ fontSize: '11px', height: '20px', padding: '0' }}>
        <Trans i18nKey="employee.view.salvy.button">Access user in Salvy</Trans>
      </Button>
    </>
  );
}

function IntegrationUserStatus({ status }: { status: PeopleIntegrationUserStatus }): JSX.Element {
  const label =
    status == PeopleIntegrationUserStatus.Active ? (
      <Trans i18nKey="employee.view.integration-users-status.active">Active</Trans>
    ) : (
      <Trans i18nKey="employee.view.integration-users-status.disabled">Disabled</Trans>
    );

  const color = status == PeopleIntegrationUserStatus.Active ? 'var(--mdc-theme-green-enabled)' : '';

  return <Chip label={label} style={{ fontSize: '80%', height: '23px', marginLeft: '15px', backgroundColor: color }} />;
}

function IntegrationOriginConveniaDetails({ originId }: { originId: string }): JSX.Element {
  const conveniaId = originId.replace('CONVENIA#', '');
  const conveniaEmployeeLink = `https://app.convenia.com.br/colaboradores/${conveniaId}/detalhes/pessoal/`;

  return (
    <>
      <p style={{ marginBottom: '0.4em', marginTop: '0.4em' }}>
        <Icon icon={ConveniaLogo} style={{ minHeight: '1.2em', verticalAlign: 'middle', marginRight: '10px' }} aria-hidden="true" />
        Convenia
      </p>
      <Button tag="a" href={conveniaEmployeeLink} target="_blank" rel="noopener noreferrer" size={5} style={{ fontSize: '11px', height: '20px', padding: '0' }}>
        <Trans i18nKey="employee.view.convenia.button">Access data in Convenia</Trans>
      </Button>
    </>
  );
}

function IntegrationOriginSolidesDetails({ originId }: { originId: string }): JSX.Element {
  const solidesId = originId.replace('SOLIDES#', '');
  const solidesEmployeeLink = `https://system.solides.com/crud/colaborador/${solidesId}`;

  return (
    <>
      <p style={{ marginBottom: '0.4em', marginTop: '0.4em' }}>
        <Icon icon={SolidesLogo} style={{ minHeight: '1.2em', verticalAlign: 'text-bottom', marginRight: '10px', fontSize: '1.3rem' }} aria-hidden="true" />
        Sólides
      </p>
      <Button tag="a" href={solidesEmployeeLink} target="_blank" rel="noopener noreferrer" size={5} style={{ fontSize: '11px', height: '20px', padding: '0' }}>
        <Trans i18nKey="employee.view.solides.button">Access data in Sólides</Trans>
      </Button>
    </>
  );
}

function IntegrationOriginFileDetails({ originId }: { originId: string }): JSX.Element {
  return (
    <p style={{ marginBottom: '0.4em', marginTop: '0.4em' }}>
      <Icon icon="upload_file" style={{ minHeight: '1.2em', verticalAlign: 'middle', marginRight: '10px' }} aria-hidden="true" title={originId} />
      <Trans i18nKey="employee.view.integration-file">File import</Trans>
    </p>
  );
}

function IntegrationOriginGupyDetails({ originId }: { originId: string }): JSX.Element {
  return (
    <p style={{ marginBottom: '0.4em', marginTop: '0.4em' }}>
      <Icon icon={GupyLogo} style={{ minHeight: '1.2em', verticalAlign: 'middle', marginRight: '10px' }} aria-hidden="true" title={originId} />
      Gupy
    </p>
  );
}

function IntegrationOriginSeniorDetails({ originId }: { originId: string }): JSX.Element {
  return (
    <p style={{ marginBottom: '0.4em', marginTop: '0.4em' }}>
      <Icon icon={SeniorLogo} style={{ minHeight: '1.2em', verticalAlign: 'middle', marginRight: '10px' }} aria-hidden="true" title={originId} />
      Senior
    </p>
  );
}

function IntegrationOriginADPDetails({ originId }: { originId: string }): JSX.Element {
  return (
    <p style={{ marginBottom: '0.4em', marginTop: '0.4em' }}>
      <Icon icon={ADPLogo} style={{ minHeight: '1.2em', verticalAlign: 'middle', marginRight: '10px' }} aria-hidden="true" title={originId} />
      ADP
    </p>
  );
}

function IntegrationOriginDominioDetails({ originId }: { originId: string }): JSX.Element {
  return (
    <p style={{ marginBottom: '0.4em', marginTop: '0.4em' }}>
      <Icon icon={DominioLogo} style={{ minHeight: '1.2em', verticalAlign: 'middle', marginRight: '10px' }} aria-hidden="true" title={originId} />
      Dominio
    </p>
  );
}

function EmailWithCopyButton({ email }: { email: string | undefined }): JSX.Element {
  const [copied, copy, setCopied] = useCopy(email || '');

  function copyText(): void {
    copy();

    setTimeout(() => {
      setCopied(false);
    }, 4000);
  }

  if (!email) {
    return <>-</>;
  }

  return (
    <>
      <Typography use="subtitle2">{email}</Typography>
      <Button size={5} onClick={copyText} style={{ fontSize: '11px', height: '20px' }}>
        {copied ? <Trans i18nKey="employee.view.copied">Copied!</Trans> : <Trans i18nKey="employee.view.copy">Copy</Trans>}
      </Button>
    </>
  );
}

function formattedEmployeeAddressString({ employee }: { employee: Employee }): string {
  if (!employee || !employee.address || !employee.address.address) {
    return '';
  }
  return `${employee.social_name || employee.first_name} ${employee.last_name}
${employee.address.address}, ${employee.address.number}
${employee.address.complement || ''}
${employee.address.district || ''}${employee.address.district ? ', ' : ''}CEP ${employee.address.zip_code}
${employee.address.city}, ${employee.address.state}`;
}

function AddressWithCopyButton({ employee }: { employee: Employee }): JSX.Element {
  const formattedAddress = formattedEmployeeAddressString({ employee });
  const [copied, copy, setCopied] = useCopy(formattedAddress);

  function copyText(): void {
    copy();

    setTimeout(() => {
      setCopied(false);
    }, 4000);
  }

  if (!employee || !employee.address || !employee.address.address) {
    return <></>;
  }

  return (
    <>
      <GridCell span={12} style={cellWordWrap}>
        <Typography use="subtitle1" tag="p" style={{ margin: '5px 0' }}>
          <strong>
            <Trans i18nKey="employee.view.address.label">Address</Trans>
          </strong>
        </Typography>
        <Typography use="subtitle2" tag="p" style={{ marginBottom: '0.4em', marginTop: '0.4em' }}>
          {employee.address.address}, {employee.address.number}
        </Typography>
        <Typography use="subtitle2" tag="p" style={{ marginBottom: '0.4em', marginTop: '0.4em' }}>
          {employee.address.complement}
        </Typography>
        <Typography use="subtitle2" tag="p" style={{ marginBottom: '0.4em', marginTop: '0.4em' }}>
          {employee.address.district || ''}
          {employee.address.district ? ', ' : ''}CEP {employee.address.zip_code}
        </Typography>
        <Typography use="subtitle2" tag="p" style={{ marginBottom: '0.4em', marginTop: '0.4em' }}>
          {employee.address.city}, {employee.address.state}
        </Typography>
        <Button size={5} onClick={copyText} style={{ fontSize: '11px', height: '20px', padding: '0' }}>
          {copied ? <Trans i18nKey="employee.view.copied">Copied!</Trans> : <Trans i18nKey="employee.view.address.copy">Copy address</Trans>}
        </Button>
      </GridCell>
      <GridCell span={12} style={cellWordWrap}>
        <ListDivider />
      </GridCell>
    </>
  );
}

function SocialMedias({ employee }: { employee: Employee }): JSX.Element {
  if (!employee || (!employee.instagram_handle && !employee.linkedin_url)) {
    return <></>;
  }

  function instagramTransformUsername(instagram_handle: string | undefined): string {
    const instagramCleanUsername = instagram_handle?.trim();
    if (!instagramCleanUsername) {
      return '';
    }

    if (instagramCleanUsername.indexOf('@') > -1) {
      return instagramCleanUsername;
    }

    return '@' + instagramCleanUsername;
  }

  function linkedinTransformUsername(linkedin_url: string | undefined): string {
    const linkedinCleanUrl = linkedin_url?.trim();
    if (!linkedinCleanUrl) {
      return '';
    }

    const lastSlash = linkedinCleanUrl.lastIndexOf('/');
    if (lastSlash) {
      return linkedinCleanUrl.substring(lastSlash);
    }

    return linkedinCleanUrl;
  }

  const instagramUsername = instagramTransformUsername(employee.instagram_handle);
  const linkedinUsername = linkedinTransformUsername(employee.linkedin_url);

  function instagramUrl(instagram_handle: string) {
    if (!instagram_handle) {
      return '';
    }
    const userId = instagram_handle.replace('@', '').trim();
    return `https://www.instagram.com/${userId}/`;
  }

  return (
    <>
      <GridCell span={12} style={cellWordWrap}>
        <Typography use="subtitle1" tag="p" style={{ margin: '5px 0' }}>
          <strong>
            <Trans i18nKey="employee.view.social.label">Social Medias</Trans>
          </strong>
        </Typography>
        {instagramUsername ? (
          <>
            <Typography use="caption" tag="div" style={{ marginTop: '1em' }}>
              <Trans i18nKey="employee.view.social.instagram.label">Instagram</Trans>
            </Typography>
            <Typography use="subtitle2" tag="p" style={{ marginBottom: '0.4em', marginTop: '0' }}>
              <Button
                tag="a"
                use="subtitle2"
                href={instagramUrl(instagramUsername)}
                target="_blank"
                rel="noopener noreferrer"
                style={{ padding: '0', textTransform: 'none' }}
                icon={{ icon: InstagramIcon, size: 'xsmall' }}
                theme="onSurface"
              >
                <Typography use="subtitle2" tag="span" style={{ maxWidth: '160px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'inline-block' }}>
                  {instagramUsername}
                </Typography>
                <Icon icon={{ icon: 'launch', size: '15px' }} style={{ marginLeft: '5px', verticalAlign: 'text-top', fontSize: '15px' }} />
              </Button>
            </Typography>
          </>
        ) : null}
        {linkedinUsername ? (
          <>
            <Typography use="caption" tag="div" style={{ marginTop: '1em' }}>
              <Trans i18nKey="employee.view.social.linkedin.label">Linkedin</Trans>
            </Typography>
            <Typography use="subtitle2" tag="p" style={{ marginBottom: '0.4em', marginTop: '0' }}>
              <Button
                tag="a"
                use="subtitle2"
                href={employee.linkedin_url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ padding: '0', textTransform: 'none' }}
                icon={{ icon: LinkedinIcon, size: 'xsmall' }}
                theme="onSurface"
              >
                <Typography use="subtitle2" tag="span" style={{ maxWidth: '160px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'inline-block' }}>
                  {linkedinUsername}
                </Typography>
                <Icon icon={{ icon: 'launch', size: '15px' }} style={{ marginLeft: '5px', verticalAlign: 'text-top', fontSize: '15px' }} />
              </Button>
            </Typography>
          </>
        ) : null}
      </GridCell>
      <GridCell span={12} style={cellWordWrap}>
        <ListDivider />
      </GridCell>
    </>
  );
}

function MobileWhatsappLink({ mobileNumber }: { mobileNumber: string | undefined }): JSX.Element {
  if (!mobileNumber) {
    return <>-</>;
  }
  const countryCode = 'BR';
  const mobileNumberFormatter = new PhoneNumber(mobileNumber, countryCode);

  if (!mobileNumberFormatter.isMobile()) {
    return <>{mobileNumber}</>;
  }

  const normalizedMobile = mobileNumberFormatter.getNumber();
  return (
    <a href={`https://wa.me/${normalizedMobile}`} target="_blank" rel="noopener noreferrer">
      <IconButton
        aria-label="Whatsapp"
        tag="a"
        target="_blank"
        icon={{
          strategy: 'component',
          icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
            </svg>
          )
        }}
        style={{
          width: '20px',
          height: '20px',
          padding: '1px',
          marginBottom: '-4px',
          marginLeft: '10px'
        }}
      />
    </a>
  );
}

function formattedDateAddProbation(date: string | undefined, experienceDays: string | undefined): string {
  if (!date || !experienceDays) {
    return '';
  }
  const addDays = +experienceDays;
  if (addDays > 0) {
    const parsedDate = new Date(date);
    parsedDate.setDate(parsedDate.getDate() + addDays);
    return parsedDate.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
  }
  return '';
}

function formattedDate(date: string | undefined): string {
  if (!date) {
    return '';
  }
  return new Date(date).toLocaleDateString('pt-BR', { timeZone: 'UTC' });
}

function parseTranslateAnswer(answer: string | undefined): string {
  if (!answer) {
    return '-';
  }

  switch (answer) {
    case 'yes':
      return 'Sim';
    case 'no':
      return 'Não';
    case 'uploaded':
      return 'Enviada';
  }

  return answer;
}

function parseDismissedType(dismissedType: DismissedReasonType): JSX.Element | string {
  if (!dismissedType) {
    return '';
  }

  switch (dismissedType) {
    case DismissedReasonType.Company:
      return <Trans i18nKey="employee.view.dismissed-type.company">Company</Trans>;
    case DismissedReasonType.Person:
      return <Trans i18nKey="employee.view.dismissed-type.person">Employee</Trans>;
    case DismissedReasonType.Both:
      return <Trans i18nKey="employee.view.dismissed-type.both">Both</Trans>;
  }

  return dismissedType;
}

function PersonalDataDetailTable({ employee }: { employee: Employee }): JSX.Element {
  return (
    <DataTable style={{ width: '100%', border: '0' }}>
      <DataTableContent>
        <DataTableBody>
          <DataTableRow style={{ border: '0' }}>
            <DataTableCell>
              <Trans i18nKey="employee.view.name.label">Name</Trans>
            </DataTableCell>
            <DataTableCell>
              {employee.social_name || employee.first_name} {employee.last_name}
            </DataTableCell>
          </DataTableRow>
          <DataTableRow style={{ border: '0' }}>
            <DataTableCell>
              <Trans i18nKey="employee.view.name.social_name">Social name</Trans>
            </DataTableCell>
            <DataTableCell>{employee.social_name || '-'}</DataTableCell>
          </DataTableRow>
          <DataTableRow style={{ border: '0' }}>
            <DataTableCell>
              <Trans i18nKey="employee.view.name.short_name">Short name</Trans>
            </DataTableCell>
            <DataTableCell>{employee.short_name || '-'}</DataTableCell>
          </DataTableRow>
          {employee.fake_name ? (
            <DataTableRow style={{ border: '0' }}>
              <DataTableCell>
                <Trans i18nKey="employee.view.name.fake_name">Fake name</Trans>
              </DataTableCell>
              <DataTableCell>{employee.fake_name || '-'}</DataTableCell>
            </DataTableRow>
          ) : null}
          <DataTableRow style={{ border: '0' }}>
            <DataTableCell>
              <Trans i18nKey="employee.view.birthday.label">Birthday</Trans>
            </DataTableCell>
            <DataTableCell>{formattedDate(employee.birth_date) || '-'}</DataTableCell>
          </DataTableRow>
          <DataTableRow style={{ border: '0' }}>
            <DataTableCell>
              <Trans i18nKey="employee.view.birthday_celebrate.label">Celebrate Birthday?</Trans>
            </DataTableCell>
            <DataTableCell>{parseTranslateAnswer(employee.birth_date_celebrate)}</DataTableCell>
          </DataTableRow>
          <DataTableRow style={{ border: '0' }}>
            <DataTableCell>
              <Trans i18nKey="employee.view.phone.label">Phone</Trans>
            </DataTableCell>
            <DataTableCell>{employee.phone_number || '-'}</DataTableCell>
          </DataTableRow>
          <DataTableRow style={{ border: '0' }}>
            <DataTableCell>
              <Trans i18nKey="employee.view.gender-pronouns.label">Gender pronouns</Trans>
            </DataTableCell>
            <DataTableCell>{employee.gender_pronouns || '-'}</DataTableCell>
          </DataTableRow>
          <DataTableRow style={{ border: '0' }}>
            <DataTableCell>
              <Trans i18nKey="employee.view.gender.label">Gender</Trans>
            </DataTableCell>
            <DataTableCell>{employee.gender || '-'}</DataTableCell>
          </DataTableRow>
          <DataTableRow style={{ border: '0' }}>
            <DataTableCell>
              <Trans i18nKey="employee.view.ethnicity.label">Ethnicity</Trans>
            </DataTableCell>
            <DataTableCell>{employee.ethnicity || '-'}</DataTableCell>
          </DataTableRow>
          <DataTableRow style={{ border: '0' }}>
            <DataTableCell>
              <Trans i18nKey="employee.view.education.label">Education</Trans>
            </DataTableCell>
            <DataTableCell>{employee.education || '-'}</DataTableCell>
          </DataTableRow>
        </DataTableBody>
      </DataTableContent>
    </DataTable>
  );
}

function LeaderDetailsWithDataLink({ employee }: { employee: Employee }): JSX.Element {
  if (!employee.leader_id || !employee.leader) {
    return <>-</>;
  }

  const text = employee.leader.first_name ? `${employee.leader.first_name} ${employee.leader.last_name}` : '-';
  const link = employee.leader_id ? `/employees/${employee.leader_id}` : undefined;

  return (
    <Button tag="a" use="body1" href={link} target="_blank" rel="noopener noreferrer" size={5} style={{ padding: '0', textTransform: 'none' }}>
      {text}
      <Icon icon={{ icon: 'launch', size: '15px' }} style={{ marginLeft: '10px', verticalAlign: 'text-bottom', fontSize: '15px' }} />
    </Button>
  );
}

function BuddyDetailsWithDataLink({ employee }: { employee: Employee }): JSX.Element {
  if (!employee.buddy_id || !employee.buddy) {
    return <>-</>;
  }

  const text = employee.buddy.first_name ? `${employee.buddy.first_name} ${employee.buddy.last_name}` : '-';
  const link = employee.buddy_id ? `/employees/${employee.buddy_id}` : undefined;

  return (
    <Button tag="a" use="body1" href={link} target="_blank" rel="noopener noreferrer" size={5} style={{ padding: '0', textTransform: 'none' }}>
      {text}
      <Icon icon={{ icon: 'launch', size: '15px' }} style={{ marginLeft: '10px', verticalAlign: 'text-bottom', fontSize: '15px' }} />
    </Button>
  );
}

function CompanyDataDetailTable({ employee }: { employee: Employee }): JSX.Element {
  return (
    <DataTable style={{ width: '100%', border: '0' }}>
      <DataTableContent>
        <DataTableBody>
          {employee.registration_id ? (
            <DataTableRow style={{ border: '0' }}>
              <DataTableCell>
                <Trans i18nKey="employee.view.registration.label">Registration Id</Trans>
              </DataTableCell>
              <DataTableCell>{employee.registration_id}</DataTableCell>
            </DataTableRow>
          ) : null}
          <DataTableRow style={{ border: '0' }}>
            <DataTableCell>
              <Trans i18nKey="employee.view.leader.label">Leader / Manager</Trans>
            </DataTableCell>
            <DataTableCell>
              <LeaderDetailsWithDataLink employee={employee} />
            </DataTableCell>
          </DataTableRow>
          <DataTableRow style={{ border: '0' }}>
            <DataTableCell>
              <Trans i18nKey="employee.view.first-day.label">First day</Trans>
            </DataTableCell>
            <DataTableCell>{formattedDate(employee.start_date)}</DataTableCell>
          </DataTableRow>
          {employee.start_date && employee.experience_period_days ? (
            <DataTableRow style={{ border: '0' }}>
              <DataTableCell>
                <Trans i18nKey="employee.view.probation-period.label">Probation period</Trans>
              </DataTableCell>
              <DataTableCell>
                {formattedDateAddProbation(employee.start_date, employee.experience_period_days)} (
                <Trans i18nKey="employee.view.probation-period.days" values={{ days: employee.experience_period_days }}>
                  {{ days: '0' }} days
                </Trans>
                )
              </DataTableCell>
            </DataTableRow>
          ) : null}
          {employee.experience_period_days ? (
            <DataTableRow style={{ border: '0' }}>
              <DataTableCell>
                <Trans i18nKey="employee.view.experience_period_days.label">Experience period days</Trans>
              </DataTableCell>
              <DataTableCell>{employee.experience_period_days}</DataTableCell>
            </DataTableRow>
          ) : null}
          {employee.last_date ? (
            <DataTableRow style={{ border: '0' }}>
              <DataTableCell>
                <Trans i18nKey="employee.view.last-day.label">Last day</Trans>
              </DataTableCell>
              <DataTableCell>{formattedDate(employee.last_date)}</DataTableCell>
            </DataTableRow>
          ) : null}
          {employee.dismissed_reason ? (
            <DataTableRow style={{ border: '0' }}>
              <DataTableCell>
                <Trans i18nKey="employee.view.dismissed-reason.label">Turnover reason</Trans>
              </DataTableCell>
              <DataTableCell>{employee.dismissed_reason}</DataTableCell>
            </DataTableRow>
          ) : null}
          <DataTableRow style={{ border: '0' }}>
            <DataTableCell>
              <Trans i18nKey="employee.view.contract_type.label">Contract type</Trans>
            </DataTableCell>
            <DataTableCell>{employee.contract_type || '-'}</DataTableCell>
          </DataTableRow>
          <DataTableRow style={{ border: '0' }}>
            <DataTableCell>
              <Trans i18nKey="employee.view.job_title.label">Job title</Trans>
            </DataTableCell>
            <DataTableCell>{employee.job_title || '-'}</DataTableCell>
          </DataTableRow>
          <DataTableRow style={{ border: '0' }}>
            <DataTableCell>
              <Trans i18nKey="employee.view.department.label">Department</Trans>
            </DataTableCell>
            <DataTableCell>{employee.department || '-'}</DataTableCell>
          </DataTableRow>
          <DataTableRow style={{ border: '0' }}>
            <DataTableCell>
              <Trans i18nKey="employee.view.cost_center.label">Cost center</Trans>
            </DataTableCell>
            <DataTableCell>{employee.cost_center || '-'}</DataTableCell>
          </DataTableRow>
          <DataTableRow style={{ border: '0' }}>
            <DataTableCell>
              <Trans i18nKey="employee.view.cost_category.label">Cost category</Trans>
            </DataTableCell>
            <DataTableCell>{employee.cost_category || '-'}</DataTableCell>
          </DataTableRow>
        </DataTableBody>
      </DataTableContent>
    </DataTable>
  );
}

EmployeeDetails.propTypes = {
  employee: PropTypes.any
};

EmployeeViewHome.propTypes = {
  employee: PropTypes.any,
  onGoBack: PropTypes.func
};

export default EmployeeViewHome;
