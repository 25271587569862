import HttpRepository from '../../../services/HttpRepository/HttpRepository';
import { PeopleOriginType } from '../../Employee/EmployeeRepository';

export interface Company {
  id?: string;
  name?: string;
  name_pronoun?: string;
  timezone?: string;
  color_primary?: string;
  color_secondary?: string;
  logo_url?: string;
  avatar_name?: string;
  avatar_name_pronoun?: string;
  avatar_url?: string;
  sent_from_email?: string;
  notify_integration_emails?: string;
  dashboard_id?: string;
  dashboard_messages_id?: string;
  dashboard_surveys_id?: string;
  dashboard_turnover_id?: string;
  import_file?: PeopleOriginType;
}

class CompanyRepository extends HttpRepository<Company> {
  constructor() {
    super({ basePath: '/company' });
  }

  public async get(id: string): Promise<Company> {
    const { data } = await super.getCachedById(id);
    return data;
  }
}

export default CompanyRepository;
