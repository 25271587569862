import React from 'react';
import { Control, FieldErrors } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { CustomizableOption } from './CustomizableOption';
import { Typography } from 'rmwc';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseOptionsDefaultValues(options: any[]): { value: string; id?: string }[] | undefined {
  if (!options) {
    return undefined;
  }

  if (typeof options[0] == 'string') {
    const formattedOptions = options.map(({ value }) => {
      return { value: value };
    });
    return formattedOptions;
  }

  return options;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ScoreOptions({ control, block, index, errors, isLoading }: { control: Control; block: any; index: number; errors: FieldErrors; isLoading: boolean }): JSX.Element {
  const { t } = useTranslation();

  const optionsDefaultValues = parseOptionsDefaultValues(block.options);
  const validOptionsArray = optionsDefaultValues && optionsDefaultValues?.length > 0;

  return (
    <>
      <Typography use="body1" tag="p">
        <label htmlFor="journey-step.form.blocks.poll.type.score">
          <Trans i18nKey="journey-step.form.blocks.poll.type.score">Score options</Trans>
        </label>
      </Typography>
      <CustomizableOption
        control={control}
        defaultValue={validOptionsArray ? optionsDefaultValues[0].value : t('journey-step.form.blocks.poll.score-options.one.value', 'Amazing 😍')}
        controllerName={`message_blocks.${index}.options.0.value`}
        requiredProps={{ i18nKey: 'journey-step.form.blocks.poll.options.required', requiredText: 'Option text is required' }}
        placeholderTag="journey-step.form.blocks.poll.score-options.one.placeholder"
        invalid={errors?.message_blocks?.[index]?.options?.[0]?.message}
        isLoading={isLoading}
      />

      <CustomizableOption
        control={control}
        defaultValue={validOptionsArray ? optionsDefaultValues[1].value : t('journey-step.form.blocks.poll.score-options.two.value', 'Happy 😆')}
        controllerName={`message_blocks.${index}.options.1.value`}
        requiredProps={{ i18nKey: 'journey-step.form.blocks.poll.options.required', requiredText: 'Option text is required' }}
        placeholderTag="journey-step.form.blocks.poll.score-options.two.placeholder"
        invalid={errors?.message_blocks?.[index]?.options?.[1]?.message}
        isLoading={isLoading}
      />

      <CustomizableOption
        control={control}
        defaultValue={validOptionsArray ? optionsDefaultValues[2].value : t('journey-step.form.blocks.poll.score-options.three.value', 'Normal 🙂')}
        controllerName={`message_blocks.${index}.options.2.value`}
        requiredProps={{ i18nKey: 'journey-step.form.blocks.poll.options.required', requiredText: 'Option text is required' }}
        placeholderTag="journey-step.form.blocks.poll.score-options.three.placeholder"
        invalid={errors?.message_blocks?.[index]?.options?.[2]?.message}
        isLoading={isLoading}
      />

      <CustomizableOption
        control={control}
        defaultValue={validOptionsArray ? optionsDefaultValues[3].value : t('journey-step.form.blocks.poll.score-options.four.value', 'Sad 😞')}
        controllerName={`message_blocks.${index}.options.3.value`}
        requiredProps={{ i18nKey: 'journey-step.form.blocks.poll.options.required', requiredText: 'Option text is required' }}
        placeholderTag="journey-step.form.blocks.poll.score-options.four.placeholder"
        invalid={errors?.message_blocks?.[index]?.options?.[3]?.message}
        isLoading={isLoading}
      />

      <CustomizableOption
        control={control}
        defaultValue={validOptionsArray ? optionsDefaultValues[4].value : t('journey-step.form.blocks.poll.score-options.five.value', 'Confusing 😖')}
        controllerName={`message_blocks.${index}.options.4.value`}
        requiredProps={{ i18nKey: 'journey-step.form.blocks.poll.options.required', requiredText: 'Option text is required' }}
        placeholderTag="journey-step.form.blocks.poll.score-options.five.placeholder"
        invalid={errors?.message_blocks?.[index]?.options?.[4]?.message}
        isLoading={isLoading}
      />
    </>
  );
}
