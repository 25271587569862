import { AxiosError } from 'axios';
import { FieldValues } from 'react-hook-form';
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import HttpRepository from '../HttpRepository';
import { SearchFilter } from '../HttpRepository/SearchFilter';

abstract class HttpBaseService<T> {
  public constructor(private queryKey: string, private repository: HttpRepository<T>, protected queryClient = useQueryClient()) {}

  public useList(): UseQueryResult<T[]> {
    return useQuery<T[]>(
      this.queryKey,
      async () => {
        return this.repository.list();
      },
      { refetchOnWindowFocus: false }
    );
  }

  public useListSearch(searchFilter: SearchFilter, dataLimit: number): UseInfiniteQueryResult<T[]> {
    return useInfiniteQuery<T[]>(
      [this.queryKey, searchFilter.query],
      async ({ pageParam }) => {
        if (pageParam) {
          searchFilter.lastKey = pageParam;
        }
        return this.repository.listSearch(searchFilter);
      },
      {
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPageData) => {
          const dataLength = lastPageData?.length;
          if (!dataLength || dataLength < dataLimit) {
            return undefined;
          }
          const lastDataItem = lastPageData[dataLength - 1] as unknown as { id: string };
          return {
            id: lastDataItem.id
          };
        }
      }
    );
  }

  public useGet(id: string, retry: number | boolean = 3): UseQueryResult<T> {
    return useQuery<T>(
      [this.queryKey, id],
      async () => {
        return this.repository.get(id);
      },
      { enabled: !!id, refetchOnWindowFocus: false, retry }
    );
  }

  public useDelete(): UseMutationResult<T, AxiosError, string> {
    return useMutation<T, AxiosError, string>((id: string) => this.repository.delete(id), {
      onMutate: () => {
        this.queryClient.cancelQueries(this.queryKey);
      },
      onSettled: () => {
        this.queryClient.invalidateQueries(this.queryKey);
      }
    });
  }

  public useCreate(): UseMutationResult<T, AxiosError, FieldValues> {
    return useMutation<T, AxiosError, FieldValues>(
      (values: FieldValues) => {
        const data: T = this.convertValuesToModel(values);
        return this.repository.create(data);
      },
      {
        onMutate: () => {
          this.queryClient.cancelQueries(this.queryKey);
        },
        onSuccess: () => {
          this.queryClient.invalidateQueries(this.queryKey);
        }
      }
    );
  }

  public useUpdate(): UseMutationResult<T, AxiosError, FieldValues> {
    return useMutation<T, AxiosError, FieldValues>(
      ({ id, ...values }) => {
        const data: T = this.convertValuesToModel(values);
        return this.repository.update(id, data);
      },
      {
        onMutate: () => {
          this.queryClient.cancelQueries(this.queryKey);
        },
        onSuccess: () => {
          this.queryClient.invalidateQueries(this.queryKey);
        }
      }
    );
  }

  public convertToNumber(value: string): number {
    return +value;
  }

  abstract convertValuesToModel(values: FieldValues): T;
}

export default HttpBaseService;
