import React, { RefObject, useRef } from 'react';
import withSizes, { Sizes } from 'react-sizes';
import { useParams } from 'react-router-dom';
import PropTypes, { InferProps } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

import { GridList, GridTile } from '@rmwc/grid-list';
import '@rmwc/grid-list/styles';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Card, CardPrimaryAction, CardMedia } from '@rmwc/card';
import '@rmwc/card/styles';

import { Typography } from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

import { List, ListItem } from '@rmwc/list';
import '@rmwc/list/styles';

import StepJourneyTemplateService from './StepJourneyTemplateService';

import Breadcrumb from '../common/Breadcrumb';
import Message from '../../services/Message';

import PlusCircle from '../Ilustrations/plus_circle.svg';

const templatesGroupStyleOnDesktop = {
  position: 'relative',
  marginLeft: '240px',
  paddingLeft: '20px',
  backgroundColor: 'var(--mdc-theme-background-light)'
} as React.CSSProperties;

function StepJourneyNewSelect({ isMobile, history }: InferProps<typeof StepJourneyNewSelect.propTypes>): JSX.Element {
  const { journeyId } = useParams() as { journeyId: string };
  const { t } = useTranslation();
  const stepJourneyTemplateService = new StepJourneyTemplateService();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onCancel = async (): Promise<void> => {
    history.goBack();
  };

  const cardDataBeforeList = stepJourneyTemplateService.templatesBeforeFistDay();
  const cardDataFirstList = stepJourneyTemplateService.templatesFirstDay();
  const cardDataAfterList = stepJourneyTemplateService.templatesAfterFistDay();

  const anchorRefTop = useRef<HTMLElement>(null);
  const anchorRefBefore = useRef<HTMLElement>(null);
  const anchorRefFirst = useRef<HTMLElement>(null);
  const anchorRefAfter = useRef<HTMLElement>(null);

  function scrollToElement(element: RefObject<HTMLElement>): void {
    element?.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }

  function onClickDisabled(): void {
    Message.error('Modelos desabilitados. Clique em crie uma nova etapa em branco para iniciar.');
    scrollToElement(anchorRefTop);
  }

  const gridTileStyle = {
    width: '100%',
    maxWidth: isMobile ? '320px' : '250px',
    padding: '10px'
  } as React.CSSProperties;

  return (
    <Grid style={{ position: 'relative' }} ref={anchorRefTop}>
      <GridCell span={12} style={{ position: 'relative' }}>
        <Breadcrumb
          routeSegments={[
            { name: t('navlink.journeys'), path: '/journeys' },
            { name: t('journey.view-title'), path: `/journeys/${journeyId}` },
            { name: t('journey-step.new-title') }
          ]}
        />
      </GridCell>
      {!isMobile && (
        <GridCell span={3} style={{ position: 'fixed', marginTop: '40px' }}>
          <List>
            <ListItem className="mdc-ripple-upgraded--background-focused" onClick={(): void => scrollToElement(anchorRefBefore)}>
              Antes do 1o dia de trabalho
            </ListItem>
            <ListItem onClick={(): void => scrollToElement(anchorRefFirst)}>No 1o dia de trabalho</ListItem>
            <ListItem onClick={(): void => scrollToElement(anchorRefAfter)}>Depois do 1o dia de trabalho</ListItem>
          </List>
        </GridCell>
      )}
      <GridCell span={12} style={isMobile ? {} : templatesGroupStyleOnDesktop}>
        <GridList tileAspect={isMobile ? '1x1' : '3x2'}>
          <GridTile key={'start_black'} style={gridTileStyle}>
            <StepJourneyCardBlank journeyId={journeyId} />
          </GridTile>
        </GridList>

        <Typography use="heading3" style={{ paddingLeft: '8px' }} tag="h3" id="before" ref={anchorRefBefore}>
          Modelos antes do 1º dia de trabalho
        </Typography>

        <GridList tileAspect={isMobile ? '1x1' : '4x3'}>
          {cardDataBeforeList.map((cardData, index) => (
            <GridTile key={index} style={gridTileStyle}>
              <StepJourneyCard imageUrl={cardData.image} name={cardData.name} text={cardData.text} onClick={onClickDisabled} />
            </GridTile>
          ))}
        </GridList>

        <Typography use="heading3" style={{ paddingLeft: '8px' }} tag="h3" id="first-day" ref={anchorRefFirst}>
          Modelos no 1º dia de trabalho
        </Typography>
        <GridList tileAspect={isMobile ? '1x1' : '4x3'}>
          {cardDataFirstList.map((cardData, index) => (
            <GridTile key={index} style={gridTileStyle}>
              <StepJourneyCard imageUrl={cardData.image} name={cardData.name} text={cardData.text} onClick={onClickDisabled} />
            </GridTile>
          ))}
        </GridList>

        <Typography use="heading3" style={{ paddingLeft: '8px' }} tag="h3" id="after" ref={anchorRefAfter}>
          Modelos depois do 1º dia de trabalho
        </Typography>
        <GridList tileAspect={isMobile ? '1x1' : '4x3'}>
          {cardDataAfterList.map((cardData, index) => (
            <GridTile key={index} style={gridTileStyle}>
              <StepJourneyCard imageUrl={cardData.image} name={cardData.name} text={cardData.text} onClick={onClickDisabled} />
            </GridTile>
          ))}
        </GridList>
      </GridCell>
    </Grid>
  );
}

function StepJourneyCardBlank({ journeyId }: { journeyId: string }): JSX.Element {
  return (
    <Card style={{ maxWidth: '19rem', height: '100%', margin: 'auto' }}>
      <CardPrimaryAction style={{ height: '100%' }} tag={Link} {...{ to: `/journeys/${journeyId}/steps/new` }}>
        <CardMedia
          sixteenByNine
          style={{
            backgroundImage: `url(${PlusCircle})`,
            backgroundSize: 'contain',
            border: 'inset 0px transparent',
            borderRightWidth: '40px',
            borderLeftWidth: '40px',
            borderTopWidth: '20px'
          }}
        />
        <div style={{ padding: '0 1rem 1rem 1rem' }}>
          <Typography use="subtitle1" tag="h2" style={{ marginBottom: '0' }}>
            Crie uma nova etapa
          </Typography>
          <Typography use="body1" tag="div" theme="textSecondaryOnBackground">
            Etapa em branco para você customizar.
          </Typography>
        </div>
      </CardPrimaryAction>
    </Card>
  );
}

function StepJourneyCard({ imageUrl, name, text, onClick }: { imageUrl: string; name: string; text: string; onClick: () => void }): JSX.Element {
  return (
    <Card style={{ maxWidth: '19rem', height: '100%', margin: 'auto' }}>
      <CardPrimaryAction
        style={{ height: '100%' }}
        onClick={(): void => {
          onClick();
        }}
      >
        <CardMedia
          sixteenByNine
          style={{ backgroundImage: `url(${imageUrl})`, backgroundSize: 'contain', border: 'inset 10px transparent', borderRightWidth: '40px', borderLeftWidth: '40px' }}
        />
        <div style={{ padding: '0 1rem 1rem 1rem' }}>
          <Typography use="subtitle1" tag="h2" style={{ marginBottom: '0' }}>
            {name}
          </Typography>
          <Typography use="body1" tag="div" theme="textSecondaryOnBackground">
            {text}
          </Typography>
        </div>
      </CardPrimaryAction>
    </Card>
  );
}

StepJourneyNewSelect.propTypes = {
  history: PropTypes.any,
  isMobile: PropTypes.bool
};

function mapSizesToProps({ width }: Sizes): InferProps<typeof StepJourneyNewSelect.propTypes> {
  return {
    isMobile: width < 500
  } as InferProps<typeof StepJourneyNewSelect.propTypes>;
}

export default withSizes(mapSizesToProps)(StepJourneyNewSelect);
