import React from 'react';
import { Trans } from 'react-i18next';

import { Link } from 'react-router-dom';

import LoadingModal from '../../LoadingModal';
import ErrorMessage from '../../ErrorMessage';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import authService from '../../../services/AuthService';
import UsersSettingsTable from './UsersSettingsTable';
import AdminUsersService from './AdminUsersService';

function UsersSettingsHome(): JSX.Element {
  const userService = new AdminUsersService();

  const { data, isFetching, isError, error } = userService.useList();

  const hasDataCreated = data && data.length > 0;

  let usersData = data;
  if (!hasDataCreated) {
    const authUser = authService.getAuthUser();
    usersData = [
      {
        email: authUser.email,
        name: authUser.name,
        cognito_username: authUser.name
      }
    ];
  }

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching) {
    return <LoadingModal open={true} />;
  }

  return (
    <Grid>
      <GridCell span={6}>
        <h2>
          <Trans i18nKey="users-settings.title">Users</Trans>
        </h2>
      </GridCell>
      <GridCell span={6} style={{ textAlign: 'right' }}>
        <Button raised icon="add" tag={Link} {...{ to: '/settings/users/new' }}>
          <Trans i18nKey="users-settings.create">New Admin User</Trans>
        </Button>
      </GridCell>
      <GridCell span={12}>
        <UsersSettingsTable users={usersData}></UsersSettingsTable>
      </GridCell>
    </Grid>
  );
}

export default UsersSettingsHome;
