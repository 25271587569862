import HttpRepository from '../../../services/HttpRepository/HttpRepository';

export interface ContentsGlossary {
  id?: string;
  tags: string;
  title: string;
  description: string;
  source_url?: string;
}

class ContentsGlossaryRepository extends HttpRepository<ContentsGlossary> {
  constructor() {
    super({ basePath: '/contents/glossary' });
  }
}

export default ContentsGlossaryRepository;
