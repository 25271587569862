import React from 'react';
import Chart from '../Chart';

import { Trans, useTranslation } from 'react-i18next';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Typography } from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

/**
 * Employee Net Promoter Score (eNPS)
 * On a scale from 0 to 10 how likely are you to recommend your organization as a good place to work?
 * Promoter (gave a score of 9 or 10)
 * Detractor (gave a score ranging from 0 to 6)
 * Passive (gave a score of 7 or 8)
 *
 * eNPS score could range from -100 to +100
 *
 * The score is then calculated by subtracting the percentage of Detractors from the
 * percentage of Promoters (% of Promoters -% of Detractors= eNPS).
 * Passives aren’t included in the calculation because they’re considered to be neutral.
 *
 * The Engagement score is an average of the data for all of the metrics measured,
 * but the eNPS represents only one aspect of overall Engagement.
 * It's not unusual to see a positive eNPS score and a low Engagement score, or vice versa.
 *
 * -100 to -10 Needs improvement
 * -10 to 0 Okay
 * 0 to 30 Good
 * 30 to 70 Great
 * 70 to 100 Excellent
 */
function EmployeeNPSChart(): JSX.Element {
  return (
    <Grid>
      <GridCell span={12}>
        <h2>
          <Typography use="headline5">
            <Trans i18nKey="employee-score.title">Employee Net Promoter Score (eNPS)</Trans>
          </Typography>
        </h2>
      </GridCell>
      <GridCell span={5}>
        <EmployeeScoresPieChart></EmployeeScoresPieChart>
      </GridCell>
      <GridCell span={7}>
        <EmployeeScoreLineChart></EmployeeScoreLineChart>
      </GridCell>
    </Grid>
  );
}

function EmployeeScoresPieChart(): JSX.Element {
  const { t } = useTranslation();
  const chartTitle = t('employee-score.pie.title', 'eNPS Details');
  const chartSeriesName = t('employee-score.pie.series-name', 'eNPS Details');
  const promoterName = t('employee-score.pie.promoter', 'Promoter');
  const passiveName = t('employee-score.pie.passive', 'Passive');
  const detractorName = t('employee-score.pie.detractor', 'Detractor');
  //   const notAnsweredName = t('employee-score.pie.not-answered', 'Not Answered');
  const textColor = '#303030';
  const style = {};
  const option = {
    title: {
      show: true,
      text: chartTitle,
      left: 'center',
      textStyle: {
        color: textColor,
        fontSize: 16,
        fontFamily: 'PT Sans'
      }
    },
    legend: {
      show: true,
      itemGap: 10,
      icon: 'roundRect',
      bottom: 0,
      textStyle: {
        color: textColor,
        fontSize: 13
      }
    },
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c} ({d}%)'
    },
    xAxis: [
      {
        axisLine: {
          show: false
        },
        splitLine: {
          show: false
        }
      }
    ],
    yAxis: [
      {
        axisLine: {
          show: false
        },
        splitLine: {
          show: false
        }
      }
    ],
    series: [
      {
        name: chartSeriesName,
        type: 'pie',
        radius: ['40%', '72.55%'],
        center: ['50%', '47%'],
        avoidLabelOverlap: false,
        stillShowZeroSum: false,
        label: {
          normal: {
            show: false,
            position: 'center', // shows the description data to center, turn off to show in right side
            textStyle: {
              color: textColor,
              fontSize: 13,
              fontFamily: 'PT Sans'
            },
            formatter: '{a}'
          },
          emphasis: {
            show: true,
            textStyle: {
              fontSize: 14,
              fontWeight: 'normal'
            },
            formatter: '{b} \n{c} ({d}%)'
          }
        },
        labelLine: {
          normal: {
            show: false
          }
        },
        data: [
          {
            value: 76, // promoters
            name: promoterName,
            itemStyle: { color: '#45B96C' }
          },
          {
            value: 40, // passive
            name: passiveName,
            itemStyle: { color: '#FBB959' }
          },
          {
            value: 15, // detractor
            name: detractorName,
            itemStyle: { color: '#F16662' }
          }
          //   {
          //     value: 15, // not answered poll
          //     name: notAnsweredName,
          //     itemStyle: { color: '#E4E2Cf' }
          //   }
        ],
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        }
      }
    ],
    colors: []
  };
  return <Chart option={option} style={style} />;
}

function EmployeeScoreLineChart(): JSX.Element {
  const textColor = '#303030';
  //   const style = { height: '100px' };
  const style = {};
  const option = {
    grid: {
      top: '10%',
      bottom: '10%',
      left: '5%',
      right: '5%'
    },
    legend: {
      itemGap: 20,
      icon: 'circle',
      textStyle: {
        color: textColor,
        fontSize: 13,
        fontFamily: 'PT Sans'
      }
    },
    tooltip: {},
    xAxis: {
      type: 'category',
      data: ['Set', 'Oct', 'Nov', 'Dec', '2021', 'Fev', 'Mar'],
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        color: textColor,
        fontSize: 14,
        fontFamily: 'PT Sans'
      }
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      splitLine: {
        // show: false
        lineStyle: {
          color: textColor,
          opacity: 0.1
        }
      },
      axisLabel: {
        color: textColor,
        fontSize: 13,
        fontFamily: 'PT Sans'
      }
    },
    series: [
      {
        // 51.06% (promoters) - 10.27 (detractors) = 40.79
        data: [10, 11, 9, 18, 25, 31, 40.79],
        type: 'line',
        stack: 'eNPS',
        name: 'eNPS',
        smooth: true,
        symbolSize: 4,
        lineStyle: {
          width: 4
        }
      }
    ],
    colors: []
  };
  return <Chart option={option} style={style} />;
}

// function eNPSGaugeChart(): JSX.Element {
//   const textColor = '#303030';
//   //   const style = { height: '100px' };
//   const style = {};
//   const option = {
//     title: {
//       show: true,
//       text: 'eNPS',
//       left: 'center',
//       textStyle: {
//         color: textColor,
//         fontSize: 16,
//         fontFamily: 'PT Sans'
//       }
//     },
//     series: [
//       {
//         name: 'eNPS',
//         type: 'gauge',
//         startAngle: 180,
//         endAngle: 0,
//         min: -100,
//         max: 100,
//         splitNumber: 10,
//         axisLine: {
//           lineStyle: {
//             width: 10,
//             color: [
//               [0.5, '#F16662'],
//               [0.666, '#FBB959'],
//               [0.832, '#3CC474'],
//               [1, '#45B96C']
//             ]
//           }
//         },
//         pointer: {
//           icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
//           length: '12%',
//           width: 20,
//           offsetCenter: [0, '-60%'],
//           itemStyle: {
//             color: 'auto'
//           }
//         },
//         axisTick: {
//           show: false,
//           length: 12,
//           lineStyle: {
//             color: 'auto',
//             width: 2
//           }
//         },
//         splitLine: {
//           show: false,
//           length: 20,
//           lineStyle: {
//             color: 'auto',
//             width: 5
//           }
//         },
//         axisLabel: {
//           color: textColor,
//           fontSize: 14,
//           fontFamily: 'PT Sans',
//           distance: -15,
//           formatter: (value: number): string | undefined => {
//             console.log('value == ', value);
//             if (value === -100) {
//               return '-100';
//             } else if (value === -30) {
//               return '-30';
//             } else if (value === 0) {
//               return '0';
//             } else if (value === 30) {
//               return '30';
//             } else if (value === 70) {
//               return '70';
//             }
//           }
//         },
//         detail: {
//           fontSize: 40,
//           fontFamily: 'PT Sans',
//           offsetCenter: [0, '-10%'],
//           valueAnimation: true,
//           color: 'auto'
//         },
//         data: [30]
//       }
//     ],
//     colors: []
//   };
//   return <Chart option={option} style={style} />;
// }

export default EmployeeNPSChart;
