import HttpRepository from '../../../services/HttpRepository/HttpRepository';
import { IntegrationName } from '../IntegrationSettings/IntegrationsRepository';

export enum LogsIntegrationDirection {
  InboundFromExternal = 'INBOUND', // External to Inboarding API. E.g.: Convenia and Gupy webhooks or files import
  OutboundFromInbording = 'OUTBOUND' // Inboarding to external API. E.g.: Create users on Feedz
}
export enum LogsIntegrationStatus {
  Processing = 'PROCESSING',
  Error = 'ERROR',
  Success = 'SUCCESS'
}

export interface LogsIntegration {
  id: string;
  createdAt: string;
  source_ip: string;
  user_id: string;
  user_name: string;
  user_email: string;
  people_id: string;
  people_origin_id: string;
  people_name: string;
  integration_name: IntegrationName;
  integration_details: string;
  direction: LogsIntegrationDirection;
  status: LogsIntegrationStatus;
  method: string;
  url: string;
  response_code: string;
  response_body: string;
  request_body: string;
  error: string;
}

class LogsIntegrationInboundRepository extends HttpRepository<LogsIntegration> {
  constructor() {
    super({ basePath: '/reports/logs/inbound' });
  }
}

export default LogsIntegrationInboundRepository;
