import React from 'react';
import { Trans } from 'react-i18next';

import LoadingModal from '../../LoadingModal';
import ErrorMessage from '../../ErrorMessage';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import FieldsSettingsTable from './FieldsSettingsTable';

interface CustomFiels {
  name: string;
  type: string;
  configuration: string;
}

function FieldsSettingsHome(): JSX.Element {
  const [isFetching, setIsFetching] = React.useState(true);
  const [error, setError] = React.useState<unknown>(null);
  const [fields, setFields] = React.useState<CustomFiels[]>([]);

  React.useEffect(() => {
    setTimeout(() => {
      try {
        setFields([
          {
            name: 'Líder',
            type: 'Dinâmico',
            configuration: 'Integração'
          },
          {
            name: 'Co-piloto',
            type: 'Dinâmico',
            configuration: 'Definido pelo Líder'
          },
          {
            name: 'Nome Fake',
            type: 'Campo',
            configuration: 'Integração'
          }
        ]);
        setIsFetching(false);
      } catch (err) {
        setError(err);
      }
    }, 300);
  }, []);

  if (error) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching) {
    return <LoadingModal open={true} />;
  }

  return (
    <Grid>
      <GridCell span={6}>
        <h2>
          <Trans i18nKey="fields-settings.title">Custom fields</Trans>
        </h2>
      </GridCell>
      <GridCell span={6} style={{ textAlign: 'right' }}></GridCell>
      <GridCell span={12}>
        <FieldsSettingsTable fields={fields}></FieldsSettingsTable>
      </GridCell>
      <GridCell span={12}>
        <small>
          <Trans i18nKey="fields-settings.more">To add or update custom fields contact your account manager or support.</Trans>
        </small>
      </GridCell>
    </Grid>
  );
}

export default FieldsSettingsHome;
