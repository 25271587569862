import { FieldValues } from 'react-hook-form';
import HttpBaseService from '../../../services/HttpBaseService';
import LogsIntegrationOutboundRepository from './LogsIntegrationOutboundRepository';
import { LogsIntegration } from './LogsIntegrationInboundRepository';

const QUERY_CACHE_KEY = 'logs-integration-outbound';

class LogsIntegrationsOutboundService extends HttpBaseService<LogsIntegration> {
  constructor(private integrationsRepository = new LogsIntegrationOutboundRepository()) {
    super(QUERY_CACHE_KEY, integrationsRepository);
  }

  convertValuesToModel(values: FieldValues): LogsIntegration {
    return {
      id: values.id
    } as LogsIntegration;
  }
}

export default LogsIntegrationsOutboundService;
