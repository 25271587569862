import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import LoadingModal from '../../LoadingModal';

import CelebrationsWorkService from './CelebrationsWorkService';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { TextField } from '@rmwc/textfield';
import '@rmwc/textfield/styles';

import { IconButton } from '@rmwc/icon-button';
import '@rmwc/icon-button/styles';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/circular-progress.css';

import CelebrationsWorkTable from './CelebrationsWorkTable';
import ErrorMessage from '../../ErrorMessage';
import Breadcrumb from '../../common/Breadcrumb';
import EllaMessage from '../../common/EllaMessage';

import { dateExtractMonth } from '../../common/DatesFormat/dateExtractMonth';
import { dateAddMonths } from '../../common/DatesFormat/dateAddMonths';
import { dateFormatMonthInput } from '../../common/DatesFormat/dateFormatMonthInput';
import { Employee } from '../../Employee/EmployeeRepository';

const LIST_SIZE = 20;
const todayDate = new Date();

function CelebrationsWorkHome({ history }: InferProps<typeof CelebrationsWorkHome.propTypes>): JSX.Element {
  const { t } = useTranslation();
  const celebrationService = new CelebrationsWorkService();

  const [currentDate, setCurrentDate] = React.useState(new Date());
  const currentMonth = dateExtractMonth(currentDate);
  const [queryFilter, setQueryFilter] = React.useState(currentMonth);

  const searchFilter = { limit: LIST_SIZE, query: queryFilter };

  const { data, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage, isError, error } = celebrationService.useListSearch(searchFilter, LIST_SIZE);

  async function onClickPerson(values: { id: string }): Promise<void> {
    if (history) history.push(`/employees/${encodeURIComponent(values.id)}`);
  }

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching && !isFetchingNextPage) {
    return <LoadingModal open={true} />;
  }

  function handleOnDataInput(event: React.SyntheticEvent<HTMLInputElement>): void {
    const dateValue = event.currentTarget.value;
    const parsedDate = new Date(dateValue);
    setCurrentDate(parsedDate);
    const month = dateExtractMonth(parsedDate);
    setQueryFilter(month);
  }

  function handleSetNextMonth() {
    const nextMonth = dateAddMonths(currentDate, 1);
    setCurrentDate(nextMonth);
    const month = dateExtractMonth(nextMonth);
    setQueryFilter(month);
  }

  function handleSetPreviousMonth() {
    const nextMonth = dateAddMonths(currentDate, -1);
    setCurrentDate(nextMonth);
    const month = dateExtractMonth(nextMonth);
    setQueryFilter(month);
  }

  function handlePrintPage() {
    window.print();
  }

  const employeeData = data?.pages.flat();
  const hasDataCreated = employeeData && employeeData.length > 0;
  const filteredEmployeesByLastDateAndStartYear = hasDataCreated ? filterEmployeesByLastDateAndStartYear(employeeData) : undefined;

  return (
    <Grid>
      <GridCell span={12}>
        <Breadcrumb routeSegments={[{ name: t('navlink.celebrations') }, { name: t('navlink.celebration.work') }]} />
      </GridCell>
      <GridCell span={12}>
        <h2>
          <Trans i18nKey="celebrations.work.title">Work anniversary celebrations</Trans>
        </h2>
      </GridCell>

      <GridCell span={6} align="middle">
        <IconButton label={t('celebrations.date.previous', 'Previous month')} icon="arrow_back_ios" onClick={handleSetPreviousMonth} />
        <TextField label={t('celebrations.date.month', 'Month')} type="month" value={dateFormatMonthInput(currentDate)} onChange={handleOnDataInput} />
        <IconButton label={t('celebrations.date.next', 'Next month')} icon="arrow_forward_ios" onClick={handleSetNextMonth} />
      </GridCell>
      <GridCell className="no-print" span={6} align="middle" style={{ textAlign: 'right', marginRight: '15px' }}>
        <Button icon="picture_as_pdf" onClick={handlePrintPage}>
          <Trans i18nKey="celebrations.export.print">Print</Trans>
        </Button>
      </GridCell>

      {hasDataCreated ? (
        <>
          <GridCell span={12}>
            <CelebrationsWorkTable people={filteredEmployeesByLastDateAndStartYear} onRowClick={onClickPerson}></CelebrationsWorkTable>
          </GridCell>
          {hasNextPage && (
            <GridCell span={12} style={{ textAlign: 'center' }}>
              <Button
                outlined
                {...(isFetching ? { icon: <CircularProgress theme="secondary" /> } : {})}
                disabled={isFetching}
                onClick={(): void => {
                  fetchNextPage();
                }}
              >
                <Trans i18nKey="table.load-more">Load More</Trans>
              </Button>
            </GridCell>
          )}
        </>
      ) : (
        <CelebrationsWorkNoData></CelebrationsWorkNoData>
      )}
    </Grid>
  );
}

export function employeeLeftCompany(employeeLastDate: string | undefined): boolean {
  if (employeeLastDate) {
    const convertedLastDate = new Date(employeeLastDate);
    return convertedLastDate < todayDate;
  }

  return false;
}

function employeeStartedThisYear(employeeStartDate: string | undefined): boolean {
  if (employeeStartDate) {
    const convertedStartDate = new Date(employeeStartDate);
    return convertedStartDate.getFullYear() === todayDate.getFullYear();
  }

  return false;
}

function filterEmployeesByLastDateAndStartYear(employees: Employee[]): Employee[] {
  const filteredEmployees = employees.filter((employee) => {
    const leftCompany = employeeLeftCompany(employee.last_date);

    if (leftCompany) {
      return false;
    }

    const startedThisYear = employeeStartedThisYear(employee.start_date);

    return !startedThisYear;
  });

  return filteredEmployees;
}

function CelebrationsWorkNoData(): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <GridCell span={12} align="middle">
        <EllaMessage title={t('celebrations.work.nodata.title')} message={t('celebrations.work.nodata.text')} />
      </GridCell>
    </>
  );
}

CelebrationsWorkHome.propTypes = {
  history: PropTypes.any
};

export default withRouter(CelebrationsWorkHome);
