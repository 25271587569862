import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { FieldValues } from 'react-hook-form';

import LoadingModal from '../../LoadingModal';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import ContentsGlossaryService from './ContentsGlossaryService';

import ContentsGlossaryForm from './ContentsGlossaryForm';
import ErrorMessage from '../../ErrorMessage';
import Message from '../../../services/Message';
import Breadcrumb from '../../common/Breadcrumb';

function ContentsGlossaryEdit({ history }: InferProps<typeof ContentsGlossaryEdit.propTypes>): JSX.Element {
  const { id } = useParams() as { id: string };
  const { t } = useTranslation();

  const glossaryService = new ContentsGlossaryService();
  const useUpdateGlossary = glossaryService.useUpdate();

  const { data, isFetching, isError, error } = glossaryService.useGet(id);

  const onSubmit = async (values: FieldValues): Promise<void> => {
    try {
      const glossaryUpdated = await useUpdateGlossary.mutateAsync({ id, ...values });
      Message.success(t('contents.glossary.updated'));
      history.push(`/contents/glossary/${encodeURIComponent(glossaryUpdated.id || '')}`);
    } catch (error) {
      Message.error(t('contents.glossary.updated-error'));
      console.error('Error on save: ', error);
    }
  };

  const onCancel = async (): Promise<void> => {
    history.goBack();
  };

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching) {
    return <LoadingModal open={true} />;
  }

  return (
    <Grid>
      <GridCell span={12}>
        <Breadcrumb
          routeSegments={[
            { name: t('navlink.contents'), path: '/contents/glossary/' },
            { name: t('navlink.content.glossary'), path: '/contents/glossary/' },
            { name: t('contents.glossary.edit-title') }
          ]}
        />
      </GridCell>
      <GridCell span={12}>
        <h1>
          <Trans i18nKey="contents.glossary.edit-title">Update glossary</Trans>
        </h1>
      </GridCell>
      <GridCell span={12}>
        <ContentsGlossaryForm onFormSubmit={onSubmit} onCancel={onCancel} isLoading={useUpdateGlossary.isLoading} defaultValues={data}></ContentsGlossaryForm>
      </GridCell>
    </Grid>
  );
}

ContentsGlossaryEdit.propTypes = {
  history: PropTypes.any
};

export default ContentsGlossaryEdit;
