import { AxiosError } from 'axios';
import { FieldValues } from 'react-hook-form';
import HttpBaseService from '../../../services/HttpBaseService';
import { useMutation, UseMutationResult } from 'react-query';
import IntegrationsRepository, { Integrations } from './IntegrationsRepository';

const QUERY_CACHE_KEY = 'integrations';

interface CreateSlackAppParams {
  tenantId: string;
  code: string;
}

class IntegrationsService extends HttpBaseService<Integrations> {
  constructor(private integrationsRepository = new IntegrationsRepository()) {
    super(QUERY_CACHE_KEY, integrationsRepository);
  }

  convertValuesToModel(values: FieldValues): Integrations {
    return {
      name: values.name
    } as Integrations;
  }

  public useSaveSlackApp(): UseMutationResult<Integrations, AxiosError, CreateSlackAppParams> {
    return useMutation<Integrations, AxiosError, CreateSlackAppParams>(
      ({ tenantId, code }) => {
        return this.integrationsRepository.createSlackApp(tenantId, code);
      },
      {
        onMutate: () => {
          this.queryClient.cancelQueries(QUERY_CACHE_KEY);
        },
        onSuccess: () => {
          this.queryClient.invalidateQueries(QUERY_CACHE_KEY);
        }
      }
    );
  }
}

export default IntegrationsService;
