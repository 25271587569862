import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useForm, useWatch, Controller, FieldValues } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import AsyncSelect from 'react-select/async';

import { ListDivider } from 'rmwc';
import { GridRow, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { TextField } from '@rmwc/textfield';
import '@rmwc/textfield/styles';

import { Select } from '@rmwc/select';
import '@rmwc/select/styles';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/circular-progress.css';

import { Typography } from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

import { Icon } from '@rmwc/icon';
import '@rmwc/icon/styles';

import InstagramIcon from '../common/SocialMediaIcons/instagram.svg';
import LinkedinIcon from '../common/SocialMediaIcons/linkedin.svg';

import TooltipWrapper from '../common/TooltipWrapper/TooltipWrapper';
import EmployeeService from './EmployeeService';
import { Employee } from './EmployeeRepository';

const fullWithStyle = {
  width: '100%',
  backgroundColor: 'whitesmoke'
} as React.CSSProperties;

function EmployeeForm({ onFormSubmit, onCancel, defaultValues, isLoading }: InferProps<typeof EmployeeForm.propTypes>): JSX.Element {
  const { t } = useTranslation();
  const employeeService = new EmployeeService();

  if (defaultValues && !defaultValues.address) {
    defaultValues.address = {};
  }

  if (defaultValues?.leader) {
    defaultValues.leader = {
      value: defaultValues.leader.id || defaultValues.leader_id,
      label: `${defaultValues.leader.social_name || defaultValues.leader.first_name} ${defaultValues.leader.last_name}`
    };
  }
  const leaderDeafultOptions = defaultValues?.leader ? [defaultValues.leader] : [];

  if (defaultValues?.buddy) {
    defaultValues.buddy = {
      value: defaultValues.buddy.id,
      label: `${defaultValues.buddy.social_name || defaultValues.buddy.first_name} ${defaultValues.buddy.last_name}`
    };
  }
  const buddyDeafultOptions = defaultValues?.buddy ? [defaultValues.buddy] : [];

  const { control, handleSubmit, errors, setValue } = useForm({ defaultValues });
  const genderValue: string | undefined = useWatch({ control, name: 'gender' });

  const onSubmit = async (values: FieldValues): Promise<void> => {
    onFormSubmit(values);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function searchEmployeeSelect(inputValue: string): Promise<any> {
    const employeesSearch = await employeeService.listSearchEmployees(inputValue);
    const selectOptions = employeesSearch.map((employee: Employee) => {
      const fullName = `${employee.social_name || employee.first_name} ${employee.last_name}`;
      const complement = employee.job_title || employee.department || employee.work_email;
      return {
        value: employee.id,
        label: `${fullName} ${complement ? '(' + complement + ')' : ''}`
      };
    });
    return selectOptions;
  }

  async function generateFakeName(): Promise<string> {
    const nameGenerator = require('leite/lib/pessoa/nome'); // eslint-disable-line @typescript-eslint/no-var-requires
    if (genderValue && genderValue === 'M') {
      return nameGenerator({ sexo: 'Masculino' });
    } else if (genderValue && genderValue === 'F') {
      return nameGenerator({ sexo: 'Feminino' });
    }
    return nameGenerator();
  }

  const onClickGenerateFakeName = async (): Promise<void> => {
    const newFakeName = await generateFakeName();
    setValue('fake_name', newFakeName, { shouldValidate: true });
  };

  const lockedFields = defaultValues && defaultValues.origin_type !== 'MANUAL';
  const disableFields = !!isLoading || lockedFields;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GridRow>
        <GridCell span={12}>
          <h2>
            <Trans i18nKey="employee.form.data.label">Personal data</Trans>
          </h2>
        </GridCell>
        <GridCell span={6}>
          <Controller
            control={control}
            defaultValue=""
            name="first_name"
            rules={{ required: t('employee.form.first_name.required', 'The first name is required.') as string }}
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="employee.form.first_name.label">What is the employee first name?</Trans>
                  </label>
                </Typography>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  placeholder={t('employee.form.first_name.placeholder')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.first_name?.message ? true : false}
                  aria-invalid={errors?.first_name?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />
              </>
            )}
          />
          {errors?.first_name?.message && <div role="alert">{errors?.first_name?.message}</div>}
        </GridCell>
        <GridCell span={6}>
          <Controller
            control={control}
            defaultValue=""
            name="last_name"
            rules={{ required: t('employee.form.last_name.required', 'The last name is required.') as string }}
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="employee.form.last_name.label">Last name?</Trans>
                  </label>
                </Typography>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  placeholder={t('employee.form.last_name.placeholder')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.last_name?.message ? true : false}
                  aria-invalid={errors?.last_name?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />
              </>
            )}
          />
          {errors?.last_name?.message && <div role="alert">{errors?.last_name?.message}</div>}
        </GridCell>
        <GridCell span={6}>
          <Controller
            control={control}
            defaultValue=""
            name="social_name"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="employee.form.social_name.label">How would you like to be called?</Trans>
                  </label>
                </Typography>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  placeholder={t('employee.form.social_name.placeholder')}
                  helpText={t('employee.form.social_name.help-text')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.social_name?.message ? true : false}
                  aria-invalid={errors?.social_name?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />
              </>
            )}
          />
          {errors?.social_name?.message && <div role="alert">{errors?.social_name?.message}</div>}
        </GridCell>
        <GridCell span={6}>
          <Controller
            control={control}
            defaultValue=""
            name="gender_pronouns"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="employee.form.gender_pronouns.label">Gender pronouns</Trans>
                  </label>
                </Typography>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  placeholder={t('employee.form.gender_pronouns.placeholder')}
                  helpText={t('employee.form.gender_pronouns.help-text')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.gender_pronouns?.message ? true : false}
                  aria-invalid={errors?.gender_pronouns?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />
              </>
            )}
          />
          {errors?.gender_pronouns?.message && <div role="alert">{errors?.gender_pronouns?.message}</div>}
        </GridCell>
        <GridCell span={6}>
          <Controller
            control={control}
            defaultValue=""
            name="short_name"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="employee.form.short_name.label">Short name</Trans>
                  </label>
                </Typography>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  placeholder={t('employee.form.short_name.placeholder')}
                  helpText={t('employee.form.short_name.help-text')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.short_name?.message ? true : false}
                  aria-invalid={errors?.short_name?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />
              </>
            )}
          />
          {errors?.short_name?.message && <div role="alert">{errors?.short_name?.message}</div>}
        </GridCell>
        <GridCell span={6}>
          <Controller
            control={control}
            defaultValue=""
            name="fake_name"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="employee.form.fake_name.label">Fake name</Trans>
                  </label>
                </Typography>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  placeholder={t('employee.form.fake_name.placeholder')}
                  helpText={t('employee.form.fake_name.help-text')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.fake_name?.message ? true : false}
                  aria-invalid={errors?.fake_name?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                  trailingIcon={{
                    icon: 'auto_mode',
                    tabIndex: 0,
                    onClick: onClickGenerateFakeName,
                    label: t('employee.form.fake_name.generate-button'),
                    title: t('employee.form.fake_name.generate-button')
                  }}
                />
              </>
            )}
          />
          {errors?.fake_name?.message && <div role="alert">{errors?.fake_name?.message}</div>}
        </GridCell>
        <GridCell span={6}>
          <Controller
            control={control}
            defaultValue=""
            name="personal_email"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="employee.form.personal_email.label">Personal email</Trans>
                  </label>
                </Typography>
                <TextField
                  type="email"
                  name={name}
                  placeholder={t('employee.form.personal_email.placeholder')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.personal_email?.message ? true : false}
                  aria-invalid={errors?.personal_email?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />
              </>
            )}
          />
          {errors?.personal_email?.message && <div role="alert">{errors?.personal_email?.message}</div>}
        </GridCell>
        <GridCell span={6}>
          <Controller
            control={control}
            defaultValue=""
            name="work_email"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="employee.form.work_email.label">Work email</Trans>
                  </label>
                </Typography>
                <TextField
                  type="email"
                  name={name}
                  placeholder={t('employee.form.work_email.placeholder')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.work_email?.message ? true : false}
                  aria-invalid={errors?.work_email?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />
              </>
            )}
          />
          {errors?.work_email?.message && <div role="alert">{errors?.work_email?.message}</div>}
        </GridCell>
        <GridCell span={6}>
          <Controller
            control={control}
            defaultValue=""
            name="mobile_number"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="employee.form.mobile_number.label">Mobile</Trans>
                  </label>
                </Typography>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  placeholder={t('employee.form.mobile_number.placeholder')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.mobile_number?.message ? true : false}
                  aria-invalid={errors?.mobile_number?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />
              </>
            )}
          />
          {errors?.mobile_number?.message && <div role="alert">{errors?.mobile_number?.message}</div>}
        </GridCell>
        <GridCell span={6}>
          <Controller
            control={control}
            defaultValue=""
            name="phone_number"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="employee.form.phone_number.label">Phone</Trans>
                  </label>
                </Typography>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  placeholder={t('employee.form.phone_number.placeholder')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.phone_number?.message ? true : false}
                  aria-invalid={errors?.phone_number?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />
              </>
            )}
          />
          {errors?.phone_number?.message && <div role="alert">{errors?.phone_number?.message}</div>}
        </GridCell>
        <GridCell span={6}>
          <Controller
            control={control}
            defaultValue=""
            name="birth_date"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="employee.form.birth_date.label">Birthday</Trans>
                  </label>
                </Typography>
                <TextField
                  type="date"
                  name={name}
                  placeholder={t('employee.form.birth_date.placeholder')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.birth_date?.message ? true : false}
                  aria-invalid={errors?.birth_date?.message ? true : false}
                  disabled={!!isLoading}
                />
              </>
            )}
          />
          {errors?.birth_date?.message && <div role="alert">{errors?.birth_date?.message}</div>}
        </GridCell>
        <GridCell span={6}>
          <Controller
            control={control}
            defaultValue=""
            name="education"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <InputFormField inputName={name} inputLocked={lockedFields} inputLabel={<Trans i18nKey="employee.form.education.label">Education</Trans>}>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  disabled={disableFields}
                  readOnly={disableFields}
                  style={fullWithStyle}
                />
              </InputFormField>
            )}
          />
        </GridCell>
        <GridCell span={6}>
          <Controller
            control={control}
            defaultValue=""
            name="gender"
            render={({ onChange, onBlur, value, name }): React.ReactElement => (
              <InputFormField inputName={name} inputLocked={lockedFields} inputLabel={<Trans i18nKey="employee.form.gender.label">Gender</Trans>}>
                <Select
                  enhanced
                  id={name}
                  name={name}
                  placeholder=""
                  options={[
                    { label: '', value: '' },
                    { label: 'M', value: 'M' },
                    { label: 'F', value: 'F' }
                  ]}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  disabled={disableFields}
                  readOnly={disableFields}
                  style={fullWithStyle}
                />
              </InputFormField>
            )}
          />
        </GridCell>
        <GridCell span={6}>
          <Controller
            control={control}
            defaultValue=""
            name="ethnicity"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <InputFormField inputName={name} inputLocked={lockedFields} inputLabel={<Trans i18nKey="employee.form.ethnicity.label">Ethnicity</Trans>}>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  disabled={disableFields}
                  readOnly={disableFields}
                  style={fullWithStyle}
                />
              </InputFormField>
            )}
          />
        </GridCell>
        <GridCell span={12}>
          <ListDivider style={{ marginTop: '20px' }} />
          <h2>
            <Trans i18nKey="employee.form.company.label">Company data</Trans>
          </h2>
        </GridCell>
        <GridCell span={6}>
          <Controller
            control={control}
            defaultValue=""
            name="start_date"
            rules={{ required: t('employee.form.start_date.required', 'Start date is required.') as string }}
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="employee.form.start_date.label">What is the first day date?</Trans>
                  </label>
                </Typography>
                <TextField
                  type="date"
                  name={name}
                  placeholder={t('employee.form.start_date.placeholder')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.start_date?.message ? true : false}
                  aria-invalid={errors?.start_date?.message ? true : false}
                  disabled={!!isLoading}
                />
              </>
            )}
          />
          {errors?.start_date?.message && <div role="alert">{errors?.start_date?.message}</div>}
        </GridCell>
        <GridCell span={6}>
          <Controller
            control={control}
            defaultValue=""
            name="last_date"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="employee.form.last_date.label">What is the last day of work?</Trans>
                  </label>
                </Typography>
                <TextField
                  type="date"
                  name={name}
                  placeholder={t('employee.form.last_date.placeholder')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.last_date?.message ? true : false}
                  aria-invalid={errors?.last_date?.message ? true : false}
                  disabled={!!isLoading}
                />
              </>
            )}
          />
          {errors?.last_date?.message && <div role="alert">{errors?.last_date?.message}</div>}
        </GridCell>
        <GridCell span={6}>
          <Controller
            control={control}
            defaultValue=""
            name="leader"
            render={({ onChange, onBlur, value, name }): React.ReactElement => (
              <InputFormField inputName={name} inputLocked={false} inputLabel={<Trans i18nKey="employee.form.leader.label">Leader/Manager</Trans>}>
                <AsyncSelect
                  id={name}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  cacheOptions
                  placeholder={t('employee.form.leader.placeholder') as string}
                  noOptionsMessage={() => t('employee.form.leader.no-options')}
                  defaultOptions={leaderDeafultOptions}
                  loadOptions={searchEmployeeSelect}
                  className="material-ui-select"
                />
              </InputFormField>
            )}
          />
        </GridCell>

        <GridCell span={6}>
          <Controller
            control={control}
            defaultValue=""
            name="buddy"
            render={({ onChange, onBlur, value, name }): React.ReactElement => (
              <InputFormField inputName={name} inputLocked={false} inputLabel={<Trans i18nKey="employee.form.buddy.label">Buddy</Trans>}>
                <AsyncSelect
                  id={name}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  cacheOptions
                  placeholder={t('employee.form.buddy.placeholder') as string}
                  noOptionsMessage={() => t('employee.form.buddy.no-options')}
                  defaultOptions={buddyDeafultOptions}
                  loadOptions={searchEmployeeSelect}
                  className="material-ui-select"
                />
              </InputFormField>
            )}
          />
        </GridCell>
        <GridCell span={6}>
          <Controller
            control={control}
            defaultValue=""
            name="cost_center"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <InputFormField inputName={name} inputLocked={lockedFields} inputLabel={<Trans i18nKey="employee.form.cost_center.label">Cost Center</Trans>}>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  disabled={disableFields}
                  readOnly={disableFields}
                  style={fullWithStyle}
                />
              </InputFormField>
            )}
          />
        </GridCell>
        <GridCell span={6}>
          <Controller
            control={control}
            defaultValue=""
            name="cost_category"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <InputFormField inputName={name} inputLocked={lockedFields} inputLabel={<Trans i18nKey="employee.form.cost_category.label">Cost Category</Trans>}>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  disabled={disableFields}
                  readOnly={disableFields}
                  style={fullWithStyle}
                />
              </InputFormField>
            )}
          />
        </GridCell>
        <GridCell span={6}>
          <Controller
            control={control}
            defaultValue=""
            name="job_title"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <InputFormField inputName={name} inputLocked={lockedFields} inputLabel={<Trans i18nKey="employee.form.job_title.label">Job title</Trans>}>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  disabled={disableFields}
                  readOnly={disableFields}
                  style={fullWithStyle}
                />
              </InputFormField>
            )}
          />
        </GridCell>
        <GridCell span={6}>
          <Controller
            control={control}
            defaultValue=""
            name="department"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <InputFormField inputName={name} inputLocked={lockedFields} inputLabel={<Trans i18nKey="employee.form.department.label">Department</Trans>}>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  disabled={disableFields}
                  readOnly={disableFields}
                  style={fullWithStyle}
                />
              </InputFormField>
            )}
          />
        </GridCell>
        <GridCell span={6}>
          <Controller
            control={control}
            defaultValue=""
            name="contract_type"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <InputFormField inputName={name} inputLocked={lockedFields} inputLabel={<Trans i18nKey="employee.form.contract_type.label">Contract type</Trans>}>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  disabled={disableFields}
                  readOnly={disableFields}
                  style={fullWithStyle}
                />
              </InputFormField>
            )}
          />
        </GridCell>
        <GridCell span={6}>
          <Controller
            control={control}
            defaultValue=""
            name="experience_period_days"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <InputFormField inputName={name} inputLocked={lockedFields} inputLabel={<Trans i18nKey="employee.form.experience_period_days.label">Experience period</Trans>}>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  disabled={disableFields}
                  readOnly={disableFields}
                  style={fullWithStyle}
                />
              </InputFormField>
            )}
          />
        </GridCell>
        <GridCell span={12}>
          <ListDivider style={{ marginTop: '20px' }} />
          <h2>
            <Trans i18nKey="employee.form.address.label">Address</Trans>
          </h2>
        </GridCell>
        <GridCell span={6}>
          <Controller
            control={control}
            defaultValue=""
            name="address.zip_code"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="employee.form.address.zip_code.label">Zip Code</Trans>
                  </label>
                </Typography>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  placeholder={t('employee.form.address.zip_code.placeholder')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.address?.zip_code?.message ? true : false}
                  aria-invalid={errors?.address?.zip_code?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />
              </>
            )}
          />
          {errors?.address?.zip_code?.message && <div role="alert">{errors?.address?.zip_code?.message}</div>}
        </GridCell>
        <GridCell span={9}>
          <Controller
            control={control}
            defaultValue=""
            name="address.address"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="employee.form.address.address.label">Address</Trans>
                  </label>
                </Typography>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  placeholder={t('employee.form.address.address.placeholder')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.address?.address?.message ? true : false}
                  aria-invalid={errors?.address?.address?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />
              </>
            )}
          />
          {errors?.address?.address?.message && <div role="alert">{errors?.address?.address?.message}</div>}
        </GridCell>
        <GridCell span={3}>
          <Controller
            control={control}
            defaultValue=""
            name="address.number"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="employee.form.address.number.label">Number</Trans>
                  </label>
                </Typography>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  placeholder={t('employee.form.address.number.placeholder')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.address?.number?.message ? true : false}
                  aria-invalid={errors?.address?.number?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />
              </>
            )}
          />
          {errors?.address?.number?.message && <div role="alert">{errors?.address?.number?.message}</div>}
        </GridCell>
        <GridCell span={12}>
          <Controller
            control={control}
            defaultValue=""
            name="address.complement"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="employee.form.address.complement.label">Complement</Trans>
                  </label>
                </Typography>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  placeholder={t('employee.form.address.complement.placeholder')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.address?.complement?.message ? true : false}
                  aria-invalid={errors?.address?.complement?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />
              </>
            )}
          />
          {errors?.address?.complement?.message && <div role="alert">{errors?.address?.complement?.message}</div>}
        </GridCell>
        <GridCell span={4}>
          <Controller
            control={control}
            defaultValue=""
            name="address.district"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="employee.form.address.district.label">District</Trans>
                  </label>
                </Typography>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  placeholder={t('employee.form.address.district.placeholder')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.address?.district?.message ? true : false}
                  aria-invalid={errors?.address?.district?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />
              </>
            )}
          />
          {errors?.address?.district?.message && <div role="alert">{errors?.address?.district?.message}</div>}
        </GridCell>
        <GridCell span={3}>
          <Controller
            control={control}
            defaultValue=""
            name="address.state"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="employee.form.address.state.label">State</Trans>
                  </label>
                </Typography>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  placeholder={t('employee.form.address.state.placeholder')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.address?.state?.message ? true : false}
                  aria-invalid={errors?.address?.state?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />
              </>
            )}
          />
          {errors?.address?.state?.message && <div role="alert">{errors?.address?.state?.message}</div>}
        </GridCell>
        <GridCell span={5}>
          <Controller
            control={control}
            defaultValue=""
            name="address.city"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="employee.form.address.city.label">City</Trans>
                  </label>
                </Typography>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  placeholder={t('employee.form.address.city.placeholder')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.address?.city?.message ? true : false}
                  aria-invalid={errors?.address?.city?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />
              </>
            )}
          />
          {errors?.address?.city?.message && <div role="alert">{errors?.address?.city?.message}</div>}
        </GridCell>
        <GridCell span={12}>
          <ListDivider style={{ marginTop: '20px' }} />
          <h2>
            <Trans i18nKey="employee.form.social-medias.label">Social Medias</Trans>
          </h2>
        </GridCell>
        <GridCell span={12}>
          <Controller
            control={control}
            defaultValue=""
            name="instagram_handle"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="employee.form.instagram_handle.label">Instagram</Trans>
                  </label>
                </Typography>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  placeholder={t('employee.form.instagram_handle.placeholder')}
                  helpText={t('employee.form.instagram_handle.help-text')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.instagram_handle?.message ? true : false}
                  aria-invalid={errors?.instagram_handle?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                  icon={{ icon: InstagramIcon }}
                />
              </>
            )}
          />
          {errors?.instagram_handle?.message && <div role="alert">{errors?.instagram_handle?.message}</div>}
        </GridCell>
        <GridCell span={12}>
          <Controller
            control={control}
            defaultValue=""
            name="linkedin_url"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="employee.form.linkedin_url.label">Linkedin</Trans>
                  </label>
                </Typography>
                <TextField
                  type="url"
                  id={name}
                  name={name}
                  placeholder={t('employee.form.linkedin_url.placeholder')}
                  helpText={t('employee.form.linkedin_url.help-text')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.linkedin_url?.message ? true : false}
                  aria-invalid={errors?.linkedin_url?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                  icon={{ icon: LinkedinIcon }}
                />
              </>
            )}
          />
          {errors?.linkedin_url?.message && <div role="alert">{errors?.linkedin_url?.message}</div>}
        </GridCell>
        <GridCell span={12}>
          <ListDivider style={{ marginTop: '20px' }} />
          <h2>
            <Trans i18nKey="employee.form.notes.label">Notes</Trans>
          </h2>
        </GridCell>
        <GridCell span={4}>
          <Controller
            control={control}
            defaultValue=""
            name="notes_software"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="employee.form.notes_software.label">Software licenses</Trans>
                  </label>
                </Typography>
                <TextField
                  type="text"
                  textarea
                  rows={3}
                  id={name}
                  name={name}
                  placeholder={t('employee.form.notes_software.placeholder')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.notes_software?.message ? true : false}
                  aria-invalid={errors?.notes_software?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />
              </>
            )}
          />
          {errors?.notes_software?.message && <div role="alert">{errors?.notes_software?.message}</div>}
        </GridCell>
        <GridCell span={4}>
          <Controller
            control={control}
            defaultValue=""
            name="notes_equipament"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="employee.form.notes_equipament.label">Equipaments and hardwares</Trans>
                  </label>
                </Typography>
                <TextField
                  type="text"
                  textarea
                  rows={3}
                  id={name}
                  name={name}
                  placeholder={t('employee.form.notes_equipament.placeholder')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.notes_equipament?.message ? true : false}
                  aria-invalid={errors?.notes_equipament?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />
              </>
            )}
          />
          {errors?.notes_equipament?.message && <div role="alert">{errors?.notes_equipament?.message}</div>}
        </GridCell>
        <GridCell span={4}>
          <Controller
            control={control}
            defaultValue=""
            name="notes_other"
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="employee.form.notes_other.label">Other notes</Trans>
                  </label>
                </Typography>
                <TextField
                  type="text"
                  textarea
                  rows={3}
                  id={name}
                  name={name}
                  placeholder={t('employee.form.notes_other.placeholder')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.notes_other?.message ? true : false}
                  aria-invalid={errors?.notes_other?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />
              </>
            )}
          />
          {errors?.notes_other?.message && <div role="alert">{errors?.notes_other?.message}</div>}
        </GridCell>
        {Object.keys(errors).length > 0 && (
          <GridCell span={12} style={{ textAlign: 'right' }}>
            <Typography use="body1" tag="p" theme="error" className="field-validation-text">
              <Trans i18nKey="crud.form.errors">There are field validations. Check the fields for more details.</Trans>
            </Typography>
          </GridCell>
        )}
        <GridCell span={12} style={{ textAlign: 'right' }}>
          <Button type="button" onClick={onCancel} style={{ marginRight: '1.5rem' }}>
            <Trans i18nKey="crud.cancel">Cancel</Trans>
          </Button>
          <Button type="submit" raised {...(isLoading ? { icon: <CircularProgress theme="secondary" /> } : {})}>
            {defaultValues && defaultValues.id ? <Trans i18nKey="employee.save-update">Update employee</Trans> : <Trans i18nKey="employee.save-new">Create new employee</Trans>}
          </Button>
        </GridCell>
      </GridRow>
    </form>
  );
}

function InputFormField({
  children,
  inputName,
  inputLabel,
  inputLocked
}: {
  children: JSX.Element;
  inputName: string;
  inputLabel: JSX.Element;
  inputLocked: boolean;
}): JSX.Element {
  return (
    <InputWrapLockedTooltip inputLocked={inputLocked}>
      <>
        <Typography use="body1" theme={!inputLocked ? '' : 'textDisabledOnBackground'} tag="p">
          <label htmlFor={inputName}>
            {inputLabel}
            {!!inputLocked && <Icon icon={{ icon: 'lock', size: 'xsmall' }} style={{ verticalAlign: 'middle', margin: '0 5px', paddingBottom: '2px' }} />}
          </label>
        </Typography>
        <>{children}</>
      </>
    </InputWrapLockedTooltip>
  );
}

function InputWrapLockedTooltip({ children, inputLocked }: { children: JSX.Element; inputLocked: boolean }): JSX.Element {
  const { t } = useTranslation();
  const lockedContentText = t('form.field.locked', 'Field locked because it was created by integration. Please edit in the source system.');

  return (
    <TooltipWrapper showTooltip={!!inputLocked} tooltipText={lockedContentText}>
      {children}
    </TooltipWrapper>
  );
}

EmployeeForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  defaultValues: PropTypes.any,
  isLoading: PropTypes.bool
};

export default EmployeeForm;
