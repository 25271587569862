import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import LoadingModal from '../LoadingModal';

import styles from './Start.module.css';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Theme } from '@rmwc/theme';
import { Typography } from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

import { Avatar } from '@rmwc/avatar';
import '@rmwc/avatar/avatar.css';

import { Elevation } from '@rmwc/elevation';
import '@material/elevation/dist/mdc.elevation.css';

import Ella from '../Brand/ella.svg';
import Startup from '../Ilustrations/startup.svg';
import SmallCompany from '../Ilustrations/small-company.svg';
import MediumCompany from '../Ilustrations/medium-company.svg';
import LargeCompany from '../Ilustrations/large-company.svg';
import Enterprise from '../Ilustrations/enterprise.svg';
import NonProfit from '../Ilustrations/non-profit.svg';

import userService from '../../services/UserService';
import messageSnackbarQueue from '../MessagesSnackbar/MessagesSnackbarQueue';

function StartCompanySize({ history }: InferProps<typeof StartCompanySize.propTypes>): JSX.Element {
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation();

  function handleOnClick({ loading = true }): void {
    setLoading(loading);
  }

  return (
    <>
      <Helmet>
        <title>{t('start2.title')}</title>
      </Helmet>
      <LoadingModal open={loading} />

      <Grid className={styles.startHeader}>
        <GridCell phone={1} tablet={2} desktop={2} align="middle" className={styles.avatarCell}>
          <Avatar src={Ella} name="Ella" size="large" className={styles.ellaAvatar} />
        </GridCell>
        <GridCell phone={3} tablet={6} desktop={10} tabIndex={0}>
          <Typography use="headline5" tag="h1">
            <Trans i18nKey="start2.header">How big is your company?</Trans>
          </Typography>
          <p>
            <Trans i18nKey="start2.text">Choose the option that best fits youy company!</Trans>
          </p>
        </GridCell>
      </Grid>

      <Grid className={styles.answersGrid}>
        <GridCell phone={2} tablet={4} desktop={4}>
          <ElevationCardAnswer
            answerImage={Startup}
            answerKey="micro"
            answerText={t('start2.micro')}
            answerHelp={t('start2.micro-help')}
            handleOnClick={handleOnClick}
            history={history}
          />
        </GridCell>
        <GridCell phone={2} tablet={4} desktop={4}>
          <ElevationCardAnswer
            answerImage={SmallCompany}
            answerKey="small"
            answerText={t('start2.small')}
            answerHelp={t('start2.small-help')}
            handleOnClick={handleOnClick}
            history={history}
          />
        </GridCell>
        <GridCell phone={2} tablet={4} desktop={4}>
          <ElevationCardAnswer
            answerImage={MediumCompany}
            answerKey="medium"
            answerText={t('start2.medium')}
            answerHelp={t('start2.medium-help')}
            handleOnClick={handleOnClick}
            history={history}
          />
        </GridCell>
        <GridCell phone={2} tablet={4} desktop={4}>
          <ElevationCardAnswer
            answerImage={LargeCompany}
            answerKey="large"
            answerText={t('start2.large')}
            answerHelp={t('start2.large-help')}
            handleOnClick={handleOnClick}
            history={history}
          />
        </GridCell>
        <GridCell phone={2} tablet={4} desktop={4}>
          <ElevationCardAnswer
            answerImage={Enterprise}
            answerKey="enterprise"
            answerText={t('start2.enterprise')}
            answerHelp={t('start2.enterprise-help')}
            handleOnClick={handleOnClick}
            history={history}
          />
        </GridCell>
        <GridCell phone={2} tablet={4} desktop={4}>
          <ElevationCardAnswer
            answerImage={NonProfit}
            answerKey="charity"
            answerText={t('start2.charity')}
            answerHelp={t('start2.charity-help')}
            handleOnClick={handleOnClick}
            history={history}
          />
        </GridCell>
      </Grid>
    </>
  );
}

function ElevationCardAnswer({ answerImage, answerKey, answerText, answerHelp, handleOnClick, history }: InferProps<typeof ElevationCardAnswer.propTypes>): JSX.Element {
  async function handleAnswerOnClick(): Promise<void> {
    handleOnClick({ loading: true });
    try {
      await userService.updateCompanySize(answerKey);
      handleOnClick({ loading: false });
      history.push('/start/challenge');
    } catch (error) {
      console.log('Error saving: ', error);
      const { message } = error as Error;
      messageSnackbarQueue.notifyError(message);
      handleOnClick({ loading: false });
    }
  }

  return (
    <Theme use="primary">
      <Elevation z={2} className={styles.answerCard} onClick={handleAnswerOnClick} onKeyPress={handleAnswerOnClick} tabIndex={0}>
        <img src={answerImage} aria-hidden="true" alt={answerText} className={styles.answerIlustration} />
        <p className={styles.answerTextWithSubtitle}>{answerText}</p>
        <p className={styles.answerSubtitle}>{answerHelp}</p>
      </Elevation>
    </Theme>
  );
}

StartCompanySize.propTypes = {
  history: PropTypes.any
};

ElevationCardAnswer.propTypes = {
  answerImage: PropTypes.string.isRequired,
  answerKey: PropTypes.string.isRequired,
  answerText: PropTypes.string.isRequired,
  answerHelp: PropTypes.string,
  handleOnClick: PropTypes.func.isRequired,
  history: PropTypes.any
};

export default withRouter(StartCompanySize);
