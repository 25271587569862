import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import WelcomeSection from '../../WelcomeSection';

function AutomationHome(): JSX.Element {
  const { t } = useTranslation();

  return (
    <WelcomeSection betaFeatureForm={t('automation.beta-name', 'Automation')}>
      <p>
        <Trans i18nKey="automation.beta">
          We are in beta. If you would like to have early access to the Automation feature, please contact us on
          <a href="mailto:hello@inboarding.co">hello@inboarding.co</a>.
        </Trans>
      </p>
      <p>
        <Trans i18nKey="automation.beta-feature">
          With the automation you will be able to create rules and automation of process, for example, you can define a rule to assign a journey to an employee based on rules and
          criteria.
        </Trans>
      </p>
    </WelcomeSection>
  );
}

export default AutomationHome;
