import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import authService from '../../../services/AuthService';
import Breadcrumb from '../../common/Breadcrumb';

function SupportHome(): JSX.Element {
  const { t } = useTranslation();
  const authUser = authService.getAuthUser();

  return (
    <Grid>
      <GridCell span={12}>
        <Breadcrumb routeSegments={[{ name: t('navlink.help') }]} />
      </GridCell>
      <GridCell span={12} style={{ minHeight: '1000px' }}>
        <iframe
          title="Inboarding suporte"
          src={`https://share.hsforms.com/1D4zonhWwR4uuFoU-aREC9Q3e2oh?email=${authUser.email}`}
          width="100%"
          height="100%"
          frameBorder="0"
          style={{ width: '100%', height: '100%', border: '0', minHeight: '500px' }}
        />
      </GridCell>
      <GridCell span={12}>
        <small>
          Se tiver problema com o formulário acima,{' '}
          <a href="https://share.hsforms.com/1D4zonhWwR4uuFoU-aREC9Q3e2oh" target="_blank" rel="noopener noreferrer">
            clique aqui para abrir em um nova página
          </a>{' '}
          ou envie um email para <a href="mailto:hello@inboarding.co">hello@inboarding.co</a>.
        </small>
      </GridCell>
      <GridCell span={12}>
        <small>
          Version {BUILD_PACKAGE_VERSION} - Build {new Date(BUILD_VERSION_DATETIME).toLocaleString()} - API {BUILD_APP_ENV}
        </small>
      </GridCell>
    </Grid>
  );
}

export default SupportHome;
