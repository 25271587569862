import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { FieldValues } from 'react-hook-form';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import JourneyService from './JourneyService';

import JourneyForm from './JourneyForm';
import LoadingModal from '../LoadingModal';
import ErrorMessage from '../ErrorMessage';
import Message from '../../services/Message';
import Breadcrumb from '../common/Breadcrumb';

function JourneyEdit({ history }: InferProps<typeof JourneyEdit.propTypes>): JSX.Element {
  const { t } = useTranslation();
  const { id } = useParams() as { id: string };

  const journeyService = new JourneyService();
  const useUpdateJourney = journeyService.useUpdate();

  const { data, isFetching, isError, error } = journeyService.useGet(id);

  const onSubmit = async (values: FieldValues): Promise<void> => {
    try {
      const journeyUpdated = await useUpdateJourney.mutateAsync({ id, ...values });
      Message.success(t('journey.updated'));
      history.push(`/journeys/${journeyUpdated.id}`);
    } catch (error) {
      Message.error(t('journey.updated-error'));
      console.error('Error on save: ', error);
    }
  };

  const onCancel = async (): Promise<void> => {
    history.goBack();
  };

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching) {
    return <LoadingModal open={true} />;
  }

  return (
    <Grid>
      <GridCell span={12}>
        <Breadcrumb routeSegments={[{ name: t('navlink.journeys'), path: '/journeys' }, { name: t('journey.edit-title') }]} />
      </GridCell>
      <GridCell span={12}>
        <h1>
          <Trans i18nKey="journey.edit-title">Update journey</Trans>
        </h1>
      </GridCell>
      <GridCell span={3}></GridCell>
      <GridCell span={6}>
        <JourneyForm onFormSubmit={onSubmit} onCancel={onCancel} isLoading={useUpdateJourney.isLoading} defaultValues={data}></JourneyForm>
      </GridCell>
      <GridCell span={3}></GridCell>
    </Grid>
  );
}

JourneyEdit.propTypes = {
  history: PropTypes.any
};

export default JourneyEdit;
