import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Typography, TextField } from 'rmwc';
import { fullWithStyle } from '../../StepJourneyForm';

export interface RequiredProps {
  i18nKey: string;
  requiredText: string;
}

interface LabelProps {
  labelText: string;
  htmlFor: string;
  i18nKey: string;
}

export function TextQuestion({
  control,
  property,
  labelProps,
  controllerName,
  invalid,
  block,
  isLoading,
  bigTextField,
  requiredProps,
  defaultValue,
  placeholder,
  type
}: {
  control: Control;
  property: string;
  labelProps: LabelProps;
  controllerName: string;
  invalid: string;
  block: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  isLoading: boolean;
  bigTextField?: boolean;
  requiredProps?: RequiredProps;
  defaultValue?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  placeholder?: string;
  type?: string;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <Typography use="body1" tag="p">
        <label htmlFor={labelProps.htmlFor}>
          <Trans i18nKey={labelProps.i18nKey}>{labelProps.labelText}</Trans>
        </label>
      </Typography>
      <Controller
        control={control}
        defaultValue={defaultValue || block[property] || ''}
        name={controllerName}
        rules={requiredProps ? { required: t(requiredProps.i18nKey, requiredProps.requiredText) as string } : {}}
        render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
          <TextField
            textarea={bigTextField}
            rows={bigTextField ? 1 : undefined}
            type={type ?? 'text'}
            id={name}
            name={name}
            placeholder={placeholder || t(`journey-step.form.blocks.form.${property}.placeholder`)}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            inputRef={ref}
            invalid={invalid ? true : false}
            aria-invalid={invalid ? true : false}
            disabled={!!isLoading}
            style={fullWithStyle}
          />
        )}
      />
      {invalid && <div role="alert">{invalid}</div>}
    </>
  );
}
