export const dateIsToday = (dateISO: string): boolean => {
  if (!dateISO) {
    return false;
  }

  const date = new Date(dateISO);
  if (!date) {
    return false;
  }

  const nowDate = new Date();

  return date.getUTCDate() === nowDate.getDate() && date.getUTCMonth() === nowDate.getMonth();
};

export const dateIsTomorrow = (dateISO: string): boolean => {
  if (!dateISO) {
    return false;
  }

  const date = new Date(dateISO);
  if (!date) {
    return false;
  }

  const tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);

  return date.getUTCDate() === tomorrowDate.getDate() && date.getUTCMonth() === tomorrowDate.getMonth();
};

export const dateIsThisWeek = (dateISO: string): boolean => {
  if (!dateISO) {
    return false;
  }

  const date = new Date(dateISO);
  if (!date) {
    return false;
  }

  const nowDate = new Date();
  const todayDate = nowDate.getDate();
  const todayDay = nowDate.getDay();

  const firstDayOfWeek = new Date(nowDate.setDate(todayDate - todayDay));

  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(todayDate + 6);

  date.setFullYear(nowDate.getFullYear());

  return date >= firstDayOfWeek && date <= lastDayOfWeek;
};
