import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import styles from '../Journey/StepJourneyPreview.module.css';

import { Grid, GridRow } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Dialog, DialogTitle, DialogContent, DialogActions, DialogButton } from '@rmwc/dialog';
import '@rmwc/dialog/styles';

import { Typography } from '@rmwc/typography';
import '@rmwc/typography/styles';

import StepJourneyMessagePreview from '../Journey/StepJourneyMessagePreview';
import { StepJourneyChannel } from '../Journey/StepJourneyRepository';
import { EmployeeJourneyStep, StepEmailMessage } from './EmployeeRepository';

function EmployeeStepPreviewDialog({ step, onClose }: { step: EmployeeJourneyStep; onClose: () => void }): JSX.Element {
  const [open, setOpen] = React.useState(true);

  const journeyId = step.journey_id || '';
  const stepId = step.step_id;

  function onCloseDialog() {
    setOpen(false);
    onClose && onClose();
    return true;
  }

  const messageHtml = step.message_sent ? GetSentMessageHtml(step) : '';
  // eslint-disable-next-line no-useless-escape
  const regexToMatchPortalLinks = new RegExp('(?<=href="https:\\/\\/)portal[.-].*?(?=["\\s])');
  const replacedPortalLinksMessageHtml = messageHtml.replace(regexToMatchPortalLinks, 'portal.inboarding.co');

  const stepSubject = step.step_channel == StepJourneyChannel.Email ? GetSentMessageSubject(step.message_sent as StepEmailMessage) : '';

  return (
    <Dialog open={open} onClosed={onCloseDialog} className={styles.previewDialog}>
      <DialogTitle>
        <Typography use="headline6">{step.step_name}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid>
          <GridRow style={{ height: '100%' }}>
            <StepJourneyMessagePreview
              loadFromFormEdit={true}
              journeyId={journeyId}
              stepId={stepId}
              messageChannel={step.step_channel}
              messageToWhom={step.to_whom}
              messageToWhomCopy={step.to_whom_copy}
              messageSubject={stepSubject}
              messageHtml={replacedPortalLinksMessageHtml || ''}
              messageBlocks={undefined}
              whatsappTemplate={undefined}
            />
          </GridRow>
        </Grid>
      </DialogContent>
      <DialogActions>
        <DialogButton raised action="close">
          <Trans i18nKey="crud.close">Close</Trans>
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
}

function GetSentMessageHtml(employeeJourneyStep: EmployeeJourneyStep): string {
  switch (employeeJourneyStep.step_channel) {
    case StepJourneyChannel.Email: {
      const sentMessage = employeeJourneyStep.message_sent as StepEmailMessage;
      if (sentMessage?.html) {
        return sentMessage?.html;
      }
      break;
    }

    case StepJourneyChannel.Whatsapp:
    case StepJourneyChannel.Slack: {
      if (employeeJourneyStep.message_html) {
        const sentMessage = employeeJourneyStep.message_html;
        return sentMessage.text + sentMessage.actions;
      }
      break;
    }
  }

  return '';
}

function GetSentMessageSubject(messageHtml: StepEmailMessage): string {
  return messageHtml.subject ?? '';
}

EmployeeStepPreviewDialog.propTypes = {
  step: PropTypes.object,
  onClose: PropTypes.func
};

export default EmployeeStepPreviewDialog;
