import HttpRepository from '../../services/HttpRepository/HttpRepository';

export interface EmployeePhotoMetadata {
  contentType?: string;
  title?: string;
  description?: string;
  url?: string;
}

class EmployeePhotoRepository extends HttpRepository<EmployeePhotoMetadata> {
  constructor(employeeId: string) {
    super({ basePath: `/photos/people/${employeeId}` });
  }
}

export default EmployeePhotoRepository;
