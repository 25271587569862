import React from 'react';

import PropTypes from 'prop-types';

import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { Journey, JourneyFlowType } from './JourneyRepository';

import JourneyService from './JourneyService';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { Typography } from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

import { Select, FormattedOption } from '@rmwc/select';
import '@rmwc/select/styles';

import { TextField } from '@rmwc/textfield';
import '@rmwc/textfield/styles';

import LoadingModal from '../LoadingModal';
import ErrorMessage from '../ErrorMessage';
import Breadcrumb from '../common/Breadcrumb';
import EmployeeService from '../Employee/EmployeeService';
import { SearchFilter } from '../../services/HttpRepository/SearchFilter';
import { EmployeeDepartmentCell, EmployeeNameCell, EmployeeStartEndDateCell, SelectEmployeeCell } from '../Employee/EmployeeTable';
import { EmployeeStartJourneyTable } from '../Employee/EmployeeStartJourney';
import { Employee, EmployeeJourneyStep, SendStepStatus } from '../Employee/EmployeeRepository';
import { FieldValues } from 'react-hook-form';
import { Cell } from 'react-table';
import { InferProps } from 'prop-types';
import SimpleTable from '../Table/SimpleTable';
import { delay } from '../../services/commons/delay';
import { Chip, Icon } from 'rmwc';

function JourneyStart(): JSX.Element {
  const { journeyId } = useParams() as { journeyId: string };
  const { t } = useTranslation();

  const journeyService = new JourneyService();
  const { data: journey, isFetching, isError, error } = journeyService.useGet(journeyId);

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching) {
    return <LoadingModal open={true} />;
  }

  return (
    <Grid>
      <GridCell span={12}>
        <Breadcrumb
          routeSegments={[
            { name: t('navlink.journeys'), path: '/journeys' },
            { name: t('journey.view-title'), path: `/journeys/${journeyId}` },
            { name: t('journey-start.start-title') }
          ]}
        />
      </GridCell>
      <GridCell span={4} align="middle">
        <h2 style={{ marginBottom: 0 }}>
          <Trans i18nKey="journey-start.start-title">Start journey</Trans>
        </h2>
      </GridCell>
      <GridCell span={12} align="middle">
        <JourneyBuildSelectInput journey={journey}></JourneyBuildSelectInput>
      </GridCell>
      <GridCell span={12} align="middle">
        <JourneyTypeSelectEmployees journey={journey}></JourneyTypeSelectEmployees>
      </GridCell>
    </Grid>
  );
}

function JourneyTypeSelectEmployees({ journey }: { journey: Journey | undefined }): JSX.Element {
  if (journey?.flow_type === JourneyFlowType.Offboarding) {
    return <JourneyOffboardingSelectLastDate journey={journey}></JourneyOffboardingSelectLastDate>;
  }

  if (journey?.flow_type === JourneyFlowType.Communication) {
    return <JourneyCommunicationSelectFilter journey={journey}></JourneyCommunicationSelectFilter>;
  }

  return <JourneyOnboardingSelectStartDate journey={journey}></JourneyOnboardingSelectStartDate>;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function JourneyOffboardingSelectLastDate({ journey }: { journey: Journey | undefined }): JSX.Element {
  return <>Offboarding...</>;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function JourneyCommunicationSelectFilter({ journey }: { journey: Journey | undefined }): JSX.Element {
  return <>Communication...</>;
}

function JourneyOnboardingSelectStartDate({ journey }: { journey: Journey | undefined }): JSX.Element {
  const nextDay = new Date();
  nextDay.setDate(nextDay.getDate() + 1);
  const defaultStartDateValue = nextDay.toISOString().substring(0, 10);
  const [startDate, setStartDate] = React.useState(defaultStartDateValue);
  const [confirmedStartDate, setConfirmedStartDate] = React.useState('');
  const [reviewingSteps, setReviewingSteps] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);

  function handleOnChangeStartDate(value: string) {
    setStartDate(value);
  }

  function handleOnClickStart() {
    setConfirmedStartDate(startDate);
  }

  return (
    <>
      <GridCell span={12} align="middle">
        <Typography use="body1" tag="p">
          <label htmlFor="start_date">
            <Trans i18nKey="journey-start.form.start_date.label">What is the first day date for the onboarding journey?</Trans>
          </label>
        </Typography>
        <TextField
          type="date"
          name="start_date"
          onChange={(event: { currentTarget: { value: string } }) => handleOnChangeStartDate(event.currentTarget.value)}
          value={startDate}
          // inputRef={ref}
          // invalid={errors?.start_date?.message ? true : false}
          // aria-invalid={errors?.start_date?.message ? true : false}
          // disabled={!!isLoading}
        />
        <Button disabled={!startDate || reviewingSteps || submitted} raised onClick={handleOnClickStart} style={{ marginLeft: '15px' }}>
          <Trans i18nKey="journey-start.form.confirm-start-date">Confirm start date</Trans>
        </Button>
      </GridCell>
      <GridCell span={12} align="middle">
        {confirmedStartDate ? (
          <JourneyOnboardingSelectEmployees
            journey={journey}
            startDate={confirmedStartDate}
            submitted={submitted}
            setReviewingSteps={setReviewingSteps}
            setSubmitted={setSubmitted}
          ></JourneyOnboardingSelectEmployees>
        ) : null}
      </GridCell>
    </>
  );
}

function JourneyBuildSelectInput({ journey, onChangeJourneyId }: { journey?: Journey | undefined; onChangeJourneyId?: (id: string) => void }): JSX.Element {
  if (journey?.id) {
    const journeysOptions = [
      {
        label: journey.name,
        value: journey.id
      }
    ] as FormattedOption[];
    return <JourneySelectInput selectedJourneyId={journey.id} journeysOptions={journeysOptions}></JourneySelectInput>;
  }

  const journeyService = new JourneyService();
  const { data, isFetching, isError, error } = journeyService.useList();

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching) {
    return <LoadingModal open={true} />;
  }

  const journeysOptions = data?.map((journey: Journey) => ({
    label: journey.name,
    value: journey.id
  })) as FormattedOption[];

  return <JourneySelectInput journeysOptions={journeysOptions} onChangeJourneyId={onChangeJourneyId}></JourneySelectInput>;
}

function JourneySelectInput({
  selectedJourneyId,
  journeysOptions,
  onChangeJourneyId
}: {
  selectedJourneyId?: string;
  journeysOptions: FormattedOption[];
  onChangeJourneyId?: (id: string) => void;
}): JSX.Element {
  function handleOnChangeJourneyId(value: string) {
    if (onChangeJourneyId) {
      onChangeJourneyId(value);
    }
  }

  return (
    <>
      <Typography use="body1" tag="p">
        <label htmlFor="journey-id">
          {!!selectedJourneyId && <Trans i18nKey="journey-start.form.journey-selected">Journey selected to start</Trans>}
          {!selectedJourneyId && <Trans i18nKey="journey-start.form.journey">Select a journey to start</Trans>}
        </label>
      </Typography>
      <Select
        value={selectedJourneyId}
        disabled={!!selectedJourneyId}
        readOnly={!!selectedJourneyId}
        id="journey-id"
        onChange={(event: { currentTarget: { value: string } }) => handleOnChangeJourneyId(event.currentTarget.value)}
        options={journeysOptions}
        style={{
          minWidth: '100%',
          backgroundColor: 'whitesmoke'
        }}
      />
    </>
  );
}

function JourneyOnboardingSelectEmployees({
  journey,
  startDate,
  submitted,
  setReviewingSteps,
  setSubmitted
}: {
  journey: Journey | undefined;
  startDate: string;
  submitted: boolean;
  setReviewingSteps: (reviewingSteps: boolean) => void;
  setSubmitted: (submitted: boolean) => void;
}): JSX.Element {
  const listSizeLimit = 100;
  const searchFilter = {
    queryFieldName: 'start_date',
    query: startDate,
    limit: listSizeLimit
  } as SearchFilter;

  const employeeService = new EmployeeService();
  const { data, isFetching, isError, error } = employeeService.useListSearch(searchFilter, listSizeLimit);
  const useCreateStartJourney = employeeService.useCreateStartJourney({ updateCache: false });

  const [employeeData, setEmployeeData] = React.useState<Employee[]>();
  const [employeeJourneyStatus, setEmployeeJourneyStatus] = React.useState<{ [key: string]: SendStepStatus }>({});
  const [journeyId, setJourneyId] = React.useState('');
  const [firstEmployeeId, setFirstEmployeeId] = React.useState('');
  const [cancelled, setCancelled] = React.useState(false);
  const [selectedEmployees, setSelectedEmployees] = React.useState<boolean[]>([]);
  const [totalSelectedEmployees, setTotalSelectedEmployees] = React.useState(0);

  React.useEffect(() => {
    const newTotalSelectedEmployees = Object.values(selectedEmployees).filter((isSelected) => isSelected).length;
    setTotalSelectedEmployees(newTotalSelectedEmployees);
  }, [selectedEmployees]);

  function setEmployeeDataIndex(index: number, status: SendStepStatus) {
    if (employeeData) {
      const employee = employeeData[index];
      if (employee && employee.id) {
        setEmployeeJourneyStatus((prevStatus) => ({
          ...prevStatus,
          [employee.id || '']: status
        }));
      }
    }
  }

  React.useEffect(() => {
    const filteredEmployeesWithoutLastDate = filterEmployeesWithLastDate(data?.pages.flat());
    setEmployeeData(filteredEmployeesWithoutLastDate);
  }, [data]);

  React.useEffect(() => {
    if (employeeData) {
      setSelectedEmployees(() => Array(employeeData.length).fill(true));
    }
  }, [employeeData]);

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching) {
    return <LoadingModal open={true} />;
  }

  const dataLength = employeeData ? employeeData.length : 0;
  const isFirstEmployeeValid = !!employeeData && !!employeeData[0] && !!employeeData[0].id;

  function handleReviewSteps() {
    if (!!journey && !!journey.id && !!employeeData && !!employeeData[0].id) {
      setJourneyId(journey.id);
      setFirstEmployeeId(employeeData[0].id);
      setReviewingSteps(true);
      setCancelled(false);
    }
  }

  async function startJourneyForSelectedEmployees(mergedValues: unknown) {
    if (employeeData) {
      for (let index = 0; index < employeeData.length; index++) {
        const employee = employeeData[index];

        if (employee.id && selectedEmployees[index]) {
          try {
            await useCreateStartJourney.mutateAsync({ id: employee.id, journeyId, journeySteps: mergedValues });
            setEmployeeDataIndex(index, SendStepStatus.Success);
          } catch (error) {
            setEmployeeDataIndex(index, SendStepStatus.Error);
          }
        } else {
          setEmployeeDataIndex(index, SendStepStatus.Unchecked);
        }

        await delay(1000);
      }
    }

    setReviewingSteps(false);
  }

  return (
    <>
      {dataLength > 0 ? (
        <>
          <GridCell span={12} align="middle" style={{ paddingTop: '10px' }}>
            <SelectedByDateEmployeesTable
              employees={employeeData}
              employeeStatus={employeeJourneyStatus}
              processing={submitted}
              selectedEmployees={selectedEmployees}
              setSelectedEmployess={setSelectedEmployees}
            ></SelectedByDateEmployeesTable>
          </GridCell>
          {!submitted && totalSelectedEmployees > 0 ? (
            <>
              <GridCell span={12} align="middle" style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px' }}>
                <Button disabled={!startDate && isFirstEmployeeValid} onClick={handleReviewSteps} raised icon="auto_graph">
                  <Trans i18nKey="employee.start-journey.form.submit-selected-employees" count={totalSelectedEmployees}>
                    Review steps for {{ count: totalSelectedEmployees }} people
                  </Trans>
                </Button>
              </GridCell>
              {!cancelled && (
                <GridCell span={12} align="middle">
                  <LoadJourneyForReviewAndStart
                    firstEmployeeId={firstEmployeeId}
                    journeyId={journeyId}
                    totalEmployees={totalSelectedEmployees}
                    startJourney={startJourneyForSelectedEmployees}
                    setSubmitted={setSubmitted}
                    setCancelled={setCancelled}
                  />
                </GridCell>
              )}
            </>
          ) : (
            <GridCell span={12} align="middle" style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px', marginRight: '20px' }}>
              <Typography use="body1" tag="p">
                <Trans i18nKey={submitted ? 'employee.start-journey.batch.journey-started' : 'employee.start-journey.form.no-employee-to-submit'}>
                  {submitted ? 'Journey started! You can track through the status column 😉' : 'Choose at least one person to review steps 😉'}
                </Trans>
              </Typography>
            </GridCell>
          )}
        </>
      ) : (
        <Typography use="body1" tag="p">
          <Trans i18nKey="employee.start-journey.batch.no-employees-found">No talents were found for the given filter</Trans>
        </Typography>
      )}
    </>
  );
}

function filterEmployeesWithLastDate(employees: Employee[] | undefined) {
  if (employees) {
    const filteredEmployees = employees.filter((employee) => {
      return !employee.last_date;
    });

    return filteredEmployees;
  }
}

function LoadJourneyForReviewAndStart({
  firstEmployeeId,
  journeyId,
  totalEmployees,
  startJourney,
  setSubmitted,
  setCancelled
}: {
  firstEmployeeId: string;
  journeyId: string;
  totalEmployees: number;
  startJourney: (mergedValues: unknown) => void;
  setSubmitted: (submitted: boolean) => void;
  setCancelled: (cancelled: boolean) => void;
}): JSX.Element {
  if (!firstEmployeeId) {
    return <></>;
  }

  const employeeService = new EmployeeService();
  const { data, isFetching, isError, error } = employeeService.useListStartJourney(firstEmployeeId, journeyId);

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching) {
    return <LoadingModal open={true} />;
  }

  const handleCancel = () => {
    setCancelled(true);
  };

  const onSubmit = async (values: FieldValues): Promise<void> => {
    setSubmitted(true);
    const mergedValues = values.data.map((field: EmployeeJourneyStep, index: number) => {
      return {
        ...data?.[index],
        ...field
      };
    });

    startJourney(mergedValues);
  };

  return (
    <>
      {data && (
        <GridCell>
          <EmployeeStartJourneyTable data={data as EmployeeJourneyStep[]} submitting={false} totalEmployees={totalEmployees} onCancel={handleCancel} onSubmit={onSubmit} />
        </GridCell>
      )}
    </>
  );
}

function SelectedByDateEmployeesTable({
  employees,
  employeeStatus,
  processing,
  selectedEmployees,
  setSelectedEmployess,
  onRowClick,
  onDelete,
  onEdit
}: InferProps<typeof SelectedByDateEmployeesTable.propTypes>): JSX.Element {
  const { t } = useTranslation();

  function EmployeeStatusCell({ cell }: { cell: Cell }): JSX.Element {
    if (employeeStatus) {
      const employee = cell.row.original as Employee;

      const status = employeeStatus[employee.id || ''];

      if (status === SendStepStatus.Error) {
        return <Chip label={t('employee.status.error')} style={{ backgroundColor: 'var(--mdc-theme-error)' }} />;
      }

      if (status === SendStepStatus.Success) {
        return <Chip label={t('employee.status.success')} style={{ backgroundColor: 'var(--mdc-theme-green-enabled)' }} />;
      }

      if (status === SendStepStatus.Unchecked) {
        return <Chip label={t('employee.status.unchecked')} style={{ backgroundColor: 'var(--mdc-theme-grey-disabled)' }} />;
      }

      if (processing) {
        return <Chip label={t('employee.status.processing')} style={{ backgroundColor: 'var(--mdc-theme-yellow)' }} />;
      }
    }

    return <>-</>;
  }

  function handleRedirectToProgress(employeeId: string | undefined) {
    if (employeeId) {
      window.open(`/employees/${employeeId}/progress`, '_blank');
    }
  }

  function EmployeeGoToProgress({ cell }: { cell: Cell }): JSX.Element {
    if (employeeStatus) {
      const employee = cell.row.original as Employee;
      const status: SendStepStatus = employeeStatus[employee.id || ''];

      if (status === SendStepStatus.Success || status === SendStepStatus.Error) {
        return (
          <Button
            type="button"
            onClick={() => handleRedirectToProgress(employee.id || '')}
            icon={<Icon icon="open_in_new" style={{ fontSize: '12px', height: '12px', width: '12px' }} />}
            style={{ fontSize: '11px' }}
          >
            <Trans i18nKey="employee.check_progress">Check progress</Trans>
          </Button>
        );
      }
    }

    return <></>;
  }

  const columns = React.useMemo(
    () => [
      {
        Header: t('employee.employee'),
        id: 'employeeName',
        Cell: ({ cell }: { cell: Cell }) => (
          <div style={{ display: 'flex', boxAlign: 'center', alignItems: 'center' }}>
            <SelectEmployeeCell index={cell.row.index} selectedRows={selectedEmployees} displayCheckBox={true} submitted={processing} setSelectedRows={setSelectedEmployess} />
            <EmployeeNameCell cell={cell} />
          </div>
        )
      },
      {
        Header: t('employee.department'),
        id: 'employeeDepartment',
        Cell: EmployeeDepartmentCell
      },
      {
        Header: t('employee.start_date'),
        id: 'employeeStartDate',
        Cell: EmployeeStartEndDateCell
      },
      {
        Header: t('employee.status.placeholder'),
        id: 'employeeStatus',
        Cell: EmployeeStatusCell
      },
      {
        Header: '',
        id: 'employeeGoToProgress',
        Cell: EmployeeGoToProgress
      }
    ],
    [t, employeeStatus, selectedEmployees]
  );

  const isRowDisabled = (rowData: Employee, rowIndex: number): boolean => {
    return Boolean(rowData?.last_date) || !selectedEmployees[rowIndex];
  };

  return (
    <>
      <SimpleTable columns={columns} data={employees} onRowClick={onRowClick} onDelete={onDelete} onEdit={onEdit} isRowDisabled={isRowDisabled}></SimpleTable>
    </>
  );
}

SelectedByDateEmployeesTable.propTypes = {
  employees: PropTypes.array,
  employeeStatus: PropTypes.any,
  processing: PropTypes.bool.isRequired,
  selectedEmployees: PropTypes.arrayOf(PropTypes.bool.isRequired).isRequired,
  setSelectedEmployess: PropTypes.func.isRequired,
  onRowClick: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func
};

export default JourneyStart;
