import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import LoadingModal from '../LoadingModal';

import JourneyService from './JourneyService';

import { Link } from 'react-router-dom';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import JourneyTable from './JourneyTable';
import ErrorMessage from '../ErrorMessage';
import Message from '../../services/Message';
import Breadcrumb from '../common/Breadcrumb';

import EllaMessage from '../common/EllaMessage';

function Journey({ history }: InferProps<typeof Journey.propTypes>): JSX.Element {
  const { t } = useTranslation();
  const journeyTranslation = t('journey.model');
  const journeyService = new JourneyService();

  const { data, isFetching, isError, error } = journeyService.useList();
  const useDeleteJourney = journeyService.useDelete();

  async function onDeleteJourney(values: { id: string; name: string }): Promise<void> {
    if (window.confirm(t('delete.confirm', { name: values.name }))) {
      const deletingMessageId = Message.loading(t('delete.processing', { model: journeyTranslation }));
      await useDeleteJourney.mutateAsync(values.id);
      Message.dismiss(deletingMessageId);
      Message.success(t('journey.deleted'));
    }
  }

  async function onEditJourney(values: { id: string }): Promise<void> {
    if (history) history.push(`/journeys/${values.id}/edit`);
  }

  async function onClickJourney(values: { id: string }): Promise<void> {
    if (history) history.push(`/journeys/${values.id}`);
  }

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching) {
    return <LoadingModal open={true} />;
  }

  const hasDataCreated = data && data.length > 0;

  return (
    <Grid>
      <GridCell span={12}>
        <Breadcrumb routeSegments={[{ name: t('navlink.journeys') }]} />
      </GridCell>
      <GridCell span={6}>
        <h2>
          <Trans i18nKey="journey.title">Journeys</Trans>
        </h2>
      </GridCell>
      <GridCell span={6} style={{ textAlign: 'right' }}>
        {hasDataCreated && (
          <Button raised icon="add" tag={Link} {...{ to: '/journeys/new' }}>
            <Trans i18nKey="journey.create">New Journey</Trans>
          </Button>
        )}
      </GridCell>
      {hasDataCreated ? (
        <GridCell span={12}>
          <JourneyTable journeys={data} onRowClick={onClickJourney} onDelete={onDeleteJourney} onEdit={onEditJourney}></JourneyTable>
        </GridCell>
      ) : (
        <FirstJourneyInstruction></FirstJourneyInstruction>
      )}
    </Grid>
  );
}

function FirstJourneyInstruction(): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      {/* style={{ margin: '40px', maxWidth: '150px', textAlign: 'center' }} */}
      <GridCell span={12} align="middle">
        <EllaMessage title={t('journey.nodata.title')} message={t('journey.nodata.text')} />
      </GridCell>
      <GridCell span={12} style={{ textAlign: 'center' }}>
        <Button raised icon="add" tag={Link} {...{ to: '/journeys/new' }}>
          <Trans i18nKey="journey.create">New Journey</Trans>
        </Button>
      </GridCell>
    </>
  );
}

Journey.propTypes = {
  history: PropTypes.any
};

export default withRouter(Journey);
