import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Cell } from 'react-table';

import SimpleTable from '../../Table/SimpleTable';
import { ContentsTagsChipsTableCell } from '../../common/ContentsTagsChips/ContentsTagsChips';

import { Tooltip } from '@rmwc/tooltip';
import '@rmwc/tooltip/styles';

function ContentsTitleCell({ value }: { value: string }): JSX.Element {
  return (
    <Tooltip content={value}>
      <div>{value}</div>
    </Tooltip>
  );
}

function ContentsQuestionsTable({ questions, onRowClick, onDelete, onEdit }: InferProps<typeof ContentsQuestionsTable.propTypes>): JSX.Element {
  const { t } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        Header: t('contents.questions.question'),
        accessor: 'title',
        Cell: ContentsTitleCell
      },
      {
        Header: t('contents.questions.categories'),
        id: 'contentTags',
        Cell: ({ cell }: { cell: Cell }): JSX.Element => ContentsTagsChipsTableCell({ cell, max: 1 })
      },
      {
        Header: t('contents.questions.answer'),
        accessor: 'description'
      },
      {
        Header: t('contents.questions.created-date'),
        accessor: 'createdAt',
        Cell: ({ value }: { value: string }): string => (value ? new Date(value).toLocaleDateString() : '')
      },
      {
        Header: t('contents.questions.updated-date'),
        accessor: 'updatedAt',
        Cell: ({ value }: { value: string }): string => (value ? new Date(value).toLocaleDateString() : '')
      }
    ],
    [t]
  );
  return (
    <>
      <SimpleTable columns={columns} data={questions} onRowClick={onRowClick} onDelete={onDelete} onEdit={onEdit}></SimpleTable>
    </>
  );
}

ContentsQuestionsTable.propTypes = {
  questions: PropTypes.array,
  onRowClick: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func
};

export default ContentsQuestionsTable;
