import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { FieldValues } from 'react-hook-form';

import ContentsGlossaryService from './ContentsGlossaryService';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import ContentsGlossaryForm from './ContentsGlossaryForm';
import Message from '../../../services/Message';
import Breadcrumb from '../../common/Breadcrumb';

function ContentsGlossaryNew({ history }: InferProps<typeof ContentsGlossaryNew.propTypes>): JSX.Element {
  const { t } = useTranslation();
  const glossaryService = new ContentsGlossaryService();
  const useCreateGlossary = glossaryService.useCreate();

  const onSubmit = async (values: FieldValues): Promise<void> => {
    try {
      await useCreateGlossary.mutateAsync(values);
      Message.success(t('contents.glossary.created'));
      history.push('/contents/glossary');
    } catch (error) {
      Message.error(t('contents.glossary.created-error'));
      console.error('Error on save glossary: ', error);
    }
  };

  const onCancel = async (): Promise<void> => {
    history.goBack();
  };

  return (
    <Grid>
      <GridCell span={12}>
        <Breadcrumb
          routeSegments={[
            { name: t('navlink.contents'), path: '/contents/glossary/' },
            { name: t('navlink.content.glossary'), path: '/contents/glossary/' },
            { name: t('contents.glossary.new-title') }
          ]}
        />
      </GridCell>
      <GridCell span={12}>
        <h1>
          <Trans i18nKey="contents.glossary.new-title">New Frequently Asked Glossary</Trans>
        </h1>
      </GridCell>
      <GridCell span={12}>
        <ContentsGlossaryForm onFormSubmit={onSubmit} onCancel={onCancel} isLoading={useCreateGlossary.isLoading}></ContentsGlossaryForm>
      </GridCell>
    </Grid>
  );
}

ContentsGlossaryNew.propTypes = {
  history: PropTypes.any
};

export default ContentsGlossaryNew;
