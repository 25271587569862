import HttpRepository from '../../../services/HttpRepository/HttpRepository';
import { Employee } from '../../Employee/EmployeeRepository';

class CelebrationsBirthRepository extends HttpRepository<Employee> {
  constructor() {
    super({ basePath: '/celebrations/birth' });
  }
}

export default CelebrationsBirthRepository;
