import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import styles from './LoadingModal.module.css';

import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/circular-progress.css';

import { Dialog } from '@rmwc/dialog';
import '@material/dialog/dist/mdc.dialog.css';

function LoadingModal({ open }: InferProps<typeof LoadingModal.propTypes>): JSX.Element {
  return (
    <Dialog open={open} className={styles.loadingModal} preventOutsideDismiss={true}>
      <CircularProgress size={72} />
    </Dialog>
  );
}

LoadingModal.propTypes = {
  open: PropTypes.bool.isRequired
};

export default LoadingModal;
