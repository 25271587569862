import React from 'react';
import Chart from '../Chart';
import { useTranslation } from 'react-i18next';

function HiresGenderChart(): JSX.Element {
  const { t } = useTranslation();
  const chartTitle = t('hires-gender.pie.title', 'People by Gender');
  const chartSeriesName = t('hires-gender.pie.series-name', 'People by Gender');
  const maleName = t('hires-gender.pie.male', 'Men');
  const femaleName = t('hires-gender.pie.female', 'Women');
  const othersName = t('hires-gender.pie.others', 'Others');
  const textColor = '#303030';
  //   const style = { height: '100px' };
  const style = {};
  const option = {
    title: {
      show: true,
      text: chartTitle,
      left: 'center',
      textStyle: {
        color: textColor,
        fontSize: 16,
        fontFamily: 'PT Sans'
      }
    },
    legend: {
      show: true,
      itemGap: 10,
      icon: 'roundRect',
      bottom: 0,
      textStyle: {
        color: textColor,
        fontSize: 13
      }
    },
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c} ({d}%)'
    },
    xAxis: [
      {
        axisLine: {
          show: false
        },
        splitLine: {
          show: false
        }
      }
    ],
    yAxis: [
      {
        axisLine: {
          show: false
        },
        splitLine: {
          show: false
        }
      }
    ],
    series: [
      {
        name: chartSeriesName,
        type: 'pie',
        radius: ['45%', '72.55%'],
        center: ['50%', '50%'],
        avoidLabelOverlap: false, // hoverOffset: 5,
        stillShowZeroSum: false,
        label: {
          normal: {
            show: false,
            position: 'center', // shows the description data to center, turn off to show in right side
            textStyle: {
              color: textColor,
              fontSize: 13,
              fontFamily: 'PT Sans'
            },
            formatter: '{a}'
          },
          emphasis: {
            show: true,
            textStyle: {
              fontSize: 14,
              fontWeight: 'normal'
            },
            formatter: '{b} \n{c} ({d}%)'
          }
        },
        labelLine: {
          normal: {
            show: false
          }
        },
        data: [
          {
            value: 65,
            name: maleName
          },
          {
            value: 20,
            name: femaleName
          },
          {
            value: 15,
            name: othersName
          }
        ],
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        }
      }
    ],
    colors: []
  };
  return <Chart option={option} style={style} />;
}

export default HiresGenderChart;
