import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Link } from 'react-router-dom';

import { Trans, useTranslation } from 'react-i18next';
import { Cell } from 'react-table';

import { Avatar } from '@rmwc/avatar';
import '@rmwc/avatar/avatar.css';

import { Typography } from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

import { ChipSet, Chip } from '@rmwc/chip';
import '@rmwc/chip/styles';

import SimpleTable from '../../Table/SimpleTable';
import { Employee } from '../../Employee/EmployeeRepository';
import { dateFormat } from '../../common/DatesFormat/dateTimeFormat';
import { dateIsThisWeek, dateIsToday, dateIsTomorrow } from '../../common/DatesFormat/dateIsPeriod';

const avatarStyle = {
  backgroundColor: 'var(--mdc-theme-secondary)',
  marginRight: '10px',
  fontSize: '2.3rem'
} as React.CSSProperties;

function EmployeeNameCell({ cell }: { cell: Cell }): JSX.Element {
  return <EmployeeNameCellComponent employee={cell.row.original as Employee}></EmployeeNameCellComponent>;
}

function EmployeeNameCellComponent({ employee }: { employee: Employee }): JSX.Element {
  return (
    <>
      <div style={{ display: 'flex' }}>
        <Avatar name={employee.social_name || employee.first_name} size="xlarge" style={avatarStyle} src={employee.photo_url || ''} />
      </div>
      <div style={{ marginLeft: '0.4rem', whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
        <Link to={`/employees/${employee.id}`} style={{ color: 'rgba(0, 0, 0, 0.87)', textDecoration: 'none' }} onClick={(event): void => event.stopPropagation()}>
          <Typography use="subtitle1" tag="h5" style={{ margin: 0, lineHeight: '85%', textDecoration: 'underline' }}>
            {employee.social_name || employee.first_name} {employee.last_name}
          </Typography>
          <Typography use="caption" tag="small">
            {employee.work_email || employee.personal_email || employee.mobile_number}
          </Typography>
        </Link>
      </div>
    </>
  );
}

function EmployeeDepartmentCell({ cell }: { cell: Cell }): JSX.Element {
  const employee = cell.row.original as Employee;

  return (
    <>
      {employee.job_title && <div>{employee.job_title}</div>}
      {employee.department && (
        <div>
          {employee.department} {employee.cost_center && <span> - {employee.cost_center}</span>}
        </div>
      )}
    </>
  );
}

export function CelebrationDateTag({ dateIso }: { dateIso: string | undefined }): JSX.Element {
  const { t } = useTranslation();

  if (!dateIso) {
    return <></>;
  }

  if (dateIsToday(dateIso)) {
    return (
      <ChipSet>
        <Chip label={t('celebrations.date.today', 'Today')} style={{ backgroundColor: 'var(--mdc-theme-green-enabled)', fontSize: '80%', borderRadius: '5px', height: '20px' }} />
      </ChipSet>
    );
  }

  if (dateIsTomorrow(dateIso)) {
    return (
      <ChipSet>
        <Chip label={t('celebrations.date.tomorrow', 'Tomorrow')} style={{ backgroundColor: 'var(--mdc-theme-yellow)', fontSize: '80%', borderRadius: '5px', height: '20px' }} />
      </ChipSet>
    );
  }

  if (dateIsThisWeek(dateIso)) {
    return (
      <ChipSet>
        <Chip label={t('celebrations.date.week', 'This week')} style={{ fontSize: '80%', borderRadius: '5px', height: '20px' }} />
      </ChipSet>
    );
  }

  return <></>;
}

function EmployeeBirthDateCell({ cell }: { cell: Cell }): JSX.Element {
  const employee = cell.row.original as Employee;

  const birthDateFormatted = dateFormat(employee.birth_date);

  return (
    <>
      <div>{birthDateFormatted}</div>
      <div>
        <CelebrationDateTag dateIso={employee.birth_date}></CelebrationDateTag>
      </div>
    </>
  );
}

function EmployeeBirthCelebrate({ value }: { value: string }): JSX.Element {
  const celebrate = value;

  if (!celebrate) {
    return <>-</>;
  }

  if (['yes', 'sim'].includes(celebrate.toLowerCase())) {
    return <Trans i18nKey="celebrations.birth.celebrate.yes">Yes</Trans>;
  }

  if (['no', 'nao', 'não'].includes(celebrate.toLowerCase())) {
    return <Trans i18nKey="celebrations.birth.celebrate.no">No</Trans>;
  }

  return <>-</>;
}

function CelebrationsBirthTable({ people, onRowClick }: InferProps<typeof CelebrationsBirthTable.propTypes>): JSX.Element {
  const { t } = useTranslation();
  const columns = React.useMemo(
    () => [
      {
        Header: t('celebrations.birth.name'),
        id: 'employeeName',
        Cell: ({ cell }: { cell: Cell }) => (
          <div style={{ display: 'flex', boxAlign: 'center', alignItems: 'center' }}>
            <EmployeeNameCell cell={cell} />
          </div>
        )
      },
      {
        Header: t('celebrations.birth.department'),
        id: 'employeeDepartment',
        Cell: EmployeeDepartmentCell
      },
      {
        Header: t('celebrations.birth.birth-date'),
        accessor: 'birth_date',
        Cell: EmployeeBirthDateCell
      },
      {
        Header: t('celebrations.birth.birth-date-celebrate'),
        accessor: 'birth_date_celebrate',
        Cell: EmployeeBirthCelebrate
      }
    ],
    [t]
  );
  return (
    <>
      <SimpleTable columns={columns} data={people} onRowClick={onRowClick}></SimpleTable>
    </>
  );
}

CelebrationsBirthTable.propTypes = {
  people: PropTypes.array,
  onRowClick: PropTypes.func
};

export default CelebrationsBirthTable;
