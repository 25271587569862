/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Grid, GridRow, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { List } from '@rmwc/list';
import '@rmwc/list/styles';

import { DataTable, DataTableContent, DataTableHead, DataTableHeadCell, DataTableBody, DataTableRow, DataTableCell } from '@rmwc/data-table';
import '@rmwc/data-table/styles';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { Tooltip } from '@rmwc/tooltip';
import '@rmwc/tooltip/styles';

import { ChipSet, Chip } from '@rmwc/chip';
import '@rmwc/chip/styles';

import { Typography } from '@rmwc/typography';
import '@rmwc/typography/styles';

import { Icon } from '@rmwc/icon';
import '@rmwc/icon/styles';

import { Theme } from '@rmwc/theme';

import { Employee, EmployeeJourneyStep, EmployeeJourneyStepStatus, EmployeeJourneyStepToPerson } from './EmployeeRepository';
import EmployeeService from './EmployeeService';

import LoadingModal from '../LoadingModal';
import ErrorMessage from '../ErrorMessage';
import parseExtractHashId from '../../services/HttpRepository/parseExtractHashId';
import Message from '../../services/Message';

import { dateFormatWeekdayText } from '../common/DatesFormat/dateFormatWeekdayText';
import { dateTimeFormat } from '../common/DatesFormat/dateTimeFormat';
import { StepJourneyChannelImage } from '../Journey/StepJourneyMessagePreview';
import { dateIsBefore, dateIsBeforeNow } from '../common/DatesFormat/dateIsBeforeNow';
import { StepJourneyChannel } from '../Journey/StepJourneyRepository';
import { MessageBlocksTypes } from '../Journey/StepJourneyForm';
import EmployeeStepPreviewDialog from './EmployeeStepPreviewDialog';

const textColumnStyle = {
  textOverflow: 'unset',
  wordWrap: 'break-word',
  overflowWrap: 'break-word',
  whiteSpace: 'normal'
} as React.CSSProperties;

function EmployeeViewProgress({ employee }: { employee: Employee }): JSX.Element {
  const employeeService = new EmployeeService();

  const { data, isFetching, isError, error } = employeeService.useListSteps(employee?.id || '');

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching) {
    return <LoadingModal open={true} />;
  }

  return (
    <Grid>
      <GridCell span={12}>
        <EmployeeStepsData steps={data as any[]} employee={employee} />
      </GridCell>
    </Grid>
  );
}

function EmployeeStepsData({ steps, employee }: { steps?: any[]; employee: Employee }): JSX.Element {
  if (!steps || !steps.length) {
    return <EmployeeProgressListEmpty />;
  }

  return (
    <>
      <GridRow>
        <GridCell span={12}>
          <List>
            <EmployeeProgresList steps={steps} employee={employee} />
          </List>
        </GridCell>
      </GridRow>
    </>
  );
}

const mouseOverStyle = {
  cursor: 'pointer'
};

function EmployeeProgressListEmpty(): JSX.Element {
  return (
    <p>
      <Trans i18nKey="employee.progress.no-data">No journey progress saved for this employee.</Trans>
    </p>
  );
}

function EmployeeProgresList({ steps, employee }: { steps: any[]; employee: Employee }): JSX.Element {
  const { t } = useTranslation();

  const [openPreview, setOpenPreview] = React.useState(false);
  const [stepToDisplay, setStepToDisplay] = React.useState({} as EmployeeJourneyStep);

  const employeeCreatedDate = employee.createdAt || '';
  const employeeService = new EmployeeService();
  const useSendPeopleStepJourney = employeeService.useSendStep();
  const useForceReSendPeopleStepJourney = employeeService.useForceReSendStep();

  const validStatusToDisplayMessage = [
    EmployeeJourneyStepStatus.Clicked,
    EmployeeJourneyStepStatus.Confirmed,
    EmployeeJourneyStepStatus.Delivered,
    EmployeeJourneyStepStatus.ManualRead,
    EmployeeJourneyStepStatus.Opened,
    EmployeeJourneyStepStatus.Sent
  ];

  async function handleOnClickSend(stepItem: any) {
    if (confirm(t('employee.progress.message-alerts.force-send-message'))) {
      try {
        const parsedId = parseExtractHashId(stepItem.id || '');
        await useSendPeopleStepJourney.mutateAsync({ id: stepItem.people_id, stepId: parsedId });
        Message.success(t('employee.progress.step-sent'));
      } catch (error) {
        console.error('Error on send employee progress step message: ', error);
        Message.error(t('employee.progress.step-send-error'));
      }
    }
  }

  async function handleOnClickForceReSend(stepItem: any, confirmMessage: string) {
    if (confirm(confirmMessage)) {
      try {
        const parsedId = parseExtractHashId(stepItem.id || '');
        await useForceReSendPeopleStepJourney.mutateAsync({ id: stepItem.people_id, stepId: parsedId });
        Message.success(t('employee.progress.step-sent'));
      } catch (error) {
        console.error('Error on re-send employee progress step message: ', error);
        Message.error(t('employee.progress.step-send-error'));
      }
    }
  }

  function handleOnClickForceToSendAgain(stepItem: any) {
    const message = t('employee.progress.message-alerts.force-resend-message');
    handleOnClickForceReSend(stepItem, message);
  }

  function handleOnClickForceToSendAnyway(stepItem: any) {
    const message = t('employee.progress.message-alerts.force-send-not-scheduled-message');
    handleOnClickForceReSend(stepItem, message);
  }

  function showSendMessageNow(status: EmployeeJourneyStepStatus): boolean {
    return [EmployeeJourneyStepStatus.Scheduled].includes(status);
  }

  function showForceReSendMessageNow(status: EmployeeJourneyStepStatus): boolean {
    return ![EmployeeJourneyStepStatus.Scheduled, EmployeeJourneyStepStatus.Sending, EmployeeJourneyStepStatus.Removed].includes(status);
  }

  function showForceSendAnyway(status: EmployeeJourneyStepStatus): boolean {
    return [EmployeeJourneyStepStatus.Removed].includes(status);
  }

  const rowStyle = (step: any) => {
    let styleClick, underlinedText;
    if (validStatusToDisplayMessage.includes(step.status)) {
      styleClick = mouseOverStyle;
      underlinedText = {
        textDecorationLine: 'underline'
      };
    }

    return Object.assign({}, textColumnStyle, styleClick, underlinedText);
  };

  function handleOpenPreview(step: any) {
    setOpenPreview(!openPreview);
    setStepToDisplay(step);
  }

  return (
    <>
      {openPreview && <EmployeeStepPreviewDialog step={stepToDisplay} onClose={(): void => setOpenPreview(false)} />}
      <DataTable style={{ width: '100%' }}>
        <DataTableContent>
          <DataTableHead>
            <DataTableRow>
              <DataTableHeadCell>
                <Trans i18nKey="employee.progress.step-channel">Channel</Trans>
              </DataTableHeadCell>
              <DataTableHeadCell>
                <Trans i18nKey="employee.progress.step-to">To</Trans>
              </DataTableHeadCell>
              <DataTableHeadCell>
                <Trans i18nKey="employee.progress.step-name">Message name</Trans>
              </DataTableHeadCell>
              <DataTableHeadCell>
                <Trans i18nKey="employee.progress.scheduled-date">Scheduled</Trans>
              </DataTableHeadCell>
              <DataTableHeadCell>
                <Trans i18nKey="employee.progress.sent-date">Sent</Trans>
              </DataTableHeadCell>
              <DataTableHeadCell>
                <Trans i18nKey="employee.progress.message-status">Status</Trans>
              </DataTableHeadCell>
              <DataTableHeadCell></DataTableHeadCell>
            </DataTableRow>
          </DataTableHead>
          <DataTableBody>
            {steps.map((stepItem) => (
              <DataTableRow key={stepItem.id}>
                <DataTableCell>
                  <StepJourneyChannelImage channel={stepItem.step_channel} />
                </DataTableCell>
                <DataTableCell>
                  <StepJourneyToCell step={stepItem} to={stepItem.to_whom} employee={employee} />
                </DataTableCell>
                <DataTableCell style={rowStyle(stepItem)} onClick={validStatusToDisplayMessage.includes(stepItem.status) ? () => handleOpenPreview(stepItem) : undefined}>
                  {stepItem.step_name}
                </DataTableCell>
                <DataTableCell>
                  {stepItem.status === EmployeeJourneyStepStatus.Removed ? (
                    <Typography use="caption" tag="div">
                      <Trans i18nKey="employee.progress.schedule-removed">Marked to not send.</Trans>
                    </Typography>
                  ) : (
                    <Theme use={!stepItem.sent_date ? '' : 'textDisabledOnBackground'} tag="div">
                      <div>{dateTimeFormat(stepItem.schedule_date)}</div>
                      <div>{dateFormatWeekdayText(stepItem.schedule_date)}</div>
                      {dateIsBefore(stepItem.schedule_date, employeeCreatedDate) ? (
                        <Typography use="caption" tag="div">
                          <Icon
                            icon={{ icon: 'warning', size: 'xsmall' }}
                            style={{ fontSize: '0.8rem', marginTop: '-2px', marginRight: '4px', verticalAlign: 'middle', color: 'var(--mdc-theme-error)' }}
                            title={t('employee.start-journey.status.date-past-warning', 'Date in the past. It will not be sent automatically.')}
                          />
                          <Trans i18nKey="employee.progress.schedule-past-warning">Scheduled before the creation date.</Trans>
                          {<div>{dateTimeFormat(employeeCreatedDate)}</div>}
                        </Typography>
                      ) : null}
                    </Theme>
                  )}
                </DataTableCell>
                <DataTableCell>
                  <div>{dateTimeFormat(stepItem.sent_date)}</div>
                  <div>{dateFormatWeekdayText(stepItem.sent_date)}</div>
                </DataTableCell>
                <DataTableCell>
                  <EmployeeStepStatusCell stepItem={stepItem} scheduledInPast={dateIsBeforeNow(stepItem.schedule_date)} />
                </DataTableCell>
                <DataTableCell>
                  <Button
                    type="button"
                    onClick={() => handleOnClickSend(stepItem)}
                    icon={<Icon icon="send" style={{ fontSize: '12px', height: '12px', width: '12px' }} />}
                    style={{ fontSize: '11px', display: showSendMessageNow(stepItem.status) ? '' : 'none' }}
                  >
                    <Trans i18nKey="employee.progress.sent-now-button">Send now</Trans>
                  </Button>
                  <Button
                    type="button"
                    onClick={() => handleOnClickForceToSendAgain(stepItem)}
                    icon={<Icon icon="sync_problem" style={{ fontSize: '12px', height: '12px', width: '12px' }} />}
                    style={{ fontSize: '12px', display: showForceReSendMessageNow(stepItem.status) ? '' : 'none' }}
                  >
                    <Trans i18nKey="employee.progress.sent-again-now-button">Send again now</Trans>
                  </Button>
                  <Button
                    type="button"
                    onClick={() => handleOnClickForceToSendAnyway(stepItem)}
                    icon={<Icon icon="send" style={{ fontSize: '12px', height: '12px', width: '12px' }} />}
                    style={{ fontSize: '12px', display: showForceSendAnyway(stepItem.status) ? '' : 'none' }}
                  >
                    <Trans i18nKey="employee.progress.sent-anyway-button">Send anyway</Trans>
                  </Button>
                </DataTableCell>
              </DataTableRow>
            ))}
          </DataTableBody>
        </DataTableContent>
      </DataTable>
    </>
  );
}

export function EmployeeStepStatusCell({ stepItem, scheduledInPast }: { stepItem: EmployeeJourneyStep; scheduledInPast?: boolean }): JSX.Element {
  const { t } = useTranslation();

  return (
    <Tooltip
      content={
        <div style={{ margin: '10px' }}>
          {stepItem.status_history &&
            stepItem.status_history.slice(0, 20).map((history: any, index: number) => {
              return (
                <div key={index} style={{ margin: '5px' }}>
                  <b>{employeeStepProgressStatusTextDetailed(history)}: </b>
                  {history.status === EmployeeJourneyStepStatus.Removed ? t('employee.progress.schedule-removed') : dateTimeFormat(history.timestamp)}
                </div>
              );
            })}
        </div>
      }
    >
      <div>
        <EmployeeStepProgressStatus stepItem={stepItem} scheduledInPast={scheduledInPast} />
      </div>
    </Tooltip>
  );
}

function EmployeeStepProgressStatus({ stepItem, scheduledInPast }: { stepItem: EmployeeJourneyStep; scheduledInPast?: boolean }): JSX.Element {
  const { t } = useTranslation();
  const status = stepItem.status;
  const hasMessageBlocks = !!stepItem.message_block_type && [MessageBlocksTypes.Form, MessageBlocksTypes.Poll, MessageBlocksTypes.Sequence].includes(stepItem.message_block_type);

  switch (status) {
    case EmployeeJourneyStepStatus.Scheduled:
      return (
        <ChipSet>
          {scheduledInPast ? (
            <Chip
              label={
                <>
                  <Icon
                    icon={{ icon: 'warning', size: 'xsmall' }}
                    style={{ fontSize: '0.8rem', marginTop: '-2px', marginRight: '4px', verticalAlign: 'middle', color: 'var(--mdc-theme-error)' }}
                  />
                  {t('employee.progress.message-status-labels.scheduled-past', 'Not sent')}
                </>
              }
            />
          ) : (
            <Chip label={employeeStepProgressStatusText(status)} />
          )}
        </ChipSet>
      );

    case EmployeeJourneyStepStatus.Sending:
    case EmployeeJourneyStepStatus.Removed:
    case EmployeeJourneyStepStatus.Disabled:
      return (
        <ChipSet>
          <Chip label={employeeStepProgressStatusText(status)} />
        </ChipSet>
      );

    case EmployeeJourneyStepStatus.Sent:
    case EmployeeJourneyStepStatus.Delivered:
      return (
        <ChipSet>
          <Chip label={employeeStepProgressStatusText(status)} style={{ backgroundColor: 'var(--mdc-theme-yellow)' }} />
        </ChipSet>
      );

    case EmployeeJourneyStepStatus.Opened:
    case EmployeeJourneyStepStatus.Clicked:
      return (
        <ChipSet>
          <Chip
            label={employeeStepProgressStatusText(status)}
            style={{
              backgroundColor: hasMessageBlocks ? 'var(--mdc-theme-yellow)' : 'var(--mdc-theme-green-enabled)'
            }}
          />
        </ChipSet>
      );

    case EmployeeJourneyStepStatus.ManualRead:
    case EmployeeJourneyStepStatus.Confirmed:
      return (
        <ChipSet>
          <Chip label={employeeStepProgressStatusText(status)} style={{ backgroundColor: 'var(--mdc-theme-green-enabled)' }} />
        </ChipSet>
      );

    case EmployeeJourneyStepStatus.Bounced:
      return (
        <ChipSet>
          <Chip label={employeeStepProgressStatusText(status)} style={{ backgroundColor: 'var(--mdc-theme-error-light)' }} />
        </ChipSet>
      );

    case EmployeeJourneyStepStatus.Error: {
      const statusDetails = JSON.stringify(stepItem.status_history);
      let statusErroLabel = '';

      if (statusDetails.includes('not defined for person')) {
        statusErroLabel = t('employee.progress.message-status-labels.error-connection-not-defined');
      } else if (stepItem.step_channel !== StepJourneyChannel.Email) {
        statusErroLabel = t('employee.progress.message-status-labels.error-not-installed', 'Not installed');
        if (statusDetails.includes('findSlackUserName') || statusDetails.includes('User not found')) {
          statusErroLabel = t('employee.progress.message-status-labels.error-user-not-found', 'User not found');
        }
      }
      if (statusErroLabel) {
        return (
          <ChipSet>
            <Chip
              label={
                <>
                  <Icon
                    icon={{ icon: 'warning', size: 'xsmall' }}
                    style={{ fontSize: '0.8rem', marginTop: '-2px', marginRight: '4px', verticalAlign: 'middle', color: 'var(--mdc-theme-error)' }}
                  />
                  {statusErroLabel}
                </>
              }
            />
          </ChipSet>
        );
      }
      return (
        <ChipSet>
          <Chip label={employeeStepProgressStatusText(status)} style={{ backgroundColor: 'var(--mdc-theme-error-light)' }} />
        </ChipSet>
      );
    }
  }

  return <>{status}</>;
}

function employeeStepProgressStatusTextDetailed(statusDetails: { status: string; data: { bounceType: string; bounceSubType: string } }): string {
  const statusText = employeeStepProgressStatusText(statusDetails.status);

  if (statusDetails.status === EmployeeJourneyStepStatus.Bounced) {
    if (statusDetails.data?.bounceType) {
      return `${statusText} (${statusDetails.data?.bounceType} - ${statusDetails.data?.bounceSubType})`;
    }
  }

  return statusText;
}

function employeeStepProgressStatusText(status: string): string {
  const { t } = useTranslation();

  switch (status) {
    case EmployeeJourneyStepStatus.Removed:
      return t('employee.progress.message-status-labels.removed', 'Do not send');

    case EmployeeJourneyStepStatus.Scheduled:
      return t('employee.progress.message-status-labels.scheduled', 'Scheduled');

    case EmployeeJourneyStepStatus.Sending:
      return t('employee.progress.message-status-labels.sending', 'Sending');

    case EmployeeJourneyStepStatus.Sent:
      return t('employee.progress.message-status-labels.send', 'Sent');

    case EmployeeJourneyStepStatus.Delivered:
      return t('employee.progress.message-status-labels.delivered', 'Delivered');

    case EmployeeJourneyStepStatus.Opened:
      return t('employee.progress.message-status-labels.opened', 'Opened');

    case EmployeeJourneyStepStatus.Clicked:
      return t('employee.progress.message-status-labels.clicked', 'Clicked');

    case EmployeeJourneyStepStatus.ManualRead:
      return t('employee.progress.message-status-labels.manual-read', 'Confirmed');

    case EmployeeJourneyStepStatus.Confirmed:
      return t('employee.progress.message-status-labels.confirmed', 'Completed');

    case EmployeeJourneyStepStatus.Bounced:
      return t('employee.progress.message-status-labels.bounced', 'Bounced');

    case EmployeeJourneyStepStatus.Error:
      return t('employee.progress.message-status-labels.error', 'Error');

    case EmployeeJourneyStepStatus.Disabled:
      return t('employee.progress.message-status-labels.disabled', 'Disabled');
  }

  return status;
}

function employeeStepProgressToText(to: EmployeeJourneyStepToPerson | undefined): string {
  const { t } = useTranslation();

  if (!to) {
    return t('employee.progress.message-to-labels.new-hire', 'New Hire');
  }

  switch (to) {
    case EmployeeJourneyStepToPerson.NewHireWorkEmail:
      return t('employee.progress.message-to-labels.new-hire-work-email', 'New Hire Work Email');

    case EmployeeJourneyStepToPerson.NewHirePersonalEmail:
      return t('employee.progress.message-to-labels.new-hire-personal-email', 'New Hire Personal Email');

    case EmployeeJourneyStepToPerson.NewHire:
      return t('employee.progress.message-to-labels.new-hire', 'New Hire');

    case EmployeeJourneyStepToPerson.Leader:
      return t('employee.progress.message-to-labels.leader', 'Leader');

    case EmployeeJourneyStepToPerson.Buddy:
      return t('employee.progress.message-to-labels.buddy', 'Buddy');

    case EmployeeJourneyStepToPerson.FixedEmail:
      return t('mentions.entity.fixed_email');
  }

  return to;
}

function employeeStepProgressToName(step: any, to: EmployeeJourneyStepToPerson | undefined, employee: Employee): string | JSX.Element {
  const { t } = useTranslation();

  function notDefinedWarning(): JSX.Element {
    return (
      <>
        <Icon
          icon={{ icon: 'warning', size: 'xsmall' }}
          style={{ fontSize: '0.8rem', marginTop: '-2px', marginRight: '4px', verticalAlign: 'middle', color: 'var(--mdc-theme-error)' }}
          title={t('employee.progress.message-to-labels.not-defined', 'Not defined')}
        />
        {t('employee.progress.message-to-labels.not-defined', 'Not defined')}
      </>
    );
  }

  switch (to) {
    case EmployeeJourneyStepToPerson.Leader:
      if (employee.leader && employee.leader.first_name) {
        return `${employee.leader.social_name || employee.leader.first_name} ${employee.leader.last_name}`;
      } else {
        return notDefinedWarning();
      }

    case EmployeeJourneyStepToPerson.Buddy:
      if (employee.buddy && employee.buddy.first_name) {
        return `${employee.buddy.social_name || employee.buddy.first_name} ${employee.buddy.last_name}`;
      } else {
        return notDefinedWarning();
      }

    case EmployeeJourneyStepToPerson.FixedEmail:
      if (step.to_whom_fixed_email) {
        return transformEmailsToString(step.to_whom_fixed_email);
      }
  }

  return `${employee.social_name || employee.first_name} ${employee.last_name}`;
}

function transformEmailsToString(emails: string): string {
  const formattedEmails = emails.split(';').join(', ');
  return formattedEmails;
}

export function StepJourneyToCell({ step, to, employee }: { step: any; to: EmployeeJourneyStepToPerson | undefined; employee?: Employee }): JSX.Element {
  const description = employee ? employeeStepProgressToName(step, to, employee) : '';
  return (
    <>
      <div>{employeeStepProgressToText(to)}</div>
      {employee ? (
        <Tooltip content={description}>
          <Typography use="caption" tag="div" style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '85px' }}>
            {description}
          </Typography>
        </Tooltip>
      ) : null}
    </>
  );
}

export default EmployeeViewProgress;
