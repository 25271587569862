const parseConvertTimestampToDate = (timestampDate: string) => {
  if (+timestampDate) {
    const unixTimestamp = +timestampDate;
    const milliseconds = unixTimestamp * 1000;
    return new Date(milliseconds);
  }

  return new Date(timestampDate);
};

export const dateTimeFormat = (dateISO: string): string => {
  if (!dateISO) {
    return '';
  }

  const date = parseConvertTimestampToDate(dateISO);
  if (!date || !date.getDate()) {
    return '';
  }

  const time = date.toLocaleTimeString('pt-BR');
  const formattedTime = time.substring(0, time.length - 3); // Remove second: 07:07:17 -> 07:07

  return `${date.toLocaleDateString('pt-BR')} ${formattedTime}`;
};

export const dateFormat = (dateISO: string | undefined): string => {
  if (!dateISO) {
    return '';
  }

  const date = new Date(dateISO);
  if (!date) {
    return '';
  }

  return date.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
};
