import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import LoadingModal from '../LoadingModal';

import { Link } from 'react-router-dom';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';
import ConnectionTable from './ConnectionTable';
import ConnectionService from './ConnectionService';
import ErrorMessage from '../ErrorMessage';
import Message from '../../services/Message';
import Breadcrumb from '../common/Breadcrumb';

function Connection(): JSX.Element {
  const { t } = useTranslation();
  const connectionTranslation = t('connection.model');
  const connectionService = new ConnectionService();

  const { data, isFetching, isError, error } = connectionService.useList();
  const useDeleteConnection = connectionService.useDelete();

  async function onDeleteConnection(values: { id: string; name: string }): Promise<void> {
    if (window.confirm(t('delete.confirm', { name: values.name }))) {
      const deletingMessageId = Message.loading(t('delete.processing', { model: connectionTranslation }));
      await useDeleteConnection.mutateAsync(values.id);
      Message.dismiss(deletingMessageId);
      Message.success(t('connection.deleted'));
    }
  }

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching) {
    return <LoadingModal open={true} />;
  }

  return (
    <Grid>
      <GridCell span={12}>
        <Breadcrumb routeSegments={[{ name: t('navlink.connections') }]} />
      </GridCell>
      <GridCell span={12} style={{ textAlign: 'right' }}>
        <Button raised icon="add" tag={Link} {...{ to: '/engagement/connection/new' }}>
          <Trans i18nKey="connection.create">New Connection</Trans>
        </Button>
      </GridCell>
      <GridCell span={12}>
        <ConnectionTable connections={data} onDelete={onDeleteConnection}></ConnectionTable>
      </GridCell>
    </Grid>
  );
}

export default Connection;
