import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Trans } from 'react-i18next';

import authService from '../../services/AuthService';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { CollapsibleList } from '@rmwc/list';
import '@rmwc/list/styles';

import ErrorStatusPageButton from './ErrorStatusPageButton';

export function ErrorNotAuthorizedMessage({ errorMessage }: InferProps<typeof ErrorNotAuthorizedMessage.propTypes>): JSX.Element {
  console.error('Error not authorizes message = ', errorMessage);

  function handleLogout(): void {
    authService.logout();
  }

  return (
    <Grid>
      <GridCell span={12} align="middle" style={{ textAlign: 'center' }}>
        <h1>
          <Trans i18nKey="error.not-authorized.title">Not authorized</Trans>
        </h1>
        <p>
          <Trans i18nKey="error.not-authorized.text">Your user is not authorized to access the admin platform, if you are a user having issues to login, please contact us.</Trans>
        </p>
        <p>
          <Trans i18nKey="error.not-authorized.portal-text">
            If you are an employee trying to access the app, please access the employee portal app: https://portal.inboarding.co
          </Trans>
        </p>
      </GridCell>
      <GridCell span={12} align="middle" style={{ textAlign: 'center' }}>
        <ErrorStatusPageButton />
        <Button raised onClick={handleLogout}>
          <Trans i18nKey="navlink.logout-auth">Logout and authenticate again</Trans>
        </Button>
      </GridCell>
      <GridCell span={12} align="middle" style={{ textAlign: 'center' }}>
        {errorMessage && (
          <CollapsibleList
            handle={
              <Button>
                <Trans i18nKey="error.details-button">More advanced details</Trans>
              </Button>
            }
          >
            <p style={{ padding: '1rem' }}>
              <Trans i18nKey="error.details">Details:</Trans> {errorMessage}
            </p>
          </CollapsibleList>
        )}
      </GridCell>
    </Grid>
  );
}

ErrorNotAuthorizedMessage.propTypes = {
  errorMessage: PropTypes.string
};

export default ErrorNotAuthorizedMessage;
