import React from 'react';
import { Trans } from 'react-i18next';

import { Link } from 'react-router-dom';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Typography } from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import NotFoundImage from '../Ilustrations/not-found.svg';

function NotFound(): JSX.Element {
  return (
    <>
      <Grid>
        <GridCell span={6} align="middle" style={{ paddingLeft: '40px' }}>
          <h2>
            <Typography use="headline3">
              <Trans i18nKey="not-found.title">Page not found</Trans>
            </Typography>
          </h2>
        </GridCell>
        <GridCell span={6} align="middle">
          <img src={NotFoundImage} alt="Not found" style={{ width: '70%', maxWidth: '400px', padding: '80px' }} />
        </GridCell>
        <GridCell span={12} align="middle" style={{ textAlign: 'center' }}>
          <Button raised tag={Link} {...{ to: '/' }}>
            <Trans i18nKey="navlink.home">Go to homepage</Trans>
          </Button>
        </GridCell>
      </Grid>
    </>
  );
}

export default NotFound;
