import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import styles from './SimpleTable.module.css';

import { DataTable, DataTableCell, DataTableHeadCell, DataTableRow, DataTableBody, DataTableContent, DataTableHead } from '@rmwc/data-table';
import { IconButton } from '@rmwc/icon-button';
import '@rmwc/data-table/styles';
import '@rmwc/icon-button/styles';

import { useTable } from 'react-table';

const mouseOverStyle = {
  cursor: 'pointer'
};

function SimpleTable({ columns, data, onRowClick, onDelete, onEdit, isRowDisabled }: InferProps<typeof SimpleTable.propTypes>): JSX.Element {
  const { t } = useTranslation();

  if (!data) {
    data = [];
  }

  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data
  });

  const rowStyle = (rowClick: boolean, rowDisabled: boolean): React.CSSProperties => {
    const styleClick = rowClick ? mouseOverStyle : {};
    const styleDisabled = rowDisabled ? { filter: 'grayscale(100%) opacity(0.7)' } : {};
    return Object.assign({}, styleClick, styleDisabled);
  };

  /* eslint-disable react/jsx-key */
  return (
    <div className={styles.fullWideWrapTable}>
      <DataTable {...getTableProps()} className={styles.fullWideTable}>
        <DataTableContent className={styles.fullWideTable}>
          <DataTableHead>
            {headerGroups.map((headerGroup) => (
              <DataTableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <DataTableHeadCell {...column.getHeaderProps()}>{column.render('Header')}</DataTableHeadCell>
                ))}
                {typeof onDelete === 'function' || typeof onEdit === 'function' || typeof onRowClick === 'function' ? (
                  <DataTableHeadCell key="actions" alignMiddle>
                    <Trans i18nKey="table.actions">Actions</Trans>
                  </DataTableHeadCell>
                ) : (
                  ''
                )}
              </DataTableRow>
            ))}
          </DataTableHead>
          <DataTableBody>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <DataTableRow
                  {...row.getRowProps()}
                  onClick={(): void => {
                    return onRowClick && onRowClick(row.original);
                  }}
                  style={rowStyle(!!onRowClick, isRowDisabled && isRowDisabled(row.original, row.index))}
                >
                  {row.cells.map((cell) => {
                    return <DataTableCell {...cell.getCellProps()}>{cell.render('Cell')}</DataTableCell>;
                  })}
                  {typeof onDelete === 'function' || typeof onEdit === 'function' || typeof onRowClick === 'function' ? (
                    <DataTableCell key={'actions-' + row.id} alignMiddle>
                      {typeof onEdit === 'function' ? (
                        <IconButton
                          icon="edit"
                          onClick={(event): void => {
                            event.stopPropagation();
                            return onEdit(row.original);
                          }}
                          title={t('table.edit')}
                        />
                      ) : (
                        ''
                      )}
                      {typeof onDelete === 'function' ? (
                        <IconButton
                          icon="delete"
                          onClick={(event): void => {
                            event.stopPropagation();
                            return onDelete(row.original);
                          }}
                          title={t('table.delete')}
                        />
                      ) : (
                        ''
                      )}
                      {typeof onRowClick === 'function' ? (
                        <IconButton
                          icon="arrow_right_alt"
                          onClick={(event): void => {
                            event.stopPropagation();
                            return onRowClick(row.original);
                          }}
                          title={t('table.view')}
                        />
                      ) : (
                        ''
                      )}
                    </DataTableCell>
                  ) : (
                    ''
                  )}
                </DataTableRow>
              );
            })}
          </DataTableBody>
        </DataTableContent>
      </DataTable>
    </div>
  );
}

SimpleTable.propTypes = {
  columns: PropTypes.any.isRequired,
  data: PropTypes.any.isRequired,
  onRowClick: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  isRowDisabled: PropTypes.func
};

export default SimpleTable;
