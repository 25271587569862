import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';

import { SearchFilter } from '../../../services/HttpRepository/SearchFilter';
import HttpBaseService from '../../../services/HttpBaseService';

import { Employee } from '../../Employee/EmployeeRepository';
import { FieldValues } from 'react-hook-form';
import EmployeeSurveyAnswersRepository from './EmployeeSurveyAnswersRepository';

const queryKey = 'analytics/employee/survey';

class EmployeeSurveyAnswersService extends HttpBaseService<Employee> {
  constructor(private employeeSurveyAnswersRepository = new EmployeeSurveyAnswersRepository()) {
    super(queryKey, employeeSurveyAnswersRepository);
  }

  public useListSearch(searchFilter: SearchFilter, dataLimit: number): UseInfiniteQueryResult<Employee[]> {
    return useInfiniteQuery<Employee[]>(
      [queryKey, searchFilter.query],
      async ({ pageParam }) => {
        if (pageParam) {
          searchFilter.lastKey = pageParam;
        }
        return this.employeeSurveyAnswersRepository.listSearch(searchFilter);
      },
      {
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPageData) => {
          const dataLength = lastPageData?.length;
          if (!dataLength || dataLength < dataLimit) {
            return undefined;
          }
          const lastDataItem = lastPageData[dataLength - 1] as unknown as Employee;
          return {
            id: lastDataItem.id,
            has_survey_answers: lastDataItem.has_survey_answers
          };
        }
      }
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  convertValuesToModel(_values: FieldValues): Employee {
    throw new Error('Method not implemented.');
  }
}

export default EmployeeSurveyAnswersService;
