import React from 'react';
import Chart from '../Chart';

import { Trans, useTranslation } from 'react-i18next';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Typography } from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

function EmployeeNPSGenderCorrelationChart(): JSX.Element {
  return (
    <Grid>
      <GridCell span={12}>
        <h2>
          <Typography use="headline5">
            <Trans i18nKey="employee-score-gender-correlation.title">Employee Net Promoter Score (eNPS) by Gender</Trans>
          </Typography>
        </h2>
      </GridCell>
      <GridCell span={12}>
        <EmployeeScoreCorrelationLineChart></EmployeeScoreCorrelationLineChart>
      </GridCell>
    </Grid>
  );
}

function EmployeeScoreCorrelationLineChart(): JSX.Element {
  const { t } = useTranslation();
  const maleName = t('employee-score-gender-correlation.line.male', 'Men');
  const femaleName = t('employee-score-gender-correlation.line.female', 'Woman');
  const textColor = '#303030';
  //   const style = { height: '100px' };
  const style = {};
  const option = {
    grid: {
      top: '10%',
      bottom: '10%',
      left: '5%',
      right: '5%'
    },
    legend: {
      itemGap: 20,
      icon: 'circle',
      textStyle: {
        color: textColor,
        fontSize: 13,
        fontFamily: 'PT Sans'
      }
    },
    tooltip: {},
    xAxis: {
      type: 'category',
      data: ['Set', 'Oct', 'Nov', 'Dec', '2021', 'Fev', 'Mar'],
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        color: textColor,
        fontSize: 14,
        fontFamily: 'PT Sans'
      }
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      splitLine: {
        // show: false
        lineStyle: {
          color: textColor,
          opacity: 0.1
        }
      },
      axisLabel: {
        color: textColor,
        fontSize: 13,
        fontFamily: 'PT Sans'
      }
    },
    series: [
      {
        data: [10, 11, 9, 18, 25, 31, 40.79],
        type: 'line',
        name: maleName,
        symbol: 'rect',
        symbolSize: 7,
        lineStyle: {
          width: 2
        }
      },
      {
        data: [7, 9, 15, 16, 27, 35, 42],
        type: 'line',
        name: femaleName,
        symbol: 'circle',
        symbolSize: 7,
        lineStyle: {
          width: 2
        }
      }
    ],
    colors: []
  };
  return <Chart option={option} style={style} />;
}

export default EmployeeNPSGenderCorrelationChart;
