import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { Switch } from 'rmwc';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function RequiredFieldSwitch({ control, controllerName, block }: { control: Control; controllerName: string; block: any }): JSX.Element {
  return (
    <Controller
      control={control}
      defaultValue={block.required ?? true}
      name={controllerName}
      render={({ onChange, value, name }): React.ReactElement => (
        <>
          <Switch
            id={name}
            name={name}
            checked={value}
            onChange={(evt: { currentTarget: { checked: boolean } }): void => onChange(!!evt.currentTarget.checked)}
            style={{ display: 'flex', alignItems: 'center', paddingTop: '10px' }}
          >
            <div style={{ paddingLeft: '10px' }}>
              <Trans i18nKey="journey-step.form.blocks.form.answer-required">Required field</Trans>
            </div>
          </Switch>
        </>
      )}
    />
  );
}
