import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { ListDivider } from '@rmwc/list';
import { TabBar, Tab } from '@rmwc/tabs';
import '@rmwc/tabs/styles';

import { CompanyConfigurationContext } from '../../common/Contexts/appContexts';
import Breadcrumb from '../../common/Breadcrumb';
import MetabaseAnalytics from '../../MetabaseAnalytics';
import { ReportDashboardType } from './ResportsDashboardsRepository';

function AnalyticsTurnover(): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <Grid>
        <GridCell span={12}>
          <Breadcrumb routeSegments={[{ name: t('navlink.analytics') }, { name: t('navlink.analytic.turnover') }]} />
        </GridCell>
        <GridCell span={12} align="middle">
          <h2>
            <Trans i18nKey="analytics.turnover.title">Turnover</Trans>
          </h2>
        </GridCell>
      </Grid>
      <TabBar>
        <Tab minWidth icon="bar_chart" label={t('navlink.analytic.report-dashboard')} />
      </TabBar>
      <ListDivider />
      <TurnoverAnalytics />
    </>
  );
}

function TurnoverAnalytics(): JSX.Element {
  const companyConfigurationValue = React.useContext(CompanyConfigurationContext);
  const dashboardId = companyConfigurationValue.dashboard_turnover_id;

  if (dashboardId) {
    return <MetabaseAnalytics dashboardName={ReportDashboardType.TURNOVER} showPrintExport={true} />;
  }

  return (
    <Grid>
      <GridCell span={12}>
        <Trans i18nKey="analytics.not-enabled">Dashboard not enabled for your account.</Trans>
      </GridCell>
    </Grid>
  );
}

export default AnalyticsTurnover;
