import { FieldValues } from 'react-hook-form';
import HttpBaseService from '../../../services/HttpBaseService';
import ContentsQuestionsRepository, { ContentsQuestion } from './ContentsQuestionsRepository';

class ContentsQuestionsService extends HttpBaseService<ContentsQuestion> {
  constructor(private questionsRepository = new ContentsQuestionsRepository()) {
    super('contents-questions', questionsRepository);
  }

  convertValuesToModel(values: FieldValues): ContentsQuestion {
    return {
      tags: values.tags,
      title: values.title,
      description: values.description,
      source_url: values.source_url
    } as ContentsQuestion;
  }
}

export default ContentsQuestionsService;
