import React from 'react';
import Chart from '../Chart';

import { Trans } from 'react-i18next';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Typography } from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

function EmployeeNPSCostCenterChart(): JSX.Element {
  return (
    <Grid>
      <GridCell span={12}>
        <h2>
          <Typography use="headline5">
            <Trans i18nKey="employee-cost-center-score.title">Employee by Cost Centers</Trans>
          </Typography>
        </h2>
      </GridCell>
      <GridCell span={5}>
        <HiresCostCenterChart></HiresCostCenterChart>
      </GridCell>
      <GridCell span={7}>
        <EmployeeScoreLineChart></EmployeeScoreLineChart>
      </GridCell>
    </Grid>
  );
}
function HiresCostCenterChart(): JSX.Element {
  const chartTitle = 'Talentos por Centro de Custo';
  const chartSeriesName = 'Centro de Custo';
  const babyBoomersName = 'Marketing';
  const millenialsName = 'Product';
  const genXName = 'Customer';
  const genZName = 'Sales';
  const textColor = '#303030';
  //   const style = { height: '100px' };
  const style = {};
  const option = {
    title: {
      show: true,
      text: chartTitle,
      left: 'center',
      textStyle: {
        color: textColor,
        fontSize: 16,
        fontFamily: 'PT Sans'
      }
    },
    legend: {
      show: true,
      itemGap: 10,
      icon: 'roundRect',
      bottom: 0,
      textStyle: {
        color: textColor,
        fontSize: 13
      }
    },
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c} ({d}%)'
    },
    xAxis: [
      {
        axisLine: {
          show: false
        },
        splitLine: {
          show: false
        }
      }
    ],
    yAxis: [
      {
        axisLine: {
          show: false
        },
        splitLine: {
          show: false
        }
      }
    ],
    series: [
      {
        name: chartSeriesName,
        type: 'pie',
        radius: ['45%', '72.55%'],
        center: ['50%', '50%'],
        avoidLabelOverlap: false, // hoverOffset: 5,
        stillShowZeroSum: false,
        label: {
          normal: {
            show: false,
            position: 'center', // shows the description data to center, turn off to show in right side
            textStyle: {
              color: textColor,
              fontSize: 13,
              fontFamily: 'PT Sans'
            },
            formatter: '{a}'
          },
          emphasis: {
            show: true,
            textStyle: {
              fontSize: 14,
              fontWeight: 'normal'
            },
            formatter: '{b} \n{c} ({d}%)'
          }
        },
        labelLine: {
          normal: {
            show: false
          }
        },
        data: [
          {
            value: 9,
            name: babyBoomersName
          },
          {
            value: 35,
            name: millenialsName
          },
          {
            value: 35,
            name: genXName
          },
          {
            value: 21,
            name: genZName
          }
        ],
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        }
      }
    ],
    colors: []
  };
  return <Chart option={option} style={style} />;
}

function EmployeeScoreLineChart(): JSX.Element {
  const maleName = 'Marketing';
  const femaleName = 'Sales';
  const textColor = '#303030';
  //   const style = { height: '100px' };
  const style = {};
  const option = {
    grid: {
      top: '10%',
      bottom: '10%',
      left: '5%',
      right: '5%'
    },
    legend: {
      itemGap: 20,
      icon: 'circle',
      textStyle: {
        color: textColor,
        fontSize: 13,
        fontFamily: 'PT Sans'
      }
    },
    tooltip: {},
    xAxis: {
      type: 'category',
      data: ['Set', 'Oct', 'Nov', 'Dec', '2021', 'Fev', 'Mar'],
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        color: textColor,
        fontSize: 14,
        fontFamily: 'PT Sans'
      }
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      splitLine: {
        // show: false
        lineStyle: {
          color: textColor,
          opacity: 0.1
        }
      },
      axisLabel: {
        color: textColor,
        fontSize: 13,
        fontFamily: 'PT Sans'
      }
    },
    series: [
      {
        data: [10, 11, 9, 18, 25, 31, 40.79],
        type: 'line',
        name: maleName,
        symbol: 'rect',
        symbolSize: 7,
        lineStyle: {
          width: 2
        }
      },
      {
        data: [7, 9, 15, 16, 27, 35, 42],
        type: 'line',
        name: femaleName,
        symbol: 'circle',
        symbolSize: 7,
        lineStyle: {
          width: 2
        }
      }
    ],
    colors: []
  };
  return <Chart option={option} style={style} />;
}

// function eNPSGaugeChart(): JSX.Element {
//   const textColor = '#303030';
//   //   const style = { height: '100px' };
//   const style = {};
//   const option = {
//     title: {
//       show: true,
//       text: 'eNPS',
//       left: 'center',
//       textStyle: {
//         color: textColor,
//         fontSize: 16,
//         fontFamily: 'PT Sans'
//       }
//     },
//     series: [
//       {
//         name: 'eNPS',
//         type: 'gauge',
//         startAngle: 180,
//         endAngle: 0,
//         min: -100,
//         max: 100,
//         splitNumber: 10,
//         axisLine: {
//           lineStyle: {
//             width: 10,
//             color: [
//               [0.5, '#F16662'],
//               [0.666, '#FBB959'],
//               [0.832, '#3CC474'],
//               [1, '#45B96C']
//             ]
//           }
//         },
//         pointer: {
//           icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
//           length: '12%',
//           width: 20,
//           offsetCenter: [0, '-60%'],
//           itemStyle: {
//             color: 'auto'
//           }
//         },
//         axisTick: {
//           show: false,
//           length: 12,
//           lineStyle: {
//             color: 'auto',
//             width: 2
//           }
//         },
//         splitLine: {
//           show: false,
//           length: 20,
//           lineStyle: {
//             color: 'auto',
//             width: 5
//           }
//         },
//         axisLabel: {
//           color: textColor,
//           fontSize: 14,
//           fontFamily: 'PT Sans',
//           distance: -15,
//           formatter: (value: number): string | undefined => {
//             console.log('value == ', value);
//             if (value === -100) {
//               return '-100';
//             } else if (value === -30) {
//               return '-30';
//             } else if (value === 0) {
//               return '0';
//             } else if (value === 30) {
//               return '30';
//             } else if (value === 70) {
//               return '70';
//             }
//           }
//         },
//         detail: {
//           fontSize: 40,
//           fontFamily: 'PT Sans',
//           offsetCenter: [0, '-10%'],
//           valueAnimation: true,
//           color: 'auto'
//         },
//         data: [30]
//       }
//     ],
//     colors: []
//   };
//   return <Chart option={option} style={style} />;
// }

export default EmployeeNPSCostCenterChart;
