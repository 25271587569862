import { FieldValues } from 'react-hook-form';
import HttpBaseService from '../../services/HttpBaseService';
import EmployeeHistoryRepository, { EmployeeHistory } from './EmployeeHistoryRepository';

class EmployeeHistoryService extends HttpBaseService<EmployeeHistory> {
  constructor(private employeeId: string, private employeeHistoryRepository = new EmployeeHistoryRepository(employeeId)) {
    super(`employee-history/${employeeId}`, employeeHistoryRepository);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  convertValuesToModel(values: FieldValues): EmployeeHistory {
    return {} as EmployeeHistory;
  }
}

export default EmployeeHistoryService;
