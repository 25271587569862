/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import withSizes, { Sizes } from 'react-sizes';
import { Trans } from 'react-i18next';

import { Link, NavLink } from 'react-router-dom';

import LoadingModal from '../LoadingModal';
import LogoImage from '../Brand/LogoImage';
import Header from '../Header';

import { Auth } from 'aws-amplify';

import authService from '../../services/AuthService';
import CompanyService from '../Settings/CompanySettings/CompanyService';
import { CompanyConfigurationContext, UserAuthContext, UserAuthTenantIdContext } from '../common/Contexts/appContexts';

import { ThemeProvider } from '@rmwc/theme';

import { Drawer, DrawerHeader, DrawerTitle, DrawerContent } from '@rmwc/drawer';
import { DrawerAppContent } from '@rmwc/drawer';
import '@material/drawer/dist/mdc.drawer.css';

import { List, SimpleListItem, ListDivider, CollapsibleList } from '@rmwc/list';
import '@material/list/dist/mdc.list.css';
import '@material/ripple/dist/mdc.ripple.css';
import '@rmwc/list/collapsible-list.css';
import '@rmwc/icon/icon.css';

import { IconButton } from '@rmwc/icon-button';
import '@material/icon-button/dist/mdc.icon-button.css';
import { SkipToContent } from './SkipToContent';

function AppCustomLayout({ userAuthTenantId, isMobile, children, contentDivRef }: InferProps<typeof AppCustomLayout.propTypes>): JSX.Element {
  const [sideMenuOpen, setSideMenuOpen] = React.useState(!isMobile);
  const [isLoading, setIsLoading] = React.useState(true);

  const companyService = new CompanyService();
  const companyResult = companyService.useGet(userAuthTenantId, false);
  const isFetching = companyResult.isFetching;

  function handleOnClickMenu(): void {
    setSideMenuOpen(!sideMenuOpen);
  }

  if (isLoading && isFetching === false) {
    setIsLoading(false);
  }

  if (isLoading) {
    return <LoadingModal open={true} />;
  }

  let data = companyResult.data;
  if (!data) {
    data = {};
  }

  const logo = <LogoImage companyName={data?.name} companyLogoUrl={data?.logo_url} />;

  return (
    <CompanyConfigurationContext.Provider value={data}>
      <AppCustomThemeProvider companySettings={data as any}>
        <DrawerSideMenu isMobile={isMobile} sideMenuOpen={sideMenuOpen} setSideMenuOpen={setSideMenuOpen} logo={logo} contentDivRef={contentDivRef} />
        <DrawerAppContent>
          <Header isMobile={isMobile} onClickMenu={handleOnClickMenu} logo={logo} />
          {children}
        </DrawerAppContent>
      </AppCustomThemeProvider>
    </CompanyConfigurationContext.Provider>
  );
}

function AppLayout({ isMobile, children, contentDivRef }: InferProps<typeof AppLayout.propTypes>): JSX.Element {
  const [isFetching, setIsFetching] = React.useState(true);
  const [authUser, setAuthUser] = React.useState({} as any);
  const [tenantId, setTenantId] = React.useState('');

  const loadAuthUser = React.useCallback(async (): Promise<void> => {
    if (authUser && (authUser.email || authUser.name)) {
      return;
    }
    try {
      const currentAuthUser: any = await Auth.currentAuthenticatedUser();
      if (currentAuthUser) {
        authService.setAuthUser(currentAuthUser.attributes);
        const authUserTenantId = authService.tenantId();
        setAuthUser(currentAuthUser.attributes);
        setTenantId(authUserTenantId);
        setIsFetching(false);
      }
    } catch (error) {
      console.log('Error', error);
      setTimeout(() => {
        setIsFetching(false);
      }, 5000);
    }
  }, [authUser]);

  React.useEffect(() => {
    loadAuthUser();
  });

  if (isFetching) {
    return <LoadingModal open={true} />;
  }

  return (
    <UserAuthContext.Provider value={authUser}>
      <UserAuthTenantIdContext.Provider value={tenantId}>
        <AppCustomLayout userAuthTenantId={tenantId} isMobile={isMobile} contentDivRef={contentDivRef}>
          {children}
        </AppCustomLayout>
      </UserAuthTenantIdContext.Provider>
    </UserAuthContext.Provider>
  );
}

function DrawerSideMenu({ isMobile, sideMenuOpen, setSideMenuOpen, logo, contentDivRef }: InferProps<typeof DrawerSideMenu.propTypes>): JSX.Element {
  return (
    <DrawerFromScreenSize isMobile={isMobile} sideMenuOpen={sideMenuOpen} setSideMenuOpen={setSideMenuOpen}>
      <DrawerHeader>
        <SkipToContent contentDivRef={contentDivRef} />
        <DrawerTitle>
          <div style={{ padding: '0 20px', textAlign: 'center' }}>
            <Link to="/">{logo}</Link>
          </div>
        </DrawerTitle>
      </DrawerHeader>
      <DrawerContent>
        <List>
          <SimpleListItem graphic="dashboard" tag={NavLink} {...{ to: '/', exact: true }}>
            <Trans i18nKey="navlink.dashboard">Dashboard</Trans>
          </SimpleListItem>

          <SimpleListItem graphic="auto_graph" tag={NavLink} {...{ to: '/journeys' }}>
            <Trans i18nKey="navlink.journeys">Jorneys</Trans>
          </SimpleListItem>

          <CollapsibleList
            handle={
              <SimpleListItem
                text={<Trans i18nKey="navlink.employees">Employees</Trans>}
                graphic="people"
                metaIcon="chevron_right"
                onClick={(e: React.MouseEvent): void => e.stopPropagation()}
              />
            }
          >
            <SimpleListItem tag={NavLink} {...{ to: '/employees' }} style={{ paddingLeft: '30px' }}>
              <Trans i18nKey="navlink.employees">Employees</Trans>
            </SimpleListItem>
            <SimpleListItem tag={NavLink} {...{ to: '/offboarding/employees' }} style={{ paddingLeft: '30px' }}>
              <Trans i18nKey="navlink.employees-offboarding">Offboarding</Trans>
            </SimpleListItem>
          </CollapsibleList>

          {/* <SimpleListItem graphic="connect_without_contact" tag={NavLink} {...{ to: '/engagement/connection', exact: true }}>
            <Trans i18nKey="navlink.connections">Connections</Trans>
          </SimpleListItem> */}

          <CollapsibleList
            handle={
              <SimpleListItem
                text={<Trans i18nKey="navlink.contents">Contents</Trans>}
                graphic="question_answer"
                metaIcon="chevron_right"
                onClick={(e: React.MouseEvent): void => e.stopPropagation()}
              />
            }
          >
            <SimpleListItem tag={NavLink} {...{ to: '/contents/questions' }} style={{ paddingLeft: '30px' }}>
              <Trans i18nKey="navlink.content.questions">FAQ</Trans>
            </SimpleListItem>
            <SimpleListItem tag={NavLink} {...{ to: '/contents/glossary' }} style={{ paddingLeft: '30px' }}>
              <Trans i18nKey="navlink.content.glossary">Glossary</Trans>
            </SimpleListItem>
            <SimpleListItem tag={NavLink} {...{ to: '/contents/files' }} style={{ paddingLeft: '30px' }}>
              <Trans i18nKey="navlink.content.files">Files</Trans>
            </SimpleListItem>
          </CollapsibleList>

          <CollapsibleList
            handle={
              <SimpleListItem
                text={<Trans i18nKey="navlink.celebrations">Celebrations</Trans>}
                graphic="celebration"
                metaIcon="chevron_right"
                onClick={(e: React.MouseEvent): void => e.stopPropagation()}
              />
            }
          >
            <SimpleListItem tag={NavLink} {...{ to: '/celebrations/birth' }} style={{ paddingLeft: '30px' }}>
              <Trans i18nKey="navlink.celebration.birthdays">Birthdays</Trans>
            </SimpleListItem>
            <SimpleListItem tag={NavLink} {...{ to: '/celebrations/work' }} style={{ paddingLeft: '30px' }}>
              <Trans i18nKey="navlink.celebration.work">Work anniversary</Trans>
            </SimpleListItem>
            <SimpleListItem tag={NavLink} {...{ to: '/celebrations/diversity' }} style={{ paddingLeft: '30px' }}>
              <Trans i18nKey="navlink.celebration.diversity">Diversity</Trans>
            </SimpleListItem>
          </CollapsibleList>

          <SimpleListItem graphic="workspaces" tag={NavLink} {...{ to: '/awareness', exact: true }}>
            <Trans i18nKey="navlink.diversity">Diversity</Trans>
          </SimpleListItem>

          {/* <SimpleListItem graphic="celebration" tag={NavLink} {...{ to: '/engagement/praise', exact: true }}>
            <Trans i18nKey="navlink.praise">Praise</Trans>
          </SimpleListItem> */}

          <SimpleListItem graphic="auto_mode" tag={NavLink} {...{ to: '/automation' }}>
            <Trans i18nKey="navlink.automation">Automation</Trans>
          </SimpleListItem>

          <CollapsibleList
            handle={
              <SimpleListItem
                text={<Trans i18nKey="navlink.analytics">Analytics</Trans>}
                graphic="bar_chart"
                metaIcon="chevron_right"
                onClick={(e: React.MouseEvent): void => e.stopPropagation()}
              />
            }
          >
            <SimpleListItem tag={NavLink} {...{ to: '/analytics/messages-delivery' }} style={{ paddingLeft: '30px' }}>
              <Trans i18nKey="navlink.analytic.messages">Messages</Trans>
            </SimpleListItem>
            <SimpleListItem tag={NavLink} {...{ to: '/analytics/form-answers' }} style={{ paddingLeft: '30px' }}>
              <Trans i18nKey="navlink.analytic.form">Form</Trans>
            </SimpleListItem>
            <SimpleListItem tag={NavLink} {...{ to: '/analytics/survey-answers' }} style={{ paddingLeft: '30px' }}>
              <Trans i18nKey="navlink.analytic.survey">Survey</Trans>
            </SimpleListItem>
            <SimpleListItem tag={NavLink} {...{ to: '/analytics/turnover' }} style={{ paddingLeft: '30px' }}>
              <Trans i18nKey="navlink.analytic.turnover">Turnover</Trans>
            </SimpleListItem>
          </CollapsibleList>

          <ListDivider tabIndex={0} />

          <SimpleListItem graphic="settings" tag={NavLink} {...{ to: '/settings' }}>
            <Trans i18nKey="navlink.settings">Settings</Trans>
          </SimpleListItem>

          <SimpleListItem graphic="help_outline" tag={NavLink} {...{ to: '/support' }}>
            <Trans i18nKey="navlink.help">Help</Trans>
          </SimpleListItem>
        </List>
      </DrawerContent>
    </DrawerFromScreenSize>
  );
}

function DrawerFromScreenSize({ isMobile, sideMenuOpen, setSideMenuOpen, children }: InferProps<typeof DrawerFromScreenSize.propTypes>): JSX.Element {
  function handleDrawerOnClick(): void {
    setSideMenuOpen(!sideMenuOpen);
  }

  if (isMobile) {
    return (
      <Drawer modal open={sideMenuOpen} onClick={handleDrawerOnClick}>
        <IconButton icon="close" style={{ position: 'absolute', right: '0', top: '0' }} onClick={(): void => setSideMenuOpen(false)} />
        {children}
      </Drawer>
    );
  }
  return (
    <Drawer dismissible open={sideMenuOpen} style={{ position: 'fixed' }}>
      {children}
    </Drawer>
  );
}

function AppCustomThemeProvider({ companySettings, children }: InferProps<typeof AppCustomThemeProvider.propTypes>): JSX.Element {
  if (companySettings?.color_primary) {
    const options = {
      primary: companySettings.color_primary
    } as { [key: string]: string };
    if (companySettings.color_secondary) {
      options.secondary = companySettings.color_secondary;
    }
    return <ThemeProvider options={options}>{children}</ThemeProvider>;
  }

  return <>{children}</>;
}

AppLayout.propTypes = {
  isMobile: PropTypes.bool,
  children: PropTypes.node,
  contentDivRef: PropTypes.any
};

AppCustomLayout.propTypes = {
  userAuthTenantId: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
  children: PropTypes.node,
  contentDivRef: PropTypes.any
};

DrawerSideMenu.propTypes = {
  isMobile: PropTypes.bool,
  sideMenuOpen: PropTypes.bool.isRequired,
  setSideMenuOpen: PropTypes.func.isRequired,
  logo: PropTypes.element.isRequired,
  contentDivRef: PropTypes.any
};

DrawerFromScreenSize.propTypes = {
  isMobile: PropTypes.bool,
  sideMenuOpen: PropTypes.bool.isRequired,
  setSideMenuOpen: PropTypes.func.isRequired,
  children: PropTypes.node
};

AppCustomThemeProvider.propTypes = {
  companySettings: PropTypes.shape({
    name: PropTypes.string,
    logo_url: PropTypes.string,
    color_primary: PropTypes.string,
    color_secondary: PropTypes.string
  }).isRequired,
  children: PropTypes.node
};

function mapSizesToProps({ width }: Sizes): InferProps<typeof AppLayout.propTypes> {
  return {
    isMobile: width < 480
  } as InferProps<typeof AppLayout.propTypes>;
}

export default withSizes(mapSizesToProps)(AppLayout);
