import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import WelcomeSection from '../../WelcomeSection';

function Awareness(): JSX.Element {
  const { t } = useTranslation();

  return (
    <WelcomeSection betaFeatureForm={t('awareness.beta-name', 'Diversity and Inclusion')}>
      <Trans i18nKey="awareness.beta">
        We are in beta. If you would like to have early access to the Diversity and Inclusion awareness feature, please contact us on
        <a href="mailto:hello@inboarding.co">hello@inboarding.co</a>.
      </Trans>
    </WelcomeSection>
  );
}

export default Awareness;
