import React from 'react';
import { Trans } from 'react-i18next';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

function ErrorStatusPageButton(): JSX.Element {
  return (
    <Button outlined tag="a" href="http://status.inboarding.co/" target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>
      <Trans i18nKey="error.generic.status-button">Check our status page</Trans>
    </Button>
  );
}

export default ErrorStatusPageButton;
