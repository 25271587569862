import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import EmployeeService from './EmployeeService';
import { SearchFilter } from '../../services/HttpRepository/SearchFilter';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { TextField } from '@rmwc/textfield';
import '@rmwc/textfield/styles';

import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/circular-progress.css';

import { CompanyConfigurationContext } from '../common/Contexts/appContexts';

import LoadingModal from '../LoadingModal';
import EmployeeTable from './EmployeeTable';
import ErrorMessage from '../ErrorMessage';
import Message from '../../services/Message';
import Breadcrumb from '../common/Breadcrumb';
import EllaMessage from '../common/EllaMessage';

const LIST_SIZE = 15;

function Employee({ history }: InferProps<typeof Employee.propTypes>): JSX.Element {
  const { t } = useTranslation();
  const employeeTranslation = t('employee.model');
  const employeeService = new EmployeeService();

  const companyConfigurationValue = React.useContext(CompanyConfigurationContext);
  const importFileType = companyConfigurationValue.import_file;

  const [queryFilter, setQueryFilter] = React.useState('');
  const [searchFilter, setSearchFilter] = React.useState<SearchFilter>({ limit: LIST_SIZE, query: queryFilter });

  const { data, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage, isError, error } = employeeService.useListSearch(searchFilter, LIST_SIZE);
  const useDeleteEmployee = employeeService.useDelete();

  async function onDeleteEmployee(values: { id: string; first_name: string }): Promise<void> {
    if (window.confirm(t('delete.confirm', { name: values.first_name }))) {
      const deletingMessageId = Message.loading(t('delete.processing', { model: employeeTranslation }));
      try {
        await useDeleteEmployee.mutateAsync(values.id);
        Message.dismiss(deletingMessageId);
        Message.success(t('employee.deleted'));
      } catch (error) {
        console.error('Error deleting employee.', error);
        Message.success(t('employee.not-deleted'));
      }
    }
  }

  async function onEditEmployee(values: { id: string }): Promise<void> {
    if (history) history.push(`/employees/${values.id}/edit`);
  }

  async function onClickEmployee(values: { id: string }): Promise<void> {
    if (history) history.push(`/employees/${values.id}`);
  }

  function handleOnSearchInput(event: React.SyntheticEvent<HTMLInputElement>): void {
    const searchValue = event.currentTarget.value;
    setQueryFilter(searchValue);

    if ((event as React.KeyboardEvent<HTMLInputElement>).key === 'Enter' || (!searchValue && !!searchFilter.query)) {
      setSearchFilter({ limit: LIST_SIZE, query: searchValue });
    }
  }

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching && !isFetchingNextPage && !searchFilter.query) {
    return <LoadingModal open={true} />;
  }

  const employeeData = data?.pages.flat();
  const dataLength = employeeData ? employeeData.length : 0;
  const hasDataCreated = !!searchFilter.query || !!dataLength;

  return (
    <Grid>
      <GridCell span={12}>
        <Breadcrumb routeSegments={[{ name: t('navlink.employees') }]} />
      </GridCell>
      <GridCell span={4} align="middle">
        <h2>
          <Trans i18nKey="employee.title">Employees</Trans>
        </h2>
      </GridCell>
      <GridCell span={8} align="middle" style={{ textAlign: 'right' }}>
        {hasDataCreated && (
          <>
            <TextField
              outlined
              type="search"
              icon="search"
              label={t('employee.search.label', 'Search by name')}
              value={queryFilter}
              onChange={handleOnSearchInput}
              onKeyPress={handleOnSearchInput}
              disabled={isFetching}
              style={{ height: '37px' }}
            />
            {importFileType && (
              <Button outlined icon="upload_file" tag={Link} {...{ to: '/import/employees' }} style={{ marginLeft: '15px' }}>
                <Trans i18nKey="employee.import">Import</Trans>
              </Button>
            )}
            <Button raised icon="add" tag={Link} {...{ to: '/employees/new' }} style={{ marginLeft: '15px' }}>
              <Trans i18nKey="employee.create">New Employee</Trans>
            </Button>
          </>
        )}
      </GridCell>
      {hasDataCreated ? (
        <>
          <GridCell span={12}>
            <EmployeeTable employees={employeeData} onRowClick={onClickEmployee} onDelete={onDeleteEmployee} onEdit={onEditEmployee}></EmployeeTable>
            {searchFilter.query && !dataLength && (
              <p>
                {isFetching ? (
                  <CircularProgress theme="secondary" />
                ) : (
                  <>
                    <Trans i18nKey="employee.no-data-search">No data found for filter:</Trans>
                    {` "${queryFilter}"`}
                  </>
                )}
              </p>
            )}
          </GridCell>

          {!queryFilter && hasNextPage && (
            <GridCell span={12} style={{ textAlign: 'center' }}>
              <Button
                outlined
                {...(isFetching ? { icon: <CircularProgress theme="secondary" /> } : {})}
                disabled={isFetching}
                onClick={(): void => {
                  fetchNextPage();
                }}
              >
                <Trans i18nKey="employee.load-more">Load More</Trans>
              </Button>
            </GridCell>
          )}
        </>
      ) : (
        <FirstEmployeeInstruction></FirstEmployeeInstruction>
      )}
    </Grid>
  );
}

function FirstEmployeeInstruction(): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <GridCell span={12} align="middle">
        <EllaMessage title={t('employee.nodata.title')} message={t('employee.nodata.text')} />
      </GridCell>
      <GridCell span={12} style={{ textAlign: 'center' }}>
        <Button outlined tag={Link} {...{ to: '/settings/integrations' }} style={{ marginRight: '15px', marginBottom: '15px' }}>
          <Trans i18nKey="employee.add-integration">Integration with other systems</Trans>
        </Button>
        <Button raised icon="add" tag={Link} {...{ to: '/employees/new' }} style={{ marginBottom: '15px' }}>
          <Trans i18nKey="employee.create">New employee</Trans>
        </Button>
      </GridCell>
    </>
  );
}

Employee.propTypes = {
  history: PropTypes.any
};

export default withRouter(Employee);
