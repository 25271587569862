import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TabBar, Tab } from '@rmwc/tabs';
import '@rmwc/tabs/styles';

import { ListDivider } from '@rmwc/list';
import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import Breadcrumb from '../common/Breadcrumb';
import CompanySettingsEdit from './CompanySettings/CompanySettingsEdit';
import IntegrationSettings from './IntegrationSettings/IntegrationSettings';
import UsersSettingsHome from './UsersSettings/UsersSettingsHome';
import FieldsSettingsHome from './FieldsSettings/FieldsSettingsHome';
import LogsSettingsHome from './LogsSettings/LogsSettingsHome';

enum SETTINGS_TABS {
  COMPANY = 0,
  USERS = 1,
  CUSTOM_FIELDS = 2,
  INTEGRATIONS = 3,
  LOGS = 4
}

function tabIndexFromSettingsType(type: string): number {
  switch (type) {
    case 'company':
      return SETTINGS_TABS.COMPANY;
    case 'users':
      return SETTINGS_TABS.USERS;
    case 'fields':
      return SETTINGS_TABS.CUSTOM_FIELDS;
    case 'integrations':
      return SETTINGS_TABS.INTEGRATIONS;
    case 'logs':
      return SETTINGS_TABS.LOGS;
    default:
      break;
  }
  return 0;
}

function Settings(): JSX.Element {
  const { t } = useTranslation();
  const { type } = useParams() as { type: string };
  const currentActiveTabIndex = tabIndexFromSettingsType(type);
  const [activeTabIndex, setActiveTabIndex] = React.useState<number>(currentActiveTabIndex);

  function tabNameFromIndex(activeTabIndex: number): string {
    switch (activeTabIndex) {
      case SETTINGS_TABS.COMPANY:
        return t('navlink.settings-company');
      case SETTINGS_TABS.USERS:
        return t('navlink.settings-users');
      case SETTINGS_TABS.CUSTOM_FIELDS:
        return t('navlink.settings-fields');
      case SETTINGS_TABS.INTEGRATIONS:
        return t('navlink.settings-integrations');
      case SETTINGS_TABS.LOGS:
        return t('navlink.settings-logs');
      default:
        break;
    }
    return t('navlink.settings-company');
  }

  return (
    <>
      <Grid>
        <GridCell span={12}>
          <Breadcrumb routeSegments={[{ name: t('navlink.settings') }, { name: tabNameFromIndex(activeTabIndex) }]} />
        </GridCell>
      </Grid>
      <TabBar activeTabIndex={activeTabIndex} onActivate={(event): void => setActiveTabIndex(event.detail.index)}>
        <Tab minWidth icon="settings" label={t('navlink.settings-company')} />
        <Tab minWidth icon="people" label={t('navlink.settings-users')} />
        <Tab minWidth icon="smart_button" label={t('navlink.settings-fields')} />
        <Tab minWidth icon="device_hub" label={t('navlink.settings-integrations')} />
        <Tab minWidth icon="checklist" label={t('navlink.settings-logs')} />
      </TabBar>
      <ListDivider />
      <SettingsContent activeTabIndex={activeTabIndex}></SettingsContent>
    </>
  );
}

function SettingsContent({ activeTabIndex }: InferProps<typeof SettingsContent.propTypes>): JSX.Element {
  switch (activeTabIndex) {
    case SETTINGS_TABS.INTEGRATIONS:
      return <IntegrationSettings></IntegrationSettings>;
    case SETTINGS_TABS.USERS:
      return <UsersSettingsHome></UsersSettingsHome>;
    case SETTINGS_TABS.CUSTOM_FIELDS:
      return <FieldsSettingsHome></FieldsSettingsHome>;
    case SETTINGS_TABS.LOGS:
      return <LogsSettingsHome></LogsSettingsHome>;
  }
  return <CompanySettingsEdit></CompanySettingsEdit>;
}

SettingsContent.propTypes = {
  activeTabIndex: PropTypes.number
};

export default Settings;
