import HttpRepository from '../../../services/HttpRepository/HttpRepository';
import { PeopleOriginType } from '../../Employee/EmployeeRepository';

export interface ImportFilenameMetadata {
  s3FileName?: string;
  status?: string;
  total_processed?: number;
}

class ImportEmployeeFileRepository extends HttpRepository<ImportFilenameMetadata> {
  constructor(integrationType: PeopleOriginType) {
    super({ basePath: `api/integrations/${integrationType?.toLocaleLowerCase()}/people-import` });
  }
}

export default ImportEmployeeFileRepository;
