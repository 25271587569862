import React, { RefObject } from 'react';
import styles from './SkipToContent.module.css';
import { Trans } from 'react-i18next';

export const SkipToContent = ({ contentDivRef }: { contentDivRef: RefObject<HTMLDivElement> }): JSX.Element => {
  return (
    <button className={styles.skip} onClick={() => handleClick(contentDivRef)}>
      <Trans i18nKey="navlink.skip-to-content">Skip to content</Trans>
    </button>
  );
};

function handleClick(element: RefObject<HTMLDivElement>) {
  element?.current?.focus();
}
