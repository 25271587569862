export const dateIsBeforeNow = (dateISO: string): boolean => {
  if (!dateISO) {
    return false;
  }

  const date = new Date(dateISO);
  if (!date) {
    return false;
  }

  const nowDate = new Date();

  return date < nowDate;
};

export const dateIsBefore = (startDateISO: string, endDateISO: string): boolean => {
  if (!startDateISO || !endDateISO) {
    return false;
  }

  const startDate = new Date(startDateISO);
  if (!startDate) {
    return false;
  }

  const endDate = new Date(endDateISO);
  if (!endDate) {
    return false;
  }

  return startDate < endDate;
};
